import React from "react";

const CardPerso = ({ id,header,saClass,children,icon,customClickEvent,deleteFunctionArgument,bgBody,bgBodyColor,textColor,saClassBody}) => {
  let cardAttribute={}
  if(id){
    cardAttribute={
      id:id
    }
  }

  return (
    <div {...cardAttribute}  className={(saClass) ? saClass+" col card mb-4" : "col card mb-4"}>
        {(header) &&
            <div className="card-header ">
              {header} 
              {(icon)&& 
                <span 
                  style={{float: 'right', cursor:'pointer'}}
                  onClick={()=>customClickEvent(deleteFunctionArgument)}
                >
                  {icon}
                </span>}
            </div>
        }
        <div className={(saClassBody) ? saClassBody+" card-body" : "card-body"} style={(bgBody==='true') ? {backgroundColor:bgBodyColor, color:textColor} : {backgroundColor:'white'}}>
            {children}
        </div>
    </div>
  );
};

export default CardPerso;
