import { lazy } from "react";

const Pos = lazy(() => import("./Pos"));
const Open = lazy(() => import("./OpenClose/Open"));
const Close = lazy(() => import("./OpenClose/Close"));
const CashOutExternIdent = lazy(() => import("./CashOut/CashOutExternIdent"));
const CashOutExtern = lazy(() => import("./CashOut/CashOutExtern"));
const SearchStock = lazy(() => import("./SearchStock/SearchStock"));
const CashOut = lazy(() => import("./CashOut/CashOut"));
const Consulter = lazy(() => import("./Objectif/Consulter/Consulter"));
const Attribuer = lazy(() => import("./Objectif/Attribuer/Attribuer"));
const BaseArticles = lazy(() => import("./BaseArticle/BaseArticles"));
const ArticleDetailsPos = lazy(() => import("./BaseArticle/Components/ArticleDetailsPos"));
const AutoReassortParentPos = lazy(() => import("./BaseArticle/Components/AutoReassortParentPos"));
const HistoriqueClient = lazy(() => import("./Historique/HistoriqueClient"));
const HistoriqueVente = lazy(() => import("./Historique/HistoriqueVente"));
const HistoriqueCommande = lazy(() => import("./Historique/HistoriqueCommande"));
const ActivityReport = lazy(() => import("./ActivityReport/ActivityReport"));
const PeripheriquePos = lazy(() => import("./Peripherique/PeripheriquePos"));
const TransferOrderPos = lazy(() => import("./TransferOrder/TransferOrderPos"));
const TransferOrderDetailPos = lazy(() => import("./TransferOrder/TransferOrderDetailPos"));

/**********!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!***************** */
/*     Toutes les routes POS doivent commencer par pos/:posid            */
/*     A part la route d'ouverture et de cloture de caisse               */
/**********!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!***************** */
const posBaseRoutes = [
  {
    path: "/openpos/:posid",
    component: Open
  },
  {
    path: "/cloturepos/:posid",
    component: Close
  },
  {
    exact:true,
    path: "/cashout/:posid",
    component: CashOutExternIdent
  },
  {
    exact:true,
    path: "/cashout/:posid/:caisse_ident_id",
    component: CashOutExtern
  },
  {
    exact:true,
    path: "/pos/:posid",
    component: Pos
  },
  {
    path: "/pos/:posid/searchstock/:reference?",
    component: SearchStock
  },
  {
    path: "/pos/:posid/cashout",
    component: CashOut
  },
  {
    path: "/pos/:posid/objectif",
    component: Consulter
  },
  {
    path: "/pos/:posid/rhobjectif",
    component: Attribuer
  },
  {
    exact:true,
    path: "/pos/:posid/item-base", 
    component: BaseArticles
  },
  {
    path: "/pos/:posid/item-base/details/:id",
    component: ArticleDetailsPos
  },
  {
    path: "/pos/:posid/automatic-restock/parent-item/:id",
    component: AutoReassortParentPos
  },
  {
    path: "/pos/:posid/sales-historical",
    component: HistoriqueVente
  },
  {
    path: "/pos/:posid/customer-historical",
    component: HistoriqueClient
  },
  {
    path: "/pos/:posid/sales-order-historical",
    component: HistoriqueCommande
  },
  {
    path: "/pos/:posid/activity-report",
    component: ActivityReport
  },
  {
    path: "/pos/:posid/peripherals",
    component: PeripheriquePos
  },
  {
    exact:true,
    path: "/pos/:posid/transfer-order",
    component: TransferOrderPos
  },
  {
    exact:true,
    path: "/pos/:posid/transfer-order-detail/:toId",
    component: TransferOrderDetailPos
  },
];
  
  export default posBaseRoutes;