import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';

const  RedirectBinsBtn = (props) => {
    const t = useTranslate();
    const history = useHistory();

    const handleEdit = (path) => {
        history.push(path);
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    Bins
                </Tooltip>
            }
        >
            <span onClick={() => handleEdit(props.path)} style={{cursor:'Pointer', color:'#DEB887'}}><FontAwesomeIcon icon={faBox} /></span>
        </OverlayTrigger>  
    )
}

export default RedirectBinsBtn;