import React, {useState,} from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { CardPerso,ProfilActionButton,EditModalActionButton,CustomToggleList} from "@gull";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Modal, Button } from "react-bootstrap";
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons';

import { useCookies} from 'react-cookie';

const PurchaseOrdersTab = (props) => {
    const t = useTranslate();
    let { SearchBar } = Search;
    const [cookies, setCookie] = useCookies(['purchase_order_List']);

    //Pour le cookie
    let current_date = new Date()
    let timestampdate = current_date.setDate(current_date.getDate() + 90)
    let expire_date = new Date(timestampdate)

    let cookieLecture = ['name','status','created_at','updated_at','memo','referencePo','fournisseur','lines_number','action'];
    if(cookies.purchase_order_Lists){
        let columnShowVar = cookies.purchase_order_Lists;
        cookieLecture = columnShowVar.split(',')
    }
    const [columnShow,setColumnShow] = useState(cookieLecture)

    var maxCarac = 15;

    let purchaseOrdersList = new Array
    props.purchaseOrders.map((value,ind) => {
        let obj = {
            id: value.id,
            name: value.name,
            memo: (value.memo.length>maxCarac) ? value.memo.replace( /(<([^>]+)>)/ig, '').substring(0,maxCarac)+' ...' : value.memo.replace( /(<([^>]+)>)/ig, ''), 
            status: (value.status == 0) ? t('notDelivered') : (value.status == 1) ? t('enCours') : (value.status == 2) ? t('completed') : false,
            created_at: <Moment format="DD/MM/YYYY HH:mm:ss">{value.created_at}</Moment>,
            updated_at: <Moment format="DD/MM/YYYY HH:mm:ss">{value.updated_at}</Moment>,
            user_name: value.user.name,
            action: <><ProfilActionButton path={'/erp/purchase-order/details/'+value.id} openWindow={true}/> <>&nbsp;</> <EditModalActionButton setShowEdit={props.setShow} hisId={value.id} setModalEditId={props.setModalEditId}/> </>,
            memo_total: value.memo,
            referencePo:value.reference,
            fournisseur:(value.fournisseur != null) ? value.fournisseur.name : '',
            lines_number:value.count,
            index: ind + 1,
        }
        props.customFieldsCol.forEach((item,index)=>{
            if(value.custom_fields){
                obj[item.name]=value.custom_fields[item.name]
            }
        })
        purchaseOrdersList.push(obj)
    })


    let defaultSorted = [
        {
          dataField: "name",
          order: "asc"
        }
    ];

    const rowStyle = (row, rowIndex) => {
        const style = {};

        if (row.status == t('notDelivered')) {
          style.backgroundColor = '#fccfcf';

        }else if(row.status == t('enCours')){
          style.backgroundColor = '#ffffbb';

        }else{
            style.backgroundColor = '#d9fcd2';
        }
       
        return style;
    };

    let columns = [
        {
            dataField: "name",
            text: t('tableName'),
            editable:false,
            hidden: (columnShow.includes("name")) ? false : true,
        },
        {
            dataField: "status",
            text: t('state'),
            editable:false,
            hidden: (columnShow.includes("status")) ? false : true
        },
        {
            dataField: "created_at",
            text: t('createdAt'),
            editable:false,
            hidden: (columnShow.includes("created_at")) ? false : true,
        },
        {
            dataField: "updated_at",
            text: t('lastUpdate'),
            editable: false,
            hidden: (columnShow.includes("updated_at")) ? false : true,
        },
        {
            dataField: "memo",
            text: "Memo",
            editable: false,
            hidden: (columnShow.includes("memo")) ? false : true,
        },
        {
            dataField: "referencePo",
            text: t("referencePo"),
            editable: false,
            hidden: (columnShow.includes("referencePo")) ? false : true,
        },
        {
            dataField: "fournisseur",
            text: t("fournisseur"),
            editable: false,
            hidden: (columnShow.includes("fournisseur")) ? false : true,
        },
    ];

    props.customFieldsCol.map(value=>columns.push({
        dataField: value.name,
        text:  value.name,
        editable: false,
        hidden: (columnShow.includes(value.name)) ? false : true
    }))

    columns.push(
        {
            dataField: "user_name",
            text: t('createBy'),
            editable: false,
            hidden: (columnShow.includes("user_name")) ? false : true,
        },
        {
            dataField: "lines_number",
            text: t('linesNumber'),
            editable: false,
            hidden: (columnShow.includes("lines_number")) ? false : true,
        },
        {
            dataField: "action",
            text: "Actions",
            editable: false,
            hidden: (columnShow.includes("action")) ? false : true,
        },
    )

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        showTotal: true,
        totalSize: purchaseOrdersList.length,
    };

    const expandRow = {
        className: 'bg-expandTable',
        renderer: row => (
          <div>
            <p dangerouslySetInnerHTML={{ __html: row.memo_total }}></p>
          </div>
        ),
        
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            if (isAnyExpands) {
              return <b style={{cursor:'pointer'}}><FontAwesomeIcon icon={faMinusCircle} /></b>;
            }
            return <b style={{cursor:'pointer'}}><FontAwesomeIcon icon={faPlusCircle} /></b>;
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (
                    <b style={{cursor:'pointer'}}><FontAwesomeIcon icon={faMinusCircle} /></b>
                );
                }
                return (
                <b style={{cursor:'pointer'}}><FontAwesomeIcon icon={faPlusCircle} /></b>
            );
        }
    };

    const handleToggleColumns = (column) => {
        if(column.toggle){
            var newColumnShow = columnShow.filter(value=>value!=column.dataField)
            setColumnShow(newColumnShow)
        }
        else{
            var newColumnShow = [...columnShow,column.dataField]
            setColumnShow(newColumnShow)
        }
        setCookie("purchase_order_Lists",newColumnShow.join(),{ path: "/", expires: expire_date }) 
    }

    return (<>
        <div className="row">
            <div className='col-3 mb-3'>
                <div className="form-group">
                    <select className="form-control" onChange={(e) => props.onChange(e)}>
                        <option value={props.valueAll}>{t('allPo')+' '+'('+t('exceptReceivedVoucher')+')'}</option>
                        <option value="/0">{t('notDelivered')+' '+'('+t('only').toLowerCase()+')'}</option>
                        <option value="/1">{t('enCours')+' '+'('+t('only').toLowerCase()+')'}</option>
                        <option value="/2">{t('completed')+' '+'('+t('only').toLowerCase()+')'}</option>
                        <option value="/all">{t('allPo')}</option>
                    </select>
                </div>
            </div>
        </div>
        <CardPerso header={t('purchaseOrders')}>
            {props.loading ?
                <div className='d-flex justify-content-center'>
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-lg"></div></span>
                </div>
            :
                <small>
                    <ToolkitProvider
                    striped
                    keyField="index"
                    data={purchaseOrdersList}
                    columns={columns}
                    search
                    columnToggle
                    >
                    {(props) => (
                        <>
                            <CustomToggleList { ...props.columnToggleProps }  executWhenToggle={handleToggleColumns}  />
                            <div className="d-flex justify-content-end align-items-center">
                                <span className="mb-2 me-1">{t('tableSearch')}:</span>
                                <SearchBar {...props.searchProps} className="mb-2" />
                            </div>
                            <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            pagination={paginationFactory(paginationOptions)}
                            noDataIndication={t('tableNoData')}
                            defaultSorted={defaultSorted}
                            expandRow={ expandRow }
                            cellEdit={cellEditFactory({ 
                                mode: "dbclick",
                                blurToSave: true
                                })}
                            rowStyle={ rowStyle }
                            />
                        </>
                    )}
                    </ToolkitProvider>
                </small>
            }
        </CardPerso>
    </>)
}

export default PurchaseOrdersTab;