import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert2";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive } from '@fortawesome/free-solid-svg-icons';

const  ArchiveActionButton = (props) => {
    const t = useTranslate();

    return (
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip>
                        {t(props.nameType)}
                    </Tooltip>
                }
            >
                <span 
                    className={props.btnColor}
                    style={{cursor:'pointer'}} 
                    onClick={() => {
                        swal
                        .fire({
                            title: t(props.actionType)+" "+props.toArchive+"?",
                            icon: "warning",
                            type: "question",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: t('btnYes'),
                            cancelButtonText: t('btnNo'),
                        })
                        .then((result) => {
                            if (result.value) {
                                props.customClickEvent(props.archiveFunctionArgument)
                            } else {
                                swal.fire(t('canceled'), "", "error");
                            }
                        });
                    }}
                >
                    <FontAwesomeIcon icon={faArchive} />
                </span>
            </OverlayTrigger>  
    )
}

export default ArchiveActionButton;