import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet } from '@fortawesome/free-solid-svg-icons';

const  ChangePaymentMethodBtn = (props) => {
    const t = useTranslate();

    const handleClick = () => {
        props.setShow(true);
        props.setBarTicket(props.ticketId)
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    {t('editPaymentMethod')}
                </Tooltip>
            }
        >
            <span onClick={() => handleClick()} style={{cursor:'Pointer', color:'	#1E90FF'}}><FontAwesomeIcon icon={faWallet} /></span>
        </OverlayTrigger>  
    )
}

export default ChangePaymentMethodBtn;