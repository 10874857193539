import React from "react";
import { useTranslate } from 'react-redux-multilingual';

const  RedirectSwitchBtn = (props) => {
    const t = useTranslate();

    const urlDetail = (props.urlDetails!=null) ? props.urlDetails.split(",") : false;

    let categoryName = {
        po: t('purchaseOrder').toLowerCase(),
        shipment: t('shipmentVoucher').toLowerCase(),
        to: t('transferOrder').toLowerCase(),
        ticket: t('ticket').toLowerCase(),
        commande: t('salesOrder').toLowerCase(),
    }

    const handleEdit = () => {
        switch (urlDetail[0]){
            case 'po':
                window.open('/erp/purchase-order/details/'+urlDetail[1]);
            case 'shipment':
                window.open('/erp/shipment/details/'+urlDetail[1]);
            case 'to':
                window.open('/erp/transfer-order/details/'+urlDetail[1]);
            case 'ticket':
                window.open('/erp/caisse/ticket/'+urlDetail[1]);
            break;
            case 'commande':
                window.open('/erp/caisse/sales-order/'+urlDetail[1]);
            break;
        }
    }

    return ((urlDetail) &&
        <div>
            <span onClick={() => handleEdit()} style={{cursor:'Pointer',textDecoration: 'underline', color:'#0000FF'}}>{t('voirLe')+' '+categoryName[urlDetail[0]]}</span>
        </div>
    )
}

export default RedirectSwitchBtn;