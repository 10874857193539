import React, {useState} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import Moment from 'react-moment';

const ModalContainOrderDetails = (props) => {
    const t = useTranslate();

    return(<>
        <div className="table-responsive">
            <table
            id="section_stock_table"
            className="table"
            style={{fontSize:'10px'}}
            >
                <thead>
                    <tr>
                        <th scope="col">{t('po')}</th>
                        <th scope="col">{t('orderDate')}</th>
                        <th scope="col">{t('expectedDeliveryDate')}</th>
                        <th scope="col">{t('expectedReceiptDate')}</th>
                        <th scope="col" style={{textAlign:'right'}}>{t('quantiteWait')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.inSection.map(value=>
                        <tr>
                            <td>{value.po}</td>
                            <td><Moment format="DD/MM/YYYY">{value.commande}</Moment></td>
                            <td><Moment format="DD/MM/YYYY">{value.expedition}</Moment></td>
                            <td><Moment format="DD/MM/YYYY">{value.expedition}</Moment></td>
                            <td style={{textAlign:'right'}}>{value.quota}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    </>);
}

export default ModalContainOrderDetails;