import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const  ProfilActionButton = (props) => {
    const t = useTranslate();
    const history = useHistory();

    const handleEdit = (path) => {
        if(props.openWindow){
            window.open(path);
        }
        else{
            history.push(path);
        }
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    {t('btnSeeProfil')}
                </Tooltip>
            }
        >
            <span onClick={() => handleEdit(props.path)} style={{cursor:'Pointer', color:'#FF8919'}}><FontAwesomeIcon icon={faEye} /></span>
        </OverlayTrigger>  
    )
}

export default ProfilActionButton;