import React, {useState,useRef,useEffect,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { InpDataList,FormatedPrice,ViewPdfButton, CardPerso,ModalChangePaymentMethod,ChangePaymentMethodBtn,GiftPrintButton,PrintTicketButton} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { useParams } from "react-router-dom";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Moment from 'react-moment';
import Ticket from "../../../app/views/Pdf/Ticket"
import { BlobProvider } from '@react-pdf/renderer';
import { Modal } from "react-bootstrap";
import { IsAuthContext,PeripheriqueContext } from '../../../app/App';
import { epson_tm_h6000V } from 'app/imprime';
import { modePaiementMustBeTranslate } from 'app/modePaiementDefaultAll';
import { espace_mille } from 'app/espace_mille';
import { conditionsRetour } from 'app/conditionsRetour';

const useSearchClients = () => {
    const t =useTranslate()
    const [loadingSearchClient,setLoadingSearchClient]=useState(false)
    const [listSearchClient,setListSearchClient]=useState([])
    const [datas,setDatas]=useState([])
    const searchInputClient = useRef('')

    const loadClients = async (toSearch) => {
        setLoadingSearchClient(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/name/"+toSearch
        ).then((response) => {
            setListSearchClient(response.data.datas)
            let initial = []
            response.data.datas.forEach((value)=>
                initial.push({
                    id: value.id,
                    name: value.name+' '+value.firstname+' - '+value.email+' - '+value.tel
                })
            )
            setListSearchClient(initial)
            let ajour = initial.filter((value)=>value.name.toLowerCase().includes(searchInputClient.current.value.toLowerCase()))
            setDatas(ajour)
            setLoadingSearchClient(false)
        }).catch((error) => {
            setLoadingSearchClient(false)
            swal.fire(t('unknowError'), "", "error");
        })
    }

    return [loadingSearchClient,loadClients,listSearchClient,datas,setDatas,searchInputClient]
}

const SearchHistoriqueTicket = (props) => {
    const t =useTranslate()
    const [loadingSearchClient,loadClients,listSearchClient,datas,setDatas,searchInputClient]=useSearchClients()
    const [valueInputClient,setValueInputClient]=useState('')
    const [allTickets,setAllTickets]=useState({})
    const [load,setLoad]=useState({
        allTicket:false,
    })
    const dataListDropClient = useRef('')
    const { posid } = useParams();
    const [clientId,setClientId]=useState(false)
    const [perPage,setPerPage] = useState('10')
    const [currentPage,setCurrentPage] = useState(1)
    const [sizeDatas,setSizeDatas]=useState(0)

    const [relance,setRelance]=useState(Date.now())
    const [ticketRef,setTicketRef]=useState(false)
    const [ticketPdf,setTicketPdf]=useState({})
    const [raisonPrint,setRaisonPrint]=useState(false)
    const [totalAchat,setTotalAchat]=useState(0)
    const [totalTgc,setTotalTgc]=useState(false)
    const [loadTicket,setLoadTicket]=useState(false)
    const btnPdf = useRef('')

    const [show,setShow]=useState(false)
    const [barTicket,setBarTicket]=useState(false)
    const { authParam } = useContext(IsAuthContext)
    const { peripheriqueParam } = useContext(PeripheriqueContext)  
    const [giftPdf,setGiftPdf]=useState(false)

    let actualShop = authParam.sections.find(shop=>shop.id == posid)

    useEffect(function () {
        (async function() {
            if(ticketRef){
                setLoadTicket(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/ticket/get_by_reference/"+ticketRef
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }
                    else{ 
                        setTicketPdf(response.data.data)
                        
                        var total = 0
                        let totaltgc = {
                            0:0,
                            3:0,
                            6:0,
                            11:0,
                            22:0
                        }
                        response.data.data.ticket_detail.map(item=>{
                            total+=item.prix_remise
                            if(item.article_retour!=null){
                                totaltgc={...totaltgc,[item.article_retour.tgc]:totaltgc[item.article_retour.tgc]+=item.prix_remise}
                            }
                            if(item.article!=null){
                                totaltgc={...totaltgc,[item.article.tgc]:totaltgc[item.article.tgc]+=item.prix_remise}
                            }
                        })
                        setTotalAchat(total)
                        setTotalTgc(totaltgc)
                        setLoadTicket(false)

                        if(raisonPrint=='giftprint'){
                            epson_tm_h6000V('giftprint',response.data.data.section_id,response.data.data,t,props.devise,peripheriqueParam,'historiqueVente')
                            
                        }else if(raisonPrint=='print'){
                            epson_tm_h6000V('print',response.data.data.section_id,response.data.data,t,props.devise,peripheriqueParam,'historiqueVente')
                        }
                    }
                }).catch((error) => {
                    swal.fire(t('unknowError'), "", "error");
                })
                setLoadTicket(false)

                if(raisonPrint=='pdf'){
                    setTimeout(()=>{
                        btnPdf.current.click()
                    },1000)
                }
            }
        })()
    }, [ticketRef,relance])    
    
    let tgc3 = +totalTgc[3] - (Math.round(+totalTgc[3] / 1.03))
    let tgc6 = +totalTgc[6]- (Math.round(+totalTgc[6] / 1.06))
    let tgc11 = +totalTgc[11] - (Math.round(+totalTgc[11] / 1.11))
    let tgc22 = +totalTgc[22] - (Math.round(+totalTgc[22] / 1.22))
    let tgcTotal = tgc3 + tgc6 + tgc11 + tgc22

    let shopId = posid ? '/'+posid : ''
    let currentShop = (Object.keys(ticketPdf).length!=0) ? props.bbgShops.find(value=>value.id==ticketPdf.section_id) : false

    const handleSearch = (e,declenchement) => {
        setValueInputClient(e.target.value)
        if(e.target.value.length>=declenchement){
            dataListDropClient.current.classList.remove("d-none");
        }
        else{
            dataListDropClient.current.classList.add("d-none");
        }
        if(e.target.value.length==declenchement){
            loadClients(e.target.value)
        }
        else{
            let ajour = listSearchClient.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }
    }

    const handleCLick = (id,valeur) => {
        setValueInputClient(valeur)
        setClientId(id)
        dataListDropClient.current.classList.add("d-none");
        setDatas([]) 
        loadAllTickets(id)
    }

    const loadAllTickets = async (clientId) =>{
        setLoad({...load,allTicket:true})
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/get_histo_ticket/"+clientId+'/'+currentPage+"/"+perPage+shopId
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                setAllTickets(response.data.datas)
                setSizeDatas(response.data.count)
                setLoad({...load,allTicket:false})
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad({...load,allTicket:false})
    }


    let paginationOptions = {
        page:currentPage,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        showTotal: true,
        totalSize: sizeDatas
    };

    let dataTable = new Array
    Object.keys(allTickets).length!=0 && Object.entries(allTickets).map(([keyName,value],ind)=>{
        let modeList =''
        value.paiement_records.length!=0 && value.paiement_records.map((item,index)=>{
            let modeName = modePaiementMustBeTranslate.includes(item.mode) ? t(item.mode) : item.mode
            if(index==0){
                modeList += modeName+': '+espace_mille(item.montant)+props.devise
            }else{
                modeList += ' / '+modeName+': '+espace_mille(item.montant)+props.devise
            }
            
        })
        let obj = {
            ticket: value.bar,
            amount: <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.montant} />,
            lines: value.lines,
            nb_articles: value.nbr_articles,
            date: <Moment format="DD/MM/YYYY HH:mm:ss">{value.created_at}</Moment>,
            mode_pay: modeList,
            id: value.id,
            action: 
                <>
                    <PrintTicketButton setRelance={setRelance} setRaisonPrint={setRaisonPrint} setTicketRef={setTicketRef} ticketRef={value.bar}/>&nbsp;&nbsp;&nbsp;&nbsp;
                    <GiftPrintButton setRelance={setRelance} setRaisonPrint={setRaisonPrint} setTicketRef={setTicketRef} ticketRef={value.bar}/>&nbsp;&nbsp;&nbsp;&nbsp; 
                    <ViewPdfButton setRelance={setRelance} setRaisonPrint={setRaisonPrint} setTicketRef={setTicketRef} ticketRef={value.bar} setGiftPdf={setGiftPdf}/>&nbsp;&nbsp;&nbsp;&nbsp;
                    <ChangePaymentMethodBtn setShow={setShow} ticketId={value.bar} setBarTicket={setBarTicket}/>
                </>,
            index: ind + 1,
        }
        dataTable.push(obj)
    })

    let defaultSorted = [
        {
          dataField: "date",
          order: "desc"
        }
    ];

    let columns = [
        {
            dataField: "ticket",
            text: t('ticket')+' n°',
            editable: false,
        },
        {
            dataField: "amount",
            text: t('amount'),
            editable: false,
        },
        {
            dataField: "lines",
            text: t('linesNumber'),
            editable: false,
        },
        {
            dataField: "nb_articles",
            text: t('itemsNumber'),
            editable: false,
        },
        {
            dataField: "mode_pay",
            text: t('paymentDetail'),
            editable: false,
        },
        {
            dataField: "date",
            text: 'Date',
            editable: false,
        },
        {
            dataField: "action",
            text: 'Action',
            editable: false,
        },
    ];

    const handleTableChange = async (type, { page, sizePerPage }) => {
        setPerPage(sizePerPage)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/get_histo_ticket/"+clientId+'/'+page+"/"+sizePerPage+shopId,
        ).then((response) => {
            setAllTickets(response.data.datas)
            setSizeDatas(response.data.count)
            setCurrentPage(page)
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
    }

    return(<>
        <Modal size="md" show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('editPaymentMethod')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ModalChangePaymentMethod 
                    barTicket={barTicket}
                    currentShop={actualShop}
                    devise={props.devise}
                    digit={props.digit}
                    posid={posid}
                    dataTicket={allTickets}
                    setDataTicket={setAllTickets}
                    from='pos'
                    module='pos'
                />
            </Modal.Body>
        </Modal>
        <BlobProvider 
            document={(Object.keys(ticketPdf).length!=0 && tgcTotal!=NaN && currentShop) ?
                <Ticket 
                    currentShop={currentShop}
                    ticketPdf={ticketPdf}
                    t={t}
                    devise={props.devise}
                    digit={props.digit}
                    totalAchat={totalAchat}
                    tgc3={tgc3}
                    tgc6={tgc6}
                    tgc11={tgc11}
                    tgc22={tgc22}
                    tgcTotal={tgcTotal}
                    conditionsRetour={conditionsRetour}
                    giftPdf={giftPdf}
                />
            : 
                false
            }
        >
            {({ url }) => (
                <a ref={btnPdf} className="d-none" href={url} target="_blank">Print</a>
            )}
        </BlobProvider>
        <div className="row d-flex justify-content-center mt-4 mb-4 position-relative">
            <div className="col-8">
                <InpDataList
                    placeholder={t('customerPlacehlder')}
                    value={valueInputClient}
                    onClick={handleCLick}
                    onChange={handleSearch}
                    datas={datas}
                    declenchement={4}
                    listRef={dataListDropClient}
                    inputRef={searchInputClient}
                />
            </div>
            {(loadingSearchClient) ? <span><div className="spinner-border spinner-border-sm historiqueGiftCardPosition"></div></span> : false }
        </div>
        <CardPerso saClass='position-relative'>
            {(loadTicket) ? 
                <div className="loaderPdfHistorique">
                    <span>{(raisonPrint=='pdf') ? t('downloadingPdf') : t('printingInProgress')}</span>&nbsp;
                    <span>
                        <span className="spinner-grow spinner-grow-sm text-dark sizeDotLoad" role="status"></span> 
                        <span className="spinner-grow spinner-grow-sm text-dark sizeDotLoad" role="status"></span> 
                        <span className="spinner-grow spinner-grow-sm text-dark sizeDotLoad" role="status"></span> 
                    </span>
                </div>
            : 
                false
            }
            <small>
                <ToolkitProvider
                    keyField="index"
                    data={dataTable}
                    columns={ columns }
                >
                    {(propst) => (
                        <BootstrapTable
                            {...propst.baseProps}
                            bootstrap4
                            remote
                            pagination={ paginationFactory(paginationOptions) }
                            onTableChange={ handleTableChange }
                            noDataIndication={load.allTicket ? <div className="spinner-border spinner-border-sm"></div> : t('tableNoData')}
                            defaultSorted={defaultSorted}
                        />
                    )}
                </ToolkitProvider>
            </small>
        </CardPerso>
        </>
    )
    
}

export default SearchHistoriqueTicket;