import React, {useState, useEffect} from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslate } from 'react-redux-multilingual'
import { Loading, ArticleModalEdit, FicheArticleCustomisation} from "@gull";
import { Modal,Button } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import swal from "sweetalert2";
import RecordTable from './RecordTable.jsx';
import Stock from './Stock.jsx';
import ReassortAuto from './ReassortAuto.jsx';
import DetailPromo from './ArticleDetailComp/DetailPromo';
import CustomFieldsDetails from './ArticleDetailComp/CustomFieldsDetails';
import DetailArticle from './ArticleDetailComp/DetailArticle';
import DetailPrice from './ArticleDetailComp/DetailPrice';
import Header from './ArticleDetailComp/Header';
import PromotionDetailsTab from './ArticleDetailComp/PromotionDetailsTab';

const useLoadArticle = (id) => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [article,setArticle]=useState(false)
    const [customField, setCustomField] =useState(false)
    const [customInterface, setCustomInterface]= useState({})
    const [repere,setRepere] = useState(Date.now())
    const [readjustReload,setReadjustReload]=useState(Date.now())
    const [fields,setFields]=useState([])
    const [articleRecord, setArticleRecord] = useState([])
    const [currentPromo, setCurrentPromo] = useState([])
    const [incomingPromo, setIncomingPromo] = useState([])
    const [sizeDatas,setSizeDatas]=useState(0)
    //pour le stock
    const [loadStock,setLoadStock]=useState(true);
    const [stock,setStock]=useState({
        total:0,
        by_section:{},
        sum_toarrive:0
    });
    //pour le RPPSL
    const [rppsl,setRppsl]=useState([])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/one/"+id
            ).then((response) => {
                setLoading(false)
                setArticle(response.data.data)
                setCustomField(response.data.custom_fields)
                setCustomInterface(response.data.custom_interface)
                setRppsl(response.data.data.rppsl)
                setCurrentPromo(response.data.data.current_promotion)
                setIncomingPromo(response.data.data.all_current_and_incoming_promotion)
            }).catch((error) => {
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [id,repere])
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/custom_fields/all/article"
            ).then((response) => {
                setFields(response.data.datas.map(value => value.name))
            }).catch((error) => {
            })
        })()
    }, [])
    useEffect(function () {
        (async function() {
            const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/articlerecords/"+id+"/10/1"
            ).then((response) => {
                setArticleRecord(response.data.datas)
                setSizeDatas(response.data.count)
            }).catch((error) => {
            })
        })()
    }, [readjustReload])
    //stock
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/large_stock/"+id
            ).then((response) => {
                setLoadStock(false)
                setStock({
                    total:response.data.total,
                    by_section:response.data.by_section,
                    sum_toarrive:response.data.sum_toarrive
                });
            }).catch((error) => {
            })
        })()
    }, [readjustReload])


    return [
        loading,
        article,
        setArticle,
        customField,
        customInterface,
        setRepere,
        fields,
        articleRecord,
        setArticleRecord,
        sizeDatas,
        setSizeDatas,
        loadStock,
        stock,
        rppsl,
        setRppsl,
        setReadjustReload,
        currentPromo,
        setCurrentPromo,
        incomingPromo,
        setIncomingPromo
    ]
}

const ArticleDetail = () => {
    const { id } = useParams();
    const [
        loading,
        article,
        setArticle,
        customField,
        customInterface,
        setRepere,
        fields,
        articleRecord,
        setArticleRecord,
        sizeDatas,
        setSizeDatas,
        loadStock,
        stock,
        rppsl,
        setRppsl,
        setReadjustReload,
        currentPromo,
        setCurrentPromo,
        incomingPromo,
        setIncomingPromo
    ] = useLoadArticle(id);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [show, setShow] = useState(false)
    const [modalEditId,setModalEditId] = useState(false)
    const t = useTranslate();

    if(loading){
        return (<Loading></Loading>);
    }
    return (
        <div className='col'>
            <ArticleModalEdit 
                showModalEdit={showModalEdit} 
                setShowModalEdit={setShowModalEdit} 
                customFields={customField} 
                modalEditId={id} 
                from="details" 
                setArticleFromDetails={setArticle}
            />
            <Modal size="xl" show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('customizationInterface')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FicheArticleCustomisation interface='ficheArticle' fields={fields}  setRepere={setRepere} moduleName='items'/>
                </Modal.Body>
            </Modal>
            <div>
                <Header article={article} setShowEdit={setShowModalEdit} setModalEditId={setModalEditId} hisId={id} setShow={setShow}/>
            </div>
            {(article.havestock!=0) ? 
                <Tabs defaultActiveKey="articleDetail" id="uncontrolled-tab-example">
                    <Tab eventKey="articleDetail" title={t('detailArticle')}>
                        <div className='row'>
                            <div className='col-8 d-flex'>
                                <DetailArticle article={article} customField={customField}/>
                            </div>
                            <div className='col-2 d-flex'>
                                <DetailPrice article={article}/>
                            </div>
                            <div className='col-2 d-flex maxHPromoArticle'>
                                {/* <DetailPromo 
                                    article={article}
                                    articleId={id}
                                    setArticle={setArticle}
                                    currentPromo={currentPromo}
                                    setCurrentPromo={setCurrentPromo}
                                    incomingPromo={incomingPromo}
                                    setIncomingPromo={setIncomingPromo}
                                /> */}
                            </div>
                        </div>
                        <div >
                            <CustomFieldsDetails customInterface={customInterface} article={article} />
                        </div>
                    </Tab>
                    <Tab eventKey="promotion" title={t('promotion')}>
                        <PromotionDetailsTab 
                            article={article}
                            articleId={id}
                            currentPromo={currentPromo}
                            setCurrentPromo={setCurrentPromo}
                            incomingPromo={incomingPromo}
                            setIncomingPromo={setIncomingPromo}  
                        />
                    </Tab>
                    <Tab eventKey="relatedRecord" title={t('relatedRecord')}>
                        <RecordTable articleRecord={articleRecord} sizeDatas={sizeDatas} id={id} setSizeDatas={setSizeDatas} setArticleRecord={setArticleRecord}/>
                    </Tab>
                    <Tab eventKey="stock" title="Stock">
                        <Stock loadStock={loadStock} stock={stock} articleid={id} setReadjustReload={setReadjustReload} article={article}/>
                    </Tab>
                    <Tab eventKey="reassort" title={t('autoRestock')}>
                        <ReassortAuto rppsl={rppsl} stock={stock} setRppsl={setRppsl}/>
                    </Tab>
                </Tabs>
            :
                <Tabs defaultActiveKey="articleDetail" id="uncontrolled-tab-example">
                    <Tab eventKey="articleDetail" title={t('detailArticle')}>
                        <div className='row'>
                            <div className='col-8 d-flex'>
                                <DetailArticle article={article} customField={customField}/>
                            </div>
                            <div className='col-2 d-flex'>
                                <DetailPrice article={article}/>
                            </div>
                            <div className='col-2 d-flex maxHPromoArticle'>
                                {/* <DetailPromo 
                                    article={article}
                                    articleId={id}
                                    setArticle={setArticle}
                                    currentPromo={currentPromo}
                                    setCurrentPromo={setCurrentPromo}
                                    incomingPromo={incomingPromo}
                                    setIncomingPromo={setIncomingPromo}
                                /> */}
                            </div>
                        </div>
                        <div >
                            <CustomFieldsDetails customInterface={customInterface} article={article} />
                        </div>
                    </Tab>
                    <Tab eventKey="promotion" title={t('promotion')}>
                        <PromotionDetailsTab 
                            article={article}
                            articleId={id}
                            currentPromo={currentPromo}
                            setCurrentPromo={setCurrentPromo}
                            incomingPromo={incomingPromo}
                            setIncomingPromo={setIncomingPromo} 
                        />
                    </Tab>
                    <Tab eventKey="relatedRecord" title={t('relatedRecord')}>
                        <RecordTable articleRecord={articleRecord} sizeDatas={sizeDatas} id={id} setSizeDatas={setSizeDatas} setArticleRecord={setArticleRecord}/>
                    </Tab>
                </Tabs>
            }
        </div>
    )
}

export default ArticleDetail;