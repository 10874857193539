import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Moment from 'react-moment';
import { modePaiementDefaultAll } from 'app/modePaiementDefaultAll';
var JsBarcode = require('jsbarcode');


// Create styles
const styles = StyleSheet.create({
    logo: {
      marginTop: 30,
      marginBottom: 30,
      textAlign:'center',
      fontSize: 20,
    },
    shopName: {
        textAlign:'center',
        fontSize: 10,
    },
    infoShop:{
        textAlign:'center',
        fontSize: 8,
    },
    infoTicket:{
        textAlign:'left',
        fontSize: 8,
    },
    detailTicket:{
        textAlign:'left',
        fontSize: 8,
        marginBottom:5,
    },
    detailTaxes:{
        textAlign:'left',
        fontSize: 8,
    },
    codebar: {
        justifyContent: 'center',
        marginTop: 1,
        marginBottom:10
    }
   
  });


  // Create Document Component
  const Ticket = (props) => {
    let canvas;
    // For Barcode
    canvas = document.createElement('canvas');
    JsBarcode(canvas, props.ticketPdf.bar, {
        width: 2,
        height: 65,
        displayValue: true,
        
    });
    const barcode = canvas.toDataURL();

    function espace_mille(nombre){
        if(nombre<=999) return nombre.toString();
        if(nombre<=999999) return nombre.toString().slice(0,nombre.toString().length-3)+' '+nombre.toString().slice(-3);
        if(nombre<=999999999) return nombre.toString().slice(0,nombre.toString().length-6)+' '+nombre.toString().slice(-6,-3)+' '+nombre.toString().slice(-3);
    }

    return(
        <Document>
            <Page size={[215]} style={{padding:'15px'}}>
                {/* Logo part */}
                <View style={styles.logo}> 
                    <Text>Logo</Text>
                </View>

                {/* Shop infos part */}
                <View style={styles.shopName}>
                    <Text>{(props.currentShop.name!=null) ? props.currentShop.name : false}</Text>
                </View>
                <View style={styles.infoShop}>
                    <Text>{(props.currentShop.commerce_zone!=null) ? props.currentShop.commerce_zone : false}</Text>
                    <Text>{(props.currentShop.adresse!=null) ? props.currentShop.adresse : false}</Text>
                    <Text>{(props.currentShop.code_postal!=null) ? props.currentShop.code_postal : false}</Text>
                    <Text style={{marginBottom:5}}>{(props.currentShop.tel!=null) ? "Tel : "+props.currentShop.tel : false}</Text>

                    <Text style={{marginBottom:5}}>{(props.currentShop.horaires!=null) ? "Horaires : "+props.currentShop.horaires : false}</Text>

                    <Text>{(props.currentShop.site!=null) ? props.currentShop.site : false}</Text>
                    <Text>{(props.currentShop.shop_entity!=null) ? props.currentShop.shop_entity : false}</Text>
                    <Text>{(props.currentShop.ridet!=null) ? "RIDET : "+props.currentShop.ridet : false}</Text>

                    <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>___________________________________________________________________</Text>

                    <Text style={{marginBottom:1,textAlign:'left'}}>{<Moment format="DD/MM/YYYY HH:mm:ss">{props.ticketPdf.created_at}</Moment>}</Text>
                    <Text style={{marginBottom:1,textAlign:'left'}}>{props.t('salesOrder')+' : '+props.ticketPdf.bar}</Text>
                    <Text style={{marginBottom:1,textAlign:'left'}}>{(props.ticketPdf.ticket.rh!=null) ? props.t('cashier')+' : '+props.ticketPdf.ticket.rh.name+' '+props.ticketPdf.ticket.rh.firstname : false}</Text>
                    <Text style={{textAlign:'left'}}>{(props.ticketPdf.ticket.client!=null) ? props.t('customer')+' : '+props.ticketPdf.ticket.client.name+' '+props.ticketPdf.ticket.client.firstname : false}</Text>
                    <Text style={{textAlign:'left'}}>{(props.ticketPdf.section_collect!=null) ? props.t('collectZone')+' : '+props.ticketPdf.section_collect.name : false}</Text>
                    {(props.ticketPdf.prevu )!= null ?
                        <Text style={{textAlign:'left'}}>{props.t('estimatedDelivery')+' : '}<Moment format="DD/MM/YYYY HH:mm:ss">{props.ticketPdf.prevu}</Moment></Text>
                    :
                        false
                    }

                    <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>___________________________________________________________________</Text>
                </View>

                {props.ticketPdf.ticket.ticket_detail.map(value=>{
                    if(value.block_quantity>0){
                        return(
                            <View key={value.id} style={styles.detailTicket}>
                                {/* Product name part */}
                                <Text style={{textAlign:'left'}}>{(value.article!=null) ? value.article.name : false}</Text>

                                {/* Detail price and taxes part */}
                                <View style={{justifyContent: 'space-between',flexDirection:'row'}}>
                                    <Text>{(value.article!=null) ? value.article.tgc+'%' : ' '}</Text>

                                    <Text>{(value.article!=null) ? espace_mille(value.article.price)+props.devise : false}</Text>

                                    <Text>{'x'+value.quantity}</Text>

                                    <Text>{(value.remise!=0) ? '-'+value.remise : '0'}</Text>

                                    <Text>{espace_mille(Math.abs(value.prix_remise))+props.devise}</Text>
                                </View>
                            </View>
                        )
                    }
                })}
                
                <View>
                    {(props.ticketPdf.ticket.paiement_records.length!=0) ?
                        <View>
                            <View style={styles.detailTaxes}>
                                <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>___________________________________________________________________</Text>

                                <View style={{flexDirection:'row',justifyContent:'space-between',marginBottom:3}}>
                                    <Text>Total TTC : </Text>
                                    <Text>{espace_mille(props.totalAchat)+props.devise}</Text>
                                </View>
                            </View>

                            <View style={styles.detailTaxes}>
                                <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>Taxes _____________________________________________________________</Text>
                                {props.tgc3!=0 ?
                                    <View style={{flexDirection:'row',justifyContent:'space-between',marginBottom:3}}>
                                        <Text>TGC 3% : </Text>
                                        <Text>{espace_mille(props.tgc3)+props.devise}</Text>
                                    </View>
                                :
                                    false
                                }
                                {props.tgc6!=0 ?
                                    <View style={{flexDirection:'row',justifyContent:'space-between',marginBottom:3}}>
                                        <Text>TGC 6% : </Text>
                                        <Text>{espace_mille(props.tgc6)+props.devise}</Text>
                                    </View>
                                :
                                    false
                                }
                                {props.tgc11!=0 ?
                                    <View style={{flexDirection:'row',justifyContent:'space-between',marginBottom:3}}>
                                        <Text>TGC 11% : </Text>
                                        <Text>{espace_mille(props.tgc11)+props.devise}</Text>
                                    </View>
                                :
                                    false
                                }
                                {props.tgc22!=0 ?
                                    <View style={{flexDirection:'row',justifyContent:'space-between',marginBottom:3}}>
                                        <Text>TGC 22% : </Text>
                                        <Text>{espace_mille(props.tgc22)+props.devise}</Text>
                                    </View>
                                :
                                    false
                                }
                                <View style={{flexDirection:'row',justifyContent:'space-between',marginBottom:3}}>
                                    <Text>Total TGC : </Text>
                                    <Text>{espace_mille(props.tgcTotal)+props.devise}</Text>
                                </View>
                            </View>
                        </View>
                    :
                        false
                    }

                    <View style={styles.detailTaxes}>
                        <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>Acompte versé ______________________________________________________</Text>

                        {props.ticketPdf.ticket.paiement_records.length!=0 ? 
                            props.ticketPdf.ticket.paiement_records.map(value=>(
                                <View key={value.id} style={{flexDirection:'row',marginBottom:3,justifyContent:'space-between'}}>
                                    <Text>{(modePaiementDefaultAll.includes(value.mode)) ? props.t(value.mode) : value.mode}</Text>
                                    <Text>{espace_mille(value.montant)+props.devise}</Text>
                                </View>
                            ))
                        :
                            <View style={{flexDirection:'row',marginBottom:3,textAlign:'right'}}>
                                <Text>{0+props.devise}</Text>
                            </View>
                        }
                    </View>
                </View>

                <View style={styles.detailTaxes}>
                    <Text style={{marginBottom:6,marginTop:6,fontSize:5}}>___________________________________________________________________</Text>

                    <Text style={{textAlign:'center',marginBottom:6}}>{props.conditionsRetour}</Text>
                    
                    <Text style={{textAlign:'center'}}>{(props.currentShop.name!=null) ? props.currentShop.name : false}</Text>
                    <Text style={{textAlign:'center',marginBottom:6}}>{props.t('messageEndReceipt')}</Text>

                    <Image style={styles.codebar} src={barcode}/>

                </View>
                
            </Page>
        </Document>
    )
    };

export default Ticket;