import React,{useState,useEffect} from "react";
import { FormatedPrice } from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faExchangeAlt} from '@fortawesome/free-solid-svg-icons'

const SavedTicketModal = (props) =>{
    const t = useTranslate();
    const [loading, setLoading]=useState(false)
    const [savedList,setSavedList]=useState([])
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    useEffect(function () {
        var listSaved = []
        if(props.cookies.save_ticket){
            let savedTickets = props.cookies.save_ticket;
            let cookieLecture = savedTickets.split(',')
    
            for(var i=0; i<cookieLecture.length; i++){
                listSaved.push(cookieLecture[i].split('/!'))
            }
        }

        setSavedList(listSaved)
    }, [])

    const getTicket = async (ticketId) =>{
        setLoading(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            id:ticketId
        }).then((response) => {
            if(!response.data.valid){
                swal.fire(t('unknowError'), "", "error");
            }
            else{   
                let newData = [] 
                for(var i=0; i<savedList.length; i++){
                    if(savedList[i][0] != ticketId){
                        newData.push(savedList[i][0]+'/!'+savedList[i][1]+'/!'+savedList[i][2])
                    }
                }

                if(props.cookies.current_ticket){//si il y aun ticket en cours
                    props.saveTicket(ticketId)
                    props.setCookie("current_ticket",ticketId,{ path: "/", expires: props.expire_date })
                }else{
                    props.setCookie("current_ticket",ticketId,{ path: "/", expires: props.expire_date })
                    props.setCookie("save_ticket",newData.join(),{ path: "/", expires: props.expire_date })
                }
                props.setCurrentTicket(response.data.data)
                setLoading(false)
                props.closeModal()
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading(false)
    }

    const deleteSavedTicket = (toDelete) =>{
        let newArr = [] 

        for(var i=0; i<savedList.length; i++){
            if(savedList[i][0] != toDelete){
                newArr.push(savedList[i][0]+'/!'+savedList[i][1]+'/!'+savedList[i][2])
            }
        }

        if(savedList.length > 1){
            props.setCookie("save_ticket",newArr.join(),{ path: "/", expires: props.expire_date })
            let newList = savedList.filter(value=>value[0]==toDelete);
            setSavedList(newList)
        }else{
            //si il reste un seul ticket sauvegardé, on suprime le cookie save_ticket
            setSavedList([])
            props.removeCookie('save_ticket',{ path: "/"})
        }
    }

    return(
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th className="col">Total</th>
                        <th className="col text-end">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {(savedList.length!=0) ?
                        savedList.map((value,ind)=>{
                            let style = (ind%2 == 0) ? 'stripeBg' : '';
                            return(
                                <tr
                                    key={ind} 
                                    className={style}
                                >
                                    <td>{<Moment format="DD/MM/YYYY hh:mm:ss">{value[1]}</Moment>}</td>
                                    <td>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value[2]} />}</td>
                                    <td className='text-end'>{<>
                                        {loading ? <span className="spinner-border spinner-border-sm"></span> : <FontAwesomeIcon className="fs-5 text-primary pointer" icon={faExchangeAlt} onClick={()=>getTicket(value[0])}/>}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  
                                        <FontAwesomeIcon className="fs-5 text-danger pointer" icon={faTimesCircle} onClick={()=>deleteSavedTicket(value[0])}/></>
                                    }</td>
                                </tr>
                            )
                        })
                    :
                        <tr>
                            <td className='text-end border-0'>{t('noSalesSaved')}</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}

export default SavedTicketModal;