import React, {useContext} from 'react';
import { IsAuthContext } from '../../../../../App';
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso, FormatedPrice} from "@gull";

const DetailPrice = (props) =>{
    const t = useTranslate();
    const { authParam } = useContext(IsAuthContext)

    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;

    return(
        <div className='d-flex'>
            <CardPerso header={t('detailPrice')}>
                <div className='col'>
                    <div className='row mb-2'>
                        <label htmlFor="price" className='fs-5 text fw-bold'>{t('priceTtc')+' '+'('+props.article.tgc+'%'+')'} :</label>
                        <FormatedPrice langRegion={'fr-FR'} devise={devise} digit={digit} priceToFormat={props.article.price} />
                        <div className='border-top mt-1'></div>
                    </div>       
                </div>
                <div className='col'>
                    <div className='row mb-2'>
                        <label htmlFor="htprice" className='fs-5 text fw-bold'>{t('priceHt')} :</label>
                        <FormatedPrice langRegion={'fr-FR'} devise={devise} digit={2} priceToFormat={props.article.ht} />
                        <div className='border-top mt-1'></div>
                    </div>       
                </div>
                <div className='col'>
                    <div className='row'>
                        <label htmlFor="prmp" className='fs-5 text fw-bold'>PRMP :</label>
                        <FormatedPrice langRegion={'fr-FR'} devise={devise} digit={2} priceToFormat={props.article.prmp} />
                        <div className='border-top mt-1'></div>
                    </div>       
                </div>
                <div className='col'>
                    <div className='row'>
                        <label htmlFor="prmp" className='fs-5 text fw-bold'>{t('prmpIndice')} :</label>
                        <div className='fw-lighter'>{(props.article.indice && props.article.indice!==null) ? props.article.indice : 0}</div>
                        <div className='border-top mt-1'></div>
                    </div>       
                </div>
                <div className='col'>
                    <div className='row'>
                        <label htmlFor="buyprice" className='fs-5 text fw-bold'>{t('buy_price')} :</label>
                        <FormatedPrice langRegion={'fr-FR'} devise={props.article.devise_achat} digit={(props.article.devise_achat!='XPF') ? 2 : 0} priceToFormat={props.article.buy_price} />
                        <div className='border-top mt-1'></div>
                    </div>       
                </div>
            </CardPerso>
        </div>
    )
}

export default DetailPrice;