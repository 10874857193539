import React, { useState, useContext } from "react";
import { IsAuthContext } from '../../../app/App';
import { useForm } from "react-hook-form";
import { useTranslate } from 'react-redux-multilingual'
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

const ObjectifDetail = (props) => {
    const t = useTranslate()
    const [show,setShow] = useState(false)
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [editTf,setEditTf]=useState(false)
    const [valueTf,setValueTf]=useState(props.data.entre)
    const { authParam } = useContext(IsAuthContext);

    let isModuleAdmin=false;
    authParam.modules.forEach((module)=>{
        if(module.name=='admin') isModuleAdmin=true;
    })
    
    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)
        let obj = (isModuleAdmin) ? {
            ca: data.ca,
            pm: data.pm,
            iv: data.iv,
            tf: data.tf,
            entre: data.entre
        } : {
            entre: data.entre
        }
        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/pos/objectif/"+props.objectifId,obj
            ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
               props.setObjectif(response.data.data)
            }
        }).catch((error) => {
            if(error.response==undefined){
                setApiError(t('unknowError'))
            }
            else if(error.response.status==403){
                setApiError(t('forbiddenGeneral'))
            }
            else {
                setApiError(t('unknowError'))
            }
        })
        setValidButton(false)
    }
    
    return (<>
        {(props.objectifId) &&
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('edit')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            {(props.from!='pos') ?
                                <div className="col-12">
                                    {(isModuleAdmin) &&
                                        <>
                                            <div className="form-group ">
                                                <label htmlFor="ca">CA:</label>
                                                <div className="input-group">
                                                    <input className="form-control" type="number" defaultValue={props.data.ca} {...register('ca',{required: true,min: 0,max: 2000000000})}/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" id="basic-addon2">
                                                            XPF
                                                        </span>
                                                    </div>
                                                    {errors.ca?.type === 'required' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('caRequired')}
                                                    </div>
                                                    )}
                                                    {errors.ca?.type === 'min' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('caMin0')}
                                                    </div>
                                                    )}
                                                    {errors.ca?.type === 'max' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('caMax2000000000')}
                                                    </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <label htmlFor="pm">PM:</label>
                                                <div className="input-group">
                                                    <input className="form-control" type="number" defaultValue={props.data.pm} {...register('pm',{required: true,min: 0,max: 2000000000})}/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" id="basic-addon2">
                                                            XPF
                                                        </span>
                                                    </div>
                                                    {errors.pm?.type === 'required' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('pmRequired')}
                                                    </div>
                                                    )}
                                                    {errors.pm?.type === 'min' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('pmMin0')}
                                                    </div>
                                                    )}
                                                    {errors.pm?.type === 'max' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('pmMax2000000000')}
                                                    </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <label htmlFor="iv">IV:</label>
                                                <div className="input-group">
                                                    <input className="form-control" step="any" type="number" defaultValue={props.data.iv} {...register('iv',{required: true,min: 0,max: 999})}/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" id="basic-addon2">
                                                            {t('items')}
                                                        </span>
                                                    </div>
                                                    {errors.iv?.type === 'required' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('ivRequired')}
                                                    </div>
                                                    )}
                                                    {errors.iv?.type === 'min' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('ivMin0')}
                                                    </div>
                                                    )}
                                                    {errors.iv?.type === 'max' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('ivMax999')}
                                                    </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="form-group ">
                                                <label htmlFor="tf">TF:</label>
                                                <div className="input-group">
                                                    <input className="form-control" type="number" defaultValue={props.data.tf} {...register('tf',{required: true,min: 0,max: 100})}/>
                                                    <div className="input-group-append">
                                                        <span className="input-group-text" id="basic-addon2">
                                                            %
                                                        </span>
                                                    </div>
                                                    {errors.tf?.type === 'required' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('tfRequired')}
                                                    </div>
                                                    )}
                                                    {errors.tf?.type === 'min' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('tfMin0')}
                                                    </div>
                                                    )}
                                                    {errors.tf?.type === 'max' && (
                                                    <div className="text-danger col-12 mt-1 ml-2">
                                                        {t('tfMax100')}
                                                    </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className="form-group ">
                                        <label htmlFor="entre">{t('nbrEntreClient')}:</label>
                                        <input className="form-control" type="number" defaultValue={props.data.entre} {...register('entre',{required: true,min: 0,max: 10000000})}/>
                                        {errors.entre?.type === 'required' && (
                                        <div className="text-danger col-12 mt-1 ml-2">
                                            {t('entreRequired')}
                                        </div>
                                        )}
                                        {errors.entre?.type === 'min' && (
                                        <div className="text-danger col-12 mt-1 ml-2">
                                            {t('entreMin0')}
                                        </div>
                                        )}
                                        {errors.entre?.type === 'max' && (
                                        <div className="text-danger col-12 mt-1 ml-2">
                                            {t('entreMax10000000')}
                                        </div>
                                        )}
                                    </div>
                                </div>
                            :
                                <div className="form-group ">
                                    <label htmlFor="entre">{t('nbrEntreClient')}:</label>
                                    <input className="form-control" type="number" defaultValue={props.data.entre} {...register('entre',{required: true,min: 0,max: 10000000})}/>
                                    {errors.entre?.type === 'required' && (
                                        <div className="text-danger col-12 mt-1 ml-2">
                                            {t('entreRequired')}
                                        </div>
                                    )}
                                    {errors.entre?.type === 'min' && (
                                        <div className="text-danger col-12 mt-1 ml-2">
                                            {t('entreMin0')}
                                        </div>
                                    )}
                                    {errors.entre?.type === 'max' && (
                                        <div className="text-danger col-12 mt-1 ml-2">
                                            {t('entreMax10000000')}
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                        {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                        <button disabled={validButton} className="btn btn-primary mt-3">
                            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                        </button>
                    </form>
                </Modal.Body>
            </Modal>   
        }
        <div className="row">
            <div className="col-md-2">
                <span style={{color: '#5679df'}}><strong>CA</strong>: {new Intl.NumberFormat('fr-FR').format(props.data.ca)+' XPF'}</span>
            </div>
            <div className="col-md-2">
                <span style={{color: '#dfa656'}}><strong>PM</strong>: {new Intl.NumberFormat('fr-FR').format(props.data.pm)+' XPF'}</span>
            </div>
            <div className="col-md-2">
                <span style={{color: '#a945b2'}}><strong>IV</strong>: {props.data.iv+' '+t('items')}</span>
            </div>
            <div className="col-md-2">
                <span style={{color: '#45b29a'}}><strong>TF</strong>: {props.data.tf+'%'}</span>
            </div>
            {(props.from!='pos' && props.objectifId) &&
                <div className="offset-md-2 col-md-2" style={{textAlign: 'right'}}>
                    <button className="btn-primary btn float-right" onClick={() => setShow(true)}><FontAwesomeIcon icon={faEdit} /></button>
                </div>
            }
        </div>
        {(props.from!='pos') ?
            <div className="col-12 mt-3">
                <strong>{t('nbrEntreClient')}</strong>: {props.data.entre}  
            </div>
        :
            <div className="col-12 mt-3">
                <strong>{t('nbrEntreClient')}</strong>: {props.data.entre}  
                <span className="text-primary">&nbsp;&nbsp;<FontAwesomeIcon icon={faEdit} onClick={()=>setShow(true)} className="pointer"/></span>
            </div>
        }
    </>)
}

export default ObjectifDetail;