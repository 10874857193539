import React,{useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FormatedPrice } from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { modePaiementDefaultAll } from 'app/modePaiementDefaultAll';

const LinePaymentRecord = (props) =>{
    const t =useTranslate()
    const [newPaymentMode,setNewPaymentMode]=useState('')
    const [loading,setLoading]=useState(false)
    const idsLocalStorage=(props.from=='pos') ? localStorage.getItem("pos").split(',') : [0,0]

    useEffect(function () {
        setNewPaymentMode(props.value.mode)
    }, [])

    const handleChangePaymentMode = async (e) =>{
        setNewPaymentMode(e.target.value)
        setLoading(true)
        let urlParam = (props.from=='pos') ? idsLocalStorage[1]+"/"+idsLocalStorage[0] : props.caisseIdent+'/'+props.pos_id

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+urlParam,{
            id:props.ticketId,
            modif_paiement_record_mode:props.value.id,
            paiement_mode:e.target.value
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{  
                if(props.from=='pos'){ 
                    //maj des infos du data table
                    let salesHisto = props.dataTicket.map(value=>value.id)
                    let indexToEdit = salesHisto.indexOf(props.value.ticket_id)

                    let newData = props.dataTicket.filter(value=>value.id!=props.value.ticket_id)

                    newData.splice(indexToEdit,0, response.data.data)
                    props.setDataTicket(newData)
                }
                
                //maj des infos de la modal
                props.setAllPaymentRecords(response.data.data.paiement_records)
                setLoading(false)
                if(props.setDataSaleOrder) props.setDataSaleOrder({...props.dataTicket,ticket:response.data.data})
            }
        }).catch((error) => {
            let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
            swal.fire(t(errorName), "", "error");
        })
        setLoading(false)
    }

    return(<>
            <tr className="position-relative">
                <td>
                    <select disabled={props.module=='pos' && props.pos_id!=idsLocalStorage[0]} value={newPaymentMode} className="form-control" type="text" onChange={(e)=>handleChangePaymentMode(e)}>
                        {props.currentShop.modepaiement.map((item,ind)=>(
                            <option key={ind} value={item}>
                                {(modePaiementDefaultAll.includes(item)) ?
                                    t(item)
                                :
                                    item
                                }
                            </option>
                        ))}
                    </select>
                    {loading ? <span className="spinnerChangeModePosition"><div className="spinner-border spinner-border-sm"></div></span> : false}
                </td>
                <td className="text-end">
                    {<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.value.montant} />}
                </td>
            </tr>
        </>
    )    
}

export default LinePaymentRecord;