import React, {useState, useEffect,useContext} from 'react';
import axios from 'axios';
import { useTranslate } from 'react-redux-multilingual';
import { CardPerso, Loading, ConfigSubmit, SelectFields, AddNewSection, DeleteUserConfBtn} from "@gull";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle,faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IsAuthContext } from 'app/App';
import swal from "sweetalert2";

const useLoadCustomInterface = (interfaceToLoad) => {
    const t = useTranslate();
    const [loading,setLoading]=useState(true)
    const [reloadingConf, setReloadingConf]=useState(false)
    const [draggableContent, setDraggableContent]=useState({})
    const [defaultConfig, setDefaultConfig]=useState(false)
    const [emptyConf, setEmptyConf]=useState(false)
    const [noDefaultConf, setNoDefaultConf]=useState(false)    

    useEffect(function () {
        (async function() {
            setReloadingConf(true)
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/custom_interface/"+interfaceToLoad
            ).then((response) => {
                setLoading(false)
                setReloadingConf(false)
                if(defaultConfig){
                    if(!response.data.default_conf){
                        setNoDefaultConf(true)
                    }else{
                        setNoDefaultConf(false)
                        setEmptyConf(false)
                        setDraggableContent(response.data.default_conf.details)
                    }
                }else{
                    if(!response.data.user_conf){
                        setEmptyConf(true)
                        setDraggableContent({})
                    }else{
                        setEmptyConf(false)
                        setDraggableContent(response.data.user_conf.details)
                    }
                }
            }).catch((error) => {
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [defaultConfig])
    return [loading,reloadingConf,draggableContent,setDraggableContent, setDefaultConfig, defaultConfig,emptyConf,setEmptyConf,noDefaultConf,setNoDefaultConf]
}

const DraggableFields = (props) => {
    const t = useTranslate();

    const removeField = (valueToDelete, keyName) =>{
        let newDraggableContent = props.draggableContent[keyName].filter(value => value!= valueToDelete)
        props.setDraggableContent({...props.draggableContent, [keyName]: newDraggableContent})
        
    }

    return(
        <Droppable droppableId={props.keyName} key={props.keyName}>
            {(provided)=> (
                <ul {...provided.droppableProps} ref={provided.innerRef} style={{paddingLeft:"0"}} >
                    {props.draggableContent[props.keyName].map((value, index) => (
                        <Draggable key={value+index} draggableId={value+index} index={index}>
                            {(provided) => (
                                <div className="row">
                                    <li 
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                        className="badge badge-primary bg-primary mb-1 p-2"
                                    >
                                        <big>
                                            {value}
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {t('remove')}
                                                    </Tooltip>
                                                }
                                            >
                                            <span style={{cursor:'pointer', marginLeft:'0.5rem'}} onClick={()=>removeField(value, props.keyName)}>
                                                <FontAwesomeIcon icon={faTimesCircle}  />
                                            </span>
                                            </OverlayTrigger>
                                        </big>
                                    </li>
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </ul>
            )}
        </Droppable>
    )

}

const FicheArticleCustomisation = (props) => {
    const { authParam } = useContext(IsAuthContext)
    const t = useTranslate();
    const [
        loading,
        reloadingConf,
        draggableContent,
        setDraggableContent,
        setDefaultConfig,
        defaultConfig,
        emptyConf,
        setEmptyConf,
        noDefaultConf,
        setNoDefaultConf,
    ]=useLoadCustomInterface(props.interface)
    


    //Fonction qui retourne un nouveau tableau avec les nouvelles positions de chaque valeurs
    function onDragEnd(result) {
        //Si on sort du champ de drag and drop, on stop l'action 
        if (!result.destination) return;

        //Drag and Drop des cards
        if(result.type === 'column'){
            //On crée un tableau avec le nom de chaque cards draggable 
            let actualCardsOrder = Object.keys(draggableContent).map((key)=>key)
            //On réorganise l'ordre des cards après avoir dropper notre card
            let [reorderedCards] = actualCardsOrder.splice(result.source.index, 1);
            actualCardsOrder.splice(result.destination.index, 0, reorderedCards);
            //On initialise un tableau vide dans lequel on va renseigner le nouveau state
            let newCardsOrder = {};
            //On boucle sur 'actualCardsOrder' pour récupérer les clés de notre objet
            //Et on les fait correspondre à leurs valeurs dans le state initial 
            actualCardsOrder.forEach(value=>{
                newCardsOrder[value] = draggableContent[value]
            })

            setDraggableContent(newCardsOrder)
        }
        else{
            //Drag and Drop des fields entre chaque cards
            if (result.source.droppableId !== result.destination.droppableId){
                const sourceColumn = draggableContent[result.source.droppableId];
                const destColumn = draggableContent[result.destination.droppableId];
                const sourceItems = [...sourceColumn];
                const destItems = [...destColumn];
                const [removed] = sourceItems.splice(result.source.index, 1);
                destItems.splice(result.destination.index, 0, removed);
                setDraggableContent({
                    ...draggableContent,
                  [result.source.droppableId]: sourceItems, 
                  [result.destination.droppableId]: destItems
                });
            }else{
                //Drag and Drop des fields dans leur card
                const column = draggableContent[result.source.droppableId];
                const copiedItems = [...column];
                const [removed] = copiedItems.splice(result.source.index, 1);
                copiedItems.splice(result.destination.index, 0, removed);
                setDraggableContent({
                    ...draggableContent,
                    [result.source.droppableId]: copiedItems
                });
            }
        }
    }

    const removeCard = (cardToDelete) =>{
        //Fonction de suppression d'une paire clé valeur d'un objet
        //cardToDelete représente la clé à supprimer de l'objet
        //removedProperty représente la ou les valeur(s) de la clé 'cardToDelete'
        //...newDraggableCards représente le nouveau state après la suppression de la paire clé valeur 
        //draggableContent représente le state initial 
        const { [cardToDelete]: removedProperty, ...newDraggableCards } = draggableContent;

        setDraggableContent(newDraggableCards)
    }

    const admin = authParam.modules.find((item) => item.name === "admin")

    if(loading && props.from==='tab'){
        return (<Loading></Loading>);
    }else if(loading && props.from ==='detailsModal'){
        return (<span className='d-flex justify-content-center'><span>&nbsp;</span><div className="spinner-border spinner-border-lg"></div></span>)
    }

    return(<>
        <div className='row mb-5'>
            {(admin) ?
                <>
                    <div className="col-7 form-check form-switch">
                        <input type="checkbox" className="form-check-input"  onChange={()=>setDefaultConfig(!defaultConfig)} />
                        <label title="" className="form-check-label">{t('defaultConfig')}</label>
                    </div>
                    <AddNewSection setDatas={setDraggableContent} datas={draggableContent} setEmptyConf={setEmptyConf} setNoDefaultConf={setNoDefaultConf} defaultConfig={defaultConfig} />
                </>
            : 
                <AddNewSection setDatas={setDraggableContent} datas={draggableContent} setEmptyConf={setEmptyConf} setNoDefaultConf={setNoDefaultConf} defaultConfig={defaultConfig} />
            }

        </div>
        <div className='row mb-3'>
            <h2 className='border-start border-dark'>
                {(defaultConfig) ? t('defaultConfig')+' - '+t(props.moduleName) : t('personalizedConfig')+' - '+t(props.moduleName)}
            </h2>
        </div>
        {(reloadingConf) ?
            <div className="spinner-border spinner-border-lg"></div>
        :
            <>
                {(!emptyConf && !defaultConfig) && <DeleteUserConfBtn interface={props.interface} setDatas={setDraggableContent} setEmptyConf={setEmptyConf} setRepere={props.setRepere}/>}
                {(emptyConf) ?
                    <p className='text-center' style={{marginTop:'5rem'}}>{t('noUserConf')}</p>
                :
                    (!defaultConfig && Object.keys(draggableContent).length===0) ?
                        <p className='text-center' style={{marginTop:'5rem'}}>{t('persoConfigVide')}</p>
                    :
                        (defaultConfig && noDefaultConf) ?
                            <li style={{listStyle: 'none', textAlign: 'center'}}>{t('noDefaultConf')}</li>
                        :
                            (defaultConfig && Object.keys(draggableContent).length===0) ? 
                                <p className='text-center' style={{marginTop:'5rem'}}>{t('defautConfigVide')}</p>
                            :
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="all-cards" direction="horizontal" type='column'>
                                        {(provided) => (
                                            <div className='row' ref={provided.innerRef} {...provided.droppableProps}>
                                                {(!emptyConf) &&
                                                    Object.entries(draggableContent).map(([keyName, columns], ind) =>{
                                                        return(
                                                            <div key={ind} className='col-3'>
                                                                <Draggable draggableId={keyName+ind} index={ind}>
                                                                {(provided) => (
                                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                        <SelectFields {...props} setDatas={setDraggableContent} datas={draggableContent} keyName={keyName} multipleCards='true'/>
                                                                        <CardPerso header={keyName} icon={<FontAwesomeIcon icon={faTimesCircle}/>}  customClickEvent={removeCard} deleteFunctionArgument={keyName}>
                                                                            <DraggableFields draggableContent={draggableContent} setDraggableContent={setDraggableContent} keyName={keyName} />
                                                                        </CardPerso>
                                                                    </div>
                                                                )}
                                                                </Draggable>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                }
                {(!defaultConfig && !emptyConf || defaultConfig && !noDefaultConf) &&  <ConfigSubmit interface={props.interface} config={defaultConfig} datas={draggableContent} setRepere={props.setRepere} setEmptyConf={setEmptyConf} />}
            </>
        }
        </>
    )
}

export default FicheArticleCustomisation;