import swal from "sweetalert2";

const zebra_zd421 = (ip_addr,tagFormated) =>{
    var zpl = tagFormated;
    var url = "http://"+ip_addr+"/pstprnt";;
    var method = "POST";
    var async = true;
    var request = new XMLHttpRequest();

    request.open(method, url, async);
    request.setRequestHeader("Content-Length", zpl.length);

    request.send(zpl);
}

export const imprimeEtiquette = (peripheriqueParam,devise,formatedPrice,t,articleName,articleUpc,quantityTag,from) =>{
    let formatedTag =  "^XA^A0,20,15^FT20,40^FB280,2,0,C^FH\^FD"+articleName+"^FS^BY1,2,60^FO70,55^A0,15,28^BC^FD"+articleUpc+"^FS^A0,20,20^FT20,180^FB280,2,0,C^FH\^FD"+formatedPrice+devise+"^FS^PQ"+quantityTag+"^XZ"
    if(from=='wms'){
        if(peripheriqueParam[2]=='zebra_zd421'){
            zebra_zd421(peripheriqueParam[1],formatedTag)
        }

    }else{
        if(peripheriqueParam && peripheriqueParam.imprimante_etiquette && peripheriqueParam.imprimante_etiquette.model=='zebra_zd421'){
            //'192.168.88.130'
            zebra_zd421(peripheriqueParam.imprimante_etiquette.host,formatedTag)
        }else{
            swal.fire(t('noConfiguredPeripheral'), "", "error");
        }  
    }
}

export const imprimeCaisseIdent = (peripheriqueParam,caisseIdent,from,t) =>{
    let formatedTag =  "^XA^BY1,1,70^FO20,50^A0,20,28^BC^FD"+caisseIdent+"^XZ" 

    if(from=='pos'){
        if(peripheriqueParam && peripheriqueParam.imprimante_etiquette && peripheriqueParam.imprimante_etiquette.model=='zebra_zd421'){
            //'192.168.88.130'
           zebra_zd421(peripheriqueParam.imprimante_etiquette.host,formatedTag)
        }else{
            swal.fire(t('noConfiguredPeripheral'), "", "error");
        }  
    }else{
        zebra_zd421('192.168.88.130',formatedTag)
    }    
}