import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { Modal, Button,Tabs, Tab} from "react-bootstrap";
import EditPriceForm from "./EditPriceForm";

const EditPriceModal = (props) =>{
    const t =useTranslate()

    return(
        <Modal size="md" show={props.showEditPrice} onHide={() => props.closeModal('price')}>
            <Modal.Header closeButton>
                <Modal.Title>{t('priceEdit')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs defaultActiveKey="percent" id="uncontrolled-tab-example" style={{marginTop:'1px'}}>
                    <Tab eventKey="percent" title={t('discountPercent')}>
                        <EditPriceForm isFor='percent' closeModal={props.closeModal} cookies={props.cookies} item={props.item} setCurrentTicket={props.setCurrentTicket} setDisabledPay={props.setDisabledPay}/>
                    </Tab>
                    <Tab eventKey="net" title={t('discountNet')}>
                        <EditPriceForm isFor='net' closeModal={props.closeModal} cookies={props.cookies} item={props.item} setCurrentTicket={props.setCurrentTicket} setDisabledPay={props.setDisabledPay}/>
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    )
}

export default EditPriceModal;