import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons'; 

const BtnPrint = (props) =>{
    const t = useTranslate();

    let style = (props.selectedPrinter[props.value.id]) ? "btn btn-warning" : "btn btn-outline-warning";

    return(
        <div className="d-grid gap-2 mb-2">
            <button 
                className={style}
                type="button"
                onClick={() => props.selectPrinter(props.value.id,props.value.host,props.value.model)}
            >
                <div className="text-dark text-center">
                    <div>
                        <FontAwesomeIcon icon={faPrint} className="fs-3 mb-2"/>
                    </div>
                    <strong>{props.value.name}</strong>
                    <div>{t(props.value.model)}</div>
                </div>
            </button>
        </div>
    )
}

export default BtnPrint;