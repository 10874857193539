import React, {useState,useEffect,useRef,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FormatedPrice } from "@gull";
import Moment from 'react-moment';


const SearchHistoriqueGiftCard = (props) => {
    const t =useTranslate()
    

    return(
        <div>
            <table
                className="table"
            >
                <tbody>
                    {Object.keys(props.detailGiftCards).length!=0 &&
                        props.detailGiftCards.gift_card_records.map((value,ind)=>{
                            let style = (ind%2 == 0) ? 'stripeBg stripePadding' : 'stripePadding';
                            return(
                                <tr key={value.id} className={style}>
                                    <td className="d-flex justify-content-between">
                                        <div>
                                            <span className={(value.is == 'credit') ? "text-success" : "text-danger"}>{t(value.is)+': '}</span>&nbsp;
                                            <span className="d-inline-block">{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.montant}/>}</span>
                                        </div>
                                        <div>
                                            {(value.is == 'credit' && value.expire) ? <><span>{t('dateExpire')+': '}</span><span className="d-inline-block">{<Moment format="DD/MM/YYYY">{value.expire}</Moment>}</span></> : false}
                                            {(value.is == 'debit') ? <><span className="d-inline-block">{<Moment format="DD/MM/YYYY">{value.created_at}</Moment>}</span></> : false}
                                        </div>
                                        
                                        
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
    
}

export default SearchHistoriqueGiftCard;