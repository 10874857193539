import React, { useState,useEffect,useRef} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {InpDataList, ActionClientBarLeft} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Ticket from "../../../../app/views/Pdf/Ticket"
import { BlobProvider } from '@react-pdf/renderer';
import { bbgShops } from 'app/bbgShops';
import { conditionsRetour } from 'app/conditionsRetour';

const GeneralInfos = (props) => {
    const t = useTranslate()
    const [load,setLoad]=useState({
        caissier:false,
        client:false,
        pdf:false
    })
    const [valueInputCashier,setValueInputCashier]=useState('')
    const [valueInputClient,setValueInputClient]=useState('')
    const [invalidInput,setInvalidInput]=useState({
        searchCashier: true,
        searchClient: true
    })
    const [datasCashier,setDatasCashier] = useState([])
    const dataListDrop = useRef('')
    const searchInput = useRef('')
    const searchInputClient = useRef('')
    const [datas,setDatas] = useState([])
    const [barObj,setBarObj] = useState({})
    const [rhIdObj,setRhIdObj] = useState({})

    const [totalAchat,setTotalAchat]=useState(0)
    const [totalTgc,setTotalTgc]=useState(false)
    const btnPdf = useRef('')

    useEffect(function () {//mise à jour des noms caisser et clients si ils sont renseignés et remplissage de a drop liste du cashier
        let initial = []
        let initialBarObj = {};
        let initialRhIdObj = {};
        props.section.rh.forEach((value)=>{
            initial.push({
                id:value.id,
                name:value.name+' '+value.firstname
            })
            initialBarObj={...initialBarObj,[value.bar]:value.name+' '+value.firstname}
            initialRhIdObj={...initialRhIdObj,[value.bar]:value.id}
        })
        setBarObj(initialBarObj)
        setRhIdObj(initialRhIdObj)
        setDatasCashier(initial)
        setDatas(initial)
        if(Object.keys(props.ticketDetail).length!=0){
            let initial = {
                searchCashier:true,
                searchClient:true
            };
            if(props.ticketDetail.rh!=null){
                setValueInputCashier(props.ticketDetail.rh.name+' '+props.ticketDetail.rh.firstname)
                initial.searchCashier=false
            }
            if(props.ticketDetail.client!=null){
                setValueInputClient(props.ticketDetail.client.name+' '+props.ticketDetail.client.firstname+' - '+props.ticketDetail.client.email+' - '+props.ticketDetail.client.tel)
                initial.searchClient=false
            }
            setInvalidInput(initial)
        }

        var total = 0
        let totaltgc = {
            0:0,
            3:0,
            6:0,
            11:0,
            22:0
        }
        props.ticketDetail.ticket_detail.map(item=>{
            total+=item.prix_remise
            if(item.article_retour!=null){
                totaltgc={...totaltgc,[item.article_retour.tgc]:totaltgc[item.article_retour.tgc]+=item.prix_remise}
            }
            if(item.article!=null){
                totaltgc={...totaltgc,[item.article.tgc]:totaltgc[item.article.tgc]+=item.prix_remise}
            }
        })
        setTotalAchat(total)
        setTotalTgc(totaltgc)
    }, [])

    let tgc3 = +totalTgc[3] - (Math.round(+totalTgc[3] / 1.03))
    let tgc6 = +totalTgc[6]- (Math.round(+totalTgc[6] / 1.06))
    let tgc11 = +totalTgc[11] - (Math.round(+totalTgc[11] / 1.11))
    let tgc22 = +totalTgc[22] - (Math.round(+totalTgc[22] / 1.22))
    let tgcTotal = tgc3 + tgc6 + tgc11 + tgc22

    let currentShop = bbgShops.find(value=>value.id==props.ticketDetail.section_id)


    const noRedInput = (isfor) => {
        if(isfor=='searchCashier') setValueInputCashier('')
        if(isfor=='searchClient') setValueInputClient('')
        setInvalidInput({...invalidInput,[isfor]:false})
    }

    const updateCurentTicket = async (isFor,id,name) =>{//fonction exécutée si un ticket est en cours 

        let data={
            id:props.ticketDetail.id,
        }
        if(isFor == 'searchCashier'){
            data['rh_id']=id
            setLoad({...load,caissier:true})
        }else{
            data['client_id']=id
            setLoad({...load,client:true})
        }
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.ticketDetail.section_id+"/"+props.ticketDetail.caisse_ident_id+"/"+props.ticketDetail.pos_id, data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setTicketDetail(response.data.data)
                setLoad({
                    ...load,
                    caissier:false,
                    client:false
                })

                if(isFor == 'searchCashier'){
                    setValueInputCashier(name)
                }
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad({
            ...load,
            caissier:false,
            client:false
        })
    }

    const handleSearch = (e,declenchement) => {
        setValueInputCashier(e.target.value)
        if(e.target.value.length>=declenchement){
            let ajour = datasCashier.filter(value=>value.name.toLowerCase().includes(e.target.value.toLowerCase()));
            setDatas(ajour)
            dataListDrop.current.classList.remove("d-none");
        }
        else{
            dataListDrop.current.classList.add("d-none");
        }        
    }

    const handleCLick = (id,valeur) => {
        setValueInputCashier(valeur)
        setInvalidInput({...invalidInput,searchCashier:false})

        updateCurentTicket('searchCashier',id,valeur)
    }

    const handleKeyPress = (e) => {
        if(e.charCode==13){
            if(barObj[e.target.value]){
                setValueInputCashier(barObj[e.target.value])
                setInvalidInput({...invalidInput,searchCashier:false})

                updateCurentTicket('searchCashier',rhIdObj[e.target.value],barObj[e.target.value])

                setTimeout(()=>{
                    searchInput.current.blur()
                },200)
            }
        }
    }

    const handleBlur = () => {
        if(Object.keys(props.ticketDetail).length!=0){
            if(props.ticketDetail.rh!=null){
                setValueInputCashier(props.ticketDetail.rh.name+' '+props.ticketDetail.rh.firstname)
                setInvalidInput({...invalidInput,searchCashier:false})

            }else{
                setValueInputCashier('')
                setInvalidInput({...invalidInput,searchCashier:true})
            }
        }
        dataListDrop.current.classList.add("d-none");
    }

    const handleClick = () =>{
        setLoad({...load,pdf:true})

        setTimeout(()=>{
            btnPdf.current.click()
            setLoad({...load,pdf:false})
        },1000)
    }
    
    return(
        <div className="row">
            <BlobProvider 
                document={(Object.keys(props.ticketDetail).length!=0 && tgcTotal!=NaN) ?
                    <Ticket 
                        currentShop={currentShop}
                        ticketPdf={props.ticketDetail}
                        t={t}
                        devise={props.devise}
                        digit={props.digit}
                        totalAchat={totalAchat}
                        tgc3={tgc3}
                        tgc6={tgc6}
                        tgc11={tgc11}
                        tgc22={tgc22}
                        tgcTotal={tgcTotal}
                        conditionsRetour={conditionsRetour}
                        //giftPdf={giftPdf}
                    />
                : 
                    false
                }
            >
                {({ url }) => (
                    <a ref={btnPdf} className="d-none" href={url} target="_blank">Print</a>
                )}
            </BlobProvider>
            <div className="col-6">
                <h5 className="fw-bold">{t('referencePo')+' :'}</h5>
                <div>{props.ticketDetail.bar}</div>
            </div>
            <div className="col-6">
                <h5 className="fw-bold">Date :</h5>
                <Moment format="DD/MM/YYYY HH:mm:ss">{props.ticketDetail.created_at}</Moment>
            </div>
            <div className="col-6 mt-3">
                <h5 className="fw-bold">{t('shop')+' :'}</h5>
                <div>{(props.ticketDetail.section) && props.ticketDetail.section.name}</div>
            </div>
            <div className="col-6 mt-3 position-relative">
                <h5 className="fw-bold">{t('cashier')+' :'}</h5>
                <InpDataList
                    placeholder={t('cashier')}
                    value={valueInputCashier}
                    onClick={handleCLick}
                    onChange={handleSearch}
                    datas={datas}
                    declenchement={2}
                    listRef={dataListDrop}
                    inputRef={searchInput}
                    onBlur={handleBlur}
                    inputName="searchCashier"
                    className={(invalidInput.searchCashier) ? "form-control is-invalid" : "form-control"}
                    onFocus={noRedInput}
                    onKeyPress={handleKeyPress}
                />
                {(load.caissier) ?
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm topBarLeftPosition3"></div></span>
                :
                    false
                }
            </div>
            <div className="col-6 mt-3">
                <h5 className="fw-bold">{t('customer')+' :'}</h5>
                <div className="row">
                    <ActionClientBarLeft 
                        setValueInputClient={setValueInputClient}
                        valueInputClient={valueInputClient}
                        invalidInput={invalidInput}
                        setInvalidInput={setInvalidInput}
                        noRedInput={noRedInput}
                        updateCurentTicket={updateCurentTicket}
                        load={load}
                        currentTicket={props.ticketDetail}
                        from='erp'
                        style="col-7 position-relative"
                        searchInputClient={searchInputClient}
                    />
                </div>
                
            </div>
            <div className="col-6 mt-3">
                <button className="btn btn-primary" onClick={()=>handleClick()}>
                    {load.pdf ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : <span>{t('download')}&nbsp;<FontAwesomeIcon icon={faFilePdf} className='fs-5' /></span>}
                </button>
            </div>
        </div>
    )
}

export default GeneralInfos;