import React from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faMinus} from '@fortawesome/free-solid-svg-icons'

const LineItemTo = (props) =>{
    const t =useTranslate()
    
    const handleClick = (name) =>{
        if(name=='plus'){
            let currentItem = props.articleToList[props.value.id]
            currentItem.quantity = currentItem.quantity = props.value.quantity+1
            props.setArticleToList({...props.articleToList,[props.value.id]:currentItem})
            
        }else if(name=='minus'){
            if(props.value.quantity>=2){
                let currentItem = props.articleToList[props.value.id]
                currentItem.quantity = currentItem.quantity = props.value.quantity-1
                props.setArticleToList({...props.articleToList,[props.value.id]:currentItem})
            }
        }else{
            //on supprime la ligne concerné
            let ajour={}
            Object.entries(props.articleToList).map(([key,value])=>{
                if(key!=props.value.id){
                    ajour[key]=value
                }
            })
            props.setArticleToList(ajour)
        }
    }

    return(
        <>
            <div className={props.style}>
                <div className="row">
                    <strong className="fsArticlePos">{props.value.name}</strong> 
                </div>
                <div className="row marginLeftArticle">
                    <div className="col">
                        <div className="row">
                            <div className="col-3">
                                <strong>{'x'+props.value.quantity}</strong>
                            </div>
                            <div className="col-9 text-end">
                                <button className="btn btn-success btn-sm sizeBtnPos" onClick={()=>handleClick('plus')} ><FontAwesomeIcon icon={faPlus} /></button>&nbsp;
                                <button className="btn btn-danger btn-sm sizeBtnPos" onClick={()=>handleClick('minus')} ><FontAwesomeIcon icon={faMinus} /></button>&nbsp;&nbsp;
                                <button className="btn btn-danger btn-sm sizeBtnPos" onClick={()=>handleClick('delete')} >{t('btnDelete')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LineItemTo;