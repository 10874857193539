import React, {useState,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual';
import {imprimeEtiquette} from 'app/imprimeEtiquette'
import { IsAuthContext } from '../../../app/App';
import { espace_mille } from 'app/espace_mille';
import swal from "sweetalert2";

const ModalTagPrintForm = (props) =>{
    const t = useTranslate();
    const [qteTag,setQteTag]=useState(1)
    const { authParam } = useContext(IsAuthContext)

    let param = (props.cookies) ? props.cookies.peripherique.split('/!') : props.peripheriqueParam
    
    let devise = authParam.company.devise;
    let formatedPrice = espace_mille(props.articlePrice)

    const handleChange = (e) =>{
        setQteTag(e.target.value.replace(/\D/g,''))
    }

    const handleSubmit = () =>{
        if(qteTag>100){
            swal.fire(t('limitTagPrint100'), "", "error");
        }else if(qteTag<1){
            swal.fire(t('mintTagPrint1'), "", "error");
        }else{
            imprimeEtiquette(param,devise,formatedPrice,t,props.articleName,props.articleUpc,qteTag,props.from)
        }
    }

    return(
        <div>
            <label>{t('qteTag')}</label>
            <input type="text" className="form-control mb-3" value={qteTag} onChange={(e)=>handleChange(e)}/>
            <button className="btn btn-primary" onClick={() => handleSubmit()}>{t('btnPrint')}</button>
        </div>
    )
}

export default ModalTagPrintForm;