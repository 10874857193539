import React,{useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import LinePaymentRecord from './Comp/LinePaymentRecord'

const ModalChangePaymentMethod = (props) =>{
    const t =useTranslate()
    const [allPaymentRecords,setAllPaymentRecords]=useState([])
    const [ticketId,setTicketId]=useState(false)
    const [ticketDetail, setTicketDetail]=useState({})
    
    const [loading,setLoading]=useState({
        data:false,
    })

    let url = (props.from=='commande') ? "api/pos/commande/get_by_reference/" : "api/pos/ticket/get_by_reference/"

    useEffect(function () {
        (async function() {
            setLoading({...loading,data:true})
            const response = await axios.get(process.env.REACT_APP_API_URL+url+props.barTicket
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{   
                    if(props.from=='commande'){
                        setAllPaymentRecords(response.data.data.ticket.paiement_records)
                        setTicketId(response.data.data.ticket.id)
                        setTicketDetail(response.data.data.ticket)
                    }else{
                        setAllPaymentRecords(response.data.data.paiement_records)
                        setTicketId(response.data.data.id)
                        setTicketDetail(response.data.data)
                    }
                    setLoading({...loading,data:false})
                }
            }).catch((error) => {
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
            setLoading({...loading,data:false})
        })()
    }, [])



    if(loading.data){
        return(
            <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
        )
    }else{
        return(
            <div className="row">
                <div className="col">
                    <div className="table-responsive">
                        <table
                        className="table"
                        >
                            <thead>
                                <tr>
                                    <th scope="col">{t('modePaiement')}</th>
                                    <th scope="col">{t('amount')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(allPaymentRecords.length!=0 && Object.keys(ticketDetail).length!=0) ?
                                    allPaymentRecords.map((value,index)=>{
                                        return(
                                            <LinePaymentRecord
                                                key={value.id}
                                                value={value}
                                                currentShop={props.currentShop}
                                                devise={props.devise}
                                                digit={props.digit}
                                                posid={props.posid}
                                                ticketId={ticketId}
                                                setAllPaymentRecords={setAllPaymentRecords}
                                                dataTicket={props.dataTicket}
                                                setDataTicket={props.setDataTicket}

                                                caisseIdent={ticketDetail.caisse_ident_id}
                                                pos_id={ticketDetail.pos_id}
                                                module={props.module}
                                                from={props.from}
                                                setDataSaleOrder={props.setDataSaleOrder}
                                            />
                                        )
                                    })
                                :
                                    <tr>
                                        <td>{t('noPaymentMethodToEdit')}</td>
                                        <td></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )    
    }
}

export default ModalChangePaymentMethod;