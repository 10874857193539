import React, {useState, useEffect} from 'react';
import { useTranslate } from 'react-redux-multilingual';
import { Modal, Button} from "react-bootstrap";
import { Loading, CardPerso, SubmitForm } from "@gull";
import { useForm } from "react-hook-form";
import axios from 'axios';
import swal from "sweetalert2";

import { tgc_list } from "app/tgc";
import { devises_list } from "app/devises";

const useLoad = (modalEditId,setValue) => {
    const [loading,setLoading]=useState(true)
    const [liaison,setLiaison]=useState(false)
    const [article,setArticle]=useState(false)
    const t = useTranslate();

    useEffect(function () {
        (async function() {
            setLoading(true)
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/one/"+modalEditId
            ).then((response) => {
                setLoading(false)
                setArticle(response.data.data)
                setValue('reference',response.data.data.reference)
                setValue('reference2',response.data.data.reference2)
                setValue('name',response.data.data.name)
                setValue('sku',response.data.data.sku)
                setValue('price',response.data.data.price)
                setValue('tgc',response.data.data.tgc)
                setValue('parent_name',response.data.data.parent_name)
                setValue('buy_price',response.data.data.buy_price)
                setValue('devise_achat',response.data.data.devise_achat)
                setValue('indice',response.data.data.indice)
                setValue('firstlevelcat',response.data.data.firstlevelcat_id)
                setValue('secondlevelcat',response.data.data.secondlevelcat_id)
                setValue('thirdlevelcat',response.data.data.thirdlevelcat_id)
                setValue('fourthlevelcat',response.data.data.fourthlevelcat_id)
                setValue('fifthlevelcat',response.data.data.fifthlevelcat_id)
                setValue('fournisseur',response.data.data.fournisseur_id)
                setValue('marque',response.data.data.marque_id)
                setValue('image',response.data.data.image)
                let havestock = (response.data.data.havestock) ? 'oui' : 'non'
                setValue('havestock',havestock)
                let keys = Object.keys(response.data.data.custom_fields);
                    for(var i = 0; i < keys.length; i++){
                        setValue(keys[i],response.data.data.custom_fields[keys[i]])
                    }
            }).catch((error) => {
                setLoading(false)
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [modalEditId])
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/liaison"
            ).then((response) => {
                setLoading(false)
                setLiaison(response.data.datas)
            }).catch((error) => {
                setLoading(false)
                let errorReturn
                if(error.response==undefined){
                    errorReturn='unknowError'
                }
                else if(error.response.status==403){
                    errorReturn='forbiddenGeneral'
                }
                else {
                    errorReturn='unknowError'
                }
                swal.fire(t(errorReturn), "", "error");
            })
        })()
    }, [])
    
    return [loading,liaison]
}



const ArticleModalEdit = (props) =>{
    const t = useTranslate();
    const { register, formState: { errors }, handleSubmit, setValue } = useForm();
    const [success,setSuccess] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [validButton, setValidButton] = useState(false)
    const [loading,liaison] = useLoad(props.modalEditId,setValue)

    const handleChangeNumber = (e,name) => {
        setValue(name,e.target.value.replace(/\D/g,''));   
    }

    const handleChangeNumberPoint = (e,name) => {
        setValue(name,e.target.value.replace(/[^0-9\.]/g, '')); 
    }

    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)
        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/article/edit/"+props.modalEditId, data
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                    }
                    else{
                        messageError+=t(interm)+'<br/>'
                    }
                })
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
                if(props.from=="list"){
                    let i = 0
                    props.articlesFromList.forEach((value,ind)=>{
                        if(value.id==response.data.data.id) i=ind
                    })
                    let updateList = props.articlesFromList.filter(value=>value.id!=response.data.data.id)
                    updateList.splice(i,0,response.data.data)
                    props.setArticlesFromList(updateList)
                    setSuccess(true)
                }
                if(props.from=="details"){
                    props.setArticleFromDetails(response.data.data)
                    setSuccess(true)
                }
                
            }
        }).catch((error) => {
            if(error.response==undefined){
                setApiError('unknowError')
            }
            else if(error.response.status==403){
                setApiError('forbiddenGeneral')
            }
            else {
                setApiError('unknowError')
            }
        })
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false);
        },1000)
    }

    return(<>
      <Modal size="xl" show={props.showModalEdit} onHide={() => props.setShowModalEdit(false)}>
          <Modal.Header closeButton>
              <Modal.Title>{t('editArticle')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {(loading) ?
                <div className="text-center"><div className="spinner-border spinner-border-sm"></div></div>
            :
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row mb-2">
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('reference')}*:</label>
                        <input type="text" className="form-control form-control-sm" {...register('reference',{required: true, onChange:(e) => handleChangeNumber(e,'reference')})} />
                        {errors.reference?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('referenceRequired')}
                        </div>
                        )}
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('reference2')}:</label>
                        <input type="text" className="form-control form-control-sm" {...register('reference2', {onChange:(e) => handleChangeNumber(e,'reference2')})} />
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('itemName')}*:</label>
                        <input type="text" className="form-control form-control-sm" {...register('name',{required: true})} />
                        {errors.name?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('nameRequired')}
                        </div>
                        )}
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('sku')}*:</label>
                        <input type="text" className="form-control form-control-sm" {...register('sku',{required: true})} />
                        {errors.name?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('skuRequired')}
                        </div>
                        )}
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('priceTtc')}*:</label>
                        <input type="text" className="form-control form-control-sm" {...register('price',{required: true, onChange:(e) => handleChangeNumberPoint(e,'price')})} />
                        {errors.price?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('priceRequired')}
                        </div>
                        )}
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('tgc')}*:</label>
                        <select type="text" className="form-control form-control-sm" {...register('tgc',{required: true})}>
                            <option value="">-- Select --</option>
                            {tgc_list.map((value,index)=>
                                <option key={index} value={value}>{value}%</option>
                            )}
                        </select>
                        {errors.tgc?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('tgcRequired')}
                        </div>
                        )}
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('parent_name')}:</label>
                        <input type="text" className="form-control form-control-sm" {...register('parent_name')} />
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('buy_price')}:</label>
                        <input type="text" className="form-control form-control-sm" {...register('buy_price', {onChange:(e) => handleChangeNumberPoint(e,'buy_price')})} />
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('devise_achat')}:</label>
                        <select type="text" className="form-control form-control-sm" {...register('devise_achat')}>
                            <option value="">-- Select --</option>
                            {devises_list.map((value,index)=>
                                <option key={index} value={value.code}>{value.name+" - "+value.code}</option>
                            )}
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('prmpIndice')}:</label>
                        <input type="text" className="form-control form-control-sm" {...register('indice', {onChange:(e) => handleChangeNumberPoint(e,'indice')})} />
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">Classification:</label>
                        <select className="form-control form-control-sm" {...register('firstlevelcat')}>
                            <option value="">-- Select --</option>
                            {(liaison.firstlevelcat) && liaison.firstlevelcat.map((value,index)=>
                                    <option key={index} value={value.id}>{value.name}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('secondlevelcat')}:</label>
                        <select className="form-control form-control-sm" {...register('secondlevelcat')}>
                            <option value="">-- Select --</option>
                            {(liaison.secondlevelcat) && liaison.secondlevelcat.map((value,index)=>
                                    <option key={index} value={value.id}>{value.name}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('thirdlevelcat')}:</label>
                        <select className="form-control form-control-sm" {...register('thirdlevelcat')}>
                            <option value="">-- Select --</option>
                            {(liaison.thirdlevelcat) && liaison.thirdlevelcat.map((value,index)=>
                                    <option key={index} value={value.id}>{value.name}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('fourthlevelcat')}:</label>
                        <select className="form-control form-control-sm" {...register('fourthlevelcat')}>
                            <option value="">-- Select --</option>
                            {(liaison.fourthlevelcat) && liaison.fourthlevelcat.map((value,index)=>
                                    <option key={index} value={value.id}>{value.name}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('fifthlevelcat')}:</label>
                        <select className="form-control form-control-sm" {...register('fifthlevelcat')}>
                            <option value="">-- Select --</option>
                            {(liaison.fifthlevelcat) && liaison.fifthlevelcat.map((value,index)=>
                                    <option key={index} value={value.id}>{value.name}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('fournisseur')}:</label>
                        <select className="form-control form-control-sm" {...register('fournisseur')}>
                            <option value="">-- Select --</option>
                            {(liaison.fournisseur) && liaison.fournisseur.map((value,index)=>
                                    <option key={index} value={value.id}>{value.name}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('marque')}:</label>
                        <select className="form-control form-control-sm" {...register('marque')}>
                            <option value="">-- Select --</option>
                            {(liaison.marque) && liaison.marque.map((value,index)=>
                                    <option key={index} value={value.id}>{value.name}</option>
                                )
                            }
                        </select>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('imageLink')}:</label>
                        <input type="text" className="form-control form-control-sm" {...register('image')} />
                    </div>
                    {props.customFields.map((item, index)=>(
                        <div className="col-md-6 col-lg-4 mb-2" key={index}>
                            <label className="col-form-label col-form-label-sm" htmlFor={item.name}>{item.name}{(item.required) ? '*' : false}</label>
                            {(item.free) ?
                                <>
                                    <input className="form-control form-control-sm" type="text" {...register(item.name,{required:item.required})}/>
                                    {errors[item.name]?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('youMustIndicateField')+' "'+item.name+'"'}
                                    </div>
                                    )}
                                </>
                                :
                                <>
                                    <select className="form-control form-control-sm" {...register(item.name,{required:item.required})} >
                                        <option value="">-- Select --</option>
                                        {item.list.map((value,index)=>
                                            <option key={index} value={value}>{value}</option>
                                        )}
                                    </select>
                                    {errors[item.name]?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('youMustIndicateField')+' "'+item.name+'"'}
                                    </div>
                                    )}
                                </>
                            }
                        </div>
                    ))}
                    <div className="col-md-6 col-lg-4 mb-2">
                        <label className="col-form-label col-form-label-sm">{t('thisItemMustHaveStock')}*:</label>
                        <select className="form-control form-control-sm" {...register('havestock',{required:true})}>
                            <option value="">-- Select --</option>
                            <option value="oui">{t('yes')}</option>
                            <option value="non">{t('no')}</option>
                        </select>
                        {errors.havestock?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('havestockRequired')}
                        </div>
                        )}
                    </div>
                </div>
                <SubmitForm error={apiError} success={success} validButton={validButton} />
            </form>}
          </Modal.Body>
      </Modal>
      </>
    )
}

export default ArticleModalEdit;