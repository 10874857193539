import React from 'react';
import { useTranslate } from 'react-redux-multilingual';
import swal from "sweetalert2";


const SelectFields = (props)=> {
    const t = useTranslate();
    
    const handleChange = (e) => {
        if(props.multipleCards==='true'){
            let arr = [];
            //On crée un nouveau tableau avec les valeurs de tous les tableaux existant
            Object.entries(props.datas).forEach(([keyName, data]) => {
                arr = arr.concat(data)
            })
            const alreadyIn = arr.find(value=> value===e.target.value)
            if (e.target.value){
                if(alreadyIn){
                    swal.fire(
                        e.target.value+' '+t('alreadyExist'), "", "error"
                    );
                }else{
                    //On crée un tableau qui reprend les valeurs du tableau sélectionné 
                    let newArr = props.datas[props.keyName]
                    //On rajoute la nouvelle valeur dans le nouveau tableau créé
                    newArr.push(e.target.value)
                    //On met à jour mon objet initial en indiquant que le nouveaut tableau remplaçera les valeurs de la clé sélectionné
                    props.setDatas({...props.datas,[props.keyName]: newArr})
                }
            }
        }else{
            const alreadyIn = (props.datas.length>0) && props.datas.find(value=> value===e.target.value)
            if (e.target.value){
                if(alreadyIn){
                    swal.fire(
                        (e.target.value==='name')? t('itemName')+' '+t('alreadyExist') : 
                        (e.target.value==='price')? t('priceTtc')+' '+t('alreadyExist') :
                        (e.target.value==='indice')? t('prmpIndice')+' '+t('alreadyExist') :
                        (e.target.value==='image')? t('imageLink')+' '+t('alreadyExist') :
                        (e.target.value==='quota_commande')? t('quantity') :
                        (e.target.value==='commande')? t('orderDate') :
                        (e.target.value==='expedition')? t('expectedDeliveryDate') :
                        (e.target.value==='reception')? t('expectedReceiptDate') :
                        (e.target.value==='user')? t('receivedBy') :
                        t(e.target.value) ? t(e.target.value)+' '+t('alreadyExist') : 
                        e.target.value+' '+t('alreadyExist'), "", "error"
                    );
                }else{
                    if(props.datas.length>0){
                        props.setDatas([...props.datas, e.target.value])
                    }else{
                        props.setEmptyConf(false)
                        props.setNoDefaultConf(false)
                        props.setDatas([e.target.value])
                    }
                    
                }
            }
        }
    }

    return(
        <div className="row ">
            <form onChange={(e) => handleChange(e)}>
                <div className="col form-group">
                    <label className="col-form-label col-form-label-sm">{t('labelSelectConfigue')}</label>
                    <select className="form-control form-control-md" >
                        <option value="">-- Select --</option>
                        {(props.fields) && props.fields.map((value,index)=>
                                <option key={index} value={value}>
                                    {
                                        (value==='name')? t('itemName') : 
                                        (value==='price')? t('priceTtc') :
                                        (value==='indice')? t('prmpIndice') :
                                        (value==='image')? t('imageLink') :
                                        (value==='quota_commande')? t('quantity') :
                                        (value==='commande')? t('orderDate') :
                                        (value==='expedition')? t('expectedDeliveryDate') :
                                        (value==='reception')? t('expectedReceiptDate') :
                                        (value==='user')? t('receivedBy') :
                                        t(value) ? t(value) : 
                                        value
                                    } 
                                </option>
                            )
                        }
                    </select>
                </div>
            </form>
        </div>
    )
}

export default SelectFields ;