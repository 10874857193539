import React, { Fragment } from "react";
import Moment from 'react-moment';
import { useTranslate } from 'react-redux-multilingual';

const HeadInfos = (props) => {
  const t = useTranslate();

  return (
    <>
      <div className="breadcrumb">
          <Fragment>
              <h1>{props.titre}</h1> 
          </Fragment>
          <ul>
            {props.otherHeadInfos
              ? props.otherHeadInfos.map((infos, index) =>(
                    <li key={index}>
                      <span className="capitalize text-muted">{infos}</span>
                    </li>
                  )
                )
              : null}
            {(props.date) 
              ? 
              <li>
                <span className="capitalize text-muted"><Moment format="DD/MM/YYYY HH:mm:ss">{props.date}</Moment></span>
              </li>
              : null
            }
          </ul>
      </div>
      {props.titre2 &&
        <div className="breadcrumb">
          <Fragment>
              <h4>{props.titre2}</h4> 
          </Fragment>
        </div>
      }
      {props.titre3 &&
        <div className="breadcrumb">
          <Fragment>
              <h6>{props.titre3}</h6> 
          </Fragment>
        </div>
      }      
      <div className="separator-breadcrumb border-top"></div>
    </>
  );
};

export default HeadInfos;
