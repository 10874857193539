import ArticleDetail from "./views/erp/Articles/ArticleDetail/ArticleDetail"

let translation = {
    en: {
      locale: 'en-US',
      messages: {
        /*********form response***********/
        //required
        nameRequired: 'The "name" field is required',
        firstnameRequired: 'The "first name" field is required',
        emailRequired: 'The "email" field is required',
        passwordRequired: 'The "password" field is required',
        password_confirmationRequired: 'The "password confirmation" field is required',
        companyRequired: 'The "company" field is required',
        moduleRequired: 'The "module" field is required',
        sectionRequired: 'The "shop" field is required',
        sexeRequired: 'The "sexe" field is required',
        typefideliteRequired: 'The "Type of Loyalty Card" field is required',
        maxusemoisRequired: 'The "Period of validity" field is required',
        maxusemoisproformaRequired: 'The "Validity period of a proforma" field is required',
        modepaiementRequired: 'The "Payment methods" field is required',
        themeRequired: 'The "Theme" field is required',
        donneeclientRequired: 'The "Activate the obligation to collect customer data " field is required',
        objectifvendeurRequired: 'The "Seller objective" field is required',
        objectifcommerceRequired: 'The "Shop objective" field is required',
        dixmilleRequired: 'You must indicate the number of 10 000 XPF banknotes',
        cinqmilleRequired: 'You must indicate the number of 5 000 XPF banknotes',
        milleRequired: 'You must indicate the number of 1 000 XPF banknotes',
        cinqcentRequired: 'You must indicate the number of 500 XPF banknotes',
        deuxcentRequired: 'You must indicate the number of  200 XPF coins',
        centRequired: 'You must indicate the number of  100 XPF coins',
        cinquanteRequired: 'You must indicate the number of  50 XPF coins',
        vingtRequired: 'You must indicate the number of  20 XPF coins',
        dixRequired: 'You must indicate the number of  10 XPF coins',
        cinqRequired: 'You must indicate the number of  5 XPF coins',
        deuxRequired: 'You must indicate the number of  2 XPF coins',
        unRequired: 'You must indicate the number of  1 XPF coins',
        gradeRequired: 'You must indicate a grade',
        caRequired: 'You must indicate a CA',
        pmRequired: 'You must indicate a PM',
        tfRequired: 'You must indicate a TF',
        ivRequired: 'You must indicate a IV',
        entreRequired: 'You must indicate a number of customers passages (entry and exit)',
        beginRequired: 'You must indicate de start date',
        endRequired: 'You must indicate de end date',
        dayRequired: 'You must indicate the day',
        telephoneRequired: 'Phone is required',
        adresseRequired: 'Pysical Adress is required',
        adressepostaleRequired: 'Postal Adress is required',
        newsletterRequired: 'You must check the newsletter',
        civiliteRequired: 'Civility is required',
        csvEncodeRequired: 'CSV Encode is required',
        csvSeparatorRequired: 'CSV Separator is required',
        csvDateFormatRequired: 'CSV Date Format is required',
        codeRequired:'Code is required',
        rcsRequired:'RCS is required',
        adressRequired:'Adress is required',
        codePostalRequired:'Zip code is required',
        villeRequired:'Town is required',
        paysRequired:'Country is required',
        codeBanqueRequired: 'Bank Code is required',
        codeGuichetRequired: 'Sort Code is required',
        numeroCompteRequired: 'Account Number is required',
        cleRibRequired: 'RIB Key is required',
        quartierRequired: 'An area is required',
        typeRequired: 'A type is required',
        dateDeNaissanceRequired: 'A date of birth is required',
        providerNameRequired: 'You must indicate a provider name',
        contactNameRequired: 'You must indicate a contact name',
        statusRequired: 'You must indicate a status',
        itemFamilyNameRequired: 'You must indicate an item family name',
        brandNameRequired: 'You must indicate un brand name',
        categoryRequired: 'You must indicate a category',
        referenceRequired: 'You must indicate a UPC Code',
        referenceToRequired: 'You must indicate a reference',
        skuRequired: 'You must indicate a SKU',
        internalidRequired: 'You must indicate the internal ID of the item',
        priceRequired: 'You must indicate a Price duty includes',
        tgcRequired: 'You must indicate the TGC rate',
        prmpRequired: 'You must indicate the PRMP',
        havestockRequired: 'You must indicate if this item must be dependent on a stock',
        colorHexaRequired: 'You must indicate a hexadecimal color code',
        banqueRequired: 'You must indicate the bank name',
        customFieldRequired: 'You must fill the "{name}"',
        numeroShipmentRequired:'You must indicate the shipment number',
        shdeCartonNameRequired:'You must indicate the carboard reference',
        shdeQuotaPrevuRequired:'You must indicate the expected quatity',
        firstStartBinsRequired:'You must indicate the beginning of the first level',
        firstEndBinsRequired:'You must indicate the end of the first level',
        zoneRequired:'You must indicate the zone',
        secondEndBinsRequiredWith:'You must indicate the end of the second level if the beginning of the second level is filled',
        thirdEndBinsRequiredWith:'You must indicate the end of the third level if the beginning of the third level is filled',
        fourthEndBinsRequiredWith:'You must indicate the end of the fourth level if the beginning of the fourth level is filled',
        fifthEndBinsRequiredWith:'You must indicate the end of the fifth level if the beginning of the fifth level is filled',
        firstBinsRequired:'You must indicate the first level',
        quotaRequired: 'The quantity is required',
        actionRequired:'You must indicate the action',
        binsRequired:'You must indicate the bins',
        quantiteRequired:'you must indicate the quantity',
        fieldRequired:'This field is required',
        expectedRequired: 'The desired date of execution is required',
        rhRequired: 'A staff is required',
        rhBarreRequired: 'A staff barcode is required',
        osectionRequired: 'The origin shop is required',
        fsectionRequired: 'The destination shop is required',
        rpRequired: 'The reorder point is required',
        pslRequired: 'The preferred stock level is required',
        toMaxRequired: 'The maximum number of instructions in a transfert order is required',
        articleRequired: 'You must choose an item',
        promotionRequired: 'You muste choose a promotion',
        allFieldsRequired: 'All fields should be filled',
        promoImport:'Promotions import',
        codeCaisseRequired: 'A POS identifier is required',
        caisseIdentRequired:'The POS identifier is required',
        hostRequired:'You must provide an IP address',
        comPortRequired:'You must provide a COM port',
        modelRequired:'You must choose a model',
        waitingEpt:'Waiting for EPT',
        errorEpt:'Error response EPT',
        newCheckoutShortcutProfil:'New shortcut profil',
        checkoutShorcutProfil:'Shortcuts profil',
        //Min
        nameMin2: 'The "name" field must be at least 2 characters long',
        nameMin5: 'The "name" field must be at least 5 characters long',
        skuMin2: 'The "Sku" field must be at least 2 characters long',
        firstnameMin2: 'The "first name" field must be at least 2 characters long',
        passwordMin8: 'The "password" field must be at least 8 characters long',
        phonedMin6: 'The "phone" field must be at least 6 characters long',
        phoneMin6: 'The "phone" field must be at least 6 characters long',
        maxusemoisdMin1: 'The "Period of validity" field must be at least 1 month',
        maxusemoisproformaMin1: 'The "Validity period of a proforma" field must be at least 1 month',
        dixmilleMin0: 'The number of 10 000 XPF banknotes must be at least 0',
        cinqmilleMin0: 'The number of 5 000 XPF banknotes must be at least 0',
        milleMin0: 'The number of 1 000 XPF banknotes must be at least 0',
        cinqcentMin0: 'The number of 500 XPF banknotes must be at least 0',
        deuxcentMin0: 'The number of 200 XPF coins must be at least 0',
        centMin0: 'The number of 100 XPF coins must be at least 0',
        cinquanteMin0: 'The number of 50 XPF coins must be at least 0',
        vingtMin0: 'The number of 20 XPF coins must be at least 0',
        dixMin0: 'The number of 10 XPF coins must be at least 0',
        cinqMin0: 'The number of 5 XPF coins must be at least 0',
        deuxMin0: 'The number of 2 XPF coins must be at least 0',
        unMin0: 'The number of 1 XPF coins must be at least 0',
        caMin0: 'The CA must be at least 0',
        pmMin0: 'The PM must be at least 0',
        tfMin0: 'The TF must be at least 0',
        ivMin0: 'The IV must be at least 0',
        entreMin0: 'The number of customers passages (entry and exit) must be at least 0',
        codeMin3: 'The "code" field must be at least 3 characters long',
        rcsMin5: 'The "RCS" field must be at least 5 characters long',
        codeBanqueMin5: 'The "Bank Code" field must be at least 5 characters long',
        codeGuichetMin5: 'The "Sort Code" field must be at least 5 characters long',
        numeroCompteMin11: 'The "Account Number" field must be at least 11 characters long',
        cleRibMin2: 'The "RIB Key" field must be at least 2 characters long',
        nomContactMin2: 'Contact name must be at least 2 characters long',
        priceMin: 'The price can\'t be under 0',
        promoPriceMin: 'The promotionnal price can\'t be under 0',
        tgcMin: 'The TGC rate can\'t be under 0',
        colorHexaMin: 'The hexadecimal color code must have 8 characters',
        buyPriceMin: 'The buying price can\'t be under 0',
        indicePrmpMin: 'The indice PRMP can\'t be under 0',
        deviseAchatMin: 'The buying currency must have 3 characters',
        quotaMin: 'The quantity can\'t be under 0',
        quotaCommandeMin: 'The quantity can\'t be under 0',
        numeroShipmentMin2:'The field "shipment number" must be at least 2 characters long',
        shdeCartonNameMin2: 'The carboard reference must be at least 2 characters long',
        shdeQuotaPrevuMin: 'The expected quantity can\'t be under 0',
        firstStartBinsMin0:'The beginning of the first level can\'t be under 0',
        firstEndBinsMin0:'The end of the first level can\'t be under 0',
        secondStartBinsMin0:'The beginning of the second level can\'t be under 0',
        secondEndBinsMin0:'The end of the second level can\'t be under 0',
        thirdStartBinsMin0:'The beginning of the third level can\'t be under 0',
        thirdEndBinsMin0:'The end of the third level can\'t be under 0',
        fourthStartBinsMin0:'The beginning of the fourth level can\'t be under 0',
        fourthEndBinsMin0:'The end of the fourth level can\'t be under 0',
        fifthStartBinsMin0:'The beginning of the fifth level can\'t be under 0',
        fifthEndBinsMin0:'The end of the fifth level can\'t be under 0',
        firstBinsMin0:'The first level can\'t be under 0',
        secondBinsMin0:'The second level can\'t be under 0',
        thirdBinsMin0:'The third level can\'t be under 0',
        fourthBinsMin0:'The fourth level can\'t be under 0',
        fifthBinsMin0:'The fifth level can\'t be under 0',
        referencePoMin2:'The purchase order reference must be at least 2 characters long',
        rpIsMin0:'{name} reorder point RP can\'t be under 0',
        pslIsMin0:'{name} preferred stock level PSL can\'t be under 0', 
        quantiteMin1:'The quantity can\'t be under 1',
        referenceToMin2:'Reference must be at least 2 characters long',
        rpMin0:'The reorder point can\'t be less than 0',
        pslMin0:'Le preferred stock level can\'t be less than 0',
        toMaxMin10:'The maximum number of instructions in a transfert order can\'t be less than 10',
        giftCardMin1:'The validity period of a deposit on a gift card cannot be less than 1 month',
        giftCardMontantMin: 'The Gift Card amount can\'t be less than 1',
        paiementMin: 'The paiements indications must be over 0',
        modifManualValueMin1: 'The value must be over 1',
        codeCaisseMin4: 'The POS ident must be at least 4 characters long',
        detailModePaiementMin0: 'The values can\'t be less than 0',
        //Max
        quantiteMax8000000:'The quantity must be less than 8 000 000',
        nameMax191: 'The "name" field must be less than 191 characters',
        nameMax250: 'The "name" field must be less than 250 characters',
        nameMax50: 'The "name" field must be less than 50 characters',
        skuMax250: 'The "name" field must be less than 250 characters',
        phoneMax20: 'The "phone" field must be less than 20 characters',
        firstnameMax50: 'The "first name" field must be less than 50 characters',
        firstnameMax191: 'The "first name" field must be less than 191 characters',
        nameMax20: 'The "name" field must be less than 20 characters',
        logoMax50: 'The "logo" field must be less than 50 characters',
        gradeMax50: 'The "grade" field must be less than 50 characters',
        maxusemoisdMax120: 'The "Period of validity" field cannot exceed 120 months',
        maxusemoisproformaMax120: 'The "Validity perdiod of a proforma" field cannot exceed 120 months',
        caMax2000000000: 'The CA cannot exceed 2 000 000 000 XPF',
        pmMax2000000000: 'The PM cannot exceed 2 000 000 000 XPF',
        tfMax100: 'The TF cannot exceed 100 %',
        ivMax999: 'The IV cannot exceed 999 items',
        entreMax10000000: 'The number of customers passages (entry and exit) cannot exceed 10 000 000',
        codeMax10: 'The "code" field must be less than 10 characters',
        rcsMax20: 'The "RCS" field must be less than 20 characters',
        codeBanqueMax5: 'The "Bank Code" field must be less than 5 characters',
        codeGuichetMax5: 'The "Sort Code" field must be less than 5 characters',
        numeroCompteMax11: 'The "Account Number" field must be less than 11 characters',
        cleRibMax2: 'The "RIB Key" field must be less than 2 characters',
        nomContactMax191: 'Contact name must be less than 191 characters',
        priceMax: 'The price can\'t be over 999999999.99',
        referenceMax191: 'The UPC code must be less than 191 characters',
        reference2Max191: 'The UPC code 2 must be less than 191 characters',
        internalIdMax191: 'The internal ID must be less than 191 characters',
        referenceToMax191: 'The reference must be less than 191 characters',
        skuMax191: 'The SKU must be less than 191 characters',
        parentNameMax250: 'The Parent Name must be less than 250 characters',
        promoPriceMax: 'The promotionnal price can\'t be over 999999999.99',
        prmpMax: 'The PRMP can\'t be over 999999999.99',
        tgcMax: 'The TGC rate can\'t be over 100',
        imageMax250: 'The image link must be less than 250 characters',
        colorHexaMax: 'The hexadecimal color code must have 8 characters',
        buyPriceMax: 'The buying price can\'t be over 999999999.99',
        indicePrmpMax: 'The indice PRMP can\'t be over 999999999.99',
        deviseAchatMax: 'The buying currency must have 3 characters',
        maxPhoneLength6: 'Phone number must contains 6 numbers maximum',
        quotaCommandeMax: 'The quantity can\'t be over 8000000',
        quotaMax: 'The quantity can\'t be over 8000000',
        numeroShipmentMax190:'The shipment number must be less than 190 characters',
        shdeCartonNameMax250: 'The carboard reference must be less than 250 characters',
        shdeQuotaPrevuMax: 'The expected quantity can\'t be over 8000000',
        firstStartBinsMax32000:'The beginning of the first level can\'t be over than 32000',
        firstEndBinsMax32000:'The end of the first level can\'t be over than 32000',
        secondStartBinsMax32000:'The beginning of the second level can\'t be over than 32000',
        secondEndBinsMax32000:'The end of the second level can\'t be over than 32000',
        thirdStartBinsMax32000:'The beginning of the third level can\'t be over than 32000',
        thirdEndBinsMax32000:'The end of the third level can\'t be over than 32000',
        fourthStartBinsMax32000:'The beginning of the fourth level can\'t be over than 32000',
        fourthEndBinsMax32000:'The end of the fourth level can\'t be over than 32000',
        fifthStartBinsMax32000:'The beginning of the fifth level can\'t be over than 32000',
        fifthEndBinsMax32000:'The end of the fifth level can\'t be over than 32000',
        firstBinsMax32000:'The first level can\'t be over than 32000',
        secondBinsMax32000:'The second level can\'t be over than 32000',
        thirdBinsMax32000:'The third level can\'t be over than 32000',
        fourthBinsMax32000:'The fourth level can\'t be over than 32000',
        fifthBinsMax32000:'The fifth level can\'t be over than 32000',
        referencePoMax190:'The purchase order reference must be less than 190 characters',
        rpIsMax8000000:'{name} reorder point RP can\'t be over than 8000000',
        pslIsMax8000000:'{name} preferred stock level PSL can\t be over than 8000000',
        generateBarcodeMax1000:'You can\'t generate more than 1000 barcode',
        rpMax8000000:'The reorder point can\'t be over 8000000',
        pslMax8000000:'Le preferred stock level can\'t be over 8000000',
        toMaxMax1000:'The maximum number of instructions in a transfert order can\'t be over 1 000',
        giftCardMax120:'The validity period of a deposit on a gift card cannot be more than 120 month',
        giftCardMontantMax: 'The Gift Card amount can\'t be over 100 000 000',
        paiementMax: 'The paiements indications must be less than 1 000 000 000',
        codeCaisseMax30: 'The POS ident must be less than 30 characters long',
        cashOutReasonMax100: 'The cash out reason must be less than 100 characters',
        //email
        emailEmail: 'The "email" field must be a valid email',
        //unique
        emailUnique: 'There is already an account registered with this email address',
        nameUnique: 'This name already exists',
        skuUnique: 'An other item already have the same Sku',
        referenceUnique: 'The UPC Code is already use by an other item',
        reference2Unique: 'The UPC Code 2 is already use by an other item',
        skuUnique: 'The SKU is already use by an other item',
        alreadyExist: 'has already been assigned',
        sectionUnique: 'This section already exist',
        //confirmed
        passwordConfirmed: 'The password was incorrectly confirmed',
        confirmDifferentQuantityCollected:'The quantity collected does not match with the quantity asked, confirm ?',
        confirmCancelSaleOrder:'Are you sure you want to cancel this sale order',
        //In
        roleIn: 'The assigned role is not accepted',
        sexeIn: 'The assigned sexe is not accepted',
        themeIn: 'The assigned theme is not accepted',
        modepaiementIn: 'One of the payment methods indicated is not accepted',
        customFieldIn: 'The field "{name}" has an invalid value',
        //exists
        companyExists: 'This company does not exist',
        moduleExists: 'This module does not exist',
        sectionExists: 'This shop does not exist',
        rhExists: 'This staff does not exist',
        gradeExists: 'This grade does not exist',
        typefideliteExists: 'This type of Loyalty Card does not exist',
        programfideliteExists: 'This program of Loyalty Card does not exist',
        programgiftcardExists: 'This program of Gift Card does not exist',
        clientExists: 'There is already a registered customer with this firstname and last name',
        internalIdExists: 'Internal ID is incorrect',
        firstlevelcatExists: 'No match found with the "Classification" field',
        secondlevelcatExists: 'No match found with the "Femille" field',
        thirdlevelcatExists: 'No match found with the "Sous-famille" field',
        fourthlevelcatExists: 'No match found with the "Catégorie" field',
        fifthlevelcatExists: 'No match found with the "Sous-catégorie" field',
        fournisseurExists: 'No match found with the "Fournisseur" field',
        marqueExists: 'No match found with the "Marque" field',
        nameExists: 'This name is already in use',
        referenceExists: 'This reference is already in use',
        articleNameExists: 'No item matches this item name',
        articleSkuExists: 'No item matches this Sku',
        poNameExists: 'This purchase orders doesn\'t exist',
        sectionCodeExists: 'This shop code doesn\'t exist',
        numeroShipmentExists: 'This shipment number is already used',
        shipmentNameExists: 'This shipment number doesn\'t exists',
        idsExists: 'ID is wrong',
        binsExists: 'A bins with the same adress already exist for this stock zone',
        shdeCartonNameExists: 'This carboard doesn\'t exist in this shipment',
        binsAlreadyExistsInSection: 'There is already a bins with the same adress in this shop',
        binsNotInThisSection: 'This bins doesn\'t exists in this shop',
        codeCaisseExists: 'This POS ident is already use',
        caisseIdentExists:'This POS identifier doesn\'t exist',
        namePeripheriqueExists:'This device name is already in use',
        //array
        moduleArray: 'An unexpected error has occurred',
        sectionArray: 'An unexpected error has occurred',
        rhArray: 'An unexpected error has occurred',
        modepaiementArray: 'An unexpected error has occurred',
        raisonmodifprixArray: 'An unexpected error has occurred',
        //General
        sectionGeneral: 'An unexpected error has occurred',
        rhGeneral: 'An unexpected error has occurred',
        programfideliteGeneral: 'An unexpected error has occurred',
        programgiftcardGeneral: 'An unexpected error has occurred',
        forbiddenGeneral: 'You are not authorized to perform this action',
        //Numeric
        maxusemoisNumeric: 'The "Period of validity" field must be a numeric value',
        maxusemoisproformaNumeric: 'The "Validity period of a proforma" field must be a numeric value',
        dixmilleNumeric: 'The 10 000 XPF banknotes must be a numeric value',
        cinqmilleNumeric: 'The 5 000 XPF banknotes must be a numeric value',
        milleNumeric: 'The 1 000 XPF banknotes must be a numeric value',
        cinqcentNumeric: 'The 500 XPF banknotes must be a numeric value',
        deuxcentNumeric: 'The 200 XPF coins must be a numeric value',
        centNumeric: 'The 100 XPF coins must be a numeric value',
        cinquanteNumeric: 'The 50 XPF coins must be a numeric value',
        vingtNumeric: 'The 20 XPF coins must be a numeric value',
        dixNumeric: 'The 10 XPF coins must be a numeric value',
        cinqNumeric: 'The 5 XPF coins must be a numeric value',
        deuxNumeric: 'The 2 XPF coins must be a numeric value',
        unNumeric: 'The 1 XPF coins must be a numeric value',
        caNumeric: 'The CA must be a numeric value',
        pmNumeric: 'The PM must be a numeric value',
        tfNumeric: 'The TF must be a numeric value',
        ivNumeric: 'The IV must be a numeric value',
        entreNumeric: 'The number of customers passages (entry and exit) must be a numeric value',
        codeBanqueNumeric: 'The "Bank Code" must be a numeric value',
        codeGuichetNumeric: 'The "Sort Code" must be a numeric value',
        numeroCompteNumeric: 'The "Account Number" must be a numeric value',
        cleRibNumeric: 'The "RIB Key" must be a numeric value',
        priceNumeric: 'The Price must be a numeric value',
        promoPriceNumeric: 'The Promotionnal Price must be a numeric value',
        prmpNumeric: 'The PRMP must be a numeric value',
        tgcNumeric: 'The TGC rate must be a numeric value',
        buyPriceNumeric: 'The buying price must be a numeric value',
        indicePrmpNumeric: 'The PRMP indice must be a numeric value',
        internalIdNumeric: 'Internal Id must be a numeric value',
        referenceNumeric: 'The UPC code must be a numeric value',
        quotaNumeric: 'The quantity must be a numeric value',
        quotaCommandeNumeric: 'The quantity must be a numeric value',
        shdeQuotaPrevuNumeric: 'The expected quantity must be a numeric value',
        firstStartBinsNumeric: 'The beginning of the first level must be a numeric value',
        firstEndBinsNumeric: 'The end of the first level must be a numeric value',
        secondStartBinsNumeric: 'The beginning of the second level must be a numeric value',
        secondEndBinsNumeric: 'The end of the second level must be a numeric value',
        thirdStartBinsNumeric: 'The beginning of the third level must be a numeric value',
        thirdEndBinsNumeric: 'The end of the third level must be a numeric value',
        fourthStartBinsNumeric: 'The beginning of the fourth level must be a numeric value',
        fourthEndBinsNumeric: 'The end of the fourth level must be a numeric value',
        fifthStartBinsNumeric: 'The beginning of the fifth level must be a numeric value',
        fifthEndBinsNumeric: 'The end of the fifth level must be a numeric value',
        firstBinsNumeric: 'The first level must be a numeric value',
        secondBinsNumeric: 'The second level must be a numeric value',
        thirdBinsNumeric: 'The third level must be a numeric value',
        fourthBinsNumeric: 'The fourth level must be a numeric value',
        fifthBinsNumeric: 'The fifth level must be a numeric value',
        rpIsNumeric:'{name} reorder point RP must be a numeric value',
        pslIsNumeric:'{name} preferred stock level PSL must be a numeric value',
        quantiteNumeric:'The quantity must be a numeric value',
        rpNumeric:'The reorder pointmust be a numeric value',
        pslNumeric:'Le preferred stock level must be a numeric value',
        toMaxNumeric:'The maximum number of instructions in a transfert order must be a numeric value',
        giftCardNumeric:'The validity period of a deposit on a gift card must be a numerical value',
        giftCardMontantNumeric: 'The Gift Card amount must be a numeric value',
        paiementNumeric: 'The paiements indications must be some numerics values',
        modifManualValueNumeric: 'The value must be numeric',
        detailModePaiementNumeric: 'All values must be numerics',
        //Boolean
        donneeclientBoolean: 'An unexpected error has occurred',
        objectifvendeurBoolean: 'An unexpected error has occurred',
        objectifcommerceBoolean: 'An unexpected error has occurred',
        //General
        unknowError: 'An unexpected error has occurred',
        identFailed: 'Login credentials are incorrect',
        //divers
        assignedTo:'Assigned to',
        finalize:'Finalize',
        finalized:'Finalized',
        orderedInThisShop:'Ordered in this shop',
        toBeCollectedInThisShop:'To be collected in this shop',
        createATransferOrder:'Create a transfer order',
        hour:'Hour',
        ordered:'Ordered',
        articleNoReferenceFind: 'No item corresponds to this reference',
        mustIndicateReasonRetrait: 'You must indicate the reason for withdrawing cash',
        gradeHasUser: 'You can\'t delete this grade because some staff have this grade. To delete this grade you must before remove this grade for all staff who have it .',
        modepaiementRemove: 'You cannot delete this payment method because it is assigned to some of your shops. If you really want to delete it, you must first remove it from shops for which this payment method is assigned.',
        adminOverQuota: 'You cannot add the "Administration" module to this user because your users quota for this module has already been reached. Please contact C.I.Web if you wish to modify this quota.',
        erpOverQuota: 'You cannot add the "Orders and Inventory Management" module to this user because your users quota for this module has already been reached. Please contact C.I.Web if you wish to modify this quota.',
        usersOverQuota: 'You cannot add the "Users" module to this user because your users quota for this module has already been reached. Please contact C.I.Web if you wish to modify this quota.',
        wmsOverQuota: 'You cannot add the "WMS" module to this user because your users quota for this module has already been reached. Please contact C.I.Web if you wish to modify this quota.',
        posOverQuota: 'You cannot add the "POS" module to this user because your users quota for this module has already been reached. Please contact C.I.Web if you wish to modify this quota.',
        endUnderrBegin: 'The period end date cannot be lower than the period start date',
        perdioExceedAYear: 'The period cannot be longer than one year',
        dayDateFormat: 'The date of this CSV row is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        commandeDateFormat: 'The date of order is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        expectedDateFormat: 'The date of transfer is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        expeditionDateFormat: 'The expected delivery date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        receptionDateFormat: 'The expected receipt date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        beginDateFormat: 'The start date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        endDateFormat: 'The end date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        dateFormat: 'The date is not in a correct format. The correct format is the one indicated in your company\'s advanced settings.',
        beginRequiredWithEnd: 'You must indicate a start date since you indicated an begin date',
        endRequiredWithBegin: 'You must indicate an end date since you indicated a start date',
        sizeLineCsv: 'This row of the CSV file doesn\'t contain the correct numbers of column',
        row: 'Row',
        lines: 'Lines',
        noRhOrNoAuth: 'Non-existent or unauthorized staff to access this section',
        noRhTos: 'You don\'t have pending transfers orders',
        imageUrl: 'The image link must be a valid URL',
        havestockIn: 'You must indicate if this item must be dependent on a stock',
        zoneAlreadyHaveCommercial: 'This shop already has a stock zone with the type selling area',
        zoneAlreadyHaveCommande: 'This shop already has a stock zone with the type order area',
        archiveZoneAddArticle: 'This stock zone has some items and therefore can\'t be archived',
        deleteZoneAddArticle: 'This stock zone has some items and therefore can\'t be deleted',
        customFieldsAlreadyExists: 'A custom field with the same name already exist',
        youMustIndicateField: 'You must fill the',
        limitCsv: 'The CSV file must be less than {limit} lines',
        filename: 'File name',
        notANameForCustomFields: 'This name cannot be given to a custom field',
        quotaCommandeInteger: 'The quantity must be an integer',
        selectBinsOrZone: 'You must scan a bins or select a zone',
        shipmentLineAlreadyReceptione:'This block has already been validated',
        changHaveBinsMustEqualZero:'You cannot change the bins option for an area that already contains items. This area currently contains: {quota} items',
        referenceToAlreadyUse:'This reference is already use for a Transfer Order who is already start',
        rhNotInSection:'This staff doesn\'t exist or doesn\'t work in this origin shop',
        communToLineNotTheSame:'Some data that must be common to all lines of the same transfer order is not',
        noDoubleArticleTo:'This item is already present in another line of this transfer order',
        noAutorizedUpdateTo:'You cannot update a transfer order that has passed the collection stage',
        cantDeleteLine: 'You cannot delete this line',
        pslGte: 'The preferred stock level can\'t be less than the redorder point',
        noDockForReassort: 'You have no dock defined',
        noArticleForReassort: 'There are no items in your selection',
        noToMaxForReassort: 'You must first set the maximum number of instructions a transfer order can contain in your company settings',
        valuePromotionNotOk: 'The promotions values are incorrect',
        resteIsOverTicket: 'The amount paid is higher than the remaining amount of the ticket',
        majorPaiementError: 'Major paiement error, the customer has paid more than the asked price',
        paiementIsNegative: 'The amount payable cannot be negative',
        paiementIsPositive: 'The outstanding amount must be 0',
        noCommercialZone:'No commercial zone is define for this shop',
        noGiftCardFound: 'No gift card matches this reference',
        noTicketFound: 'No ticket matches this reference',
        noCommandeFound: 'No sales order matches this reference',
        noPossibilityToReturnArticle:'An error prevents the return of the item',
        articleAlreadyReturn: 'This item has been already return',
        hostIp:'This is not a valid IP adress',
        optional:'Optional',
        sectionDontHaveCommandeZone: 'The sections affected by this command do not have a command zone',
        commandeAlreayCancel: 'This sales order has already been cancelled',
        mustSpecifyRemboursement: 'You must specify the method of refund',
        mustSpecifyGiftCardReference: 'You must specify the gift card bar code',
        mustChooseOneRaccourci: 'You must choose a shortcut',
        /*************Modules name**************/
        moduleadmin:'Configuration',
        moduleerp:'ERP',
        modulewms:'WMS',
        modulepos:'POS',
        moduleusers:'Users',
        /*********divers***********/
        closeWithDifference:'Your checkout account does not appear to be correct. Do you still want to close as is ?',
        cetteLigne:'this line',
        yes: 'Yes',
        no: 'No',
        signIn: 'Sign In',
        password: 'Password',
        signOut: 'Sign Out',
        administration: 'Configuration',
        users: 'Licences',
        gestionStockCommandes: 'ERP',
        shops: 'Shops',
        shop: 'Shop',
        delete: 'Delete',
        remove: 'Remove',
        update: 'Update',
        confirmDeleteUser: 'Are you sure you want to delete the user',
        confirmDeleteGrade: 'Are you sure you want to delete the grade',
        typeDeleteX: 'Are you sure you want to delete ',
        typeRemoveX: 'Are you sure you want to remove ',
        confirmArchiveX: 'Are you sure you want to archive ',
        confirmActiveX: 'Are you sure you want to active ',
        confirmDeleteGoals: 'Are you sure you want to delete the goals of this perdiod: ',
        confirmRemoveRh: 'Are you sure you want to remove',
        confirmOpenFonCaisse: 'Are you sure you want to open this checkout with ',
        confirmAddClientForm: 'Are you sure you want to add a client form',
        confirmRemoveClientForm: 'Are you sure you want to remove the client form',
        ofFondCaisse: 'of cash fund',
        confirmArchiveX: 'Are you sure you want to archive ',
        confirmUnarchiveX: 'Are you sure you want to active ',
        confirmClockingX: 'Are you sure you want to clock in ',
        confirmValidateLine:'Are you sure you want to validate this block',
        confirmValidateAll:'Are you sure you want to validate all',
        confirmClosefondCaisse:'Are you sure you want to close checkout with ',
        confirmDifferenceFondCaisse:'Are you sure you want to open this checkout with a difference of ',
        de: 'From',
        vers: 'To',
        irreversibleAction: 'Warning: this action will be irreversible',
        company: 'Company',
        canceled: 'Canceled',
        noLogoActualy: 'No logo currently',
        man: 'Man',
        woman: 'Women',
        rh: 'Staff',
        modePaiement: 'Payment method',
        general: 'General',
        genere:'Generate',
        proposal:'Propo',
        tarifSellerModification: 'Manual modification of a price',
        openFondCaisse: 'Checkout opening - Cash fund',
        closeFondCaisse: 'Closing Checkout',
        repartGoals: 'Goals distribution',
        openCaisse: 'Open checkout',
        openTiroir:'Open cash drawer',
        open:'Open',
        closeWith:'Close with',
        a: 'at',
        searchArticle: 'Search item...',
        searchInWait: 'Searching...',
        cashier: 'Cashier',
        customerPlacehlder: 'Customer...',
        noRhForSection: 'No staff have been allocated for this point of sale',
        consultPriceItem: 'See price item...',
        seeSaleOrder:'See the sale order',
        stockArea: 'Stock Area',
        createStockArea: 'Create storage Area',
        stockHaveBins: 'This stock area must Have Bins',
        itemReference: 'Item Reference...',
        cashOut: 'Cash withdrawal',
        consulter: 'Consult',
        attribuer: 'Attribute',
        generateReassort: 'Generate a proposal restock',
        forDepotBanque: 'This withdrawal is for a bank deposit',
        retraitForOtherReason: 'This withdrawal is for an other reason',
        retraitOtherLabel: 'Indicate the reason you are withdrawing cash',
        confirmRetraitOtherReason: 'Are you sure you want to withdraw cash in the amount of',
        confirmRetraitBanqueReason:' Are you sure you want to deposit an amount of',
        confirmDeleteCustomerAssociate: 'Are you sure you want to delete this customer association',
        confirmDeleteDivers: 'Are you sur you want to delete',
        confirmDelete: 'Confirm deletion',
        successOperation: 'Operation performed successfully',
        successInHistorique: 'Import launched successfully. You can follow the import status in your import history.',
        addClient: 'Add Customer',
        editClient: 'Edit Customer',
        importGoals: 'Import goals',
        createGrade: 'Create Grade',
        createModePaiement: 'Add a payment method',
        createCashOutReason: 'Add proof of cash withdrawal at the POS',
        cashOutMore: 'Your proof of cash withdrawal at the cash POS',
        modePaiementForced: 'Basic payment methods',
        modePaiementMore: 'Your added payment methods',
        items: 'Items',
        today: 'Today',
        hier: 'Yesterday',
        demain: 'Tomorrow',
        noObjectif: 'There is no POS goals for this period.',
        objectifs: 'Goals',
        realise: 'Achieved',
        nbrEntreClient: 'Number of customers passages (entry and exit)',
        edit: 'Edit',
        period: 'Period',
        oneDay: 'One day',
        manyDays: 'Many days',
        day: 'Day',
        de: 'From',
        toA: 'To',
        disponible: 'Avaible',
        addCashOut: 'CashOut',
        noEnougthForReassort: '{name} does not have enough stock to execute this change',
        needEntre: 'This chart will be avaible when the number of custimer passages will be filled in',
        createGoals: 'Create Shop Goals',
        attributeStaffGoals: 'Attribute Goals to Staff',
        deleteGoals: 'Delete Goals',
        createOneDayGoals: 'Create goal of one day',
        dropCsv: 'Drop CSV file here or click to upload.',
        noDataInCsv: 'No data found in this csv file',
        objectifCsvFileToLong: 'The csv file must have 367 line maximum',
        doublonCsv: 'Some columns of the csv file are used on multiple data',
        template: 'Modèle',
        importedRows: 'Imported rows: ',
        infoModePaiement: 'This is to list the possible payment methods for your shop. Please note: each of your shops can use different payment methods. Therefore, you must go to the options of each of your shops to indicate the payment methods that are accepted there.',
        restrictionStaff: 'Staff Restrictions',
        reassortWait:'Restock proposal being calculated. This task may take a few minutes...',
        waitReassortTo:'Transfer order(s) being created. You will be redirected soon. This task may take a few minutes...',
        authorized: 'Authorized',
        noRestriction: 'No restriction',
        scanBadge: 'Scan your badge here...',
        formclient: 'Customer Form',
        explicationFormClient: 'Indicate the fields you want to appear in the customer creation form',
        field:'Field',
        obligatoire:'Required',
        requiredField: 'Required Field',
        notRequiredField: 'Not Required Field',
        uniqueField: 'Signle value',
        notUniqueField: 'Not Single Value',
        freeField: 'Free value',
        fieldListData: 'Submitted to a data list',
        civilite: 'Civility',
        activer: 'Activate',
        adressePhysique: 'Physical Adress',
        adressePostale: 'Postal Adress',
        inscriptionNewsletter: 'Newsletter Subscription',
        noFormClient: 'The customer form for your company is not configured',
        homme: 'Man',
        femme: 'Woman',
        noClientToEdit: 'You must first indicate a customer',
        noCashierIndicated : 'You must indicate a cashier',
        periodHigherOneMonth:'The selected period must be less than 1 month',
        newItem: 'New item',
        parameterName: 'Avanced parameters',
        otherCompany: 'Other companies',
        clientType: 'Client type',
        quartier: 'Area',
        dateDeNaissance: 'Date of birth',
        selectAType: 'Please select a type',
        customField: 'Custom field',
        customFields: 'Custom fields',
        category: 'Categories',
        providerName: 'Provider name',
        contactName: 'Contact Name',
        categoryName: 'Category name',
        fieldName: 'Field name',
        defaultFields: 'Default fields',
        items: 'Items',
        providers: 'Providers',
        nouveauFournisseur: 'New provider',
        editFournisseur: 'Edit provider',
        CDD: 'CDD',
        CDIE: 'CDI - Trial period',
        CDI: 'CDI',
        archive: 'Archive',
        thisStaffIsArchive: 'Please note: this staff is archived',
        includeArchiveStaff: 'Include archived staff',
        includeArchiveProvider: 'Include archived providers',
        includeArchiveData: 'Include archived datas',
        includeArchivetem: 'Include archived items',
        clientAssocie: 'Associated customer',
        clientArchiveAccocie: 'This Staff is archived and therefore cannot be associated with a customer',
        clientArchiveBadge: 'This Staff is archived and therefore this is not possible to generate a badge',
        archive: 'Archive',
        downloadCSV: 'Export csv file',
        downloadExcel: 'Export excel file',
        downloadArticlesCSV: 'Export items in csv',
        listDonnéesCsv: 'Export the possibles datas list',
        firstlevelcat:'Classification',
        secondlevelcat: 'Family',
        thirdlevelcat: 'Subfamily',
        fourthlevelcat:'Category',
        fifthlevelcat: 'Subcategory',
        imageLink: 'Item image link',
        categorysingular: 'Category',
        addone: 'Add a',
        staffIsAssociate: 'This Staff is associate to customer',
        staffIsNotAssociate: 'This Staff is not associate to any customer',
        newbrand: 'New brand',
        includeArchiveBrands: 'Include archived brands',
        formBrand: 'Brand form',
        archivedBrand: 'This brand is archived',
        archivedProvider:'This provider is archived',
        associationStaffCustomer: 'Association of a staff and a customer',
        transferOrderAssociation:'Transfer orders association',
        saleOrderAssociation:'Sale order association',
        noAssociateCustomerResult: 'There is no association possibilities found',
        impossibleDeletingFamily: 'Deleting is impossible !',
        familyContainItems: 'This category contains items',
        impossibleArchivingFamily: 'Archiving is impossible !',
        formItem: 'Item form',
        internalId: 'Internal ID',
        fournisseur: 'Provider',
        marque: 'Brand',
        reference: 'UPC code',
        reference2: 'UPC code 2',
        itemName: 'Item name',
        priceTtc: 'Price with duty included',
        priceHt: 'Price without duty',
        discountPrice: 'Discount price',
        discountProfil: 'Discount profil',
        buy_price: 'Buying price in currency',
        devise_achat: 'Buying currency',
        stores: 'Stores',
        tgc: 'TGC Rate',
        downloadBadge: 'Download badge',
        generateBadge: 'Generate badge',
        thisItemMustHaveStock: 'This item will be dependent on a stock',
        itemHaveStock: 'Stock dependent',
        customizationBadge: 'Badge customization',
        addIdPicture: 'Add a picture on the badge',
        addGrade: 'Add the grade',
        addShopName: 'Add the entity name',
        addLastname: 'Add the lastname',
        addShopLogo:'Add shop logo',
        chooseYouCompany: 'Select the entity',
        chooseYourBgColor: 'Choose the background color',
        chooseYourTextEntityColor: 'Choose the entity text color',
        chooseRefundMethod:'Choose the refund method',
        enterYourCustomColor: 'Add a background  color in hexadecimal',
        addCustomColor: 'Add a custom background  color',
        noEntity: 'No entity',
        red: 'Red',
        blue: 'Blue',
        yellow: 'Yellow',
        pink: 'Pink',
        green: 'Green',
        black: 'Black',
        white: 'White',
        beneficiary: 'Beneficiary',
        selectABeneficiary: 'Select a beneficiary',
        muststock: 'Item Has Stock',
        mobil: 'Transit area',
        chosseMobile: 'Choose the delivery vehicle',
        choseReceptionZone:'Choose the reception zone',
        reception: 'Receiving area',
        commerciale: 'Selling area',
        prive: 'No accessible selling area and stock mouvments',
        stockage: 'Traditional storage area',
        commande: 'Order area',
        zoneArchived: 'Please note : this area is archived',
        importHistory: 'Imports',
        importReport: 'Import report',
        articleImport: 'Item import',
        poImport: 'PO import',
        toImport: 'TO import',
        shipmentImport: 'Shipment Voucher Import',
        rppslImport: 'RP/PSL Import',
        enCours: 'In progress',
        dataListpossible: 'List of possible data',
        editPersonalField: 'Edit custom field',
        editBrand: 'Edit a brand',
        clocking: 'Clocking in',
        errorClocking: ' Badge not assigned this shop or incorrect',
        validateProposalReassort: 'Are you sure you want to convert this restocking proposal into a transfer order ?',
        nonAttribue: 'Not assigned',
        detailHistory: 'History details',
        detailArticle: 'Item details',
        detailPrice: 'Price details',
        detailItem:'Item details',
        colonnes: 'Columns',
        rechercheSurValeurUnique: 'Single value search',
        filtres: 'Filters',
        prmpIndice: 'PRMP index',
        parent_name: 'Parent name',
        itemInfos: 'Item informations',
        customer: 'Customer',
        customerInfos: 'Customer informations',
        principalInfo: 'Principal informations',
        secondInfo: 'Secondary informations',
        itemLinkAndNotLinkToTock: 'Stock and no stock items',
        itemLinkToTock: 'Stock items',
        itemNotLinkToTock: 'No stock items',
        diverseInfo: 'Diverses informations',
        devise: 'Currency',
        migrate: 'Migrate',
        migrateFrom: 'Migrate from',
        to: 'to',
        errorSelectFamily: 'You must indicate a valid destination of migration',
        aucune: 'No',
        justItemsWhoHaveStock: 'Only items that have stock',
        impossibleExport: 'Unable to export',
        maxLineAuth: 'You can export a maximum of {limit} rows at once. You are currently trying to export some {actual}',
        csvWillBeReady: 'Export request recorded. Your CSV file will be available shortly in your CSV file download manager',
        lastExportCsv: 'Last export of CSV files',
        exportDate: 'Export date',
        state:'State',
        itemHasNoStock:'This item has no stock',
        errorExport: 'An error occurred during the export',
        errorImport: 'An error occurred during the import',
        download: 'Download',
        editArticle: 'Item edit',
        havestockInImport: 'You must indicate if this item must be dependent on a stock',
        itemCard: 'Item card',
        defaultConfig: 'Default configuration',
        personalizedConfig: 'Personalized configuration',
        addNewSection:'Add a new section',
        add: 'Add',
        apiKey:'API key',
        msgApiKeyExpire:'Your API key will expire in less than 30 days',
        msgNoApiKey:'No API key',
        noUserConf: 'No personalized configuration',
        createApiKey:'Create an API key',
        activeApiKey:'Active API key',
        createNewApiKey:'Create a new API key',
        msgApiKey:'Here your API key, make sure to keep this key because it will not appear again',
        msgRecreateApiKey:'Are you sure you want to regenerate an API key',
        deleteUserConf: 'Delete the personalized configuration',
        persoConfigVide: 'Your personalized configuration is empty',
        defautConfigVide: 'Your default configuration is empty',
        noDefaultConf:'No default configuration',
        customizeInterface: 'Customize the interface',
        customizationInterface: 'Interface customization',
        importArticleAlreadyInProgress:'An items import is already in progress.',
        purchaseOrder:'Purchase order',
        purchaseOrderForm:'Purchase order form',
        orderDate: 'Date of order',
        expectedDeliveryDate:'Expected delivery date',
        expectedReceiptDate:'Expected receipt date',
        estimatedDelivery:'Expected delivery',
        lastSalesOrder:'last sale order',
        searchSalesOrder:'Search a sale order',
        quantity:'Quantity',
        quantityTotal:'Total quantity',
        quantityDisp:'Quantity on hand',
        quantityBloque:'Blocked quantity',
        quantiteOnPurchase: 'Ordered quantity',
        quantiteWait: 'Pending quantity',
        purchaseOrders:'Purchase orders',
        createPurchaseOrder:'Create a purchase order',
        purchaseOrderName:'Purchase order name',
        createdAt:'Date of creation',
        lastUpdate:'Last update',
        divers:'Other',
        createBy: 'Create by',
        notDelivered: 'Not received',
        notDeliveredLines: 'lines not received',
        completed: 'Completed',
        includeAllPurchaseOrders: 'Include completed purchase orders',
        editPurchaseOrder: 'Edit purchase order',
        importPo: 'Import Purchase Orders Contents',
        importShipment: 'Import Shipment Voucher Contents',
        importReassort: 'Import Automatic Restock Contents',
        importTo: 'Import Transfers Orders Contents',
        quota_recu: 'Received quantity',
        status:'Status',
        statusItems:'Items Status',
        statusLines:'Lines Status',
        toAlreadyCompleted: 'This transfer order has already been completed',
        allItems: 'All items',
        itemsTotallyReceived: 'Items totally received',
        itemsPartiallyReceived: 'Items partially received',
        itemsNotReceived: 'Items not received',
        sku:'SKU',
        relatedRecord: 'Related records',
        redirect:'Redirect',
        updatePoRecords: 'Update in',
        insertPoRecords: 'Insert in',
        updateShipmentRecords: 'Update in',
        insertShipmentRecords: 'Insert in',
        entreStockRecords: 'Stock entry',
        voirLe: 'See',
        saleOrderCanceled:'This sale order has been canceled',
        saleOrderFinish:'This sale order has been completed',
        itemsDetail:'Items detail',
        registrationDate:'Registration date',
        shipmentVoucher: 'Shipment voucher',
        transferOrder: 'Transfer Order',
        transferOrders: 'Transfer Orders',
        shipmentVoucherAccent: 'Shipment voucher',
        entre: 'Entry',
        numero_shipment: 'Delivery number',
        numero_container_shipment: 'Container number',
        date_depart_shipment:'Departure date',
        date_arrive_shipment:'Arrival date',
        status_shipment:'Status',
        createShipmentVoucher: 'Create a shipment voucher',
        cours_chargement: 'Loading in progress',
        mer: 'On the sea',
        douane: 'At the custom office',
        dock: 'Receipt in progress',
        receptione:'Received',
        receptioneLines:'received lines',
        includeAllShipmentVoucher: 'Include all shipment voucher',
        exceptReceivedVoucher: 'Except received voucher',
        only:'Only',
        customCreateForm: 'Customize the creation form',
        customVoucherDetails: 'Customize the voucher details',
        PurchaseOrderCard: 'Purchase order card',
        shipmentVoucherCard:'Shipment voucher card',
        shipmentVoucherForm:'Shipment voucher form',
        toCustomForm:'Transfer Order Form',
        shde_carton_name:'Carboard number',
        shde_quota_prevu:'Expected quantity in carboard',
        shde_quota_prevu_accent:'Expected quantity in carboard',
        shde_quota_recu:'Received quantity',
        shde_carton_recu:'Card received',
        shde_carton_recu_accent:'Card received',
        receiptShipmentVoucher:'Receipt of shipment voucher',
        scanBoxHere:'Scan a box here',
        scanBoxToCheckHere:'Scan a box here',
        scanItems:'Scan an item here',
        boxeChoice:'Box choice',
        itemChoice:'Item choice',
        french:'French',
        english:'English',
        po: 'Purchase order',
        totalPrmp: 'PRMP total',
        allBoxes: 'All Boxes',
        allSalesOrder:'All sales order',
        allLines: 'All lines',
        boxesStatus: 'Boxes status',
        assignAWms: 'Assign to a WMS',
        assignAShop: 'Assign to a shop',
        noWmsAttribution: 'No attribution to a WMS',
        noShipmentVoucher: 'No shipment voucher',
        editShipmentVoucher: 'Edit shipment voucher',
        editTransferOrder: 'Edit transfer order',
        receivedBy:'Received by',
        of:'of',
        errorScan:'The box {barcode} does not exist',
        successScan:'The box {barcode} has been successfully scanned',
        reinstateScan:'The box {barcode} has already been scanned, would you like to reinstate to unscanned boxes ?',
        successReinstate:'The box {barcode} has been successfully reinstate',
        checkingBoxes:'Boxes receipt',
        checkingItems:'Items receipt',
        notScannedYet:'This box has not been scanned yet, would you like to be redirected to the boxes receipt ?',
        quantityCommande:'Ordered quantity',
        createStorageSpace:'Create bins in bulk',
        binsList:'Bins List',
        createOneBins:'Create a bins',
        beginningFirstLevel:'Beginning of the first level',
        endFirstLevel:'End of the first level',
        beginningSecondLevel:'Beginning of the second level',
        endSecondLevel:'End of the second level',
        beginningThirdLevel:'Beginning of the third level',
        endThirdLevel:'End of the third level',
        beginningFourthLevel:'Beginning of the fourth level',
        endFourthLevel:'End of the fourth level',
        beginningFifthLevel:'Beginning of the fifth level',
        endFifthLevel:'End of the fifth level',
        firstEndBinsGte:'The end of the firt level value can\'t be higher than the beginning of the firt level',
        secondEndBinsGte:'The end of the second level value can\'t be higher than the beginning of the second level',
        thirdEndBinsGte:'The end of the third level value can\'t be higher than the beginning of the third level',
        fourthEndBinsGte:'The end of the fourth level value can\'t be higher than the beginning of the fourth level',
        fifthEndBinsGte:'The end of the fifth level value can\'t be higher than the beginning of the fifth level',
        firstBins:'First level',
        secondBins:'Second level',
        thirdBins:'Third level',
        fourthBins:'Fourth level',
        fifthBins:'Fifth level',
        zone:'Area',
        successBinsSkip:'Operation performed successfully, however those bins have not been created because they already exist',
        scanedBox:'Scanned boxes',
        scanBinsHere:'Scan the bin here',
        attributeAStorageArea:'Attribute to a storage area',
        selectAStorageArea:'Select a storage area',
        or:'Or',
        boxesInfos:'Boxes informations',
        binsNumber:'Bins number',
        storageArea:'Storage area',
        articlesList:'Items list',
        boxNotScaned:'Box not scanned',
        itemNotExist:'The item {sku} does not exist',
        finishScanBefore:'You need to finish scanning this item before scanning another',
        selectRowScan:'Select the block to scan',
        location:'Location',
        binsDetails: 'Bins content',
        onOrder:'On order',
        receivedLines: 'Received line',
        totalHt: 'Total without duty',
        differencePrmp:'PRMP difference',
        differenceHt:'Without duty difference',
        ecart:'Difference',
        providedCashFund:'Provided cash fund',
        prevu:'Provided cash fund',
        total:'Total',
        openWith:'Open with',
        binsMovement:'Bins movement',
        stockDeplacement: 'Moving stock',
        simpleMove: 'Simple moving',
        avlQuantity:'Available quantity',
        qteToMove:'Quantity to move',
        qteThinkMove:'Quantity picked',
        qteIsMove:'Quantity deposed',
        collectedAt:'Picked at',
        deposedAt:'Deposed at',
        collected:'Picked',
        qteToCollect:'Quantity to collect',
        inLivraison:'On delivery',
        outLivraison:'Out of delivery',
        deposed:'Deposed',
        qteReached:'Maximum quantity reached',
        qteAvlInsufficient:'Available quantity is insufficient',
        selectStorageDestination:'Select the storage aera',
        cantScanDifferentItem:'You could not scan different items in the same time',
        itemNotInThisArea:'This item not appear in this storage area',
        origin:'Origin',
        destination:'Destination',
        scanTheItemHere:'Scan the item here',
        binsContent:'Bin\'s content',
        emptyBins:'This bins is empty',
        totalDevise:'Total in currency',
        referencePo:'Référence',
        seeReceiptVoucher:'See the receipt voucher',
        voucherNumber:'Voucher number',
        receiptDate:'Réceipt date',
        boxNumber:'Box number',
        itemsDetails:'Items details',
        receiptVoucher:'Receipt voucher',
        cancelBoxReceipt:'Receipt box cancellation',
        cancelItemReceipt:'Receipt item cancellation',
        someItemsAlreadyScan: 'Action impossible, items from this box are already in stock',
        cancelItemsEntry:'Cancel items entry in stock',
        itemsReinstate:'Items reintegration',
        cancellationStockEntry:'Cancellation stock entry',
        errorReinstateNotPossible:'You can\'t reinstate an item that has not been received',
        selectBlockToReinstate:'Select the block to reinstate',
        autoRestock:'Automatic restock',
        toCorrectionDeposed:'Stock correction for difference between quantity collected and quantity delivered',
        reorderPoint: 'Reorder point',
        referenceTo:'Reference',
        referenceToNoAccent:'Reference',
        transfert_leNoAccent: 'To be transferred on',
        transfert_le: 'To be transferred on',
        origin_section: 'Origin Shop',
        destination_section: 'Destination Shop',
        rhBarre: 'Barcode staff',
        preferredStockLevel:'Preferred Stock Level',
        actualStock:'Actual stock on hand',
        reassortEnCours:'Restock to come',
        recommendedDestination:'Recommended destinations',
        sameItem:'Same Item',
        sameParent:'Same Parent',
        box:'Box',
        rpInteger:'{name} reorder point RP must be a integer',
        pslInteger:'{name} preferred stock level PSL must be a integer',
        detailProvider:'Provider details',
        providerCard:'Provider card',
        allPo:'All purchase orders',
        generateABarCode:'Generate a barcode',
        generateMasseBarCode:'Generate barcodes in bulk',
        quantityManualModification:'Manual quantity modification',
        stockReorganization:'Stock reorganization',
        removeFromStock:'Remove from stock',
        addToStock:'Add to stock',
        chooseAnAction:'Choose an action',
        choisir:'Choose',
        selectSection:'Select a shop',
        stockIntegration:'Stock integration',
        stockRemove:'Stock remove',
        stockAdjustment:'Stock adjustment',
        validateAll:'Validate all',
        reinstateAllItems:'Reinstate all items',
        reinstateOneItem:'Reinstate one item',
        noBarcodeFounded:'No barcode founded',
        rppsl:'Rppsl',
        createATransferOrder:'Create a transfer order',
        allTransferOrders:'All transfer orders',
        exceptFinished:'except finished',
        transferOrderNotStarted:'Transfer orders not started',
        notStarded:'Not started',
        processOfCollecting:'In process of collecting',
        processOfDelivery:'In process of delivery',
        processOfEmptying:'In process of emptying',
        transferOrderEdit:'Edit a transfer order',
        errorDeleteTO:'You can\'t delete this transfer order',
        wrongBins:'You have scanned the wrong bins',
        wrongItem:'You have scanned the wrong item',
        allowTransferOrderWithDifferentValue:'Transfer order with different quantity',
        zoneWithoutBins:'Zone without bins',
        uncompletedLines:'Uncompleted lines',
        transferOrderCompleted:'Transfer order completed',
        errorToStockMissing:'The transfer order could not be correctly finalized due to a missing stock',
        confirmValidateAsIs:'Are you sure you want to validate as is ?',
        transferOrdersToCollect:'Transfer orders to collect',
        transferOrdersToReceive:'Tansfer orders to receive',
        quantityToReceive:'Quantity to receive',
        receiveAllItems:'Receive all items',
        receiveItemsByItems:'Receive items by items',
        validAllArticleToReceive:'Validation of all items from a transfer order at the reception',
        noTransferOrdersToReceive:'No transfer orders to receive',
        confirmReceptionWithDifferentQte:'The receive quantity is different from the collected quantity, that will generate a stock readjustment, continue ?',
        linesNumber:'Lines number',
        notCollected:'Not collected',
        itemsBase:'Items base',
        stockInShop:'Stock in this shop',
        stockInOtherShop:'Stock in other shop',
        priority:'Priority',
        restockPriority:'Restock priority',
        rppslNotConfigured:'The item\'s RPPSL has not been configured',
        rppslEdit:'Rppsl edit',
        nbMaxToInstruction:'Maximum number of instructions in a transfer order',
        allItemsAlreadyScaned:'The whole items has been scanned',
        seller:'Seller',
        discount:'Discount',
        price:'Price',
        priceEdit:'Price edit',
        priceModification:'Price modification',
        discountPercent:'Discount %',
        discountNet:'Net discount',
        noDiscount:'No discount',
        addNote:'Add a note',
        espece:'Cash',
        check:'Check',
        card:'Card',
        other:'Other',
        amount:'Amount',
        checkNumber:'Check number',
        scanGiftCard:'Scan the Gift Card here',
        payment:'Payment',
        due:'Due',
        promotion:'Promotions',
        percent:'Discount %',
        franche:'Net discount',
        xpoury:'Offer "X for Y',
        percentsecond:'Discount % on 2nd item',
        xpourfixe:'Offer for N items',
        cadeaupourx:'Gift for purchase higher than',
        noPromoPeriodDefined:'No ending date',
        createPromo:'Create a promotion',
        qteItems:'Item quantity',
        percentDesc:'Percentage discount, exemple : -20%',
        francheDesc:'Net discount of the price, example : -10$',
        xpouryDesc:'Sale a quantity of X items for the price of Y items, exemple : 2 for the price of 1',
        percentsecondDesc:'Percentage discount on the second item, exemple : -50% on the second item',
        xpourfixeDesc:'Sale a quantity of X items for a reduced price, exemple : 2 for 50$',
        cadeaupourxDesc:'A gift for a minimum amount of pruchase, exemple : buy for 100$, get a free tee-shirt',
        for:'For',
        beginPromo:'Beginning of the promotion',
        endPromo:'End of the promotion',
        editPromo:'Edit a promotion',
        undefined:'Undefined',
        addPromotion:'Add a promotion',
        currentAndUpcomingPromo: 'Current and upcoming promotions',
        currentPromo:'Current promotion',
        upcomingPromo:'Upcoming promotion',
        noCurrentPromo:'No current promotions',
        noUpcomingPromo:'No upcoming promotions',
        addToPromotion:'Add to the promotion',
        additionPromo:'Addition of the promotion',
        dependingItemPrice:'Depending on items prices',
        giftOffered:'Gift offered',
        receiptSaved:'Saved receipts',
        receiptNumber:'Receipt number',
        saleSaved:'Sale saved',
        noCurrentSale:'No current sale',
        noSalesSaved:'No sales saved',
        createGiftCard:'Gift card creation',
        scanGiftCardHere:'Scan the gift card here',
        deposit:'Deposit amount',
        giveChange:'Give change',
        notValidGiftCard:'This gift card is not valid in this shop',
        noFundAvailable:'No fund available on this gift card',
        noEnoughFund:'Not enough funds on this gift card',
        moreDetails:'More details',
        credit:'Credit',
        debit:'Debit',
        dateExpire:'Date of expiration',
        itemReturn:'Item return',
        dontRemise:'Discount',
        scanReceiptHere:'Scan the receipt here',
        scanSalesOrderHere:'Scan the sale order here',
        searchOneReceipt:'Search one receipt',
        receiptSearch:'Receipt search',
        enterRefundMethod:'Enter the refund method',
        ticket:'Ticket',
        vente:'Sale',
        retour:'Return',
        noItemsToReturn:'No items to return',
        noItems:'No items',
        paid:'Paid',
        historical:'Historical',
        salesHistorical:'Sales historical',
        customerHistorical:'Customer historicl',
        availableFund:'Available funds',
        detail:'Detail',
        itemsNumber:'Items number',
        messageEndReceipt:'thanks you for your visit',
        downloadingPdf:'Pdf downloading',
        lastSale:'Last sale',
        noPreviousSale:'No sale has been done previously',
        selectConcernedPeriod:'Select the concerned period',
        beginningPeriod:'Beginning of period',
        endPeriod:'End of period',
        editPaymentMethod:'Payment method edition',
        noPaymentMethodToEdit:'No payment method to edit',
        checkoutState:'Checkout state',
        totalWithGiftCard:'Total sales with gift card',
        totalWithoutGiftCard:'Total sales without gift card',
        averageReceiptWithGiftCard:'Average receipt with gift card',
        averageReceiptWithoutGiftCard:'Average receipt without gift card',
        wholeShop:'Whole shop',
        filterByEmployee:'Filter by employee',
        filterByCashRegister:'Filter by cash register',
        allStaff:'All staff',
        thisCashRegister:'This cash register',
        concernedShop:'Concerned shops',
        typePromo:'Promotion type',
        statePromo:'Promotion state',
        messageBienvenue:'Hello - Welcome',
        acroTel:'Pho',
        finished:'Finished',
        removeAllArticlesConfirm:'Do you really want to remove all items from this promotion',
        manualDiscountPercent:'Manual discount %',
        manualDiscountNet:'Manual net discount',
        caisseIdent:'POS identifier',
        posName:'POS name', 
        actualSumCaisse:'Cash fund expected',
        acutalSession:'Actual session',
        cashTotalInCaisse:'Total of cash in this cash register',
        derniersRetraitEspeces: 'Last cash out',
        newStaff:'New staff',
        refresh:'Refresh',
        personalizedFilter:'Personalized filter',
        currentWeek:'Current week',
        lastWeek:'Last week',
        nextWeek:'Next week',
        currentMonth:'Current month',
        lastMonth:'Last month',
        nextMonth:'Next month',
        paymentDetail:'Payment detail',
        receiptDetail:'Receipt detail',
        depositDetail:'Deposit detail',
        giftCardCredit:'Gift card credit',
        printItemTag:'Print a item tag',
        includeArchives:'Include archives',
        saleOrderDetail:'Sale order details',
        openSaleOrder:'Open sale order',
        active:'Active',
        peripheralDevice:'Peripheral devices',
        newPeripheralDevice:'New peripheral device',
        model:'Model',
        checkoutShortcut:'Checkout shortcut',
        checkoutShortcuts:'Checkout shortcuts',
        newShortcut:'New checkout shortcut',
        ipAdress:'IP adress',
        afficheur:'Display',
        tpe:'EPT',
        ingenico:'Ingenico',
        comPort:'COM port',
        imprimante_ticket:'Receipt printer',
        imprimante_etiquette:'Tag printer',
        epson_tm_h6000V:'Epson TM-H6000V',
        zebra_zd421:'Zebra ZD421',
        printCaisseIdent:'Print the checkout ident',
        aures_2_l:'Aures display',
        notAttributedPeripheral:'Not attributed peripherals',
        noPeripheral:'No peripheral',
        noConfiguredPeripheral:'No configured peripheral',
        otherSection:'Other shop',
        disponibleMini:'Avail',
        blockedMini:'Bloc',
        aVenir:'To come',
        changePrinter:'Change printer',
        selectAPrinter:'Select a printer',
        qteTag:'Tag quantity',
        tagPrint:'Tag print',
        limitTagPrint100:'The limit of tag printing is 100',
        mintTagPrint1:'The minimum quantity of tag printing is 1',
        bankDeposit:'Bank deposit',
        salesOrder:'Sale order',
        addToOrder:'Add to sale order',
        expectedCollectDate:'Expected date of collect',
        collectZone:'Collect location',
        order:'Order',
        commandeChangeCollectZone: 'Change of the collect location',
        commandeOut: 'Leaving the shop area',
        commandePut: 'Put in sales order area',
        commandeCancelledOut: 'Sales order cancelled - Leaving the sales order area',
        commandeCancelledPut: 'Sales order cancelled - Put in the shop area',
        orderHistorical:'Sales order historical',
        searchByCreationDate:'Search by date of creation',
        searchByCollectDate:'Search by expected date of collect',
        searchASalesOrder:'Search a sale order',
        searchSalesOrder:'Search sales order',
        article:'Item',
        noCheckoutShortcut:'No checkout shortcut',
        validateNewOrder:'Confirm the new order',
        transferHere:'Transfer here',
        transferToOtherShop:'Transfer to other shop',
        /************button********/
        btnAccess: 'Go !',
        btnCancelSalesOrder:'Cancel the sale order',
        btnSeeEditDelete : 'See / Edit / Delete',
        btnValidate: 'Ok',
        btnDeleteUser: 'Delete this user',
        btnDeleteGrade: 'Delete this grade',
        btnDeleteRh: 'Delete this staff',
        btnYes: "Yes",
        btnNo: "No",
        btnDelete: "Delete",
        btnAddField: "Add field",
        btnRemoveRh: "Remove from store",
        btnRemove: "Remove",
        btnGoClotureCaisse: 'Close Checkout',
        btnCancel: 'Cancel',
        btnBackToWelcome: 'Return to Home',
        btnPay: 'Pay',
        btnEffacer: 'Reset',
        btnClose: 'Close',
        btnImport: 'Import',
        btnAddClientForm: 'Add client form',
        btnRemoveClientForm: 'Remove client form',
        btnArchiveStaff: 'Archive this Staff',
        btnActiveStaff: 'Activate this Staff',
        btnArchiveProvider: 'Archive this provider',
        btnDeleteProvider: 'Delete this provider',
        btnUnarchiveProvider: 'Active this provider',
        btnSeeProfil: 'See profil',
        btnDeleteAssociate: 'Delete this association',
        btnSearchAssociateCustomer: 'Start a search for association possibilities',
        btnUnarchiveBrand: 'Active this brand',
        btnArchiveBrand: 'Archive this brand',
        btnDeleteBrand: 'Delete this brand',
        btnAssociate : 'Associate',
        btnCreateNewStaffCustomer: 'Generate a new customer associate to this Staff',
        btnUnarchiveZone: 'Active this area',
        btnArchiveZone: 'Archiver this area',
        btnDeleteZone: 'Delete this area',
        btnShowDetails: 'Show details',
        btnInsert: 'Insert',
        btnUpdate: 'Update',
        btnBack: 'Back',
        btnMove:'Move',
        btnReturn:'Return',
        btnSeeBinsContent:'See bins content',
        btnSeeParentProfil:'Parent profil',
        btnValidateAsIs:'Validate as is',
        btnSeeStock:'Get the stock',
        btnItemProfil:'Item profil',
        btnModifier:'Edit',
        btnSave:'Save',
        btnSeePdf:'See the pdf',
        btnPrint:'Print',
        btnPayPrint:'Pay & Print',
        btnGiftPrint:'Gift Print',
        btnRemoveAllItems:'Remove all items',
        /***********Menu**************/
        welcome: 'Home',
        usersList: 'Users List',
        usersCreate: 'Create User',
        rhCreate: 'Create Staff',
        administrationGenerale: 'General Administration',
        administrationModePaiement: 'Payment methods',
        administrationObjectifPos : 'Goals',
        administrationCustomForm: 'Custom Forms',
        administrationClientForm: 'Customer',
        administrationFournisseurForm: 'Provider',
        administrationMarqueForm: 'Brand',
        administrationArticleForm: 'Item',
        administrationStaff: 'Staff',
        administrationStaffList: 'Staff List',
        allCreate: 'Create',
        searchStock: 'Search Stock',
        cashRegister: 'Cash Register',
        addItem: 'Add new item',
        importItem: 'Import Items',
        importItemPromotion: 'Import item in {name} promotion',
        facturation: 'Billing',
        formProvider: 'Provider form',
        itemFamily: 'Item families',
        brand: 'Brands',
        client: 'Customers',
        customizedInterface: 'Customized interface',
        createRead:'Create and read',
        import: 'Import',
        allPromotions:'All promotions',
        printingInProgress:'Printing in progress',
        movePeripheralsShop: 'Are sure you want to move {deviceName} to {shopName} ?',
        session:'Session',
        /***********Table***********/
        tableSearch: 'Search',
        tableName: 'Name',
        tableFirst: 'First',
        tableBack: 'Back',
        tableNext: 'Next',
        tableLast: 'Last',
        tableFistTitle: 'First Page',
        tableBackTitle: 'Pre Page',
        tableNextTitle: 'Next Page',
        tableLastTitle: 'Last Page',
        tableNoData: 'No Data',
        tableNoModePaiement: 'No payment method added',
        tableNoCashOutReason: 'No proof of cash withdrawal added',
        tableSex: 'Sex',
        tableFirstName: 'Firstname',
        tablePhone: 'Phone',
        tableRemove: 'Remove',
        tableBarCode: 'Bar Code',
        tableOtherPhone: 'Other phone',
        tableProviderName: 'Provider name',
        tableContactName: 'Contact name',
        tableBrandName: 'Brand name',
        tableContraintes: 'Constraints',
        /*********** Label Form ***********/
        labelSex: 'Sex',
        labelName: 'Name',
        labelCsvSeparator: 'CSV Separator',
        labelCsvEncode: 'CSV Encode',
        labelFirstName: 'First Name',
        labelPassword: 'Password',
        labelPasswordConfirmation: 'Password Confirmation',
        labelPhone: 'Phone',
        labelMaxusemoisproforma: 'Lifespan of a proforma (in month)',
        labelRaisonmodifprix: 'Reasons why a seller can justify manually changing the price of an item',
        labelDateFormat: 'Date format',
        labelAdress: 'Adress',
        labelCodePostal: 'Zip code',
        labelVille: 'Town',
        labelPays: 'Country',
        labelCodeBank: 'Bank code',
        labelCodeGuichet: 'Sort code',
        labelNumeroDeCompte: 'Account number',
        labelCleRib: 'RIB key',
        labelBank: 'Bank',
        labelHoraires: 'Hours',
        labelProviderName: 'Provider name',
        labelContactName: 'Contact name',
        labelArchived: 'Archived',
        labelBankName: 'Bank name',
        labelSelectConfigue: 'Choose your configuration',
        labelReason:'Reason',
        /********** Success Form Response ************/
        sucessUserCreate: 'User created successfully !',
        /**********Payment methode ********************/
        especeSA: 'Cash',
        espece: 'Cash',
        cheque: 'Check',
        check: 'Check',
        cb: 'Bleue/Visa Card',
        amex: 'American Express',
        mastercard: 'Mastercard',
        jade: 'Jade Card',
        jcb: 'Jcb Card',
        giftcard: 'Gift card',
        virement: 'Bank Transfer',
        emv:'EMV',
        bonmalistecadeaux: 'Coupon "Ma Liste Cadeaux"',
        bonatelierdujouet: 'Coupon "Atelier du Jouet"',
        annulCommande: 'Sales order cancelled'
      }
    },
    fr: {
      locale: 'fr',
      messages: {
        /*********form response***********/
        //required
        nameRequired: 'Le champ "nom" est obligatoire',
        firstnameRequired: 'Le champ "prénom" est obligatoire',
        emailRequired: 'Le champ "e-mail" est obligatoire',
        passwordRequired: 'Le champ "mot de passe" est obligatoire',
        password_confirmationRequired: 'Le champ "confirmer mot de passe" est obligatoire',
        companyRequired: 'Le champ "entreprise" est obligatoire',
        moduleRequired: 'Le champ "module" est obligatoire',
        sectionRequired: 'Le champ "commerce" est obligatoire',
        sexeRequired: 'Le champ "sexe" est obligatoire',
        typefideliteRequired: 'Le champ "Type de Carte de Fidelité" est obligatoire',
        maxusemoisRequired: 'Le champ "Durée de validité" est obligatoire',
        maxusemoisproformaRequired: 'Le champ "Durée de validité d\'une proforma" est obligatoire',
        modepaiementRequired: 'Le champ "Modes de paiement" est obligatoire',
        themeRequired: 'Le champ "Theme" est obligatoire',
        donneeclientRequired: 'Le champ "Activer l\'obligation de collecte des données client" est obligatoire',
        objectifvendeurRequired: 'Le champ "Activer les objectifs vendeurs" est obligatoire',
        objectifcommerceRequired: 'Le champ "Activer les objectifs commerce" est obligatoire',
        dixmilleRequired: 'Vous devez indiquer le nombre de billets de 10 000 XPF',
        cinqmilleRequired: 'Vous devez indiquer le nombre de billets de 5 000 XPF',
        milleRequired: 'Vous devez indiquer le nombre de billets de 1 000 XPF',
        cinqcentRequired: 'Vous devez indiquer le nombre de billets de 500 XPF',
        deuxcentRequired: 'Vous devez indiquer le nombre de pièces de 200 XPF',
        centRequired: 'Vous devez indiquer le nombre de pièces de 100 XPF',
        cinquanteRequired: 'Vous devez indiquer le nombre de pièces de 50 XPF',
        vingtRequired: 'Vous devez indiquer le nombre de pièces de 20 XPF',
        dixRequired: 'Vous devez indiquer le nombre de pièces de 10 XPF',
        cinqRequired: 'Vous devez indiquer le nombre de pièces de 5 XPF',
        deuxRequired: 'Vous devez indiquer le nombre de pièces de 2 XPF',
        unRequired: 'Vous devez indiquer le nombre de pièces de 1 XPF',
        gradeRequired: 'Vous devez indiquer un grade',
        caRequired: 'Vous devez indiquer un CA',
        pmRequired: 'Vous devez indiquer un PM',
        tfRequired: 'Vous devez indiquer un TF',
        ivRequired: 'Vous devez indiquer un IV',
        entreRequired: 'Vous devez indiquer un nombre de passages clients (entrée et sortie)',
        beginRequired: 'Vous devez indiquer la date de début',
        endRequired: 'Vous devez indiquer la date de fin',
        dayRequired: 'Vous devez indiquer le jour',
        telephoneRequired: 'Vous devez indiquer le téléphone',
        adresseRequired: 'Vous devez indiquer l\'adresse physique',
        adressepostaleRequired: 'Vous devez indiquer l\'adresse postale',
        newsletterRequired: 'Vous devez cocher la newsletter',
        civiliteRequired: 'Vous devez indiquer la civilité',
        csvEncodeRequired: 'Vous devez indiquer l\'encodage CSV',
        csvSeparatorRequired: 'Vous devez indiquer le séparateur CSV',
        csvDateFormatRequired: 'Vous devez indiquer le format de date CSV',
        codeRequired:'Vous devez indiquer un code',
        rcsRequired:'Vous devez indiquer un RCS',
        adressRequired:'Vous devez indiquer l\'adresse',
        codePostalRequired:'Vous devez indiquer un code postal',
        villeRequired:'Vous devez indiquer une ville',
        paysRequired:'Vous devez indiquer un pays',
        codeBanqueRequired: 'Vous devez indiquer un code banque',
        codeGuichetRequired: 'Vous devez indiquer un code guichet',
        numeroCompteRequired: 'Vous devez indiquer un numéro de compte',
        cleRibRequired: 'Vous devez indiquer une clée RIB',
        clientTypeRequired: 'Vous devez indiquer un type',
        quartierRequired: 'Vous devez indiquer un quartier',
        typeRequired: 'Vous devez indiquer un type',
        dateDeNaissanceRequired: 'Vous devez indiquer une date de naissance',
        providerNameRequired: 'Vous devez indiquer un nom de fournisseur',
        contactNameRequired: 'Vous devez indiquer un nom de contact',
        statusRequired: 'Vous devez indiquer un status',
        itemFamilyNameRequired: 'Vous devez indiquer un nom de famille d\'article',
        brandNameRequired: 'Vous devez indiquer un nom de marque',
        categoryRequired: 'Vous devez indiquer une catégorie',
        referenceRequired: 'Vous devez indiquer un code UPC',
        referenceToRequired: 'Vous devez indiquer une référence',
        skuRequired: 'Vous devez indiquer un SKU',
        internalidRequired: 'Vous devez indiquer l\'ID interne de l\'article',
        priceRequired: 'Vous devez indiquer un prix TTC',
        tgcRequired: 'Vous devez indiquer un taux de TGC',
        prmpRequired: 'Vous devez indiquer un PRMP',
        havestockRequired: 'Vous devez indiquer si cet article doit être dépendant d\'un stock',
        colorHexaRequired: 'Vous devez indiquer un code couleur hexadecimal',
        banqueRequired: 'Vous devez indiquer le nom de la banque',
        customFieldRequired: 'Vous devez renseigner le champ "{name}"',
        numeroShipmentRequired:'Vous devez indiquer le numéro d\'expédition',
        shdeCartonNameRequired:'Vous devez indiquer une référence carton',
        shdeQuotaPrevuRequired:'Vous devez indiquer la quantité prévue',
        firstStartBinsRequired:'Vous devez indiquer le début du premier niveau',
        firstEndBinsRequired:'Vous devez indiquer la fin du premier niveau',
        zoneRequired:'Vous devez indiquer une zone',
        secondEndBinsRequiredWith:'Vous devez indiquer la fin du premier niveau si le début du premier niveau est renseigné',
        thirdEndBinsRequiredWith:'Vous devez indiquer la fin du second niveau si le début du second niveau est renseigné',
        fourthEndBinsRequiredWith:'Vous devez indiquer la fin du troisième niveau si le début du troisième niveau est renseigné',
        fifthEndBinsRequiredWith:'Vous devez indiquer la fin du quatrième niveau si le début du quatrième niveau est renseigné',
        firstBinsRequired:'Vous devez indiquer le premier niveau',
        quotaRequired: 'Vous devez indiquer la quantité',
        actionRequired:'Vous devez indiquer le champ action',
        binsRequired:'Vous devez indiquer la bins',
        quantiteRequired:'Vous devez indiquer la quantité',
        fieldRequired:'Ce champ est requis',
        expectedRequired: 'La date souhaitée d\'exécution est requise',
        rhRequired: 'Vous devez indiquer un staff',
        rhBarreRequired: 'Vous devez indiquer le code-barre d\'un staff',
        osectionRequired: 'Vous devez indiquer un commerce d\'origine',
        fsectionRequired: 'Vous devez indiquer un commerce de destination',
        rpRequired: 'Vous devez indiquer le niveau de stock minimum',
        pslRequired: 'Vous devez indiquer le niveau de stock optimal',
        toMaxRequired: 'Le nombre maximum d\'instructions pour un ordre de transfert est obligatoire',
        articleRequired: 'Vous devez choisr un article',
        promotionRequired: 'Vous devez choisir promotion',
        allFieldsRequired: 'Tous les champs doivent être remplis',
        promoImport:'Import promotions',
        codeCaisseRequired: 'Vous devez indiquer un code d\'identification de la caisse',
        caisseIdentRequired:'Vous devez indiquer l\'identifiant de caisse',
        hostRequired:'Vous devez indiquer une adresse IP',
        comPortRequired:'Vous devez indiquer un port COM',
        modelRequired:'Vous devez choisir un modèle',
        waitingEpt:'En attente TPE',
        errorEpt:'Erreur réponse TPE',
        newCheckoutShortcutProfil:'Nouveau profil de raccourci',
        checkoutShorcutProfil:'Profils de raccourci',
        //Min
        nameMin2: 'Le champ "nom" doit comporter au moins 2 caractères',
        nameMin5: 'Le champ "nom" doit comporter au moins 5 caractères',
        skuMin2: 'Le champ "sku" doit comporter au moins 2 caractères',
        firstnameMin2: 'Le champ "prénom" doit comporter au moins 2 caractères',
        passwordMin8: 'Le champ "mot de passe" doit comporter au moins 8 caractères',
        phonedMin6: 'Le champ "téléphone" doit comporter au moins 6 caractères',
        phoneMin6: 'Le champ "téléphone" doit comporter au moins 6 caractères',
        maxusemoisdMin1: 'Le champ "Durée de validité" doit être au minimum à 1 mois',
        maxusemoisproformaMin1: 'Le champ "Durée de validité d\'une proforma" doit être au minimum à 1 mois',
        dixmilleMin0: 'Le nombre de billets de 10 000 XPF ne peut pas être inférieur à 0',
        cinqmilleMin0: 'Le nombre de billets de 5 000 XPF ne peut pas être inférieur à 0',
        milleMin0: 'Le nombre de billets de 1 000 XPF ne peut pas être inférieur à 0',
        cinqcentMin0: 'Le nombre de billets de 500 XPF ne peut pas être inférieur à 0',
        deuxcentMin0: 'Le nombre de pièces de 200 XPF ne peut pas être inférieur à 0',
        centMin0: 'Le nombre de pièces 100 XPF ne peut pas être inférieur à 0',
        cinquanteMin0: 'Le nombre de pièces 50 XPF ne peut pas être inférieur à 0',
        vingtMin0: 'Le nombre de pièces 20 XPF ne peut pas être inférieur à 0',
        dixMin0: 'Le nombre de pièces 10 XPF ne peut pas être inférieur à 0',
        cinqMin0: 'Le nombre de pièces 5 XPF ne peut pas être inférieur à 0',
        deuxMin0: 'Le nombre de pièces 2 XPF ne peut pas être inférieur à 0',
        unMin0: 'Le nombre de pièces 1 XPF ne peut pas être inférieur à 0',
        caMin0: 'Le CA ne peut pas être inférieur à 0',
        pmMin0: 'Le PM ne peut pas être inférieur à 0',
        tfMin0: 'Le TF ne peut pas être inférieur à 0',
        ivMin0: 'Le IV ne peut pas être inférieur à 0',
        entreMin0: 'Le nombre de passages clients (entrée et sortie) ne peut pas être inférieur à 0',
        codeMin3: 'Le champ "code" doit comporter au moins 3 caractères',
        rcsMin5: 'Le champ "RCS" doit comporter au moins 5 caractères',
        codeBanqueMin5: 'Le champ "Code Banque" doit comporter au moins 5 caractères',
        codeGuichetMin5: 'Le champ "Code Guichet" doit comporter au moins 5 caractères',
        numeroCompteMin11: 'Le champ "Numéro de Compte" doit comporter au moins 11 caractères',
        cleRibMin2: 'Le champ "Clée RIB" doit comporter au moins 2 caractères',
        nomContactMin2: 'Le nom du contact doit comporter au moins 2 caractères',
        priceMin: 'Le prix ne peut être inférieur à 0',
        promoPriceMin: 'Le prix promotionnel ne peut être inférieur à 0',
        tgcMin: 'Le taux de TGC ne peut être inférieur à 0',
        colorHexaMin: 'Le code couleur hexadecimal doit faire 6 caractères',
        buyPriceMin: 'Le prix d\'achat ne peut être inférieur à 0',
        indicePrmpMin: 'L\'indice PRMP ne peut être inférieur à 0',
        deviseAchatMin: 'La devise d\'achat doit compoter 3 caractères',
        quotaMin: 'La quantité ne peut pas être inférieur à 0',
        quotaCommandeMin: 'La quantité ne peut pas être inférieur à 0',
        numeroShipmentMin2:'Le champ "numéro d\'expédition" doit comporter au moins 2 caractères',
        shdeCartonNameMin2: 'La référence carton doit faire au moins 2 caractères',
        shdeQuotaPrevuMin: 'La quantité prévue ne peut pas être inférieur à 0',
        firstStartBinsMin0:'Le début du premier niveau ne peut pas être inférieur à 0',
        firstEndBinsMin0:'La fin du premier niveau ne peut pas être inférieur à 0',
        secondStartBinsMin0:'Le début du second niveau ne peut pas être inférieur à 0',
        secondEndBinsMin0:'La fin du second niveau ne peut pas être inférieur à 0',
        thirdStartBinsMin0:'Le début du troisième niveau ne peut pas être inférieur à 0',
        thirdEndBinsMin0:'La fin du troisième niveau ne peut pas être inférieur à 0',
        fourthStartBinsMin0:'Le début du quatrième niveau ne peut pas être inférieur à 0',
        fourthEndBinsMin0:'La fin du quatrième niveau ne peut pas être inférieur à 0',
        fifthStartBinsMin0:'Le début du cinquième niveau ne peut pas être inférieur à 0',
        fifthEndBinsMin0:'La fin du cinquième niveau ne peut pas être inférieur à 0',
        firstBinsMin0:'Le premier niveau ne peut pas être inférieur à 0',
        secondBinsMin0:'Le second niveau ne peut pas être inférieur à 0',
        thirdBinsMin0:'Le troisième niveau ne peut pas être inférieur à 0',
        fourthBinsMin0:'Le quatrième niveau ne peut pas être inférieur à 0',
        fifthBinsMin0:'Le cinquième niveau ne peut pas être inférieur à 0',
        referencePoMin2:'La référence de bon de commande doit comporter au moins 2 caractères',
        rpIsMin0:'Le niveau de stock minimum RP de {name} ne peut pas être inférieur 0',
        pslIsMin0:'Le niveau de stock optimal PSL de {name} ne peut pas être inférieur 0',
        quantiteMin1:'La quantité ne peut être inférieur à 1',
        referenceToMin2:'La référence doit faire au moins 2 caractères',
        rpMin0:'Le niveau de stock minimal ne peut pas être inférieur à 0',
        pslMin0:'Le niveau de stock optimal ne peut pas être inférieur à 0',
        toMaxMin10:'Le nombre maximum d\'instructions pour un ordre de transfert ne peut pas être inférieur 10',
        giftCardMin1:'La durée de validité d\'un dépôt sur une carte cadeau ne peut pas être inférieur à 1 mois',
        giftCardMontantMin: 'Le montant de la carte cadeau ne peut pas être inférieur à 1',
        paiementMin: 'Les indications de paiement doivent être supérieur à 0',
        modifManualValueMin1: 'La valeur doit être supérieur à 1',
        codeCaisseMin4: 'L\'identifiant de caisse doit comporter au moins 4 caractères',
        detailModePaiementMin0: 'Les valeurs ne peuvent pas être inférieur à 0',
        //Max
        quantiteMax8000000:'The quantity must be less than 8 000 000',
        nameMax191: 'Le champ "nom" doit comporter moins de 191 caractères',
        nameMax250: 'Le champ "nom" doit comporter moins de 250 caractères',
        nameMax50: 'Le champ "nom" doit comporter moins de 50 caractères',
        skuMax250: 'Le champ "Sku" doit comporter moins de 250 caractères',
        phoneMax20: 'Le champ "téléphone" doit comporter moins de 20 caractères',
        firstnameMax50: 'Le champ "prénom" doit comporter moins de 50 caractères',
        firstnameMax191: 'Le champ "prénom" doit comporter moins de 191 caractères',
        nameMax20: 'Le champ "nom" doit comporter moins de 20 caractères',
        logoMax50: 'Le champ "logo" doit comporter moins de 50 caractères',
        gradeMax50: 'Le champ "grade" doit comporter moins de 50 caractères',
        maxusemoisdMax120: 'Le champ "Durée de validité" ne peut pas excéder 120 mois',
        maxusemoisproformaMax120: 'Le champ "Durée de validité d\'une proforma" ne peut pas excéder 120 mois',
        caMax2000000000: 'Le CA ne peut pas excéder 2 000 000 000 XPF',
        pmMax2000000000: 'Le PM ne peut pas excéder 2 000 000 000 XPF',
        tfMax100: 'Le TF ne peut pas excéder 100 %',
        ivMax999: 'Le IV ne peut pas excéder 999 articles',
        entreMax10000000: 'Le nombre de passages clients (entrée et sortie) ne peut pas excéder 10 000 000',
        codeMax10: 'Le champ "code" doit comporter moins de 10 caractères',
        rcsMax20: 'Le champ "RCS" doit comporter moins de 20 caractères',
        codeBanqueMax5: 'Le champ "Code Banque" doit comporter moins de 5 caractères',
        codeGuichetMax5: 'Le champ "Code Guichet" doit comporter moins de 5 caractères',
        numeroCompteMax11: 'Le champ "Numéro de Compte" doit comporter moins de 11 caractères',
        cleRibMax2: 'Le champ "Clée RIB" doit comporter moins de 2 caractères',
        nomContactMax191: 'Le nom du contact doit comporter moins de 191 caractères',
        priceMax: 'Le prix ne peut pas être supérieur à 999999999.99',
        referenceMax191: 'Le code UPC doit faire moins de 191 caractères',
        reference2Max191: 'Le code UPC 2 doit faire moins de 191 caractères',
        internalIdMax191: 'L\'ID interne doit faire moins de 191 caractères',
        referenceToMax191: 'Le référence doit faire moins de 191 caractères',
        skuMax191: 'Le SKU doit faire moins de 191 caractères',
        parentNameMax250: 'Le Nom Parent doit faire moins de 250 caractères',
        promoPriceMax: 'Le prix promotionnel ne peut pas être supérieur à 999999999.99',
        prmpMax: 'Le PRMP ne peut pas être supérieur à 999999999.99',
        tgcMax: 'Le taux de TGC ne peut pas être supérieur à 100',
        imageMax250: 'Le lien image article doit comporter moins de 250 caractères',
        colorHexaMax: 'Le code couleur hexadecimal doit faire 6 caractères',
        buyPriceMax: 'Le prix d\'achat ne peut pas être supérieur à 999999999.99',
        indicePrmpMax: 'L\'indice PRMP ne peut pas être supérieur à 999999999.99',
        deviseAchatMax: 'La devise d\'achat doit compoter 3 caractères',
        maxPhoneLength6: 'Le numéro de téléphone doit comporter 6 chiffres maximum',
        quotaCommandeMax: 'La quantité ne peut pas être supérieur à 8000000',
        quotaMax: 'La quantité ne peut pas être supérieur à 8000000',
        numeroShipmentMax190:'Le numéro d\'expédition doit faire moins de 190 caractères',
        shdeCartonNameMax250: 'La référence carton doit faire moins de 250 caractères',
        shdeQuotaPrevuMax: 'La quantité prévue ne peut pas être supérieur à 8000000',
        firstStartBinsMax32000:'Le début de premier niveau ne peut pas être supérieur à 32000',
        firstEndBinsMax32000:'La fin du premier niveau ne peut pas être supérieur à 32000',
        secondStartBinsMax32000:'Le début de second niveau ne peut pas être supérieur à 32000',
        secondEndBinsMax32000:'La fin du second niveau ne peut pas être supérieur à 32000',
        thirdStartBinsMax32000:'Le début de troisième niveau ne peut pas être supérieur à 32000',
        thirdEndBinsMax32000:'La fin du troisième niveau ne peut pas être supérieur à 32000',
        fourthStartBinsMax32000:'Le début de quatrième niveau ne peut pas être supérieur à 32000',
        fourthEndBinsMax32000:'La fin du quatrième niveau ne peut pas être supérieur à 32000',
        fifthStartBinsMax32000:'Le début de cinquième niveau ne peut pas être supérieur à 32000',
        fifthEndBinsMax32000:'La fin du cinquième niveau ne peut pas être supérieur à 32000',
        firstBinsMax32000:'Le premier niveau ne peut pas être supérieur à 32000',
        secondBinsMax32000:'Le second niveau ne peut pas être supérieur à 32000',
        thirdBinsMax32000:'Le troisième niveau ne peut pas être supérieur à 32000',
        fourthBinsMax32000:'Le quatrième niveau ne peut pas être supérieur à 32000',
        fifthBinsMax32000:'Le cinquième niveau ne peut pas être supérieur à 32000',
        referencePoMax190:'La référence de bon de commande doit être inférieur à 190 caractères',
        rpIsMax8000000:'Le niveau de stock minimum RP de {name} ne peut pas être supérieur à 8000000',
        pslIsMax8000000:'Le niveau de stock optimal PSL de {name} ne peut pas être supérieur à 8000000',
        generateBarcodeMax1000:'Vous ne pouvez pas générer plus 1000 code-barres',
        rpMax8000000:'Le niveau de stock minimal ne peut pas être supérieur à 8000000',
        pslMax8000000:'Le niveau de stock optimal ne peut pas être superieur à 8000000',
        toMaxMax1000:'Le nombre maximum d\'instructions pour un ordre de transfert ne peut pas être supérieur 1 000',
        giftCardMax120:'La durée de validité d\'un dépôt sur une carte cadeau ne peut pas être supérieur à 120 mois',
        giftCardMontantMax: 'Le montant de la carte cadeau ne peut pas être supérieur à 100 000 000',
        paiementMax: 'Les indications de paiement doivent être inférieures à 1 000 000 000',
        codeCaisseMax30: 'L\identifiatn de caisse doit faire moins de 30 caractères',
        cashOutReasonMax100: 'La raison d\'un retrait d\'espèces doit faire moins de 100 caractères',
        //email
        emailEmail: 'Le champ "email" doit être un e-mail valide',
        //unique
        emailUnique: 'Il y a déjà un compte enregistré avec cette adresse e-mail',
        nameUnique: 'Ce nom exist déjà',
        skuUnique: 'Un autre article possède déjà la même Sku',
        referenceUnique: 'Le code UPC est déjà utilisé par un autre article',
        reference2Unique: 'Le code UPC 2 est déjà utilisé par un autre article',
        skuUnique: 'Le SKU est déjà utilisé par un autre article',
        alreadyExist: 'à déjà été assigné',
        sectionUnique: 'Cette rubrique existe déjà',
        //confirmed
        passwordConfirmed: 'Le mot de passe a mal été confirmé',
        confirmDifferentQuantityCollected:'La quantité collectée ne correspond pas à la quantité demandée, confirmer ?',
        confirmCancelSaleOrder:'Êtes-vous sûre de vouloir annuler cette commande client',
        //In
        roleIn: 'Le role attribué n\'est pas accepté',
        sexeIn: 'Le sexe attribué n\'est pas accepté',
        themeIn: 'Le thème attribué n\'est pas accepté',
        modepaiementIn: 'Un des modes de paiements indiqué n\'est pas accepté',
        customFieldIn: 'Le champ "{name}" a une valeur non valide',
        //exists
        companyExists: 'Cette entreprise n\'existe pas',
        moduleExists: 'Ce module n\'existe pas',
        sectionExists: 'Ce commerce n\'existe pas',
        rhExists: 'Ce staff n\'existe pas',
        gradeExists: 'Ce grade n\'existe pas',
        typefideliteExists: 'Ce type de carte de fidelité n\'existe pas',
        programfideliteExists: 'Ce programme de fidelité n\'existe pas',
        programgiftcardExists: 'Ce programme de Gift Card n\'existe pas',
        clientExists: 'Il existe déjà un client enregistré avec ce nom et ce prénom',
        internalIdExists: 'L\'internal ID est incorrect',
        firstlevelcatExists: 'Aucune correspondance trouvée avec le champ "Classification"',
        secondlevelcatExists: 'Aucune correspondance trouvée avec le champ "Famille"',
        thirdlevelcatExists: 'Aucune correspondance trouvée avec le champ "Sous-famille"',
        fourthlevelcatExists: 'Aucune correspondance trouvée avec le champ "Catégorie"',
        fifthlevelcatExists: 'Aucune correspondance trouvée avec le champ "Sous-catégorie"',
        fournisseurExists: 'Aucune correspondance trouvée avec le champ "Fournisseur"',
        marqueExists: 'Aucune correspondance trouvée avec le champ "Marque"',
        nameExists: 'Ce nom est déjà utilisé',
        referenceExists: 'Cette référence est déjà utilisée',
        articleNameExists: 'Aucun article ne correspond à ce nom d\'article',
        articleSkuExists: 'Aucun article ne correspond à ce Sku',
        poNameExists: 'Ce bon de commande n\'existe pas',
        sectionCodeExists: 'Ce code de commerce n\'existe pas',
        numeroShipmentExists: 'Ce numéro de bon d\'expédition est déjà utilisé',
        shipmentNameExists: 'Ce numéro de bon d\'expédition n\'existe pas',
        idsExists: 'L\'ID est incorrect',
        binsExists: 'Une bins avec la même adresse existe déjà pour cette zone de stockage',
        shdeCartonNameExists: 'Ce carton n\'existe pas dans ce bon d\'expédition',
        binsAlreadyExistsInSection: 'Une bins avec la même adresse existe déjà pour ce commerce',
        binsNotInThisSection: 'Cette bins n\'existe pas dans ce commerce',
        codeCaisseExists: 'Cet identifiant de caisse est déjà utilisé',
        caisseIdentExists:'Cette identifiant de caisse n\'existe pas',
        namePeripheriqueExists:'Ce nom de périphérique est déjà utilisé',
        //array
        moduleArray: 'Une erreur inattendue s\'est produite',
        sectionArray: 'Une erreur inattendue s\'est produite',
        rhArray: 'Une erreur inattendue s\'est produite',
        modepaiementArray: 'Une erreur inattendue s\'est produite',
        raisonmodifprixArray: 'Une erreur inattendue s\'est produite',
        //General
        sectionGeneral: 'Une erreur inattendue s\'est produite',
        rhGeneral: 'Une erreur inattendue s\'est produite',
        programfideliteGeneral: 'Une erreur inattendue s\'est produite',
        programgiftcardGeneral: 'Une erreur inattendue s\'est produite',
        forbiddenGeneral: 'Vous n\'êtes pas autorisé à réaliser cette action',
        //Numeric
        maxusemoisNumeric: 'Le champ "Durée de validité" doit être une valeur numeriqe',
        maxusemoisproformaNumeric: 'Le champ "Durée de validité d\'une proforma" doit être une valeur numeriqe',
        dixmilleNumeric: 'Le nombre de billets de 10 000 XPF doit être une valeur numérique',
        cinqmilleNumeric: 'Le nombre de billets de 5 000 XPF doit être une valeur numérique',
        milleNumeric: 'Le nombre de billets de 1 000 XPF doit être une valeur numérique',
        cinqcentNumeric: 'Le nombre de billets de 500 XPF doit être une valeur numérique',
        deuxcentNumeric: 'Le nombre de pièces de 200 XPF doit être une valeur numérique',
        centNumeric: 'Le nombre de pièces de 100 XPF doit être une valeur numérique',
        cinquanteNumeric: 'Le nombre de pièces de 50 XPF doit être une valeur numérique',
        vingtNumeric: 'Le nombre de pièces de 20 XPF doit être une valeur numérique',
        dixNumeric: 'Le nombre de pièces de 10 XPF doit être une valeur numérique',
        cinqNumeric: 'Le nombre de pièces de 5 XPF doit être une valeur numérique',
        deuxNumeric: 'Le nombre de pièces de 2 XPF doit être une valeur numérique',
        unNumeric: 'Le nombre de pièces de 1 XPF doit être une valeur numérique',
        caNumeric: 'Le CA doit être une valeur numérique',
        pmNumeric: 'Le PM doit être une valeur numérique',
        tfNumeric: 'Le TF doit être une valeur numérique',
        ivNumeric: 'Le IV doit être une valeur numérique',
        entreNumeric: 'Le nombre de passages clients (entrée et sortie) doit être une valeur numérique',
        codeBanqueNumeric: 'Le champ "Code Banque" doit être une valeur numérique',
        codeGuichetNumeric: 'Le champ "Code Guichet" doit être une valeur numérique',
        numeroCompteNumeric: 'Le champ "Numéro de Compte" doit être une valeur numérique',
        cleRibNumeric: 'Le champ "Clée Rib" doit être une valeur numérique',
        priceNumeric: 'Le prix doit être une valeur numérique',
        promoPriceNumeric: 'Le prix promotionnel doit être une valeur numérique',
        prmpNumeric: 'Le PRMP doit être une valeur numérique',
        tgcNumeric: 'Le taux de TGC doit être une valeur numérique',
        buyPriceNumeric: 'Le prix d\'achat doit être une valeur numérique',
        indicePrmpNumeric: 'L\'indice PRMP doit être une valeur numérique',
        internalIdNumeric: 'L\'internal Id doit être une valeur numérique',
        referenceNumeric: 'Le code UPC doit être une valeur numérique',
        quotaNumeric: 'La quantité doit être une valeur numérique',
        quotaCommandeNumeric: 'La quantité doit être une valeur numérique',
        shdeQuotaPrevuNumeric: 'La quantité prévue doit être une valeur numérique',
        firstStartBinsNumeric: 'Le début du premier niveau doit être une valeur numérique',
        firstEndBinsNumeric: 'La fin du premier niveau doit être une valeur numérique',
        secondStartBinsNumeric: 'Le début du second niveau doit être une valeur numérique',
        secondEndBinsNumeric: 'La fin du second niveau doit être une valeur numérique',
        thirdStartBinsNumeric: 'Le début du troisième niveau doit être une valeur numérique',
        thirdEndBinsNumeric: 'La fin du troisième niveau doit être une valeur numérique',
        fourthStartBinsNumeric: 'Le début du quatrième niveau doit être une valeur numérique',
        fourthEndBinsNumeric: 'La fin du quatième niveau doit être une valeur numérique',
        fifthStartBinsNumeric: 'Le début du cinquième niveau doit être une valeur numérique',
        fifthEndBinsNumeric: 'La fin du cinquième niveau doit être une valeur numérique',
        firstBinsNumeric: 'Le premier niveau doit être une valeur numérique',
        secondBinsNumeric: 'Le second niveau doit être une valeur numérique',
        thirdBinsNumeric: 'Le troisième niveau doit être une valeur numérique',
        fourthBinsNumeric: 'Le quatrième niveau doit être une valeur numérique',
        fifthBinsNumeric: 'Le cinquième niveau doit être une valeur numérique',
        rpIsNumeric:'Le niveau de stock minimum RP de {name} doit être une valeur numérique',
        pslIsNumeric:'Le niveau de stock optimal PSL de {name} doit être une valeur numérique',
        quantiteNumeric:'La quantité doit être une valeur numérique',
        rpNumeric:'La niveau de stock minimum doit être une valeur numérique',
        pslNumeric:'La niveau de stock optimal doit être une valeur numérique',
        toMaxNumeric:'Le nombre maximum d\'instructions pour un ordre de transfert doit être une valeur numérique',
        giftCardNumeric:'La durée de validité d\'un dépôt sur une carte cadeau doit être une valeur numérique',
        giftCardMontantNumeric: 'Le montant de la carte cadeau doit être une valeur numérique',
        paiementNumeric: 'Les indications de paiement doivent être des valeurs numériques',
        modifManualValueNumeric: 'La valeur doit être numérique',
        detailModePaiementNumeric: 'Toutes les valeurs doivent être numériques',
        //Boolean
        donneeclientBoolean: 'Une erreur inattendue s\'est produite',
        objectifvendeurBoolean: 'Une erreur inattendue s\'est produite',
        objectifcommerceBoolean: 'Une erreur inattendue s\'est produite',
        //General
        unknowError: 'Une erreur inattendue s\'est produite',
        identFailed: 'Les identifiants de connexion sont incorrects',
        //divers
        assignedTo:'Attribué à',
        finalize:'Finaliser',
        finalized:'Finalisé',
        orderedInThisShop:'Commandé dans ce magasin',
        toBeCollectedInThisShop:'A collecter dans ce magasin',
        createATransferOrder:'Créer un ordre de transfert',
        hour:'Heure',
        ordered:'Commandé',
        closeWithDifference:'Le compte de votre caisse ne semble pas correct. Souhaitez-vous tout de même clôturer en l\'état ?',
        articleNoReferenceFind: 'Aucun article ne correspond à cette référence',
        mustIndicateReasonRetrait: 'Vous devez indiquer la raison pour laquelle vous retirez de l\'espèces',
        gradeHasUser: 'Vous ne pouvez pas supprimer ce grade car certains membres du staff ont actuelement ce grade. Pour supprimer ce grade, vous devez d\'abord retirer ce grade pour tous les membres du staff pour qui ce grade est attribué.',
        modepaiementRemove: 'Vous ne pouvez pas supprimer ce mode de paiement car il est attribué à certains de vos commerces. Si vous souhaitez vraiment le supprimer, vous devez d\'abord le retirer des commerces pour lesquels ce mode de paiement est attribué.',
        adminOverQuota: 'Vous ne pouvez pas ajouter le module "Administration" à cet utilisateur car votre quota d\'utilisateurs pour ce module est déjà atteint. Merci de contacter C.I.Web si vous souhaitez modifier ce quota.',
        erpOverQuota: 'Vous ne pouvez pas ajouter le module "Commandes et Gestion des Stocks" à cet utilisateur car votre quota d\'utilisateurs pour ce module est déjà atteint. Merci de contacter C.I.Web si vous souhaitez modifier ce quota.',
        usersOverQuota: 'Vous ne pouvez pas ajouter le module "Utilisateurs" à cet utilisateur car votre quota d\'utilisateurs pour ce module est déjà atteint. Merci de contacter C.I.Web si vous souhaitez modifier ce quota.',
        wmsOverQuota: 'Vous ne pouvez pas ajouter le module "WMS" à cet utilisateur car votre quota d\'utilisateurs pour ce module est déjà atteint. Merci de contacter C.I.Web si vous souhaitez modifier ce quota.',
        posOverQuota: 'Vous ne pouvez pas ajouter le module "POS" à cet utilisateur car votre quota d\'utilisateurs pour ce module est déjà atteint. Merci de contacter C.I.Web si vous souhaitez modifier ce quota.',
        endUnderrBegin: 'La date de fin de période ne peut pas être inférieur à celle du début de période',
        perdioExceedAYear: 'La période ne peut pas être supérieur à une année',
        dayDateFormat: 'La date de cette ligne du fichier CSV n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        commandeDateFormat: 'La date de commande n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        expectedDateFormat: 'La date de transfert n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        expeditionDateFormat: 'La date attendue d\'expédition n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        receptionDateFormat: 'La date attendue de réception n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        beginDateFormat: 'La date de commencement n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        endDateFormat: 'La date de fin n\'est pas dans un format correct. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        dateFormat: 'La date n\'est pas au bon format. Le format correct est celui qui est indiqué dans les paramètres avancés de votre compagnie.',
        beginRequiredWithEnd: 'Vous devez indiquer une date de commencement puisque vous avez indiqué une date de fin',
        endRequiredWithBegin: 'Vous devez indiquer une date de fin puisque vous avez indiqué une date de commencement',
        sizeLineCsv: 'Cette ligne du fichier CSV ne contient pas le nombre correct de colonnes',
        row: 'Ligne',
        lines: 'Lignes',
        noRhOrNoAuth: 'Staff inexistant ou non autorisé à accéder à cette section',
        noRhTos: 'Vous n\'avez pas d\'ordres de transfert en attente',
        imageUrl: 'Le lien image article doit être une URL valide',
        havestockIn: 'Vous devez indiquer si cet article doit être dépendant d\'un stock',
        zoneAlreadyHaveCommercial: 'Ce commerce a déjà une zone de stock de type lieu de vente',
        zoneAlreadyHaveCommande: 'Ce commerce a déjà une zone de stock de type commande',
        archiveZoneAddArticle: 'Cette zone de stock contient des articles et ne peut donc pas être archivée',
        deleteZoneAddArticle: 'Cette zone de stock contient des articles et ne peut donc pas être supprimée',
        customFieldsAlreadyExists: 'Un champ personalisé avec le même nom exists déjà',
        youMustIndicateField: 'Vous devez renseigner le champ',
        limitCsv: 'Le fichier CSV doit contenir moins de {limit} lignes',
        filename: 'Nom du fichier',
        notANameForCustomFields: 'Ce nom ne peut pas être donné à un champ personnalisé',
        quotaCommandeInteger: 'La quantité doit être un nombre entier',
        selectBinsOrZone: 'Vous devez scanner une bins ou sélectionner une zone',
        shipmentLineAlreadyReceptione:'Ce bloc a déjà été validé',
        changHaveBinsMustEqualZero:'Vous ne pouvez pas modifier l\'option bins pour une zone qui contient déjà des articles. Cette zone contient actuellement: {quota} articles',
        referenceToAlreadyUse:'Cette référence est déjà utilisée dans un ordre de transfer dont la collecte a débuté',
        rhNotInSection:'Ce staff n\'existe pas ou ne travaille pas dans le commerce d\'origine',
        communToLineNotTheSame:'Certaines données qui doivent être communes à toutes les lignes d\'un même ordre de transfère ne le sont pas',
        noDoubleArticleTo:'Cet article est déjà présent dans dans une autre ligne de cet ordre de transfert',
        noAutorizedUpdateTo:'Vous ne pouvez pas mettre à jour un ordre de transfert qui a passé le stade de collecte',
        cantDeleteLine: 'Vous ne pouvez pas supprimer cette ligne',
        pslGte: 'Le niveau de stock optimal ne peut pas être inférieur au niveau de stock minimum',
        noDockForReassort: 'Vous n\'avez aucun dock défini',
        noArticleForReassort: 'Il n\'y a aucun article dans votre sélection',
        noToMaxForReassort: 'Vous devez d\'abord définir le nombre maximum d\'instructions que peut contenir un ordre de transfert dans les paramètres de votre entreprise',
        valuePromotionNotOk: 'Les valeurs de promotion sont incorrectes',
        resteIsOverTicket: 'Le montant payé est supérieur au restant à payer du ticket',
        majorPaiementError: 'Erreur de paiement majeure, le client a payé plus que le prix demandé',
        paiementIsNegative: 'Le montant à payer ne peut pas être négatif',
        paiementIsPositive: 'Le restant dû doit être à 0',
        noCommercialZone:'Aucune zone commerciale n\'est définie pour ce commerce',
        noGiftCardFound: 'Aucune carte cadeau ne correspond à cette référence',
        noTicketFound: 'Aucun ticket ne correspond à cette référence',
        noCommandeFound: 'Aucune commande ne correspond à cette référence',
        noPossibilityToReturnArticle:'Une erreur empêche le retour de l\'article',
        articleAlreadyReturn: 'Cet article a déjà été retourné',
        hostIp:'Ceci n\'est pas une adresse IP valide',
        optional:'Optionnel',
        sectionDontHaveCommandeZone: 'Les sections concernées par cette commande ne possèdent pas de zone de commande',
        commandeAlreayCancel: 'Cette commande client a déjà été annulée',
        mustSpecifyRemboursement: 'Vous devez spécifier le mode de remboursement',
        mustSpecifyGiftCardReference: 'Vous devez indiquer le code-barres de la carte cadeau',
        mustChooseOneRaccourci: 'Vous devez choisir un raccourci',
        /*************Modules name**************/
        moduleadmin:'Configuration',
        moduleerp:'ERP',
        modulewms:'WMS',
        modulepos:'POS',
        moduleusers:'Utilisateurs',
        /*********divers***********/
        cetteLigne:'cette ligne',
        yes: 'Oui',
        no: 'Non',
        signIn: 'Connexion',
        password: 'Mot de passe',
        signOut: 'Deconnexion',
        administration: 'Configuration',
        users: 'Licences',
        gestionStockCommandes: 'ERP',
        shops: 'Commerces',
        shop: 'Commerce',
        delete: 'Supprimer',
        remove: 'Retirer',
        update: 'Mettre à jour',
        confirmDeleteUser: 'Souhaitez-vous vraiment supprimer l\'utilisateur',
        confirmDeleteGrade: 'Souhaitez-vous vraiment supprimer le grade',
        typeDeleteX: 'Souhaitez-vous vraiment supprimer ',
        typeRemoveX: 'Souhaitez-vous vraiment retirer ',
        confirmArchiveX: 'Souhaitez-vous vraiment archiver ',
        confirmActiveX: 'Souhaitez-vous vraiment activer ',
        confirmDeleteGoals: 'Souhaitez-vous vraiment supprimer les objectifs de cette période ?',
        confirmRemoveRh: 'Souhaitez-vous vraiment retirer',
        confirmOpenFonCaisse: 'Êtes-vous sur de vouloir ouvrir cette caisse avec ',
        confirmAddClientForm: 'Souhaitez-vous vraiment ajouter un formulaire client',
        confirmRemoveClientForm: 'Souhaitez-vous vraiment retirer le formulaire client',
        ofFondCaisse: 'de fond de caisse',
        confirmArchiveX: 'Souhaitez-vous vraiment archiver ',
        confirmUnarchiveX: 'Souhaitez-vous vraiment activer ',
        confirmClockingX: 'Souhaitez-vous vraiment pointer ',
        confirmValidateLine:'Etes-vous sûr de vouloir valider ce bloc',
        confirmValidateAll:'Etes-vous sûr de vouloir tout valider',
        confirmClosefondCaisse:'Êtes-vous sûr de vouloir clôturer cette caisse avec ',
        confirmDifferenceFondCaisse:'Êtes-vous sûr de vouloir ouvrir cette caisse avec une différence de ',
        de: 'de',
        irreversibleAction: 'Attention: cette action sera irréversible',
        company: 'Entreprise',
        canceled: 'Annulé',
        noLogoActualy: 'Aucun logo actuellement',
        man: 'Homme',
        woman: 'Femme',
        rh: 'Staff',
        modePaiement: 'Mode de paiement',
        general: 'Général',
        genere:'Générer',
        proposal:'Propositions',
        tarifSellerModification: 'Modification manuelle d\'un prix',
        openFondCaisse: 'Ouverture Caisse - Fond de caisse',
        closeFondCaisse: 'Clôture de Caisse',
        repartGoals: 'Répartition objectifs',
        openCaisse: 'Ouverture de caisse',
        openTiroir:'Ouverture du tiroir',
        open:'Ouvrir',
        closeWith:'Clôturé avec',
        a: 'à',
        searchArticle: 'Recherche article...',
        searchInWait: 'Recherche en cours...',
        cashier: 'Caissier',
        customerPlacehlder: 'Client...',
        noRhForSection: 'Aucun staff n\'a été attribuée pour ce point de vente',
        consultPriceItem: 'Voir prix article...',
        seeSaleOrder:'Voir la commande client',
        stockArea: 'Zone de Stock',
        createStockArea: 'Créer une Zone de Stock',
        stockHaveBins: 'Cette zone de stock doit avoir des bins',
        itemReference: 'Référence article...',
        cashOut: 'Retrait espèces',
        consulter: 'Consulter',
        attribuer: 'Attribuer',
        generateReassort: 'Générer une proposition de réassort',
        proposalReassortList: 'Proposition de réassort',
        forDepotBanque: 'Ce retrait est pour un dépôt en banque',
        retraitForOtherReason: 'Ce retrait est pour une autre raison',
        retraitOtherLabel: 'Indiquez la raison pour laquelle vous retirez de l\'espèces',
        confirmRetraitOtherReason: 'Souhaitez-vous vraiment retirer de l\'espèces pour un montant de',
        confirmRetraitBanqueReason:' Souhaitez-vous vraiment déposer en banque un montant de',
        confirmDeleteCustomerAssociate: 'Souhaitez-vous vraiment supprimer l\'association à ce client',
        confirmDeleteDivers: 'Souhaitez-vous vraiment supprimer',
        confirmDelete: 'Souhaitez-vous confirmer la suppression',
        successOperation: 'Opération effectuée avec succès',
        successInHistorique: 'Importation lancée avec succès. Vous pouvez suivre l\'état de l\'importation dans votre historique d\'importation.',
        addClient: 'Ajouter un client',
        editClient: 'Editer un client',
        importGoals: 'Importer des objectifs',
        createGrade: 'Créer un grade',
        createModePaiement: 'Ajouter un mode de paiement',
        createCashOutReason: 'Ajouter un justificatif de retrait d\'espèces en caisse',
        cashOutMore: 'Vos justificatif de retrait d\'espèces en caisse',
        modePaiementForced: 'Modes de paiement basiques',
        modePaiementMore: 'Vos modes de paiements ajoutés',
        items: 'Articles',
        today: 'Aujourd\'hui',
        hier: 'Hier',
        demain: 'Demain',
        noObjectif: 'Il n\'y a aucun objectif POS pour cette période',
        objectifs: 'Objectifs',
        realise: 'Réalisé',
        nbrEntreClient: 'Nombre de passage clients (entrée et sortie)',
        edit: 'Editer',
        period: 'Période',
        oneDay: 'Une journée',
        manyDays: 'Plusieurs jours',
        day: 'Jour',
        de: 'De',
        vers: 'Vers',
        toA: 'À',
        disponible: 'Disponible',
        addCashOut: 'Retrait d\'espèces',
        noEnougthForReassort: '{name} ne possède pas suffisament de stock pour satisfaire cette modification',
        needEntre: 'Ce graphique sera disponible lorsque le nomtre d\'entrée client sera renseigné',
        createGoals: 'Créer des objectifs commerce',
        attributeStaffGoals: 'Attribuer des objectifs au staff',
        deleteGoals: 'Supprimer des objectifs',
        createOneDayGoals: 'Créer les objectifs d\'une journée',
        dropCsv: 'Déposez le fichier CSV ici ou cliquez pour télécharger.',
        noDataInCsv: 'Aucne donnée n\'a été trouvé dans ce fichier csv',
        objectifCsvFileToLong: 'Le fichier csv doit avoir au maximum 367 lignes',
        doublonCsv: 'Certaines colones du fichier csv sont utilisées sur plusieurs données différentes',
        template: 'Modèle',
        importedRows: 'Lignes importées: ',
        infoModePaiement: 'Il s\'agit ici de lister les modes de paiement possibles pour votre entreprise. Attention: chacun de vos commerces peut utiliser des modes de paiements différents. De ce fait, il vous faut donc vous rendre dans les options de chacun de vos commerces pour y indiquer les modes de paiements qui y sont acceptés.',
        restrictionStaff: 'Restrictions Staff',
        reassortWait:'Proposition de réassort en cours de calcul. Cette tache peut prendre quelques minutes...',
        waitReassortTo:'Ordre(s) de transfert(s) en cours de création. Vous allez être bientôt redirigé. Cette tâche peut prendre quelques minutes...',
        authorized: 'Autorisés',
        noRestriction: 'Aucune restriction',
        scanBadge: 'Scannez votre badge ici...',
        formclient: 'Formulaire Client',
        explicationFormClient: 'Indiquez les champs que vous souhaitez faire apparaitre dans le formulaire de création d\'un client',
        field:'Champ',
        obligatoire:'Obligatoire',
        requiredField: 'Champ obligatoire',
        notRequiredField: 'Champ non obligatoire',
        uniqueField: 'Valeur unique',
        notUniqueField: 'Valeur non unique',
        freeField: 'Valeur libre',
        fieldListData: 'Soumis à une liste de données',
        civilite: 'Civilité',
        activer: 'Activer',
        adressePhysique: 'Adresse Physique',
        adressePostale: 'Adresse Postale',
        inscriptionNewsletter: 'Inscription Newsletter',
        noFormClient: 'Le forumlaire client de votre entreprise n\'est pas configuré',
        homme: 'Homme',
        femme: 'Femme',
        noClientToEdit: 'Vous devez d\'abord indiquer un client',
        noCashierIndicated : 'Vous devez d\'abord indiquer un caissier',
        periodHigherOneMonth:'La période sélectionnée doit être inférieur à 1 mois',
        newItem: 'Nouvel article',
        parameterName: 'Paramètres avancés',
        otherCompany: 'Autres entreprises',
        clientType: 'Type de client',
        quartier: 'Quartier',
        dateDeNaissance: 'Date de naissance',
        selectAType: 'Veuillez choisir un type',
        customField: 'Champ personnalisé',
        customFields: 'Champs personnalisés',
        category: 'Catégories',
        providerName: 'Nom du fournisseur',
        contactName: 'Nom du contact',
        categoryName: 'Nom de la catégorie',
        fieldName: 'Nom du champ',
        defaultFields: 'Champs par défaut',
        items: 'Articles',
        providers: 'Fournisseurs',
        nouveauFournisseur: 'Nouveau fournisseur',
        editFournisseur: 'Editer un fournisseur',
        CDD: 'CDD',
        CDIE: 'CDI - Période d\'essai',
        CDI: 'CDI',
        archive: 'Archiver',
        thisStaffIsArchive: 'Attention: ce staff est archivé',
        includeArchiveStaff: 'Inclure les Staff archivés',
        includeArchiveProvider: 'Inclure les fournisseurs archivés',
        includeArchiveData: 'Inclure les données archivées',
        includeArchivetem: 'Inclure les articles archivés',
        clientAssocie: 'Client Associé',
        clientArchiveAccocie: 'Ce Staff est archivé et ne peut donc pas être associé à un client',
        clientArchiveBadge: 'Ce Staff est archivé et il n\'est donc pas possible de générer un badge',
        archive: 'Archiver',
        downloadCSV: 'Exporter en csv',
        downloadExcel: 'Exporter en excel',
        downloadArticlesCSV: 'Exporter les articles en csv',
        listDonnéesCsv: 'Exporter la liste des données possibles',
        firstlevelcat:'Classification',
        secondlevelcat: 'Famille',
        thirdlevelcat: 'Sous-famille',
        fourthlevelcat:'Categorie',
        fifthlevelcat: 'Sous-categorie',
        imageLink: 'Lien image article',
        categorysingular: 'Catégorie',
        addone: 'Ajouter une',
        staffIsAssociate: 'Ce Staff est associé au client',
        staffIsNotAssociate: 'Ce Staff n\'est associé à aucun client',
        newbrand: 'Nouvelle marque',
        includeArchiveBrands: 'Inclure les marques archivées',
        formBrand: 'Formulaire marque',
        archivedBrand: 'Cette marque est archivée',
        archivedProvider:'Ce fournisseur est archivé',
        associationStaffCustomer: 'Association d\'un Staff et d\'un client',
        transferOrderAssociation:'Association ordres de transfert',
        saleOrderAssociation:'Association commande client',
        noAssociateCustomerResult: 'Il n\'y a pas de possibilités d\'associations trouvées',
        impossibleDeletingFamily: 'La supréssion est impossible !',
        familyContainItems: 'Cette catégorie contient des articles',
        impossibleArchivingFamily: 'L\'archivage est impossible !',
        formItem: 'Formulaire article',
        internalId: 'ID interne',
        fournisseur: 'Fournisseur',
        marque: 'Marque',
        reference: 'Code UPC',
        reference2: 'Code UPC 2',
        itemName: 'Nom article',
        priceTtc: 'Prix TTC',
        priceHt: 'Prix HT',
        discountPrice: 'Prix promotionnel',
        discountProfil: 'Profil de promotion',
        buy_price: 'Prix achat en devise',
        devise_achat: 'Devise achat',
        stores: 'Commerces',
        tgc: 'Taux TGC',
        downloadBadge: 'Télécharger le badge',
        generateBadge: 'Générer le badge',
        thisItemMustHaveStock: 'Cet article sera dépendant d\'un stock',
        itemHaveStock: 'Liaison au stock',
        customizationBadge: 'Personnalisation du badge',
        addIdPicture: 'Ajouter une photo sur le badge',
        addGrade: 'Ajouter le grade',
        addShopName: 'Ajouter le nom de l\'entité',
        addLastname: 'Ajouter le nom de famille',
        addShopLogo:'Ajouter le logo du commerce',
        chooseYouCompany: 'Selectionnez l\'entité',
        chooseYourBgColor: 'Choisissez une couleur de fond',
        chooseYourTextEntityColor: 'Choisissez la couleur du texte de l\'entité',
        chooseRefundMethod:'Choisissez le mode de remboursement',
        enterYourCustomColor: 'Ajouter une couleur en hexadécimal',
        addCustomColor: 'Ajouter une couleur de fond personnalisée',
        noEntity: 'Aucune entité',
        red: 'Rouge',
        blue: 'Bleu',
        yellow: 'Jaune',
        pink: 'Rose',
        green: 'Vert',
        black: 'Noir',
        white: 'Blanc',
        beneficiary: 'Bénéficiaire',
        selectABeneficiary: 'Sélectionner un bénéficiaire',
        muststock: 'Article a un stock',
        mobil: 'Zone de transition',
        chosseMobile: 'Choisir le véhicule de livraison',
        choseReceptionZone:'Choisir la zone de réception',
        reception: 'Zone de réception',
        commerciale: 'Lieu de vente',
        prive: 'Zone non accéssible à la vente et aux mouvements de stock',
        stockage: 'Zone traditionnelle de stockage',
        commande: 'Stockage des commandes',
        zoneArchived: 'Attention : cette zone est archivée',
        importHistory: 'Imports',
        importReport: 'Rapport d\'import',
        articleImport: 'Import d\'articles',
        poImport: 'Import Bon de Commande',
        toImport: 'Import Ordre de Transfère',
        shipmentImport: 'Import Bon d\'Expedition',
        rppslImport: 'Import Réassort automatique',
        enCours: 'En cours',
        dataListpossible: 'Liste possible de données',
        editPersonalField: 'Editer un champ personalisé',
        editBrand: 'Editer une marque',
        clocking: 'Pointage',
        errorClocking: ' Badge non attribué pour ce commerce ou incorrect',
        validateProposalReassort: 'Êtes-vous sur de vouloir convertir cette proposition de réassort en ordre de transfert ?',
        nonAttribue: 'Non attribué',
        detailHistory: 'Détails de l\'historique',
        detailArticle: 'Détails de l\'article',
        detailPrice: 'Détails du prix',
        detailItem:'Détails article',
        colonnes: 'Colonnes',
        rechercheSurValeurUnique: 'Recherche sur valeur unique',
        filtres: 'Filtres',
        prmpIndice: 'Indice PRMP',
        parent_name: 'Nom du parent',
        itemInfos: 'Informations sur l\'article',
        customer: 'Client',
        customerInfos: 'Informations du client',
        principalInfo: 'Informations principales',
        secondInfo: 'Informations secondaires',
        itemLinkAndNotLinkToTock: 'Articles liés et non liés au stock',
        itemLinkToTock: 'Articles liés au stock',
        itemNotLinkToTock: 'Articles non liés au stock',
        diverseInfo: 'Informations diverses',
        devise: 'Devise',
        migrate: 'Migrer',
        migrateFrom: 'Migrer de',
        to: 'vers',
        errorSelectFamily: 'Vous devez indiquer une destination de migration valide',
        aucune: 'Aucune',
        justItemsWhoHaveStock: 'Uniquement les articles qui ont du stock',
        impossibleExport: 'Export impossible',
        maxLineAuth: 'Vous pouvez exporter au maximum {limit} lignes d\'un coup. Vous essayez actuellement d\'en exporter {actual}',
        csvWillBeReady: 'Demande d\'export enregistrée. Votre fichier CSV sera disponible sous peu dans votre gestionnaire de téléchargement de fichier CSV',
        lastExportCsv: 'Derniers exports de fichiers CSV',
        exportDate: 'Date d\'export',
        state:'État',
        itemHasNoStock:'Cet article n\'a pas de stock',
        errorExport: 'Une erreur est survenue lors de l\'export',
        errorImport: 'Une erreur est survenue lors de l\'import',
        download: 'Télécharger',
        editArticle: 'Edition d\'un article',
        havestockInImport: 'Vous devez indiquer si cet article doit être dépendant d\'un stock',
        itemCard: 'Fiche Article',
        defaultConfig: 'Configuration par défaut',
        personalizedConfig: 'Configuration personnalisée',
        addNewSection:'Ajouter une nouvelle rubrique',
        add: 'Ajouter',
        apiKey:'Clé API',
        msgApiKeyExpire:'Votre clé API va expirer dans moins de 30 jours',
        msgNoApiKey:'Aucune clé API',
        noUserConf: 'Aucune configuration personnalisée',
        createApiKey:'Créer une clé API',
        activeApiKey:'Clé API active',
        createNewApiKey:'Créer une nouvelle clé API',
        msgApiKey:'Voici votre clé API, veillez à la conserver car elle ne réapparaîtra plus',
        msgRecreateApiKey:'Êtes-vous sûr de vouloir regénérer une clé API',
        deleteUserConf: 'Supprimer la configuration personnalisée',
        persoConfigVide: 'Votre configuration personnalisée est vide',
        defautConfigVide: 'Votre configuration par défaut est vide',
        noDefaultConf:'Aucune configuration par défaut',
        customizeInterface: 'Customiser l\'interface',
        customizationInterface: 'Customisation de l\'interface',
        importArticleAlreadyInProgress:'Un import d\'articles est déjà en cours d\'exécution',
        purchaseOrder:'Bon de commande',
        purchaseOrderForm:'Formulaire bon de commande',
        orderDate: 'Date de commande',
        expectedDeliveryDate:'Date attendue d\'expedition',
        expectedReceiptDate:'Date attendue de reception',
        estimatedDelivery:'Livraison estimee',
        lastSalesOrder:'Dernière commande client',
        searchSalesOrder:'Rechercher une commande client',
        quantity:'Quantite',
        quantityTotal:'Quantité totale',
        quantityDisp:'Quantité disponible',
        quantityBloque:'Quantité bloquée',
        quantiteOnPurchase: 'Quantite commande',
        quantiteWait: 'Quantité en attente',
        purchaseOrders:'Bons de commande',
        createPurchaseOrder:'Créer un bon de commande',
        purchaseOrderName:'Nom du bon de commande',
        createdAt:'Date de création',
        lastUpdate:'Dernière mise à jour',
        divers:'Divers',
        createBy: 'Créé par',
        notDelivered: 'Non réceptionné',
        notDeliveredLines: 'les lignes non réceptionnées',
        completed: 'Finalisé',
        includeAllPurchaseOrders: 'Inclure les bons de commande finalisés',
        editPurchaseOrder: 'Editer le bon de commande',
        importPo: 'Importer du contenu aux Bons de Commande',
        importShipment: 'Importer du contenu aux Bons d\'expéditions',
        importReassort: 'Importer du contenu au Réassort automatique',
        importTo: 'Importer du contenu aux Ordres de Transferts',
        quota_recu: 'Quantite recue',
        status:'Statut',
        toAlreadyCompleted: 'Cet ordre de tranfert a déjà été finalisé',
        allItems: 'Tous les articles',
        statusItems:'Statut des articles',
        statusLines:'Statut des lignes',
        itemsTotallyReceived: 'Articles entièrement réceptionnés',
        itemsPartiallyReceived: 'Articles partiellement réceptionnés',
        itemsNotReceived: 'Articles non réceptionnés',
        sku:'SKU',
        relatedRecord: 'Enregistrements liés',
        redirect:'Rediriger',
        updatePoRecords: 'Mise à jour dans',
        insertPoRecords: 'Ajout dans',
        updateShipmentRecords: 'Mise à jour dans',
        insertShipmentRecords: 'Ajout dans',
        entreStockRecords: 'Entrée en stock',
        voirLe: 'Voir',
        saleOrderCanceled:'Cette commande client a été annulée',
        saleOrderFinish:'Cette commande client a été finalisée',
        itemsDetail:'Détail article',
        registrationDate:'Date d\'enregistrement',
        shipmentVoucher: 'Bon d\'expédition',
        transferOrder: 'Ordre de transfert',
        transferOrders: 'Ordres de transfert',
        shipmentVoucherAccent: 'Bon expedition',
        entre: 'Entrée',
        numero_shipment: 'Numéro d\'arrivage',
        numero_container_shipment: 'Numéro du container',
        date_depart_shipment:'Date de départ',
        date_arrive_shipment:'Date d\'arrivage',
        status_shipment:'Statut',
        createShipmentVoucher: 'Créer un bon d\'expédition',
        cours_chargement: 'En cours de chargement',
        mer: 'En mer',
        douane: 'En douane',
        dock: 'En cours de réception',
        receptione:'Réceptionné',
        receptioneLines:'lignes réceptionnées',
        includeAllShipmentVoucher: 'Inclure tous les bons d\'expédition',
        exceptReceivedVoucher: 'hors réceptionnés',
        only:'Uniquement',
        customCreateForm: 'Personnaliser le formulaire de création',
        customVoucherDetails: 'Personnaliser les details du bon',
        PurchaseOrderCard: 'Fiche bon de commande',
        shipmentVoucherCard:'Fiche bon d\'expédition',
        shipmentVoucherForm:'Formulaire bon d\'expédition',
        toCustomForm:'Formulaire d\'ordre de transfert',
        shde_carton_name:'Reference carton',
        shde_quota_prevu:'Quantité prévue en carton',
        shde_quota_prevu_accent:'Quantite prevue en carton',
        shde_quota_recu:'Quantité reçue',
        shde_carton_recu:'Carton receptionne',
        shde_carton_recu_accent:'Carton receptione',
        receiptShipmentVoucher:'Réception des bons d\'expédition',
        scanBoxHere:'Scanner un carton ici',
        scanBoxToCheckHere:'Scanner un carton ici',
        scanItems:'Scanner un article ici',
        boxeChoice:'Choix du carton',
        itemChoice:'Choix de l\'article',
        french:'Français',
        english:'Anglais',
        po: 'Bon de commande',
        totalPrmp: 'Total PRMP',
        allBoxes: 'Tous les cartons',
        allSalesOrder:'Toute les commandes client',
        allLines: 'Toutes les lignes',
        boxesStatus: 'Statut des cartons',
        assignAWms: 'Attribuer à un WMS',
        assignAShop: 'Attribuer à un commerce',
        noWmsAttribution: 'Aucune attribution à un WMS',
        noShipmentVoucher: 'Aucun bon d\'expédition',
        editShipmentVoucher: 'Editer le bon d\'expédition',
        editTransferOrder: 'Editer l\'ordre de transfert',
        receivedBy:'Receptionne par',
        of:'sur',
        errorScan:'Le carton {barcode} n\'existe pas',
        successScan:'Le carton {barcode} a été scanné avec succès',
        reinstateScan:'Le carton {barcode} a déjà été scanné, voulez vous le réintégrer aux cartons non scannés ?',
        successReinstate:'Le carton {barcode} a été réintégrer avec succès',
        checkingBoxes:'Réception des cartons',
        checkingItems:'Réception des articles',
        notScannedYet:'Ce carton n\'a pas encore été scanné, voulez-vous être redirigé sur la page de réception des cartons ?',
        quantityCommande:'Quantité commandée',
        createStorageSpace:'Créer des bins en masse',
        binsList:'Liste de Bins',
        createOneBins:'Créer une bins',
        beginningFirstLevel:'Début du premier niveau',
        endFirstLevel:'Fin du premier niveau',
        beginningSecondLevel:'Début du second niveau',
        endSecondLevel:'Fin du second niveau',
        beginningThirdLevel:'Début du troisième niveau',
        endThirdLevel:'Fin du troisième niveau',
        beginningFourthLevel:'Début du quatrième niveau',
        endFourthLevel:'Fin du quatrième niveau',
        beginningFifthLevel:'Début du cinquième niveau',
        endFifthLevel:'Fin du cinquième niveau',
        firstEndBinsGte:'La fin du premier niveau ne peut pas être supérieur à la valeur du début du premier niveau',
        secondEndBinsGte:'La fin du second niveau ne peut pas être supérieur à la valeur du début du second niveau',
        thirdEndBinsGte:'La fin du troisième niveau ne peut pas être supérieur à la valeur du début du troisième niveau',
        fourthEndBinsGte:'La fin du quatrième niveau ne peut pas être supérieur à la valeur du début du quatrième niveau',
        fifthEndBinsGte:'La fin du cinquième niveau ne peut pas être supérieur à la valeur du début du cinquième niveau',
        firstBins:'Premier niveau',
        secondBins:'Second niveau',
        thirdBins:'Troisième niveau',
        fourthBins:'Quatrième niveau',
        fifthBins:'Cinquième niveau',
        zone:'Zone',
        successBinsSkip:'Opération effectuée avec succès, cependant les bins suivantes n\'ont pas été créées car elles existent déjà',
        scanedBox:'Cartons scannés',
        scanBinsHere:'Scanner la bins ici',
        attributeAStorageArea:'Attribuer à un espace de stockage',
        selectAStorageArea:'Selectionner une zone de stockage',
        or:'Ou',
        boxesInfos:'Informations sur les cartons',
        binsNumber:'Numéro de bins',
        storageArea:'Zone de stockage',
        articlesList:'Liste des articles',
        boxNotScaned:'Carton non scanné',
        itemNotExist:'L\'article {sku} n\'existe pas',
        finishScanBefore:'Vous devez finir de scanner cet article avant d\'en scanner un autre',
        selectRowScan:'Sélectionner le bloc à scanner',
        location:'Localisation',
        binsDetails: 'Contenu des bins',
        onOrder:'En commande',
        receivedLines: 'Ligne receptionnee',
        totalHt: 'Total HT',
        differencePrmp:'Ecart PRMP',
        differenceHt:'Ecart HT',
        ecart:'Ecart',
        providedCashFund:'Fond de caisse prévu',
        prevu:'Fond de caisse prévu',
        total:'Total',
        openWith:'Ouvert avec',
        binsMovement:'Mouvement de bins',
        stockDeplacement:'Déplacement de stock',
        simpleMove: 'Mouvement simple',
        avlQuantity:'Quantité disponible',
        qteToMove:'Quantité à déplacer',
        qteThinkMove:'Quantité collectée',
        qteIsMove:'Quantité déposée',
        collectedAt:'Collecté le',
        deposedAt:'Déposé le',
        collected:'Collecté',
        qteToCollect:'Quantité à collecter',
        inLivraison:'Mis en livraison',
        outLivraison:'Sorti de livraison',
        deposed:'Déposé',
        qteReached:'Quantité maximum atteinte',
        qteAvlInsufficient:'La quantité disponible est insuffisante',
        selectStorageDestination:'Sélectionner la zone de stockage',
        cantScanDifferentItem:'Vous ne pouvez pas scanner plusieurs articles en même temps',
        itemNotInThisArea:'Cet article n\'apparaît pas dans cette zone de stockage',
        origin:'Provenance',
        destination:'Destination',
        scanTheItemHere:'Scanner l\'article ici',
        binsContent:'Contenu de la bins',
        emptyBins:'Cette bins est vide',
        totalDevise:'Total en devise',
        referencePo:'Référence',
        seeReceiptVoucher:'Voir le bon de réception',
        voucherNumber:'Numéro du bon',
        receiptDate:'Date de réception',
        boxNumber:'Numéro du carton',
        itemsDetails:'Détails des articles',
        receiptVoucher:'Bon de réception',
        cancelBoxReceipt:'Annulation de la réception d\'un carton',
        cancelItemReceipt:'Annulation de la réception d\'un article',
        someItemsAlreadyScan: 'Action impossible, les articles de ce carton sont déjà intégrée au stock',
        cancelItemsEntry:'Annuler l\'entrée en stock des articles',
        itemsReinstate:'Réintégration articles',
        cancellationStockEntry:'Annulation entrée en stock',
        errorReinstateNotPossible:'Vous ne pouvez pas réintégrer un article non réceptionné',
        selectBlockToReinstate:'Sélectionner le block à réintégrer',
        autoRestock:'Réassort automatique',
        toCorrectionDeposed:'Correction de stock pour différence entre quantité collectée et quantité livrée',
        reorderPoint: 'Niveau de stock minimum RP',
        referenceTo:'Référence',
        referenceToNoAccent:'Reference',
        transfert_leNoAccent: 'A executer le',
        transfert_le: 'A exécuter le',
        origin_section: 'Commerce d\'origine',
        destination_section: 'Commerce de destination',
        rhBarre: 'Code-barre staff',
        preferredStockLevel:'Niveau de stock optimal PSL',
        actualStock:'Stock actuel disponible',
        reassortEnCours:'Réassort à venir',
        recommendedDestination:'Destinations conseillées',
        sameItem:'Même article',
        sameParent:'Même Parent',
        box:'Carton',
        rpInteger:'Le niveau de stock minimum RP de {name} doit être un nombre entier',
        pslInteger:'Le niveau de stock optimal PSL de {name} doit être un nombre entier',
        detailProvider:'Détails du fournisseur',
        providerCard:'Fiche fournisseur',
        allPo:'Tous les bons de commande',
        generateABarCode:'Générer un code-barre',
        generateMasseBarCode:'Générer les codes barres en masse',
        quantityManualModification:'Modification manuelle des quantitées',
        stockReorganization:'Réorganisation du stock',
        removeFromStock:'Retirer du stock',
        addToStock:'Ajouter au stock',
        chooseAnAction:'Choisir une action',
        choisir:'Choisir',
        selectSection:'Sélectionner un commerce',
        stockIntegration:'Intégration au stock',
        stockRemove:'Retrait du stock',
        stockAdjustment:'Ajustement du stock',
        validateAll:'Valider l\'ensemble',
        reinstateAllItems:'Réintégrer tous les articles',
        reinstateOneItem:'Réintégrer un article',
        noBarcodeFounded:'Aucun code-barre n\'a été trouvé',
        rppsl:'Rppsl',
        createATransferOrder:'Créer un ordre de transfert',
        allTransferOrders:'Tous les ordres de transferts',
        exceptFinished:'hors finalisé',
        transferOrderNotStarted:'Ordres de transferts non commencé uniquement',
        notStarded:'Non commencé',
        processOfCollecting:'En cours de collecte',
        processOfDelivery:'En cours de livraison',
        processOfEmptying:'En cours de dépotage',
        transferOrderEdit:'Edition d\'un ordre de transfert',
        errorDeleteTO:'Vous ne pouvez pas supprimer cet ordre de transfert',
        wrongBins:'Vous avez scanné la mauvaise bins',
        wrongItem:'Vous avez scanné le mauvais article',
        allowTransferOrderWithDifferentValue:'Ordre de transfert avec des quantitées différentes',
        zoneWithoutBins:'Zone sans bins',
        uncompletedLines:'Lignes inachevées',
        transferOrderCompleted:'Ordre de transfert finalisé',
        errorToStockMissing:'L\'ordre de transfert n\'a pas pu être finalisé correctement du à un défaut de stock',
        confirmValidateAsIs:'Etes vous sûr de vouloir valider en l\'état ?',
        transferOrdersToCollect:'Ordres de transfert à collecter',
        transferOrdersToReceive:'Ordres de transfert à réceptionner',
        quantityToReceive:'Quantité à réceptionner',
        receiveAllItems:'Réceptionner tous les articles',
        receiveItemsByItems:'Réceptionner article par article',
        validAllArticleToReceive:'Valider tous les articles à la réception d\'un ordre de transfert',
        noTransferOrdersToReceive:'Aucun ordres de transfert à réceptionner',
        confirmReceptionWithDifferentQte:'La quantité réceptionnée et différente de la quantité collectée, cela entraînera un réajustement de stock, continuer ?',
        linesNumber:'Nombre de lignes',
        notCollected:'Non collecté',
        itemsBase:'Base articles',
        stockInShop:'Stock en magasin',
        stockInOtherShop:'Stock dans autre magasin',
        priority:'Priorité',
        restockPriority:'Priorité de réassort',
        rppslNotConfigured:'Le RPPSL de cet article n\'a pas été configuré',
        rppslEdit:'Edition du rppsl',
        nbMaxToInstruction:'Nombre maximum d\'instructions dans un ordre de transfert',
        allItemsAlreadyScaned:'L\'ensemble des articles a été scnanné',
        seller:'Vendeur',
        discount:'Remise',
        price:'Prix',
        priceEdit:'Edition du prix',
        priceModification:'Modification du prix',
        discountPercent:'Remise %',
        discountNet:'Remise net',
        noDiscount:'Non remisé',
        addNote:'Ajouter une note',
        espece:'Espèce',
        check:'Chèque',
        card:'Carte',
        other:'Autre',
        amount:'Montant',
        checkNumber:'Numéro de chèque',
        scanGiftCard:'Scanner la carte cadeau ici',
        payment:'Règlement',
        due:'Restant dû',
        promotion:'Promotions',
        percent:'Remise en %',
        franche:'Remise net',
        xpoury:'Offre X pour Y',
        percentsecond:'Remise en % sur 2nd article',
        xpourfixe:'Offre pour N articles',
        cadeaupourx:'Cadeau pour panier supérieur à',
        noPromoPeriodDefined:'Aucune date de fin',
        createPromo:'Créer une promotion',
        qteItems:'Quantité d\'article',
        percentDesc:'Réduction en pourcentage, exemple : -20%',
        francheDesc:'Réduction net du prix, example : -10$',
        xpouryDesc:'Vendez une quantité X d\'articles pour le prix de Y articles, exemple : 2 pour le prix d\'un',
        percentsecondDesc:'Réduction en pourcentage sur le second article, exemple : -50% sur le deuxième article',
        xpourfixeDesc:'Vendez une quantité X d\'articles pour un prix remisé, exemple : 2 pour 50$',
        cadeaupourxDesc:'Un cadeau offert pour un panier minimum, exemple : un tee-shirt offert à partir de 100$ d\'achat',
        for:'Pour',
        beginPromo:'Début de la promotion',
        endPromo:'Fin de la promotion',
        editPromo:'Modifier une promotion',
        undefined:'Non défini',
        addPromotion:'Ajouter une promotion',
        currentAndUpcomingPromo: 'Promotions en cours et à venir',
        currentPromo:'Promotion en cours',
        upcomingPromo:'Promotion à venir',
        noCurrentPromo:'Aucune promotions en cours',
        noUpcomingPromo:'Aucune promotions à venir',
        addToPromotion:'Ajouter à la promotion',
        additionPromo:'Ajout de la promotion',
        dependingItemPrice:'Dépend du prix des articles',
        giftOffered:'Cadeau offert',
        receiptSaved:'Tickets sauvegardés',
        receiptNumber:'Numéro de ticket',
        saleSaved:'Vente sauvegardée',
        noCurrentSale:'Aucune vente en cours',
        noSalesSaved:'Aucune ventes sauvegardées',
        createGiftCard:'Création d\'une carte cadeau',
        scanGiftCardHere:'Scanner la card cadeau ici',
        deposit:'Montant versé',
        giveChange:'A rendre',
        notValidGiftCard:'Cette carte cadeau n\'est pas valide pour ce commerce',
        noFundAvailable:'Aucun fond de disponible sur cette carte cadeau',
        noEnoughFund:'Il n\'y a pas assez de fonds sur cette carte cadeau',
        moreDetails:'Plus de détails',
        credit:'Crédit',
        debit:'Débit',
        dateExpire:'Date d\'expiration',
        itemReturn:'Retour article',
        dontRemise:'Dont remise',
        scanReceiptHere:'Scanner le ticket ici',
        scanSalesOrderHere:'Scanner le bon de commande ici',
        searchOneReceipt:'Rechercher un ticket',
        receiptSearch:'Recherche de tickets',
        enterRefundMethod:'Renseigner le mode de remboursement',
        ticket:'Ticket',
        vente:'Vente',
        retour:'Retour',
        noItemsToReturn:'Aucun articles à retourner',
        noItems:'Aucun articles',
        paid:'Payé',
        historical:'Historique',
        salesHistorical:'Historique des ventes',
        customerHistorical:'Historique client',
        availableFund:'Fonds disponibles',
        detail:'Détail',
        itemsNumber:'Nombre d\'articles',
        messageEndReceipt:'vous remercie pour votre visite',
        downloadingPdf:'Téléchargement du pdf',
        lastSale:'Dernière vente',
        noPreviousSale:'Aucune vente effectuée précédemment',
        selectConcernedPeriod:'Choisissez la période concernée',
        beginningPeriod:'Début de période',
        endPeriod:'Fin de période',
        editPaymentMethod:'Modification du mode de paiement',
        noPaymentMethodToEdit:'Aucun mode de paiement à modifier',
        checkoutState:'État caisse',
        totalWithGiftCard:'Total des ventes avec cartes cadeaux',
        totalWithoutGiftCard:'Total des ventes sans cartes cadeaux',
        averageReceiptWithGiftCard:'Ticket moyen avec cartes cadeaux',
        averageReceiptWithoutGiftCard:'Ticket moyen sans cartes cadeaux',
        wholeShop:'Tout le commerce',
        filterByEmployee:'Filtre par employé',
        filterByCashRegister:'Filtre par caisse',
        allStaff:'Tout le staff',
        thisCashRegister:'Cette caisse',
        concernedShop:'Commerces concernés',
        typePromo:'Type de promotion',
        statePromo:'État de la promotion',
        messageBienvenue:'Bonjour - Bienvenue',
        acroTel:'Tel',
        finished:'Terminé',
        removeAllArticlesConfirm:'Voulez vous vraiment vider les articles de cette promotion',
        manualDiscountPercent:'Remise manuelle en %',
        manualDiscountNet:'Remise manuelle net',
        caisseIdent:'Identifiant de caisse',
        posName:'Nom de la caisse', 
        actualSumCaisse:'Fond de caisse attendu',
        acutalSession:'Session actuelle',
        cashTotalInCaisse:'Total des espèces en caisse',
        derniersRetraitEspeces: 'Derniers retraits d\'espèces',
        newStaff:'Nouveau staff',
        refresh:'Rafraîchir',
        personalizedFilter:'Filtre personnalisé',
        currentWeek:'Semaine en cours',
        lastWeek:'Semaine dernière',
        nextWeek:'Semaine prochaine',
        currentMonth:'Mois en cours',
        lastMonth:'Mois dernier',
        nextMonth:'Mois prochain',
        paymentDetail:'Détail du paiement',
        receiptDetail:'Détail du ticket',
        depositDetail:'Détail de l\'acompte',
        giftCardCredit:'Crédit carte cadeau',
        printItemTag:'Imprimer une étiquette',
        includeArchives:'Inclure les archives',
        saleOrderDetail:'Détail de la commande',
        openSaleOrder:'Ouvrir la commande client',
        active:'Actif',
        peripheralDevice:'Périphériques',
        newPeripheralDevice:'Nouveau périphérique',
        model:'Modèle',
        checkoutShortcut:'Raccourci caisse',
        checkoutShortcuts:'Raccourcis caisse',
        newShortcut:'Nouveau raccourci caisse',
        ipAdress:'Adresse IP',
        afficheur:'Afficheur',
        tpe:'TPE',
        ingenico:'Ingenico',
        comPort:'Port COM',
        imprimante_ticket:'Imprimante ticket',
        imprimante_etiquette:'Imprimante étiquette',
        epson_tm_h6000V:'Epson TM-H6000V',
        zebra_zd421:'Zebra ZD421',
        printCaisseIdent:'Imprimer l\'identifiant de caisse',
        aures_2_l:'Afficheur Aures',
        notAttributedPeripheral:'Périphériques non attribués',
        noPeripheral:'Aucun périphérique',
        noConfiguredPeripheral:'Aucun périphérique configuré',
        otherSection:'Autre commerce',
        disponibleMini:'Dispo',
        blockedMini:'Bloq',
        aVenir:'A venir',
        changePrinter:'Changer d\'imprimante',
        selectAPrinter:'Selectionner une imprimante',
        qteTag:'Quantité d\'étiquette',
        tagPrint:'Impréssion étiquette',
        limitTagPrint100:'La limite d\'impréssion est de 100 étiquettes',
        mintTagPrint1:'La quantité minimum d\'étiquette à imprimer est de 1',
        bankDeposit:'Dépôt bancaire',
        salesOrder:'Commande client',
        addToOrder:'Ajouter à la commande',
        expectedCollectDate:'Date prévue de collecte',
        collectZone:'Lieu de collecte',
        order:'Commander',
        commandeChangeCollectZone: 'Modification du lieu de collecte',
        commandeOut: 'Sorti de la zone de commerce',
        commandePut: 'Mis en zone de commande',
        commandeCancelledOut: 'Annulation commande - Sorti de la zone de commande',
        commandeCancelledPut: 'Annulation commande - Sorti de la zone de commerce',
        orderHistorical:'Historique commande client',
        searchByCreationDate:'Rechercher par date de création',
        searchByCollectDate:'Rechercher par date prévue de collecte',
        searchASalesOrder:'Rechercher une commande client',
        searchSalesOrder:'Recherche de commandes client',
        article:'Article',
        noCheckoutShortcut:'Aucun raccourci caisse',
        validateNewOrder:'Valider le nouvel ordre',
        transferHere:'Transférer ici',
        transferToOtherShop:'Transférer vers un magasin',
        /***********Button**************/
        btnAccess: 'Go !',
        btnCancelSalesOrder:'Annuler la commande client',
        btnSeeEditDelete : 'Voir / Editer / Supprimer',
        btnValidate: 'Valider',
        btnDeleteUser: 'Supprimer cet utilisateur',
        btnDeleteGrade: 'Supprimer ce grade',
        btnDeleteRh: 'Supprimer ce staff',
        btnYes: "Oui",
        btnNo: "Non",
        btnDelete: "Supprimer",
        btnAddField: "Ajouter un champ",
        btnRemoveRh: "Retirer du commerce",
        btnRemove: "Retirer",
        btnGoClotureCaisse: 'Clôturer la caisse',
        btnCancel: 'Annuler',
        btnBackToWelcome: 'Retour à l\'accueil',
        btnPay: 'Payer',
        btnEffacer: 'Effacer',
        btnClose: 'Fermer',
        btnImport: 'Importer',
        btnAddClientForm: 'Ajouter le formulaire client',
        btnRemoveClientForm: 'Retirer le formulaire client',
        btnArchiveStaff: 'Archiver ce Staff',
        btnActiveStaff: 'Activer ce Staff',
        btnArchiveProvider: 'Archiver ce fournisseur',
        btnDeleteProvider: 'Supprimer ce fournisseur',
        btnUnarchiveProvider: 'Activer ce fournisseur',
        btnSeeProfil: 'Voir le profil',
        btnDeleteAssociate: 'Supprimer l\'association',
        btnSearchAssociateCustomer: 'Lancer une recherche des possibilités d\'association',
        btnUnarchiveBrand: 'Activer cette marque',
        btnArchiveBrand: 'Archiver cette marque',
        btnDeleteBrand: 'Supprimer cette marque',
        btnAssociate : 'Associer',
        btnCreateNewStaffCustomer: 'Générer un nouveau client associé à ce Staff',
        btnUnarchiveZone: 'Activer cette zone',
        btnArchiveZone: 'Archiver cette zone',
        btnDeleteZone: 'Supprimer cette zone',
        btnShowDetails: 'Voir les détails',
        btnInsert: 'Insérer',
        btnUpdate: 'Mettre à jour',
        btnBack: 'Retour',
        btnMove:'Déplacer',
        btnReturn:'Retour',
        btnSeeBinsContent:'Voir le contenu de la bins',
        btnSeeParentProfil:'Profil du parent',
        btnValidateAsIs:'Valider en l\'état',
        btnSeeStock:'Voir le stock',
        btnItemProfil:'Profil article',
        btnModifier:'Modifier',
        btnSave:'Sauvegarder',
        btnSeePdf:'Voir le pdf',
        btnPrint:'Imprimer',
        btnPayPrint:'Payer & Imprimer',
        btnGiftPrint:'Ticket Cadeau',
        btnRemoveAllItems:'Retirer tous les articles',

        /***********Menu**************/
        welcome: 'Accueil',
        usersList: 'Liste Utilisateurs',
        usersCreate: 'Créer un Utilisateur',
        rhCreate: 'Créer un Staff',
        administrationGenerale: 'Administration Générale',
        administrationModePaiement: 'Modes de paiement',
        administrationObjectifPos : 'Objectifs',
        administrationCustomForm: 'Formulaires Personalisés',
        administrationClientForm: 'Client',
        administrationFournisseurForm: 'Fournisseur',
        administrationMarqueForm: 'Marque',
        administrationArticleForm: 'Article',
        administrationStaff: 'Staff',
        administrationStaffList: 'Liste Staff',
        allCreate: 'Créer',
        searchStock: 'Recherche stock',
        cashRegister: 'Caisse',
        addItem: 'Ajouter un article',
        importItem: 'Importer des articles',
        importItemPromotion: 'Importer des articles à la promotion {name}',
        facturation: 'Facturation',
        formProvider: 'Formulaire fournisseur',
        itemFamily: 'Familles d\'articles',
        brand: 'Marques',
        client: 'Clients',
        customizedInterface: 'Interface personnalisée',
        createRead:'Créer et consulter',
        import: 'Importer',
        allPromotions:'Toutes les promotions',
        printingInProgress:'Impression en cours',
        movePeripheralsShop: 'Etes-vous sûr de vouloir déplacer {deviceName} vers {shopName} ?',
        session:'Session',
        /***********Table***********/
        tableSearch: 'Rechercher',
        tableName: 'Nom',
        tableFirst: 'Début',
        tableBack: 'Précédent',
        tableNext: 'Suivant',
        tableLast: 'Dernière',
        tableFistTitle: 'Première Page',
        tableBackTitle: 'Page Précédente',
        tableNextTitle: 'Page Suivante',
        tableLastTitle: 'Dernière Page',
        tableNoData: 'Aucune donnée',
        tableNoModePaiement: 'Aucun mode de paiement ajouté',
        tableNoCashOutReason: 'Aucun justificatif de retrait d\'espèces en caisse ajouté',
        tableSex: 'Sexe',
        tableFirstName: 'Prénom',
        tablePhone: 'Téléphone',
        tableRemove: 'Retirer',
        tableBarCode: 'Code-barre',
        tableOtherPhone: 'Autre téléphone',
        tableProviderName: 'Nom du fournisseur',
        tableContactName: 'Nom du contact',
        tableBrandName: 'Nom de la marque',
        tableContraintes: 'Contraintes',
        /*********** Label Form ***********/
        labelSex: 'Sexe',
        labelName: 'Nom',
        labelCsvSeparator: 'Séparateur CSV',
        labelCsvEncode: 'Encodage CSV',
        labelFirstName: 'Prénom',
        labelPassword: 'Mot de passe',
        labelPasswordConfirmation: 'Confirmer mot de passe',
        labelPhone: 'Téléphone',
        labelMaxusemoisproforma: 'Durée de validité d\'une proforma (en mois)',
        labelRaisonmodifprix: 'Raisons pour lesquelles un vendeur peut justifier de la modification manuelle du prix d\'un article',
        labelDateFormat: 'Format de date',
        labelAdress: 'Adresse',
        labelCodePostal: 'Code postal',
        labelVille: 'Ville',
        labelPays: 'Pays',
        labelCodeBank: 'Code banque',
        labelCodeGuichet: 'Code Guichet',
        labelNumeroDeCompte: 'Numéro de compte',
        labelCleRib: 'Clé RIB',
        labelBank: 'Banque',
        labelHoraires: 'Horaires',
        labelProviderName: 'Nom du fournisseur',
        labelContactName: 'Nom du contact',
        labelArchived: 'Archivé',
        labelBankName: 'Nom de la banque',
        labelSelectConfigue: 'Choisissez votre configuration',
        labelReason:'Raison',
        /********** Success Form Response ************/
        sucessUserCreate: 'Utilisateur créé avec succès !',
        /**********Payment methode ********************/
        especeSA: 'Especes',
        espece: 'Especes',
        cheque: 'Cheque',
        check: 'Cheque',
        cb: 'Carte Bleue/Visa',
        amex: 'American Express',
        mastercard: 'Mastercard', 
        jade: 'Carte Jade',
        jcb: 'Carte Jcb',
        giftcard: 'Carte cadeau',
        virement: 'Virement bancaire',
        emv:'EMV',
        annulCommande: 'Commande client annulée'
      }
    }
  }

  export default translation