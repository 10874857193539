import { lazy, useContext } from "react";

const Erp = lazy(() => import("./Erp"));
const Articles = lazy(() => import("./Articles/Articles/Welcome"));
const Fournisseurs = lazy(()=> import("./Articles/Fournisseurs/Fournisseurs"));
const FournisseursDetailsTab = lazy(()=> import("./Articles/Fournisseurs/FournisseursDetailsTab"));
const FamilleArticle = lazy(() => import("./Articles/FamilleArticle/FamilleArticle"));
const Marques = lazy(() => import("./Articles/Marques/Marques"));
const ArticleDetail = lazy(() => import("./Articles/ArticleDetail/ArticleDetail"));
const Promotion = lazy(() => import("./Articles/Promotion/Promotion"));
const PromotionDetail = lazy(() => import("./Articles/Promotion/PromotionDetail"));
const BonCommande = lazy(() => import("./BonCommande/BonCommande"));
const ImportPo = lazy(() => import("./BonCommande/ImportPo"));
const BonCommandeDetail = lazy(() => import("./BonCommande/BonCommandeDetail"));
const ImportShipment = lazy(() => import("./Shipment/ImportShipment"));
const Shipment = lazy(() => import("./Shipment/Shipment"));
const ShipmentDetail = lazy(() => import("./Shipment/ShipmentDetail"));
const ReceiptVoucher = lazy(() => import("./Shipment/ReceiptVoucher"));
const AutoReassortConsult = lazy(() => import("./Reassort/AutoReassortConsult"));
const AutoReassortParent = lazy(() => import("./Reassort/AutoReassortParent"));
const ReassortImport = lazy(() => import("./Reassort/ReassortImport"));
const ReassortProposal = lazy(() => import("./Reassort/ReassortProposal"));
const TransferOrder = lazy(() => import("./TransferOrder/TransferOrder"));
const ImportTo = lazy(() => import("./TransferOrder/ImportTo"));
const ToDetail = lazy(() => import("./TransferOrder/ToDetail"));
const Session = lazy(() => import("./Session/Session"));
const TicketHistorique = lazy(() => import("./TicketHistorique/TicketHistorique"));
const DetailTicketAdmin = lazy(() => import("@gull/components/DetailTicketAdmin/DetailTicketAdmin"));
const CommandeClientHistorique = lazy(() => import("./CommandeClient/CommandeClientHistorique"));
const DetailCommandeAdmin = lazy(() => import("@gull/components/DetailCommandeAdmin/DetailCommandeAdmin"));

const erpRoutes = [
    {
      path: "/erp/welcome",
      component: Articles
    },
    {
        path: "/erp/item",
        component: Articles
    },
    {
        path: "/erp/itemfamily",
        component: FamilleArticle
    },
    {
        exact:true,
        path: "/erp/brand",
        component: Marques
    },
    {
        exact:true,
        path: "/erp/provider",
        component: Fournisseurs
    },
    {
        path: "/erp/provider/details/:id",
        component: FournisseursDetailsTab
    },
    {
        path: "/erp/article/edit/:id",
        component: ArticleDetail
    },
    {
        exact:true,
        path: "/erp/purchase-order",
        component: BonCommande
    },
    {
        path: "/erp/importpo",
        component: ImportPo
    },
    {
        path: "/erp/purchase-order/details/:id",
        component: BonCommandeDetail
    },
    {
        exact:true,
        path: "/erp/shipment",
        component: Shipment
    },
    {
        path: "/erp/import-shipment",
        component: ImportShipment
    },
    {
        path: "/erp/shipment/details/:id",
        component: ShipmentDetail
    },
    {
        path: "/erp/shipment/receipt-voucher/:shipmentid/:cartonnumber",
        component: ReceiptVoucher
    },
    {
        exact:true,
        path: "/erp/automatic-restock",
        component: AutoReassortConsult
    },
    {
        exact:true,
        path: "/erp/automatic-restock/parent-item/:id",
        component: AutoReassortParent
    },
    {
        path: "/erp/automatic-restock/import",
        component: ReassortImport
    },
    {
        path: "/erp/automatic-restock/proposal/:id?/:cur_page?",
        component: ReassortProposal
    },
    {
        exact:true,
        path: "/erp/transfer-order",
        component: TransferOrder
    },
    {
        exact:true,
        path: "/erp/importto",
        component: ImportTo
    },
    {
        path: "/erp/transfer-order/details/:id",
        component: ToDetail
    },
    {
        exact:true,
        path: "/erp/promotion",
        component: Promotion
    },
    {
        path: "/erp/promotion/detail/:id",
        component: PromotionDetail
    },
    {
        path: "/erp/caisse/session",
        component: Session
    },
    {
        exact:true,
        path: "/erp/caisse/ticket",
        component: TicketHistorique
    },
    {
        exact:true,
        path: "/erp/caisse/ticket/:ticketId",
        component: DetailTicketAdmin
    },
    {
        exact:true,
        path: "/erp/caisse/sales-order",
        component: CommandeClientHistorique
    },
    {
        exact:true,
        path: "/erp/caisse/sales-order/:saleOrderId",
        component: DetailCommandeAdmin
    },
  ];
  
  export default erpRoutes; 