import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { Alert,Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheckCircle} from '@fortawesome/free-solid-svg-icons';

const ToFinished = (props) =>{
    const t = useTranslate();
    const history = useHistory();
    
    return(<>
        <Alert variant="success">
            <Alert.Heading className='text-center'>{t('transferOrderCompleted')}</Alert.Heading>
            <div className='d-flex justify-content-center mt-4' style={{fontSize:'3em'}}>
                <FontAwesomeIcon icon={faCheckCircle} />
            </div>
            
            <hr />
            <div className="d-flex justify-content-end">
                <Button onClick={() => history.push("/wms/"+props.wmsid+"/"+props.path)} variant="outline-success">
                    {t('btnBack')}
                </Button>
            </div>
        </Alert>
    </>
    )

}

export default ToFinished;