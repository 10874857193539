import React,{useState, useRef} from "react";
import { InpDataList } from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import SweetAlert from "sweetalert2-react";

const useSearchClients = () => {
    const [loadingSearchClient,setLoadingSearchClient]=useState(false)
    const [generalErrorSearchClient,setGeneralErrorSearchClient]=useState(false)
    const [listSearchClient,setListSearchClient]=useState([])
    const [datas,setDatas]=useState([])
    const searchInputClient = useRef('')

    const loadClients = async (toSearch) => {
        setLoadingSearchClient(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/name/"+toSearch
        ).then((response) => {
            setListSearchClient(response.data.datas)
            let initial = []
            response.data.datas.forEach((value)=>
                initial.push({
                    id: value.id,
                    name: value.name+' '+value.firstname+' - '+value.email+' - '+value.tel
                })
            )
            setListSearchClient(initial)
            let ajour = initial.filter((value)=>value.name.toLowerCase().includes(searchInputClient.current.value.toLowerCase()))
            setDatas(ajour)
            setLoadingSearchClient(false)
        }).catch((error) => {
            setLoadingSearchClient(false)
            setGeneralErrorSearchClient(true)
        })
    }

    return [loadingSearchClient,loadClients,generalErrorSearchClient,setGeneralErrorSearchClient,listSearchClient,datas,setDatas,searchInputClient]
}

const InputSearchClient = (props) =>{
    const t =useTranslate()
    const [loadingSearchClient,loadClients,generalErrorSearchClient,setGeneralErrorSearchClient,listSearchClient,datas,setDatas,searchInputClient]=useSearchClients()

    const [clientName,setClientName] = useState('')
    const [noClientToEdit,setNoClientToEdit] = useState(false)

    const dataListDropClient = useRef('')

    const toggleAlertSearchClient = () => {
        setGeneralErrorSearchClient(false)
    }

    const toggleNoClientToEdit = () => {
        setNoClientToEdit(false)
    }

    const handleSearch = (e,declenchement) => {
        setClientName(e.target.value)
        if(e.target.value.length>=declenchement){
            dataListDropClient.current.classList.remove("d-none");
        }
        else{
            dataListDropClient.current.classList.add("d-none");
        }
        if(e.target.value.length==declenchement){
            loadClients(e.target.value)
        }
        else{
            let ajour = listSearchClient.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }
    }

    const handleCLick = (id,valeur) => {
        props.setClient(id)
        setClientName(valeur)
        dataListDropClient.current.classList.add("d-none");
    }

    const handleBlur = () => {
        if(clientName==''){
            props.setClient(false)
        }
        dataListDropClient.current.classList.add("d-none");
    }

    return(
        <>
            <div className="position-relative">
                <InpDataList
                    placeholder={t('customerPlacehlder')}
                    value={clientName}
                    onClick={handleCLick}
                    onChange={handleSearch}
                    datas={datas}
                    declenchement={4}
                    listRef={dataListDropClient}
                    inputRef={searchInputClient}
                    onBlur={handleBlur}
                    inputName="searchClient"
                />
                {(loadingSearchClient) ?
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm topBarLeftPosition2"></div></span>
                :
                    false
                }
            </div>
            {(generalErrorSearchClient) &&
                <SweetAlert show="true" title={t('unknowError')} type="error" onConfirm={() => toggleAlertSearchClient()}/>
            }
            {(noClientToEdit) &&
                    <SweetAlert show="true" title={t('noClientToEdit')} type="error" onConfirm={() => toggleNoClientToEdit()}/>
            }
        </>
    )
}

export default InputSearchClient;