import React,{useState,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { Modal, Button} from "react-bootstrap";
import axios from 'axios'
import swal from "sweetalert2";

const NoteModal = (props) =>{
    const t =useTranslate()
    const [validButton,setInvalidInput]=useState(false)
    const [noteValue,setNoteValue]=useState('')
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    useEffect(function () {
        if(props.item.note!=null){
            setNoteValue(props.item.note)
        }
        
    }, [props.item])

    const handleChange = (e) =>{
        setNoteValue(e.target.value)
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();

        setInvalidInput(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], {
            id:props.cookies.current_ticket,
            detail_id:props.lineId,
            note:noteValue
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                props.closeModal('note')
                setInvalidInput(false)
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        setInvalidInput(false)
    }

    return(
        <Modal size="md" show={props.showNote} onHide={() => props.closeModal('note')}>
            <Modal.Header closeButton>
                <Modal.Title>{t('addNote')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={(e)=>handleSubmit(e)}>
                    <div className="row">
                        <div className="col">
                            <textarea className="form-control" value={noteValue} rows="8" onChange={(e)=>handleChange(e)}></textarea>
                        </div>
                    </div>
                    <button disabled={validButton} className="btn btn-primary mt-3">
                        {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default NoteModal;