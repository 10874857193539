import { lazy, useContext } from "react";

const Users = lazy(() => import("./Users"));
const UserEdit = lazy(() => import("./UserEdit"));
const UserCreate = lazy(() => import("./UserCreate"));

const usersRoutes = [
    {
      path: "/users/welcome",
      component: Users
    },
    {
      path: "/users/edit/:id",
      component: UserEdit
    },
    {
      path: "/users/create/",
      component: UserCreate
    },
  ];
  
  export default usersRoutes;