import { lazy, useContext } from "react";

const Welcome = lazy(() => import("./Welcome"));

const welcomeRoutes = [
    {
      path: "/welcome/welcome",
      component: Welcome
    }
  ];
  
  export default welcomeRoutes;