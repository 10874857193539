import React, { useContext } from "react";
import Chart from "react-apexcharts";
import { useTranslate } from 'react-redux-multilingual'
import { IsAuthContext } from '../../../app/App';

const ObjectifSectionChart = (props) => {
  const t = useTranslate()
  const { authParam } = useContext(IsAuthContext);
  let obj;

  switch(props.isFor){
    case 'ca':
      obj={
        real: props.real.ca,
        objectif: props.data.ca,
        unit: 'XPF'
      }
      break;
    case 'pm':
        obj={
          real: props.real.pm,
          objectif: props.data.pm,
          unit: 'XPF'
        }
        break;
    case 'iv':
        obj={
          real: props.real.iv,
          objectif: props.data.iv,
          unit: t('items')
        }
        break;
    case 'tf':
        obj={
          real: (props.real.nbr_ticket!=0 && props.data.entre!=0) ? Math.round(props.real.nbr_ticket*100/Math.round(props.data.entre/2)) : 0,
          objectif: props.data.tf,
          unit: "%"
        }
        break;
  }


  const options = {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          export: {
            csv: {
              columnDelimiter: authParam.company.csv_separator,
            }
          }
        }
      },
      colors: (obj.real>obj.objectif) ? ['#49d761', '#0060fe'] : ['#fe0000', '#0060fe'],
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: "rounded",
          columnWidth: "75%"
        }
      },
      dataLabels: {
        enabled: false
      },
    
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
        lineCap: "round"
      },
      series: [
        {
          name: t('realise'),
          data: [obj.real]
        },
        {
          name: t('objectifs'),
          data: [obj.objectif]
        }
      ],
      xaxis: {
        categories: [new Intl.NumberFormat('fr-FR').format(obj.real)+' '+obj.unit, new Intl.NumberFormat('fr-FR').format(obj.objectif)+' '+obj.unit]
      },
      yaxis: {
        title: {
          text: obj.unit
        }
      },
      fill: {
        opacity: 0.5,
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return new Intl.NumberFormat('fr-FR').format(val)+' '+obj.unit
          }
        }
      }
  };

  return (
      <Chart
        options={options}
        series={options.series}
        type={options.chart.type}
      />
  );
}

export default ObjectifSectionChart;
