export const bbgShops = [
    {
        id:7,
        name: "Boardriders Kenu-in",
        commerce_zone:"Centre Commercial Kenu-in",
        adresse: "4 Rte du Centre Commercial",
        code_postal:"98835 Dumbea",
        tel:"44 26 00",
        horaires:"Lundi au Samedi 9h-18h30, Dimanche 8h30-12h30",
        site:"www.boardriders.nc",
        shop_entity:"BOARDIES",
        ridet:"1 192 103.001",
        code:"BBKI"
    },
    {
        id:8,
        name: "Boardriders Anse-Vata",
        commerce_zone:null,
        adresse: "153 route de l'Anse Vata",
        code_postal:"98800 Noumea",
        tel:"25 41 11",
        horaires:"Lundi au Dimanche 9h-19h",
        site:"www.boardriders.nc",
        shop_entity:"BOARDIES",
        ridet:"1 192 103.002",
        code:"BBAV"
    },
    {
        id:9,
        name: "Campus Dumbea Mall",
        commerce_zone:"Dumbea Mall",
        adresse: "1 Bd Du Rail Caledonien",
        code_postal:"98835 Dumbea",
        tel:"41 61 61",
        horaires:"Lundi 10h-19h, Mardi au Samedi 9h-19h, Dimanche 9h-13h",
        site:"www.campus.nc",
        shop_entity:"CAMPUS",
        ridet:"1 376 425.001",
        code:"CPDS"
    },
    {
        id:10,
        name: "Campus Anse-Vata",
        commerce_zone:null,
        adresse: "149 route de l'Anse Vata",
        code_postal:"98800 Noumea",
        tel:"25 00 99",
        horaires:"Lundi au Dimanche 9h-19h",
        site:"www.campus.nc",
        shop_entity:"CAMPUS",
        ridet:"1  376 425.001",
        code:"CPAV"
    },
    {
        id:11,
        name: "Nike Dumbea Mall",
        commerce_zone:"Dumbea Mall",
        adresse: "1 Bd Du Rail Caledonien",
        code_postal:"98835 Dumbea",
        tel:"45 30 35",
        horaires:"Lundi 10h-19h, Mardi au Samedi 9h-19h, Dimanche 9h-13h",
        site:"www.nikestore.campus.nc",
        shop_entity:"CAMPUS",
        ridet:"1 376 425.004",
        code:"NKDB"
    },
    {
        id:12,
        name: "Pro Bike",
        commerce_zone:null,
        adresse: "154 route de l'Anse Vata",
        code_postal:"98800 Noumea",
        tel:"28 54 12",
        horaires:"Lundi au Samedi 9h-19h, Dimanche 9h-18h",
        site:null,
        shop_entity:"QSAV",
        ridet:"1 365 196.001",
        code:"SPEZ"
    },
    {
        id:1,
        name: "Pro Bike",
        commerce_zone:null,
        adresse: "154 route de l'Anse Vata",
        code_postal:"98800 Noumea",
        tel:"28 54 12",
        horaires:"Lundi au Samedi 9h-19h, Dimanche 9h-18h",
        site:null,
        shop_entity:"QSAV",
        ridet:"1 365 196.001",
        code:"PBAV"
    },
    
];