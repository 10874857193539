import React,{useState,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { DetailItems, FormatedPrice,ModalChangePaymentMethod} from "@gull"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEdit,faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import { IsAuthContext } from '../../../../../../app/App';
import Moment from 'react-moment';
import { modePaiementDefaultAll } from 'app/modePaiementDefaultAll';
import { Accordion, useAccordionButton,Card} from "react-bootstrap";

const SaleOrderDataForm = (props) =>{
    const t =useTranslate()
    const { authParam } = useContext(IsAuthContext)

    let initAccordionStatus = {
        1: false,
    }
    const [accordionStatus,setAccordionStatus] = useState(initAccordionStatus);
    
    let actualShop = authParam.sections.find(shop=>shop.id == props.posid)

    const CustomToggleAccordion = ({ children, eventKey, accordionStatus, setAccordionStatus }) => {
        const decoratedOnClick = useAccordionButton(eventKey, () =>{
            let modifAccordionStatus = initAccordionStatus;
            modifAccordionStatus[eventKey]=!accordionStatus[eventKey];
            setAccordionStatus(modifAccordionStatus);
        });

        let hisClass = (accordionStatus[eventKey]) ? "btn btn-sm btn-info mb-3" : "btn btn-sm btn-outline-info mb-3"
      
        return (
          <button
            type="button"
            className={hisClass}
            style={{marginRight:'10px'}}
            onClick={decoratedOnClick}
          >
            {children}
          </button>
        );
    }

    let statusCommande = (props.dataSaleOrder.cancel!=0) ? t('canceled') : (props.dataSaleOrder.ticket.status!=0) ? t('completed') : t('enCours');

    return(
        (Object.keys(props.dataSaleOrder).length!=0) ?
            <div className="row">
                <div className="row mb-3 mx-auto position-relative">
                    <div className="form-group col-6 mb-2">
                        <label>{t('tableBarCode')}</label>
                        <div>{props.dataSaleOrder.bar}</div>
                    </div>

                    <div className="form-group col-6 mb-2">
                        <label>{t('status')}</label>
                        <div>{statusCommande}</div>
                    </div>

                    <div className="form-group col-6 mb-2">
                        <label>{t('customer')}</label>
                        <div>{props.dataSaleOrder.ticket.client.name+' '+props.dataSaleOrder.ticket.client.firstname}</div>
                    </div>

                    <div className="form-group col-6 mb-2">
                        <label>{t('transferOrderAssociation')}</label>
                        {(props.dataSaleOrder.to && props.dataSaleOrder.to.length!=0) ?
                            <ul className="list-group">
                                {props.dataSaleOrder.to.map(value=>(
                                    <li key={value.id} className='list-group lien-to' onClick={()=>window.open('/pos/'+props.posid+'/transfer-order-detail/'+value.id)}>{value.reference}</li>
                                ))}
                            </ul>
                        :
                            <div>{t('aucune')}</div>
                        }
                    </div>
                    
                    <div className="form-group col-6 mb-2">
                        <label>{t('collectZone')}</label>
                        {(props.edit && props.dataSaleOrder.ticket.section_id==props.posid) ?
                            <div className="position-relative">
                                <select className="form-control" defaultValue={(props.dataSaleOrder.section_collect!=null) && props.dataSaleOrder.section_collect.id} type="text" onChange={(e)=>props.handleEdit('shop',e)}>
                                    {(authParam.sections.length!=0) && authParam.sections.map((value)=>
                                        <option key={value.id} value={value.id}>{value.name}</option>
                                    )}                                    
                                </select>
                                {(props.load.shop) ? <span className="loaderSectionPosition2"><div className="spinner-border spinner-border-sm"></div></span> : false}
                            </div>
                        :
                            <div>{props.dataSaleOrder.section_collect.name}</div>
                        }
                    </div>
                    {(props.edit) ?
                        <div className="form-group col-6 mb-2">
                            <label>{t('expectedCollectDate')}</label>
                            <input className='form-control mb-1' type="date" value={props.valueInput.date} onChange={(e)=>props.handleChange(e,'date')}/>
                            <input className='form-control' type="time" value={props.valueInput.time} onChange={(e)=>props.handleChange(e,'time')}/>
                            <button className="btn btn-sm btn-primary mt-2" onClick={()=>props.handleEdit('editDate',false)}>
                                {props.load.editDate ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('edit')}
                            </button>
                        </div>
                    :
                        (props.dataSaleOrder.prevu != null) ?
                            <div className="form-group col-6 mb-2">
                                <label>{t('expectedCollectDate')}</label>
                                <div><Moment format="DD/MM/YYYY HH:mm">{props.dataSaleOrder.prevu}</Moment></div>
                            </div>
                        :
                            false
                    }
                    {(props.edit) ?
                        <div className="form-group col-6 mb-2">
                            <label>Note :</label>
                            <textarea cols="30" rows="5" className="form-control" value={props.valueInput.note} onChange={(e)=>props.handleChange(e,'note')}></textarea>
                            <button className="btn btn-sm btn-primary mt-2" onClick={()=>props.handleEdit('editNote',false)}>
                                {props.load.editNote ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('edit')}
                            </button>
                        </div>
                    :
                        (props.dataSaleOrder.note != null) ?
                            <div className="form-group col-6 mb-2">
                                <label>Note</label>
                                <div>{props.dataSaleOrder.note}</div>
                            </div>
                        :
                            false
                    }

                    {(props.edit) ?
                        <div className="form-group col-6 mb-2">
                            <label>{t('depositDetail')}</label>
                            <ModalChangePaymentMethod 
                                barTicket={props.dataSaleOrder.bar}
                                currentShop={actualShop}
                                devise={props.devise}
                                digit={props.digit}
                                posid={props.posid}
                                dataTicket={props.dataSaleOrder}
                                setDataSaleOrder={props.setDataSaleOrder}
                                from='commande'
                                module='pos'
                            />
                        </div>
                    :
                        (props.dataSaleOrder.ticket.paiement_records.length!=0) ?
                            <div className="form-group col-6 mb-2">
                                <label>{t('depositDetail')}</label>
                                {props.dataSaleOrder.ticket.paiement_records.map((value)=>{
                                    let modePay = modePaiementDefaultAll.includes(value.mode) ? t(value.mode) : value.mode
                                    return(
                                        <div key={value.id} className="mb-1">{modePay+': '}<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={value.montant} /></div>
                                    )
                                })}
                            </div>
                        :
                            false
                    }

                    {(props.edit)?
                        <form onSubmit={props.handleSubmit(props.onSubmit)}>
                            <Card className="row mb-3">
                                <h5 className="fw-bold text-center mt-2">{t('customFields')}</h5>
                                {(props.loading) ?
                                    <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
                                :
                                    (props.customfields) && props.customfields.map((item,index)=>(
                                        <div className="form-group col-6 mt-3" key={index}>
                                            <label>{item.name}{(item.required) ? '*' : false}</label>
                                            {(item.free) ?
                                                <>
                                                    <input autoComplete="off" className="form-control" type="text" {...props.register(item.name,{required:item.required})}/>
                                                    {props.errors[item.name]?.type === 'required' && (
                                                        <div className="text-danger mt-1 ml-2">
                                                            {t('youMustIndicateField')+' "'+item.name+'"'}
                                                        </div>
                                                    )}
                                                </>
                                                :
                                                <>
                                                    <select className="form-control" {...props.register(item.name,{required:item.required})} >
                                                        <option value="">-- Select --</option>
                                                        {item.list.map((value,index)=>
                                                            <option key={index} value={value}>{value}</option>
                                                        )}
                                                    </select>
                                                    {props.errors[item.name]?.type === 'required' && (
                                                        <div className="text-danger mt-1 ml-2">
                                                            {t('youMustIndicateField')+' "'+item.name+'"'}
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        </div>
                                ))}
                                <div className="col">
                                    <button className="btn btn-sm btn-primary mt-2 mb-2">
                                        {props.load.editCustomFields ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('edit')}
                                    </button>
                                </div>
                            </Card>
                        </form>
                    :
                        (props.dataSaleOrder.custom_fields!=null) ?
                            Object.entries(props.dataSaleOrder.custom_fields).map(([key,value],ind)=>{
                                if(value!=null){
                                    return(
                                        <div key={ind} className="form-group col-6 mb-2">
                                            <label>{key}</label>
                                            <div>{value}</div>
                                        </div>
                                    )
                                }
                            })
                        :
                            false
                    }
                    <Accordion>
                        <CustomToggleAccordion accordionStatus={accordionStatus} setAccordionStatus={setAccordionStatus} eventKey="1">{t('itemsDetail')}</CustomToggleAccordion>
                        <Accordion.Collapse eventKey="1">
                            <div>
                                {props.dataSaleOrder.ticket.ticket_detail.map((value,ind)=>{
                                    let style = (ind%2 == 0) ? 'stripeBg stripePadding2 fw-bold' : 'stripePadding2 fw-bold';
                                    if(value.block_quantity!=0){
                                        return(
                                            <DetailItems 
                                                key={ind}
                                                style={style}
                                                value={value}
                                                digit={props.digit}
                                                devise={props.devise}
                                                posid={props.posid}
                                                from='salesOrder'
                                            />
                                        )
                                    }
                                })}
                            </div>
                        </Accordion.Collapse>
                    </Accordion>
                    <div className="btnEditSaleOrder">
                        {(!props.edit) ?
                            <button className=" btn btn-sm btn-primary" onClick={()=>props.handleClick('edit')}>
                                <FontAwesomeIcon icon={faEdit} className='fs-5' />
                            </button>
                        :
                            <button className=" btn btn-sm btn-danger" onClick={()=>props.setEdit(false)}>
                                <FontAwesomeIcon icon={faTimesCircle} className='fs-5'/>
                            </button>
                        }
                    </div>
                    
                </div>
                {(props.apiError) ? <div className="alert alert-danger mt-3 mb-3" dangerouslySetInnerHTML={{ __html: props.apiError }}></div> : false}
                {(props.success) ? <div className="alert alert-success mt-3 mb-3" dangerouslySetInnerHTML={{ __html: props.success }}></div> : false}
                <div>
                    {(props.showBtnRefund) ?
                        <>
                            <h5 className="mb-4 text-center fw-bold">{t('chooseRefundMethod')}</h5>
                            <div className="d-flex justify-content-between">
                                <button className="btn btn-info" onClick={()=>props.handleClick('espece')}>{(props.load.espece) ? <div className="spinner-border spinner-border-sm"></div> : t('espece')}</button>
                                <button className="btn btn-info" onClick={()=>props.handleClick('gift_card')}>{(props.load.gift_card) ? <div className="spinner-border spinner-border-sm"></div> : t('giftcard')}</button>
                                <button className="btn btn-info" onClick={()=>props.handleClick('autre')}>{(props.load.autre) ? <div className="spinner-border spinner-border-sm"></div> : t('other')}</button>
                            </div>
                        </>
                    :
                        <div className="d-flex justify-content-between">
                            <button className="btn btn-primary" onClick={()=>props.handleClick('print')}>{(props.load.print) ? <div className="spinner-border spinner-border-sm"></div> : t('btnPrint')}</button>
                            <button className="btn btn-primary" onClick={()=>props.handleClick('pdf')}>{(props.load.pdf) ? <div className="spinner-border spinner-border-sm"></div> : t('download')}&nbsp;<FontAwesomeIcon icon={faFilePdf} className='fs-5' /></button>
                            {(props.dataSaleOrder.cancel==0 && props.dataSaleOrder.ticket.status==0) ?
                                <>
                                    <button className="btn btn-warning" onClick={()=>props.handleClick('to_Transform')}>{(props.load.alert) ? <div className="spinner-border spinner-border-sm"></div> : t('createATransferOrder')}</button>
                                    {(props.from!='formSaleOrder') ?
                                        <>
                                            {(props.dataSaleOrder.section_collect_id == props.posid) ? 
                                                <button className="btn btn-info" onClick={()=>props.handleClick('finalize')}>{(props.load.finalize) ? <div className="spinner-border spinner-border-sm"></div> : t('finalize')}</button>
                                            :
                                                false
                                            }
                                            <button className="btn btn-danger" onClick={()=>props.handleClick('cancel')}>{(props.load.cancel) ? <div className="spinner-border spinner-border-sm"></div> : t('btnCancelSalesOrder')}</button>
                                        </>
                                    :
                                        false}
                                    </>
                            :
                                false
                            }
                        </div>
                    }
                </div>
                <div className="mb-3">
                    {(props.optionRefund) ?
                        <form onSubmit={(e)=>props.cancelSaleOrder(e,props.optionRefund,props.optionRefund)}>
                            {(props.optionRefund == 'gift_card') ?
                                <input ref={props.refundGiftcard} autoComplete='off' type="text" className="form-control mt-5" onChange={(e)=>props.handleChange(e,'gift_card')} placeholder={t('scanGiftCard')}/>
                            :
                                <>
                                    <textarea ref={props.refundOther} autoComplete='off' className="form-control mt-5" cols="30" rows="5" onChange={(e)=>props.handleChange(e,'autre')} placeholder={t('enterRefundMethod')}></textarea>
                                    <button className="btn btn-primary mt-3">{t('btnValidate')}</button>
                                </>
                            }
                        </form>
                    :
                        false
                    }
                </div>
            </div>
        :
            false
        
    )
}

export default SaleOrderDataForm;