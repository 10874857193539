import React,{useState,useEffect} from "react";
import { FormatedPrice } from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faCommentAlt} from '@fortawesome/free-solid-svg-icons'
import NoteModal from "./Component/NoteModal"
import SelectSeller from "../SelectSeller"
import axios from 'axios'
import swal from "sweetalert2";

const LineItemGiftCard = (props) =>{
    const t =useTranslate()
    const [showNote,setShowNote]=useState(false)
    const [loading,setLoading]=useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    const showModal = () =>{
        setShowNote(true)
    }

    const removeArticle = async () =>{
        setLoading(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            delete_detail_id: props.item.id,
            id:props.cookies.current_ticket
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                setLoading(false)
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        props.setRelanceFocus(Date.now())
    }

    const closeModal = (name) =>{
        if(name == 'note'){
            setShowNote(false)
        }
        props.setRelanceFocus(Date.now())
    }

    return(
        <>
            <NoteModal 
                showNote={showNote}
                closeModal={closeModal}
                posid={props.posid}
                cookies={props.cookies}
                currentTicket={props.currentTicket}
                setCurrentTicket={props.setCurrentTicket}
                lineId={props.lineId}
                item={props.item}
            />
            <div className={props.style}>
                <div className="row">
                    <strong className="fsArticlePos">{t('giftcard')}</strong> 
                </div>
                <div className="row marginLeftArticle">
                    <div className="col-5 m-0 p-0">
                        <div className="row ">
                            <strong className="fsArticlePos">{props.item.details.reference}</strong>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="row">
                            <div className="col-1 m-0 p-0 zIndexBtnNotePos">
                                <button className="btn btn-primary btn-sm sizeBtnPos" onClick={()=>showModal()}><FontAwesomeIcon icon={faCommentAlt} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 pr-03">
                        <SelectSeller 
                            section={props.section} 
                            cookies={props.cookies}
                            item={props.item}
                            lineId={props.item.id}
                            posid={props.posid}
                            currentTicket={props.currentTicket}
                            setCurrentTicket={props.setCurrentTicket} 
                            setRelanceFocus={props.setRelanceFocus}
                            from='pos'
                        />
                    </div>
                    <div className="col-3">
                        <div className="text-end m-0 p-0 priceArticlePos totalPricePosition">
                            <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.item.prix_remise} />
                        </div>
                        <span className="m-0 p-0 crossPosition">
                            {(loading) ?
                                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span>
                                :
                                    <FontAwesomeIcon icon={faTimesCircle}  className='pointer' onClick={()=>removeArticle()}/>
                            }
                        </span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LineItemGiftCard;