import React,{useState, useRef, useEffect} from "react";
import { ClientForm,InpDataList } from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faEdit } from '@fortawesome/free-solid-svg-icons'
import { Modal } from "react-bootstrap";
import axios from 'axios'
import SweetAlert from "sweetalert2-react";

const useSearchClients = (searchInputClient) => {
    const [loadingSearchClient,setLoadingSearchClient]=useState(false)
    const [generalErrorSearchClient,setGeneralErrorSearchClient]=useState(false)
    const [listSearchClient,setListSearchClient]=useState([])
    const [listSearchIdClient,setListSearchIdClient]=useState({})
    const [datas,setDatas]=useState([])
    //const searchInputClient = useRef('')

    const loadClients = async (toSearch) => {
        setLoadingSearchClient(true)
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/client/name/"+toSearch
        ).then((response) => {
            let constructListSearchClientId = {}
            response.data.datas.map((client,i)=>{
                constructListSearchClientId={...constructListSearchClientId,[client.name+' '+client.firstname+' - '+client.email+' - '+client.tel]:client.id}
                
            })
            setListSearchIdClient(constructListSearchClientId)
            setListSearchClient(response.data.datas)
            let initial = []
            response.data.datas.forEach((value)=>
                initial.push({
                    id: value.id,
                    name: value.name+' '+value.firstname+' - '+value.email+' - '+value.tel
                })
            )
            setListSearchClient(initial)
            let ajour = initial.filter((value)=>value.name.toLowerCase().includes(searchInputClient.current.value.toLowerCase()))
            setDatas(ajour)
            setLoadingSearchClient(false)
        }).catch((error) => {
            setLoadingSearchClient(false)
            setGeneralErrorSearchClient(true)
        })
    }

    return [loadingSearchClient,loadClients,generalErrorSearchClient,setGeneralErrorSearchClient,listSearchClient,setListSearchClient,listSearchIdClient,setListSearchIdClient,datas,setDatas]
}

const ActionClientBarLeft = (props) =>{
    const t =useTranslate()
    const [loadingSearchClient,loadClients,generalErrorSearchClient,setGeneralErrorSearchClient,listSearchClient,setListSearchClient,listSearchIdClient,setListSearchIdClient,datas,setDatas]=useSearchClients(props.searchInputClient)

    const [showModalAdd,setShowModalAdd] = useState(false)
    const [showModalEdit,setShowModalEdit] = useState(false)
    const [clientEdit,setClientEdit] = useState(false)
    const [noClientToEdit,setNoClientToEdit] = useState(false)
    const [inputName,setInputName]=useState(false)

    const dataListDropClient = useRef('')

    useEffect(function () {
        if(props.from=='barLeft'){
            if(props.cookies.current_ticket){
                if(Object.keys(props.currentTicket).length!=0){
                    if(props.currentTicket.client!=null){
                        setListSearchClient([props.currentTicket.client])
                        setListSearchIdClient({[props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel]:props.currentTicket.client_id})
                    }
                }
            }
            if(Object.keys(props.currentTicket).length==0){
                setInputName(false)
            }
        }else if(props.from == 'giftcard'){
            if(props.cookies.current_ticket){
                if(Object.keys(props.currentTicket).length!=0){
                    if(props.currentTicket.client!=null){
                        props.setValueInputClient(props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel)
                        props.setInvalidInput({...props.invalidInput,searchClient:false})
                        setListSearchIdClient({[props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel]:props.currentTicket.client_id})
                    }
                }
            }
        }else if(props.from=='erp'){
            if(Object.keys(props.currentTicket).length!=0){
                if(props.currentTicket.client!=null){
                    setListSearchClient([props.currentTicket.client])
                    setListSearchIdClient({[props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel]:props.currentTicket.client_id})
                    setInputName(props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel)
                }
            }
        }
    }, [props.currentTicket])


    const toggleAlertSearchClient = () => {
        setGeneralErrorSearchClient(false)
    }

    const toggleNoClientToEdit = () => {
        setNoClientToEdit(false)
    }

    const editClientModal = () => {
        if(!listSearchIdClient[props.valueInputClient]){
            setNoClientToEdit(true)
        }
        else{
            setClientEdit(listSearchIdClient[props.valueInputClient]);
            setShowModalEdit(true)
        }
    }

    const showModalAddNewCustomer = () => {
        setShowModalAdd(true)
        
    }

    const handleSearch = (e,declenchement) => {
        props.setValueInputClient(e.target.value)
        if(e.target.value.length>=declenchement){
            dataListDropClient.current.classList.remove("d-none");
        }
        else{
            dataListDropClient.current.classList.add("d-none");
        }
        if(e.target.value.length==declenchement){
            loadClients(e.target.value)
        }
        else{
            let ajour = listSearchClient.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }
    }

    const handleCLick = (id,valeur) => {
        props.setValueInputClient(valeur)
        props.setInvalidInput({...props.invalidInput,searchClient:false})
        if(props.setClientId)props.setClientId(id)
        if(props.from == 'barLeft'){
            if(props.cookies && props.cookies.current_ticket){
                props.updateCurentTicket('searchClient',id)
            }
        }else if(props.from == 'erp'){
            props.updateCurentTicket('searchClient',id)
        }

        setInputName(valeur)
    }

    const handleBlur = () => {
        if(props.cookies && props.cookies.current_ticket){
            if(props.from=='giftcard'){
                if(inputName){
                    props.setValueInputClient(inputName)
                    props.setInvalidInput({...props.invalidInput,searchClient:false})
                }else{
                    props.setValueInputClient('')
                    props.setInvalidInput({...props.invalidInput,searchClient:true})
                }
            }else if(Object.keys(props.currentTicket).length!=0){
                if(props.currentTicket.client!=null){
                    props.setValueInputClient(props.currentTicket.client.name+' '+props.currentTicket.client.firstname+' - '+props.currentTicket.client.email+' - '+props.currentTicket.client.tel)
                    props.setInvalidInput({...props.invalidInput,searchClient:false})
                }else{
                    props.setValueInputClient('')
                    props.setInvalidInput({...props.invalidInput,searchClient:true})
                }
            }
        }else{
            if(inputName){
                props.setValueInputClient(inputName)
                props.setInvalidInput({...props.invalidInput,searchClient:false})
            }else{
                props.setValueInputClient('')
                props.setInvalidInput({...props.invalidInput,searchClient:true})
            }
        }

        if(props.from=='barLeft') props.setRelanceFocus(Date.now())
        
        dataListDropClient.current.classList.add("d-none");
    }

    const closeModal = (name) =>{
        if(name=='add'){
            setShowModalAdd(false)
        }else{
            setShowModalEdit(false)
        }
        if(props.from=='barLeft')props.setRelanceFocus(Date.now())
    }

    return(
        <>
            <Modal size="xl" show={showModalAdd} onHide={() => closeModal('add')}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('addClient')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClientForm 
                        setListSearchIdClient={setListSearchIdClient}
                        setClientNameInput={props.setValueInputClient}
                        invalidInput={props.invalidInput}
                        setInvalidInput={props.setInvalidInput}
                        clientid={false}
                        
                        setClientId={props.setClientId}
                        cookies={props.cookies}
                        updateCurentTicket={props.updateCurentTicket}
                        from='barLeft'
                        otherModule={props.from}
                        closeModal={closeModal}
                    />
                </Modal.Body>
            </Modal>
            <Modal size="xl" show={showModalEdit} onHide={() => closeModal('edit')}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('editClient')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ClientForm 
                        setListSearchIdClient={setListSearchIdClient}
                        setClientNameInput={props.setValueInputClient}
                        invalidInput={props.invalidInput}
                        setInvalidInput={props.setInvalidInput}
                        clientid={clientEdit}
                        closeModal={closeModal}
                    />
                </Modal.Body>
            </Modal>
            <div className={props.style}>
                <InpDataList
                    placeholder={t('customerPlacehlder')}
                    value={props.valueInputClient}
                    onClick={handleCLick}
                    onChange={handleSearch}
                    datas={datas}
                    declenchement={4}
                    listRef={dataListDropClient}
                    inputRef={props.searchInputClient}
                    onBlur={handleBlur}
                    inputName="searchClient"
                    from='clientSearch'
                    className={(props.invalidInput.searchClient) ? "form-control is-invalid" : "form-control"}
                    onFocus={props.noRedInput}
                />
                {(props.load.client) ?
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm topBarLeftPosition"></div></span>
                :
                    false
                }
            </div>
            <div className="col">
                {(loadingSearchClient) ?
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm mt-2"></div></span>
                    :
                    <>
                        <button className="btn btn-success btn-sm sizeBtnPos" onClick={() => showModalAddNewCustomer()}><FontAwesomeIcon icon={faPlus} /></button>&nbsp;
                        <button className="btn btn-warning btn-sm sizeBtnPos" onClick={() => editClientModal()}><FontAwesomeIcon icon={faEdit} /></button>&nbsp;
                    </>
                }
            </div>
            {(generalErrorSearchClient) &&
                <SweetAlert show="true" title={t('unknowError')} type="error" onConfirm={() => toggleAlertSearchClient()}/>
            }
            {(noClientToEdit) &&
                    <SweetAlert show="true" title={t('noClientToEdit')} type="error" onConfirm={() => toggleNoClientToEdit()}/>
            }
        </>
    )
}

export default ActionClientBarLeft;