import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import Moment from 'react-moment';

const ModalRestockToComeDetails = (props) => {
    const t = useTranslate();

    return(<>
        <div className="table-responsive">
            <table
            id="section_restock"
            className="table"
            style={{fontSize:'10px'}}
            >
                <thead>
                    <tr>
                        <th scope="col">{t('transferOrder')}</th>
                        <th scope="col">{t('expectedReceiptDate')}</th>
                        <th scope="col">{t('quantity')}</th>
                        <th scope="col">{t('status')}</th>
                    </tr>
                </thead>
                <tbody>
                    {props.inSection.map((value,ind)=>{
                        let toStatus = (value.to.status==0) ? t('notCollected') : (value.to.status==1) ? t('processOfCollecting') : (value.to.status==2) ? t('processOfDelivery') : (value.to.status==3) ? t('dock') : false
                        return(
                            <tr key={ind}>
                                <td>{value.to.reference}</td>
                                <td><Moment format="DD/MM/YYYY">{value.to.expected}</Moment></td>
                                <td>{value.quantite}</td>
                                <td>{toStatus}</td>
                            </tr>
                        )}
                    )}
                </tbody>
            </table>
        </div>
    </>);
}

export default ModalRestockToComeDetails;