import React, {useState,useRef,useEffect} from 'react';
import axios from "axios";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";

const Psl = (props) =>{
    const t = useTranslate();
    const [pslValue,setPslValue]=useState(0)
    const [editPsl,setEditPsl]=useState(false)
    const [loadSubmit,setLoadSubmit]=useState(false)

    useEffect(()=>{
        if(props.pslFocus){
            if(props.pslRef.current){
                setTimeout(()=>{
                    props.pslRef.current.focus()
                    props.pslRef.current.select()
                },400)
            }
        }
    },[props.plsFocus,props.refresh])

    const handleDbleClick = (value) =>{
        if(props.rpFocus){ //si l'input de modification du rp est actif on bloque l'action

        }else if(!props.pslFocus){
            setEditPsl(true) //on affiche l'input pour l'edit
            setPslValue(value) //on initialise la valeur à éditer 
            props.setPslFocus(true) //on met le focus sur le champ
            props.setRefresh(Date.now())
        }
    }

    const handleChangePsl = (e) => {
        setPslValue(e.target.value);        
    }

    const handleBlur = (e) =>{
        updatePslValue(e)
    }

    const keyUp = (e) =>{
        updatePslValue(e)
    }
    
    const updatePslValue= async(e)=>{
        if(!e.key || e.key === 'Enter' ){
            if(pslValue !== '' && pslValue !== NaN && pslValue !== null){
                setLoadSubmit(true)
                const response  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/rppsl/edit/"+props.value.id,{
                    rp: props.value.rp,
                    psl: pslValue
                }).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach((interm)=>{
                            let finalMessage=interm.split('/!');
                            if(finalMessage.length==2){
                                messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                            }
                            else{
                                messageError+=t(interm)+'<br/>'
                            }
                        })
                            messageError=messageError.replace('undefined','');
                            swal.fire(messageError, "", "error");
                    }else{
                        setLoadSubmit(false)
                        let rppslId = props.rppsl.map(value=>value.id)
                        let indexToEdit = rppslId.indexOf(response.data.data.id)

                        let newArr = props.rppsl.filter(value=>value.id!=response.data.data.id)
                        newArr.splice(indexToEdit,0,response.data.data)

                        props.setRppsl(newArr)
                    }
                }).catch((error) => {
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                })
            }
            setEditPsl(false)
            props.setPslFocus(false)
        }
    }

    return(
        (editPsl) ? 
            <td className='d-flex align-items-center'>
                <input 
                    ref={props.pslRef}
                    className=" col-12 text-center fs-6 form-control" 
                    type="number" 
                    value={pslValue} 
                    onChange={(e)=>handleChangePsl(e)} 
                    onKeyUp={(e)=>keyUp(e)} 
                    onBlur={(e)=>handleBlur(e)}
                />
                {(loadSubmit) && <span><span className="spinner-border spinner-border-sm"></span></span>}
            </td>
        :
            <td className='text-center pointer' onDoubleClick={()=>handleDbleClick(props.value.psl)}>{props.value.psl}</td>
    )
}

export default Psl;