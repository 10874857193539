import React, {useState,useRef} from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso} from "@gull";
import Psl from './ReassortAutoComp/Psl'
import Rp from './ReassortAutoComp/Rp'

const ReassortAuto = (props) => {
    const t = useTranslate();
    const [refresh,setRefresh]=useState(Date.now())
    const rpRef = useRef('')
    const pslRef = useRef('')
    const [rpFocus,setRpFocus]=useState(false)
    const [pslFocus,setPslFocus]=useState(false)

    return(<>
        <CardPerso header={t('autoRestock')}>  
            {props.rppsl.length!=0 ?
                <div className="table-responsive">
                    <table
                    id="stock_table"
                    className="table"
                    >
                        <thead>
                            <tr>
                                <th scope="col">{t('shops')}</th>
                                <th scope="col" className='text-center'>{t('reorderPoint')}</th>
                                <th scope="col" className='text-center'>{t('preferredStockLevel')}</th>
                                <th scope="col" className='text-center'>{t('actualStock')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.rppsl.map((value) =>{
                                return(
                                    <tr key={value.id} className={(Object.keys(props.stock.by_section).length!=0 && props.stock.by_section[value.section && value.section.name].total + value.rp + value.psl == 0 ) ? '' : (Object.keys(props.stock.by_section).length!=0 && props.stock.by_section[value.section && value.section.name].total < value.rp) ? 'alert-danger' : (Object.keys(props.stock.by_section).length!=0 && props.stock.by_section[value.section && value.section.name].total == value.rp || Object.keys(props.stock.by_section).length!=0 && props.stock.by_section[value.section && value.section.name].total < value.psl) ? 'alert-warning' : 'alert-success'}>
                                        <td>{value.section.name}</td>
                                        <Rp 
                                            value={value} 
                                            rpRef={rpRef} 
                                            setRpFocus={setRpFocus} 
                                            rpFocus={rpFocus} 
                                            pslFocus={pslFocus}
                                            setRppsl={props.setRppsl} 
                                            rppsl={props.rppsl} 
                                            refresh={refresh} 
                                            setRefresh={setRefresh}
                                        />
                                        <Psl 
                                            value={value} 
                                            pslRef={pslRef} 
                                            setPslFocus={setPslFocus} 
                                            rpFocus={rpFocus} 
                                            pslFocus={pslFocus} 
                                            setRppsl={props.setRppsl} 
                                            rppsl={props.rppsl} 
                                            refresh={refresh} 
                                            setRefresh={setRefresh}
                                        />
                                        <td className='text-center'>{Object.keys(props.stock.by_section).length!=0 && props.stock.by_section[value.section.name].disponible}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            :
                <div className='text-center'>{t('rppslNotConfigured')}</div>
            }
           
        </CardPerso>
    </>)
}

export default ReassortAuto;