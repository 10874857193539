import React,{useState, useContext, useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import { IsAuthContext } from 'app/App';
import { useParams } from "react-router-dom";
import swal from "sweetalert2";

const EditPriceForm =  (props) =>{
    const t =useTranslate()
    const [inputValue,setInputValue]=useState('')
    const [reason,setReason]=useState('')
    const [memo,setMemo]=useState('')
    const [validButton,setInvalidInput]=useState(false)
    const { posid } = useParams();
    const {authParam} = useContext(IsAuthContext);
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    let currentShop = authParam.sections.find(value=>value.id == posid)

    let addOn = (props.isFor == 'percent') ? '%' : '-'

    let placeHolder //on détermine le placeHolder de charque formulaires
    if(props.isFor=='percent'){
        placeHolder=t('discountPercent')

    }else if(props.isFor=='net'){
        placeHolder=t('discountNet')

    }

    useEffect(function () {
        if(props.item.note!=null){
            setMemo(props.item.note)
        }
        
    }, [props.item])

    const handleChange = (e,name) =>{
        switch(name){
            case 'amount':
                setInputValue(e.target.value.replace(/\D/g,''))
                break;
            case 'reason':
                setReason(e.target.value)
                break;
            case 'memo':
                setMemo(e.target.value)
                break;
        }
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();
        props.setDisabledPay(true)
        setInvalidInput(true)
        let obj={
            id:props.cookies.current_ticket,
            detail_id:props.item.id,
            modif_manual_type:props.isFor,
            modif_manual_value:+inputValue,
            modif_manual_reason:reason
        }

        if(memo!=''){
            obj['modif_manual_memo']=memo
        }
        
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],obj
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                setInvalidInput(false)
                props.closeModal('price')
                props.setCurrentTicket(response.data.data)
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        setInvalidInput(false)
    }

    return(
        <form onSubmit={(e)=>handleSubmit(e)}>
            <div className="input-group mb-3">
                <div className="input-group-prepend">
                    <div className="input-group-text rounded-0" >{addOn}</div>
                </div>
                <input className='form-control mb-2' placeholder={placeHolder} value={inputValue} type="text" aria-describedby="basic-addon1" onChange={(e)=>handleChange(e,'amount')} />
            </div>
            <select type="text" className="form-control mb-3" onChange={(e)=>handleChange(e,'reason')}>
                <option value="">{'-- '+t('labelReason')+' --'}</option>
                {(currentShop.raisonmodifprix != null) ?
                    currentShop.raisonmodifprix.map((value,index)=>(
                        <option key={index} value={value}>{value}</option>
                    ))
                :
                    false
                }
            </select>
            <label htmlFor="Memo">Note :</label>
            <textarea className="form-control" value={memo} rows="8" onChange={(e)=>handleChange(e,'memo')}></textarea>
            <button disabled={validButton} className="btn btn-primary mt-3">
                {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>
        </form>
    )
}

export default EditPriceForm;