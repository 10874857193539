import React, {useState} from "react";
import { CardPerso } from "@gull";
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios'
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const RhGoalsAttribute = (props) => {
    const t = useTranslate()
    let { SearchBar } = Search;
    const [rhList,setRhList] = useState(props.section.rh.map(({id,name,firstname,grade},ind) => ({
        id,
        name: name+' '+firstname,
        grade: grade.name,
        ca: (props.commerceGoals.rhgoals.filter(value=>value.rh_id===id).length > 0) ? props.commerceGoals.rhgoals.filter(value=>value.rh_id===id)[0].ca : 0,
        pm: (props.commerceGoals.rhgoals.filter(value=>value.rh_id===id).length > 0) ? props.commerceGoals.rhgoals.filter(value=>value.rh_id===id)[0].pm : 0,
        iv: (props.commerceGoals.rhgoals.filter(value=>value.rh_id===id).length > 0) ? props.commerceGoals.rhgoals.filter(value=>value.rh_id===id)[0].iv : 0,
        index: ind + 1,
    })))

    const load = async (id,field,newValue,done) => {
        let key=field;
        var obj = {rh:id};
        obj[key]=newValue.replace(',','.');
        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/pos/rhgoals/"+props.commerceGoals.id, obj
        ).then((response)=> {
            if(response.data.valid){
                return done()
            }
            else {
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                 }
                 interms=interms.split(',')
                 let messageError='';
                 interms.forEach(interm=>messageError+=t(interm)+'. ')
                 messageError=messageError.replace('undefined','');
                return done({
                    valid: false,
                    message: messageError
                });
            }
        }).catch((error)=>{
            let messageError = (error.response.status==403) ? t('forbiddenGeneral') : t('unknowError');
            return done({
                valid: false,
                message: messageError
            });
        })
    }

    let defaultSorted = [
        {
          dataField: "name",
          order: "asc"
        }
    ];

    let columns = [
        {
            dataField: "name",
            text: t('tableName'),
            editable:false,
            sort: true
        },
        {
            dataField: "grade",
            text: "Grades",
            editable:false,
            sort: true
        },
        {
            dataField: "ca",
            text: "CA*",
            validator: (newValue, row, column, done) => {
                load(row.id,column.dataField,newValue,done)
                return {
                    async: true
                };
            },
            sort: true
        },
        {
            dataField: "pm",
            text: "PM*",
            validator: (newValue, row, column, done) => {
                load(row.id,column.dataField,newValue,done)
                return {
                    async: true
                };
            },
            sort: true
        },
        {
            dataField: "iv",
            text: "IV*",
            validator: (newValue, row, column, done) => {
                load(row.id,column.dataField,newValue,done)
                return {
                    async: true
                };
            },
            sort: true
        },
    ];

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        showTotal: true,
        totalSize: rhList.length,
    };

    return (
        <small>
            <ToolkitProvider
            striped
            keyField="index"
            data={rhList}
            columns={columns}
            search
            >
            {(props) => (
                <>
                    <div className="d-flex justify-content-end align-items-center">
                        <span className="mb-2 me-1">{t('tableSearch')}:</span>
                        <SearchBar {...props.searchProps} className="mb-2" />
                    </div>
                    <BootstrapTable
                    {...props.baseProps}
                    bootstrap4
                    pagination={paginationFactory(paginationOptions)}
                    noDataIndication={t('tableNoData')}
                    defaultSorted={defaultSorted}
                    cellEdit={cellEditFactory({ 
                        mode: "dbclick",
                        blurToSave: true
                        })}
                    />
                </>
            )}
            </ToolkitProvider>
        </small>
    )
}

const GoalsAttribute = (props) => {
    const t =useTranslate()
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [commerceGoals,setCommerceGoals] = useState(false)

    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)
        setCommerceGoals(false)
        const response  = await axios.get(process.env.REACT_APP_API_URL+"api/pos/objectif/period/"+props.section.id+'/'+data.begin+'/0'
            ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
                if(!response.data.period){
                    setApiError(t('noObjectif'))
                }
                else{
                    setCommerceGoals(response.data.period)
                }
            }
        }).catch((error) => {
            if(error.response==undefined){
                setApiError(t('unknowError'))
            }
            else if(error.response.status==403){
                setApiError(t('forbiddenGeneral'))
            }
            else {
                setApiError(t('unknowError'))
            }
        })
        setValidButton(false)
    }

    return(
        <div className="row" style={{marginTop: (props.noUp) ? '0' : '-60px'}}>
            <div className="col-12">
                <CardPerso header={t('attribuer')+' '+t('objectifs')}>
                    <div className="row">
                        <div className="col-6">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group row">
                                    <label htmlFor="begin">{t('day')}</label>
                                    <input className="form-control" type="date"{...register('begin',{required: true})}/>
                                    {errors.begin?.type === 'required' && (
                                    <div className="text-danger col-12 mt-1 ml-2">
                                        {t('beginRequired')}
                                    </div>
                                    )}
                                </div>
                                {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                                <button disabled={validButton} className="btn btn-primary mt-3">
                                    {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                                </button>
                            </form>
                        </div>
                        <div className="col-5 offset-1">
                            {(commerceGoals) &&
                                <div className="row text-center" style={{borderLeft:'solid 1px #e5e5e5'}}>
                                    <div className="col-12 text-center">
                                        <p><strong>{t('objectifs')+' '+t('shop')}</strong></p>
                                    </div>
                                    <div className="col-12">
                                        <span style={{color: '#5679df'}}><strong>CA</strong>: {new Intl.NumberFormat('fr-FR').format(commerceGoals.ca)+' XPF'}</span>
                                    </div>
                                    <div className="col-12">
                                        <span style={{color: '#dfa656'}}><strong>PM</strong>: {new Intl.NumberFormat('fr-FR').format(commerceGoals.pm)+' XPF'}</span>
                                    </div>
                                    <div className="col-12">
                                        <span style={{color: '#a945b2'}}><strong>IV</strong>: {commerceGoals.iv+' '+t('items')}</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {(commerceGoals) && <>
                        <hr className="col-8 offset-2 mt-3" />
                        <RhGoalsAttribute section={props.section} commerceGoals={commerceGoals} />
                    </>}
                </CardPerso>
            </div>
        </div>
    )
}

export default GoalsAttribute;
