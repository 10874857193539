import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso} from "@gull";

const DetailArticle = (props) =>{
    const t = useTranslate();

    let actualPromo = []
    Object.keys(props.article).length!=0 && Object.entries(props.article.current_promotion).map(([key,value])=>{
        if(value!=null){
            actualPromo.push(value)
        }
    })

    return(
        <div className='d-flex'>
            <CardPerso header={t('detailArticle')}>
                <div className='row'>
                    <div className='col-5'>
                        {(props.article.image) ?
                            <div className='mb-3 text-center'>
                                <img className='img-contain' src={props.article.image} alt={props.article.name} />
                            </div>
                        :
                            <div className='mb-3 text-center'>
                                <img  src="/assets/images/no-image.png" alt="No image" />
                            </div>
                        }
                    </div>
                    <div className='col-3'>
                        <div className='row mb-2'>
                            <div className='card-header text-center'>{t('itemInfos')}</div>
                        </div>
                        <div className='row mb-2'>
                            <label className='fs-5 text fw-bold' htmlFor="havestock">{t('fournisseur')+' :'}</label>
                            {(props.article.fournisseur && props.article.fournisseur!==null) ?
                                <div>{props.article.fournisseur.name}</div>
                            :
                                <div>--</div>
                            }
                        </div>
                        <div className='row mb-2'>
                            <label className='fs-5 text fw-bold' htmlFor="marque">{t('marque')+' :'}</label>
                            {(props.article.marque) ?
                                <div>{props.article.marque.name}</div>
                            :
                                <div>--</div>
                            }
                        </div>
                        <div className='row mb-2'>
                            <label className='fs-5 text fw-bold' htmlFor="havestock">{t('itemHaveStock')+' :'}</label>
                            {(props.article.havestock) ?
                                <div className='text-success'>{t('yes')}</div>
                            :
                                <div className='text-danger'>{t('no')}</div>
                            }
                        </div>
                        <div className='row mb-2'>
                            <label className='fs-5 text fw-bold' htmlFor="havestock">{t('currentPromo')+' :'}</label>
                            {(actualPromo.length!=0) ?
                                <div className='text-success'>{t('yes')}</div>
                            :
                                <div className='text-danger'>{t('no')}</div>
                            }
                        </div>
                    </div>
                    <div className='col-4 customField-articleDetail__header'>
                        <div className='row mb-2'>
                            <div className='card-header text-center'>{t('itemFamily')}</div>
                        </div>
                        <div className='row mb-2'>
                            <label className='fs-5 text fw-bold' htmlFor="havestock">{t('firstlevelcat')+' :'}</label>
                            {(props.article.firstlevelcat && props.article.firstlevelcat.name!==null) ?
                                <div>{props.article.firstlevelcat.name}</div>
                            :
                                <div>--</div>
                            }
                        </div>
                        <div className='row mb-2'>
                            <label className='fs-5 text fw-bold' htmlFor="havestock">{t('secondlevelcat')+' :'}</label>
                            {(props.article.secondlevelcat && props.article.secondlevelcat.name!==null) ?
                                <div>{props.article.secondlevelcat.name}</div>
                            :
                                <div>--</div>
                            }
                        </div>
                        <div className='row mb-2'>
                            <label className='fs-5 text fw-bold' htmlFor="havestock">{t('thirdlevelcat')+' :'}</label>
                            {(props.article.thirdlevelcat && props.article.thirdlevelcat.name!==null) ?
                                <div>{props.article.thirdlevelcat.name}</div>
                            :
                                <div>--</div>
                            }
                        </div>
                        <div className='row mb-2'>
                            <label className='fs-5 text fw-bold' htmlFor="havestock">{t('fourthlevelcat')+' :'}</label>
                            {(props.article.fourthlevelcat && props.article.fourthlevelcat.name!==null) ?
                                <div>{props.article.fourthlevelcat.name}</div>
                            :
                                <div>--</div>
                            }
                        </div>
                        <div className='row mb-2'>
                            <label className='fs-5 text fw-bold' htmlFor="havestock">{t('fifthlevelcat')+' :'}</label>
                            {(props.article.fifthlevelcat && props.article.fifthlevelcat.name!==null) ?
                                <div>{props.article.fifthlevelcat.name}</div>
                            :
                                <div>--</div>
                            }
                        </div>
                    </div>
                </div>
            </CardPerso>
        </div>
    )
}

export default DetailArticle;