import React from 'react';
import axios from 'axios';
import { useTranslate } from 'react-redux-multilingual';
import swal from "sweetalert2";

const DeleteUserConfBtn = (props) => {
    const t = useTranslate();
    
    const deleteUserConf = async () => {
        const response  = await axios.delete(process.env.REACT_APP_API_URL+"api/custom_interface/"+props.interface
        ).then((response) => {
            props.setDatas({})
            props.setEmptyConf(true)
            if(props.setRepere){
                props.setRepere(Date.now())
            }
            if(props.setReload){
                props.setReload(Date.now())
            }
        }).catch((error) => {
            
        })
    }

    return(
        <button 
                className='btn btn-danger mb-3'
                onClick={()=> {
                    swal
                    .fire({
                        title: t('confirmDelete')+" ?",
                        icon: "warning",
                        type: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t('btnYes'),
                        cancelButtonText: t('btnNo'),
                    })
                    .then((result) => {
                        if (result.value) {
                            deleteUserConf()
                        } else {
                            swal.fire(t('canceled'), "", "error");
                        }
                    });
                }}
            >
                {t('deleteUserConf')}
        </button>
    )
}

export default DeleteUserConfBtn;