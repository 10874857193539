import React, {useState,useContext} from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {ModalTagPrintForm} from "@gull";
import { PeripheriqueContext } from '../../../app/App';
import { Modal } from "react-bootstrap";
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';

const TagArticleBtn = (props) => {
    const t = useTranslate();
    const [showTagPrint,setShowTagPrint]=useState(false)
    const { peripheriqueParam } = useContext(PeripheriqueContext)  
    
    return (<>
            <Modal size="md" show={showTagPrint} onHide={() => setShowTagPrint(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('tagPrint')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalTagPrintForm articlePrice={props.articlePrice} articleName={props.articleName} articleUpc={props.articleUpc} peripheriqueParam={peripheriqueParam} from='pos'/>
                </Modal.Body>
            </Modal>
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {t('printItemTag')}
                    </Tooltip>
                }
            >
                <span onClick={() => setShowTagPrint(!showTagPrint)} className='pointer text-secondary'><FontAwesomeIcon icon={faTag} /></span>
            </OverlayTrigger> 
        </> 
    )
}

export default TagArticleBtn;