import React from "react";

const CustomToggleList = ({
    columns,
    onColumnToggle,
    toggles,
    executWhenToggle
  }) => (
    <div data-toggle="buttons">
      {
        columns
          .map(column => ({
            ...column,
            toggle: toggles[column.dataField]
          }))
          .map(column => (
            <button
              type="button"
              key={ column.dataField }
              className={ `btn btn-sm m-1 ${column.toggle ? 'btn-info ' : 'btn-outline-info'}` }
              data-toggle="button"
              aria-pressed={ column.toggle ? 'true' : 'false' }
              onClick={ 
                  () => executWhenToggle(column) 
                }
            >
              { column.text }
            </button>
          ))
      }
    </div>
);

export default CustomToggleList;