import React, { useState,useEffect,useContext} from "react";
import { IsAuthContext } from 'app/App';
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso,Loading,ModalChangePaymentMethod} from "@gull";
import axios from 'axios'
import swal from "sweetalert2";
import { useParams } from "react-router-dom";
import GeneralInfos from './Comp/GeneralInfos'
import DetailItems from './Comp/DetailItems'

const useLoad = (ticketId) => {
    const t = useTranslate()
    const [loading,setLoading]=useState(true)
    const [ticketDetail,setTicketDetail]=useState({})
    const [section,setSection]=useState(false)
    
    useEffect(function () {
        (async function() {
            if(Object.keys(ticketDetail).length!=0){
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/section/"+ticketDetail.section_id
                ).then((response) => {
                    if(response.data.rh==null || response.data.rh.length==0){
                        swal.fire(t('noRhForSection'), "", "error");
                    }
                    else{
                        setSection(response.data)
                    }
                }).catch((error) => {
                    setLoading(false)
                    swal.fire(t('unknowError'), "", "error");
                })
                setLoading(false)
            }
        })()
    }, [ticketDetail])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/ticket/get_by_id/"+ticketId
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{ 
                    setTicketDetail(response.data.data)
                }
            }).catch((error) => {
                setLoading(false)
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])

    return [loading,ticketDetail,section,setTicketDetail]
}

const DetailTicketAdmin = () => {
    const t = useTranslate()
    const { ticketId } = useParams();
    const [loading,ticketDetail,section,setTicketDetail] = useLoad(ticketId)
    const { authParam } = useContext(IsAuthContext);
    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;

    let actualShop = (Object.keys(ticketDetail).length!=0) ? authParam.sections.find(shop=>shop.id == ticketDetail.section_id) : false

    if(loading){
        return (<Loading></Loading>);
    }

    return(
        <div className="row">
            <div className="col">
                {(Object.keys(ticketDetail).length!=0) ?
                    <CardPerso header={t('receiptDetail')}>
                        <div className="row">
                            <div className="col-4">
                                {(section) ?
                                    <GeneralInfos 
                                        ticketDetail={ticketDetail}
                                        section={section}
                                        setTicketDetail={setTicketDetail}
                                        digit={digit}
                                        devise={devise}
                                    />
                                :
                                    false
                                }
                            </div>
                            <div className="col-5">
                                {(ticketDetail.ticket_detail && ticketDetail.ticket_detail.length!=0) && ticketDetail.ticket_detail.map((value,ind)=>{
                                    let style = (ind%2 == 0) ? 'stripeBg stripePadding2 fw-bold' : 'stripePadding2 fw-bold';

                                    if(section){
                                        return(
                                            <DetailItems 
                                                key={ind}
                                                style={style}
                                                value={value}
                                                digit={digit}
                                                devise={devise}
                                                section={section}
                                                posid={ticketDetail.section_id}
                                                setTicketDetail={setTicketDetail}
                                                caisseIdent={ticketDetail.caisse_ident_id}
                                                pos_id={ticketDetail.pos_id}
                                                ticektId={ticketDetail.id}
                                            />
                                        )
                                    }else{
                                        return(false)
                                    }
                                })}
                            </div>
                            <div className="col-3">
                                {(actualShop)?
                                    <ModalChangePaymentMethod
                                        barTicket={ticketDetail.bar}
                                        currentShop={actualShop}
                                        devise={devise}
                                        digit={digit}
                                        posid={ticketDetail.section_id}
                                        ticketDetail={ticketDetail}
                                        caisseIdent={ticketDetail.caisse_ident_id}
                                        pos_id={ticketDetail.pos_id}
                                        module='erp'
                                    />
                                :
                                    false
                                }
                            </div>
                            
                        </div>
                    </CardPerso>
                :
                    false
                }
            </div>
        </div>
    )
}

export default DetailTicketAdmin;