import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice} from "@gull"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { modePaiementDefaultAll } from 'app/modePaiementDefaultAll';
import swal from "sweetalert2";
import axios from 'axios'

const LinePaymentRecord = (props) =>{
    const t =useTranslate()
    const [loading,setLoading]=useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    const handleDelete = async (paimentRecordId) =>{
        setLoading(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            id:props.cookies.current_ticket,
            delete_paiement_record:paimentRecordId
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                setLoading(false)
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading(false)
    }

    return(
        <div className={props.style}>
            <div className="col-7">
                {(modePaiementDefaultAll.includes(props.value.mode)) ?
                    t(props.value.mode)
                :
                    props.value.mode
                }
            </div>
            <div className="col-5 text-center">
                {<>
                    <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.value.montant} /> 
                    {(loading) ? 
                        <div className="spinner-border spinner-border-sm crossPaymentPosition"></div> 
                    : 
                        (props.value.pos_id == idsLocalStorage[0]) ?
                            <FontAwesomeIcon icon={faTimesCircle} className='crossPaymentPosition pointer' onClick={()=>handleDelete(props.value.id)}/>
                        : 
                            false
                    }
                </>}
            </div>
        </div>
    )
}

export default LinePaymentRecord;