import React, { useState } from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons'
import {stringNumber} from "app/stringNumber";

const MatchCsv = React.forwardRef((props, ref) => {
    let updateField = (props.updateField) ? props.updateField : false;
    let isForUpdate = (props.isForUpdate) ? props.isForUpdate : false;
    let setIsForUpdate = (props.setIsForUpdate) ? props.setIsForUpdate : false;
    const t = useTranslate()
    const [isMatch,setIsMatch] = useState(props.csvHeader.includes(props.toMatch.replace('*','')))
    let hereUpdate = isForUpdate;

    const handleChange = (e) => {
        props.setPreSendError(false)
        props.setShowValidButton(false)
        if(e.target.value=='no'){ setIsMatch(false)}
        else{
            setIsMatch(true);
        }
        let verifDoublon = new Array()
        let valid=true
        ref.current.forEach((r)=>{
            if(r.value!='no'){
                if(!verifDoublon.includes(r.value)){
                    verifDoublon.push(r.value)
                }
                else{
                    valid=false
                }
            }
        })
        if(!valid){
            props.setPreSendError(t('doublonCsv'))
        }
        else{
            if(updateField){
                props.optionMatchCsv.forEach((v,index) =>{
                    if(index==updateField){
                        if(ref.current[index].value!="no"){
                            hereUpdate=true
                            setIsForUpdate(true);
                        } 
                        else{
                            hereUpdate=false
                            setIsForUpdate(false);
                        } 
                    }
                })
            }

            let valid=true
            props.optionMatchCsv.forEach((v,index) =>{
                if(v.match(/\*$/)){
                    if(ref.current[index].value=="no") valid=false
                }
            })
            if(valid || hereUpdate){
                props.setShowValidButton(true)
            }
        }
    }

    return(
        <div className="col-12 mb-2">
            <div className="row">
                <div className="col-5" style={{textAlign:"right"}}>
                    <small><span className={(isMatch) ? 'text-success' : (props.toMatch.match(/\*$/) && !isForUpdate) ? 'text-danger' : 'text-light'}><strong>{props.toMatch}</strong></span></small>
                </div>
                <div className="col-2 text-center">
                    <small><FontAwesomeIcon className={(isMatch) ? 'text-success' : (props.toMatch.match(/\*$/) && !isForUpdate) ? 'text-danger' : 'text-light'} icon={faArrowsAltH} /></small>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-5">
                    <select className="form-control form-control-sm"  defaultValue={(props.csvHeader.includes(props.toMatch.replace('*',''))) ? props.csvHeader.indexOf(props.toMatch.replace('*','')) : 'no'} onChange={(e) => handleChange(e)} ref={(element) => (!ref.current[props.index]) && ref.current.splice(props.index,1,element)}>
                        <option value="no">-- Select --</option>
                        {props.csvHeader.map((value,index)=>
                            <option key={index} value={index}>{stringNumber[index]+' - '+value}</option>
                        )}
                    </select>
                </div>
            </div>
        </div>
    )
})

export default MatchCsv