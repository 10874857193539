import React,{useState,useContext,useEffect} from "react";
import {PaymentCompleted} from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import {Tabs, Tab} from "react-bootstrap";
import PaymentFormLeft from './Component/PaymentFormLeft'
import PaymentFormRight from './Component/PaymentFormRight'
import RefundGiftCard from './Component/RefundGiftCard'
import OrderForm from './Component/OrderForm'
import OrderFormNext from './Component/OrderFormNext'
import { IsAuthContext } from 'app/App';
import { useParams } from "react-router-dom";
import { modePaiementCb } from 'app/modePaiementCb';
import { modePaiementDefaultAll } from 'app/modePaiementDefaultAll';

const PaymentForm = (props) =>{
    const t =useTranslate()
    const { posid } = useParams();
    const {authParam} = useContext(IsAuthContext);
    const [giftCardScan,setGiftCardScan]=useState(false)
    const [valueDue,setValueDue]=useState(0)
    const [btnDisabled,setBtnDisabled]=useState(false)
  

    useEffect(function () {
        let versement = 0

        Object.keys(props.currentTicket).length!= 0 && props.currentTicket.paiement_records.map(item=>{
            versement+=item.montant
            
        })

        let newDue = +props.totalAchat - +versement
        //ici dans le cas d'un remboursement par carte cadeau dû à un réglement par une catégorie 'other' on force le restant dû à etre à 0 et non un chiffre négatif
        if(giftCardScan){
            if(+newDue < 0){
                setValueDue(0)
            }
            setGiftCardScan(false)
        }else{
            if(newDue>2){//Si il reste 1f ou 2f à payer à cause de l'arrondi à l'inférieur, on passe le valueDue à 0 
                setValueDue(newDue)
            }else{
                setValueDue(0)
            }
        }
        

    }, [props.currentTicket])

    let currentShop = authParam.sections.find(shop=>shop.id == posid)

    let cbPaymentMethod = []
    let otherPaymentMethod = []

    if(currentShop.modepaiement!=null){
        for(var i=0; i<currentShop.modepaiement.length; i++){ //on vérifie si le mode de paiement par cb est autorisé sur ce shop
            if(modePaiementCb.includes(currentShop.modepaiement[i])){
                cbPaymentMethod.push(currentShop.modepaiement[i])
            }
        }
    
        for(var i=0; i<currentShop.modepaiement.length; i++){ //on vérifie si les autres modes de paiement sont autorisés sur ce shop
            if(!modePaiementDefaultAll.includes(currentShop.modepaiement[i])){
                otherPaymentMethod.push(currentShop.modepaiement[i])
            }
        }
    }

    let total = props.totalAchat

    let devise = authParam.company.devise;
    let digit = (authParam.company.devise === "XPF") ? 0 : 2;
    
    return(
        <div className="row">
            {(props.ticketCommande) ?
                <OrderFormNext 
                    ticketCommande={props.ticketCommande}
                    setTicketCommande={props.setTicketCommande}
                    devise={devise}
                    digit={digit}
                    posid={posid}
                    from='formSaleOrder'
                    setModalTitle={props.setModalTitle}
                    setShow={props.setShow}
                />
            :
                (props.orderForm) ?
                    <OrderForm 
                        currentTicket={props.currentTicket}
                        authParam={authParam}
                        currentShop={currentShop}
                        setTicketCommande={props.setTicketCommande}
                        resetSale={props.resetSale}
                        posid={posid}
                        devise={devise}
                        setOrderForm={props.setOrderForm}
                        setCookie={props.setCookie}
                        expire_date={props.expire_date}
                    />
                :
                    (currentShop.modepaiement!=null) ?
                        <>
                            {(props.currentTicket.status==1) ? 
                                <div className="col-6">
                                    <PaymentCompleted />
                                </div>
                            :
                                (total<0) ?
                                    <div className="col-6">
                                        <RefundGiftCard
                                            total={Math.abs(total)}
                                            cookies={props.cookies}
                                            currentTicket={props.currentTicket}
                                            setCurrentTicket={props.setCurrentTicket}
                                            clientId={props.clientId}
                                            posid={posid}
                                        />
                                    </div>
                                :
                                    <div className="col-6">
                                        <Tabs defaultActiveKey="cash" id="uncontrolled-tab-example" style={{marginTop:'1px'}}>
                                            {(currentShop.modepaiement.includes('espece')) &&
                                                <Tab eventKey="cash" title={t('espece')}>
                                                    <PaymentFormLeft
                                                        total={total}
                                                        cookies={props.cookies}
                                                        clientId={props.clientId}
                                                        currentTicket={props.currentTicket}
                                                        setCurrentTicket={props.setCurrentTicket}
                                                        devise={devise}
                                                        digit={digit}
                                                        valueDue={valueDue}
                                                        posid={posid}
                                                        authParam={authParam}
                                                        giftCardScan={giftCardScan}
                                                        setGiftCardScan={setGiftCardScan}
                                                        isFor='espece'
                                                        btnDisabled={btnDisabled}
                                                        setBtnDisabled={setBtnDisabled}
                                                    />                
                                                </Tab>
                                            }
                                            {(currentShop.modepaiement.includes('cheque')) &&
                                                <Tab eventKey="check" title={t('cheque')}>
                                                    <PaymentFormLeft
                                                        total={total}
                                                        cookies={props.cookies}
                                                        clientId={props.clientId}
                                                        currentTicket={props.currentTicket}
                                                        setCurrentTicket={props.setCurrentTicket}
                                                        devise={devise}
                                                        digit={digit}
                                                        valueDue={valueDue}
                                                        posid={posid}
                                                        authParam={authParam}
                                                        giftCardScan={giftCardScan}
                                                        setGiftCardScan={setGiftCardScan}
                                                        isFor='cheque'
                                                        btnDisabled={btnDisabled}
                                                        setBtnDisabled={setBtnDisabled}
                                                    />                
                                                </Tab>
                                            }
                                            {(cbPaymentMethod.length!=0) &&
                                                <Tab eventKey="card" title={t('card')}>
                                                    <PaymentFormLeft
                                                        total={total}
                                                        cookies={props.cookies}
                                                        clientId={props.clientId}
                                                        currentTicket={props.currentTicket}
                                                        setCurrentTicket={props.setCurrentTicket}
                                                        devise={devise}
                                                        digit={digit}
                                                        valueDue={valueDue}
                                                        posid={posid}
                                                        authParam={authParam}
                                                        giftCardScan={giftCardScan}
                                                        setGiftCardScan={setGiftCardScan}
                                                        isFor='card'
                                                        btnDisabled={btnDisabled}
                                                        setBtnDisabled={setBtnDisabled}
                                                    />                
                                                </Tab>
                                            }
                                            {(currentShop.modepaiement.includes('giftcard')) &&
                                                <Tab eventKey="giftcard" title={t('giftcard')}>
                                                    <PaymentFormLeft
                                                        total={total}
                                                        cookies={props.cookies}
                                                        clientId={props.clientId}
                                                        currentTicket={props.currentTicket}
                                                        setCurrentTicket={props.setCurrentTicket}
                                                        devise={devise}
                                                        digit={digit}
                                                        valueDue={valueDue}
                                                        posid={posid}
                                                        authParam={authParam}
                                                        giftCardScan={giftCardScan}
                                                        setGiftCardScan={setGiftCardScan}
                                                        isFor='giftcard'
                                                        btnDisabled={btnDisabled}
                                                        setBtnDisabled={setBtnDisabled}
                                                    />                
                                                </Tab>
                                            }
                                            {(currentShop.modepaiement.includes('virement')) &&
                                                <Tab eventKey="virement" title={t('virement')}>
                                                    <PaymentFormLeft
                                                        total={total}
                                                        cookies={props.cookies}
                                                        clientId={props.clientId}
                                                        currentTicket={props.currentTicket}
                                                        setCurrentTicket={props.setCurrentTicket}
                                                        devise={devise}
                                                        digit={digit}
                                                        valueDue={valueDue}
                                                        posid={posid}
                                                        authParam={authParam}
                                                        giftCardScan={giftCardScan}
                                                        setGiftCardScan={setGiftCardScan}
                                                        isFor='virement'
                                                        btnDisabled={btnDisabled}
                                                        setBtnDisabled={setBtnDisabled}
                                                    />                
                                                </Tab>
                                            }
                                            {(otherPaymentMethod.length!=0) &&
                                                <Tab eventKey="other" title={t('other')}>
                                                    <PaymentFormLeft
                                                        total={total}
                                                        cookies={props.cookies}
                                                        clientId={props.clientId}
                                                        currentTicket={props.currentTicket}
                                                        setCurrentTicket={props.setCurrentTicket}
                                                        devise={devise}
                                                        digit={digit}
                                                        valueDue={valueDue}
                                                        posid={posid}
                                                        authParam={authParam}
                                                        giftCardScan={giftCardScan}
                                                        setGiftCardScan={setGiftCardScan}
                                                        isFor='other'
                                                        btnDisabled={btnDisabled}
                                                        setBtnDisabled={setBtnDisabled}
                                                    />                
                                                </Tab>
                                            }
                                        </Tabs>
                                    </div>
                            }
                            <div className="col-6">
                                <PaymentFormRight
                                    cookies={props.cookies}
                                    setCurrentTicket={props.setCurrentTicket}
                                    posid={posid}
                                    total={total}
                                    devise={devise}
                                    digit={digit}
                                    valueDue={valueDue}
                                    id='paymentDue'
                                    currentTicket={props.currentTicket}
                                    setCookie={props.setCookie}
                                    expire_date={props.expire_date}
                                    setOrderForm={props.setOrderForm}
                                    setModalTitle={props.setModalTitle}
                                    setTicketCommande={props.setTicketCommande}
                                    resetSale={props.resetSale}
                                    btnDisabled={btnDisabled}
                                    setBtnDisabled={setBtnDisabled}
                                />
                            </div>
                        </>
                    :
                        <span className="text-center">{t('tableNoModePaiement')}</span>
            }
        </div>
    )
}

export default PaymentForm;