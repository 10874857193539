
import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { OverlayTrigger,Popover,ButtonToolbar } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle} from '@fortawesome/free-solid-svg-icons';

const DescriptionRecords = (props) => {
    const t = useTranslate();

    let dataDescription;
    
    switch (props.value.description) {
        case 'updatePoRecords':
            dataDescription = t('updatePoRecords')+' : '+props.value.po.name
            break;
        case 'insertPoRecords':
            dataDescription = t('insertPoRecords')+' : '+props.value.po.name
            break;
        case 'updateShipmentRecords':
            dataDescription = t('updateShipmentRecords')+' : '+props.value.shipment.numero_shipment
            break;
        case 'insertShipmentRecords':
            dataDescription = t('insertShipmentRecords')+' : '+props.value.shipment.numero_shipment
            break;
        case 'entreStockRecords':
            dataDescription = <>{t('po')+' : '+props.value.po.name}<br />{t('shipmentVoucher')+' : '+props.value.shipment.numero_shipment}</>
            break;
        case 'simpleMouvement':
            dataDescription = <>{t('simpleMove')}</>
            break;
        case 'cancelEntreStockRecords':
            dataDescription = <>{t('po')+' : '+props.value.po.name}<br />{t('shipmentVoucher')+' : '+props.value.shipment.numero_shipment}</>
            break;
        case 'ajustStock':
            dataDescription = (props.value.quota<0) ? t('stockRemove') : t('stockIntegration');
            break;
        case 'toPickedUp':
            dataDescription = <>{t('transferOrder')+' : '+props.value.to.reference}<br />{t('collected')}</>
            break;
        case 'toPickedDown':
            dataDescription = <>{t('transferOrder')+' : '+props.value.to.reference}<br />{t('inLivraison')}</>;
            break;
        case 'toDeposedUp':
            dataDescription = <>{t('transferOrder')+' : '+props.value.to.reference}<br />{t('outLivraison')}</>
            break;
        case 'toDeposedDown':
            dataDescription = <>{t('transferOrder')+' : '+props.value.to.reference}<br />{t('deposed')}</>;
            break;
        case 'toAjust':
            dataDescription = <>{t('transferOrder')+' : '+props.value.to.reference}<br />{t('toCorrectionDeposed')}</>;
            break;
        case 'venteTicket':
            dataDescription = t('vente');
            break;
        case 'retourTicket':
            dataDescription = t('retour');
            break;
        case 'commandeChangeCollectZone':
            dataDescription = t('commandeChangeCollectZone');
            break;
        case 'commandeOut':
            dataDescription = t('commandeOut');
            break;
        case 'commandePut':
            dataDescription = t('commandePut');
            break;
        case 'commandeCancelledOut':
            dataDescription = t('commandeCancelledOut');
            break;
        case 'commandeCancelledPut':
            dataDescription = t('commandeCancelledPut');
            break;
        default:
    }

    const popoverRight = (
        <Popover id={'pop'+props.value.id} title="Popover right" className='p-3'>
            {(props.value.memo!=null) &&
                <div dangerouslySetInnerHTML={{__html: props.value.memo}}></div>
            }
        </Popover>
    );


    return(<>
        {dataDescription}
        {(props.value.memo!=null) &&
            <>
                <br/>
                <ButtonToolbar>
                    <OverlayTrigger 
                        trigger="click" 
                        rootClose
                        placement="right"
                        overlay={popoverRight}
                    >
                        <span style={{cursor:'pointer'}}><FontAwesomeIcon icon={faQuestionCircle} /></span>
                    </OverlayTrigger>
                </ButtonToolbar>
            </>
        }
    </>)
}

export default DescriptionRecords;