import React, {useState, useEffect} from 'react';
import { useForm } from "react-hook-form";
import { useTranslate } from 'react-redux-multilingual';
import axios from 'axios';
import swal from "sweetalert2";
import {SubmitForm} from "@gull";

const ShipmentVoucherModalEdit = (props) => {
    const t = useTranslate();
    const { register, formState: { errors }, handleSubmit, setValue, reset} = useForm();
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)

    const [loadingShipment,setLoadingShipment]=useState(true)
    const [shipmentOne,setShipmentOne]=useState(false)
    const [customFields, setCustomFields]=useState([])

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/shipment/one/"+props.modalEditId
            ).then((response) => {
                setLoadingShipment(false)
                setShipmentOne(response.data.shipment)  
                setCustomFields(response.data.custom_fields)              
            }).catch((error) => {
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
        })()
    }, [props.modalEditId])

    if(props.from==='shipmentList'){
        //On détermine l'index à éditer sur la modal edit 
        let shipmentNumber = props.shipment.map(value=>value.numero_shipment)
        var indexToEdit = shipmentNumber.indexOf(shipmentOne.numero_shipment)
    }


    const handleChange = (e) =>{
        setValue('indice_prmp',e.target.value.replace(/\D/g,''));
    }
    

    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)
        setSuccess(false)

        let obj = {
            numero_shipment : data.numero_shipment,
            numero_container_shipment : data.numero_container_shipment,
            date_depart_shipment : data.date_depart_shipment,
            date_arrive_shipment : data.date_arrive_shipment,
            status_shipment : data.status_shipment,
            indice_prmp : +data.indice_prmp,
            memo : data.memo,
        }

        customFields.length!=0 && customFields.map(value=>{
            obj[value.name]=data[value.name]
        })

        
        const callapi  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/shipment/"+props.modalEditId, obj)
            .then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                }
                else{
                    setSuccess(true)
                    if(props.from==='shipmentList' && indexToEdit >= 0){
                        let newData = props.shipment
                        newData.splice(indexToEdit,1, response.data.shipment)
                        props.setShipment(newData)
                    }else if(props.from==='shipmentDetail'){
                        props.setShipmentDetail(response.data.shipment)
                    }
                    setShipmentOne(response.data.shipment)   
                }
            }).catch((error) => {
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            }
        )
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false);
        },1000)
    }


    return(<>
        {(loadingShipment) ? 
            <div className="text-center"><div className="spinner-border spinner-border-sm"></div></div>
        :
            (shipmentOne) &&
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="numéro-arrivage">{t('numero_shipment')+' :'}</label>
                        <input defaultValue={shipmentOne.numero_shipment} className="form-control" type="text" {...register('numero_shipment', {required: true,minLength:2,maxLength:190})}/>
                        {errors.numero_shipment?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('numeroShipmentRequired')}
                        </div>
                        )}
                        {errors.numero_shipment?.type === 'minLength' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('numeroShipmentMin2')}
                        </div>
                        )}
                        {errors.numero_shipment?.type === 'maxLength' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('numeroShipmentMax190')}
                        </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="container-number">{t('numero_container_shipment')+' :'}</label>
                        <input defaultValue={shipmentOne.numero_container_shipment} className="form-control" type="text" {...register('numero_container_shipment')}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="departure-date">{t('date_depart_shipment')+' :'}</label>
                        <input defaultValue={(shipmentOne.date_depart_shipment) ? shipmentOne.date_depart_shipment.split(' ',1) : null} className="form-control" type="date" {...register('date_depart_shipment')}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="arrival-date">{t('date_arrive_shipment')+' :'}</label>
                        <input defaultValue={(shipmentOne.date_arrive_shipment) ? shipmentOne.date_arrive_shipment.split(' ',1) : null} className="form-control" type="date" {...register('date_arrive_shipment')}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="status">{t('status_shipment')+' :'}</label>
                        <select defaultValue={shipmentOne.status_shipment} className="form-control"  {...register('status_shipment')}>
                            <option value="cours_chargement">{t('cours_chargement')}</option>
                            <option value="mer">{t('mer')}</option>
                            <option value="douane">{t('douane')}</option>
                            <option value="dock">{t('dock')}</option>
                            <option value="receptione">{t('receptione')}</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="prmp">{t('prmpIndice')+' :'}</label>
                        <input defaultValue={(shipmentOne.indice_prmp)} className="form-control" type="text" {...register('indice_prmp', {onChange :(e) => handleChange(e)})}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="memo">Memo :</label>
                        <textarea defaultValue={shipmentOne.memo.replace( /(<([^>]+)>)/ig, '')} rows={5} className="form-control" {...register('memo')}/>
                    </div>
                    {customFields.map((item,index)=>(
                        <div className="form-group" key={index}>
                            <label htmlFor={item.name}>{item.name}{(item.required) ? '*' : false}</label>
                            {(item.free) ?
                                <>
                                    <input defaultValue={shipmentOne.custom_fields[item.name]} className="form-control" type="text" {...register(item.name,{required:item.required})}/>
                                    {errors[item.name]?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('youMustIndicateField')+' "'+item.name+'"'}
                                    </div>
                                    )}
                                </>
                                :
                                <>
                                    <select defaultValue={shipmentOne.custom_fields[item.name]} className="form-control" {...register(item.name,{required:item.required})} >
                                        <option value="">-- Select --</option>
                                        {item.list.map((value,index)=>
                                            <option key={index} value={value}>{value}</option>
                                        )}
                                    </select>
                                    {errors[item.name]?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('youMustIndicateField')+' "'+item.name+'"'}
                                    </div>
                                    )}
                                </>
                            }
                        </div>
                    ))}
                    <SubmitForm error={apiError} success={success} validButton={validButton}/>
                </form>
        }
    </>
    )
}

export default ShipmentVoucherModalEdit;