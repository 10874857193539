import { lazy } from "react";

const Wms = lazy(() => import("./Wms"));
const ShipmentVoucherReceipt = lazy(() => import("./ShipmentVoucherReceipt/ShipmentVoucherReceipt"));
const ShipmentVoucherMenu = lazy(() => import("./ShipmentVoucherReceipt/ShipmentVoucherMenu"));
const BoxScan = lazy(() => import("./Scan/BoxScan"));
const BoxOpenScan = lazy(() => import("./Scan/BoxOpenScan"));
const ItemsScan = lazy(() => import("./Scan/ItemsScan"));
const BoxesInfos = lazy(() => import("./Scan/BoxesInfos"));
const BinsDetails = lazy(() => import("./Scan/BinsDetails"));
const StockMovement = lazy(() => import("./StockMovement/StockMovement"));
const StockMovementBins = lazy(() => import("./StockMovement/StockMovementBins"));
const StockMovementZone = lazy(() => import("./StockMovement/StockMovementZone"));
const ToRh = lazy(() => import("./To/ToRh"));
const ToPicked = lazy(() => import("./To/ToPicked"));
const ToReceive = lazy(() => import("./To/ToReceive"));
const ToReceiveBefore = lazy(() => import("./To/Receive/ToReceiveBefore"));
const ItemDetailsWms = lazy(() => import("./ItemDetailsWms/ItemDetailsWms"));
const PeripheriqueWms = lazy(() => import("./PeripheriqueWms/PeripheriqueWms"));

/**********!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!***************** */
/*     Toutes les routes WMS doivent commencer par wms/:wmsid            */
/*                                                                       */
/**********!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!***************** */
const wmsRoutes = [
  {
    exact:true,
    path: "/wms/:wmsid",
    component: Wms
  },
  {
    exact:true,
    path: "/wms/:wmsid/bins-scan",
    component: BinsDetails
  },
  {
    exact:true,
    path: "/wms/:wmsid/shipment-voucher-receipt",
    component: ShipmentVoucherReceipt
  },
  {
    exact:true,
    path: "/wms/:wmsid/bins-movement",
    component: StockMovement
  },
  {
    exact:true,
    path: "/wms/:wmsid/bins-movement/bins/:binsid",
    component: StockMovementBins
  },
  {
    exact:true,
    path: "/wms/:wmsid/bins-movement/zone/:zoneid",
    component: StockMovementZone
  },
  {
    exact:true,
    path: "/wms/:wmsid/item-details",
    component: ItemDetailsWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/peripheral",
    component: PeripheriqueWms
  },
  {
    exact:true,
    path: "/wms/:wmsid/transfer-orders-collect",
    component: ToRh
  },
  {
    exact:true,
    path: "/wms/:wmsid/transfer-orders-receive",
    component: ToReceive
  },
  {
    exact:true,
    path: "/wms/:wmsid/transfer-order/:toid",
    component: ToPicked
  },
  {
    exact:true,
    path: "/wms/:wmsid/transfer-orders-receive/:toid",
    component: ToReceiveBefore
  },
  {
    exact:true,
    path: "/wms/:wmsid/:shipmentid",
    component: ShipmentVoucherMenu
  },
  {
    exact:true,
    path: "/wms/:wmsid/:shipmentid/boxes-scan",
    component: BoxScan
  },
  {
    exact:true,
    path: "/wms/:wmsid/:shipmentid/items-scan",
    component: BoxOpenScan
  },
  {
    exact:true,
    path: "/wms/:wmsid/:shipmentid/items-scan/:boxnumber",
    component: ItemsScan
  },
  {
    exact:true,
    path: "/wms/:wmsid/:shipmentid/box-informations",
    component: BoxesInfos
  }, 
];
  
  export default wmsRoutes;