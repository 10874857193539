import React, {useState,useContext,useEffect} from "react";
import {CardPerso, CreatePromoForm,ProfilActionButton,EditModalActionButton,Loading,DeleteActionButton,ClearOutButton,ModalAddPromoToItem} from '@gull'
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import Moment from 'react-moment';
import { useParams } from "react-router-dom";
import { allPromotions,promotions2Inputs,promotionsPrefixe } from 'app/allPromotions';
import { Modal,Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const PromotionDetailsTab = (props) => {
    const t =useTranslate()
    const [modalAddPromotion,setModalAddPromotion]=useState(false)

    const deleteArticleLiaison = async (promoId) =>{
        const response = await axios.put(process.env.REACT_APP_API_URL+"api/erp/article/delete_promotion/"+props.articleId,{
            promotion:promoId

        }).then((response) => {
            props.setCurrentPromo(response.data.data.current_promotion)
            props.setIncomingPromo(response.data.data.all_current_and_incoming_promotion)

        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
    }

    let datas = new Array
    Object.keys(props.article).length!=0 && props.incomingPromo.forEach((value,ind)=>{
        let percentPromo = value.type.search('percent')
        let promo
        let listShop = ''

        if(promotions2Inputs.includes(value.type)){
                promo = value.value[0]+' '+t('for')+' '+value.value[1]

        }else if(promotionsPrefixe.includes(value.type)){
            if(percentPromo==0){
                promo = '-'+value.value+'%'
            }else{
                promo = '-'+value.value
            }
        }else{
            promo = value.value
        }
        for(var i=0; i<value.section_code.length; i++){
            if(i==0){
                listShop += value.section_code[i]
            }else{
                listShop += ', '+value.section_code[i]
            }
            
        }

        let obj = {
            name: value.name,
            type: t(value.type),
            promotion: promo,
            begin: value.begin != null ? <Moment format="DD/MM/YYYY">{value.begin}</Moment> : t('undefined'),
            end: value.end != null ? <Moment format="DD/MM/YYYY">{value.end}</Moment> : t('undefined'),
            id: value.id,
            shop: listShop,
            state: (value.status==0) ? t('finished') : (value.status==1) ? t('currentPromo') : t('upcomingPromo'),
            action: 
                <>
                    <ProfilActionButton path={'/erp/promotion/detail/'+value.id} openWindow={true}/>&nbsp;&nbsp;
                    <DeleteActionButton deleteFunctionArgument={value.id} actionType={'typeRemoveX'} nameType={'remove'} toDelete={value.name} customClickEvent={deleteArticleLiaison}/>&nbsp;&nbsp;
                    {/* <EditModalActionButton hisId={value.id} setShowEdit={setShowCreate} setModalEditId={setModalEditId}/>&nbsp;&nbsp;
                    
                    <ClearOutButton title='btnRemoveAllItems' messageConfirm='removeAllArticlesConfirm' customClickEvent={removeAllItems} deleteFunctionArgument={value.id} /> */}
                </>,
            index:ind
        }
        datas.push(obj)
    })

    let paginationOptions = {
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        showTotal: true,
        totalSize: datas.length
    };

    let columns = [
        {
            dataField: "name",
            text: t('tableName'),
            editable: false,
        },
        {
            dataField: "type",
            text: 'Type',
            editable: false,
        },
        {
            dataField: "promotion",
            text: t('promotion'),
            editable: false,
        },
        {
            dataField: "begin",
            text: t('beginPromo'),
            editable: false,
        },
        {
            dataField: "end",
            text: t('endPromo'),
            editable: false,
        },
        {
            dataField: "shop",
            text: t('concernedShop'),
            editable: false,
        },
        {
            dataField: "state",
            text: t('statePromo'),
            editable: false,
        },
        {
            dataField: "action",
            text: 'Actions',
            editable: false,
        },
    ];

    const rowStyle = (row, rowIndex) => { 
        const style = {};

        if (row.state == t('finished')) {
          style.backgroundColor = '#ffffff';

        }else if(row.state == t('currentPromo')){ 
          style.backgroundColor = '#d9fcd2';

        }else{
            style.backgroundColor = '#ffffbb';
        }
       
        return style;
    };
    
    return(<>
        <Modal show={modalAddPromotion} onHide={() => setModalAddPromotion(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('addPromotion')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalAddPromoToItem 
                        articleId={props.articleId}
                        currentPromo={props.currentPromo}
                        setCurrentPromo={props.setCurrentPromo}
                        incomingPromo={props.incomingPromo}
                        setIncomingPromo={props.setIncomingPromo}
                        setModalAddPromotion={setModalAddPromotion}
                    />
                </Modal.Body>
            </Modal>
        
            <div className="row">
                <div className="col">
                    <div className="row">
                        {Object.keys(props.article).length!=0 ?
                            Object.entries(props.currentPromo).map(([shopCode,infoPromo],ind)=>{
                                let promo
                                if(infoPromo!=null){
                                    if(promotions2Inputs.includes(infoPromo.type)){
                                        promo = infoPromo.value[0]+' '+t('for')+' '+infoPromo.value[1]

                                    }else if(promotionsPrefixe.includes(infoPromo.type)){
                                        if(infoPromo.type=='percent'){
                                            promo = '-'+infoPromo.value+'%'
                                        }else{
                                            promo = '-'+infoPromo.value
                                        }
                                    }else{
                                        promo = infoPromo.value
                                    }
                                    return(
                                        <div className="col-3 col-lg-2 text-center mb-3">
                                            <CardPerso key={ind} header={shopCode}>
                                                    <h6 className=" mb-2 text-muted">{infoPromo.name}</h6>
                                                    <div className=" mb-2">{t(infoPromo.type)}</div>
                                                    <div className=" fw-bold">{promo}</div>
                                            </CardPerso>
                                        </div>
                                    )
                                }else{
                                    return(false)
                                    
                                }
                            })
                        :
                            false
                        }
                    </div>
                </div>
            </div>
        <CardPerso header={t('currentAndUpcomingPromo')} saClass='position-relative'>
            <button className="btn btn-success btn-sm newPromoPlusPosition" onClick={()=>setModalAddPromotion(true)}><FontAwesomeIcon icon={faPlus} /></button>
            <small>
                <ToolkitProvider
                keyField="index"
                data={datas}
                columns={columns}
                >
                {(props) => (
                    <>
                        <BootstrapTable
                            {...props.baseProps}
                            bootstrap4
                            pagination={paginationFactory(paginationOptions)}
                            noDataIndication={t('tableNoData')}
                            rowStyle={ rowStyle }
                        />
                    </>
                )}
                </ToolkitProvider>
            </small>
        </CardPerso>
        </>
    )
    
}

export default PromotionDetailsTab;
