import React, { Fragment, useState, useEffect } from "react";
import { PaymentForm, FormatedPrice,SavedTicketModal,ReturnArticleModal,CreditGiftCardModal,SearchSalesOrder, OrderFormNext} from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import SweetAlert from "sweetalert2-react";
import { Modal,DropdownButton,Dropdown,Badge} from "react-bootstrap";
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt} from '@fortawesome/free-solid-svg-icons'
import swal from "sweetalert2";
import { promotions2Inputs,promotionsPrefixe } from 'app/allPromotions';
import {imprimeCaisseIdent} from 'app/imprimeEtiquette'

const useLoad = (idsLocalStorage) => {
  const t =useTranslate()
  const [caisseIdentName,setCaisseIDentName]=useState(false)

  useEffect(function () {
      (async function() {
          const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/pos/get_one_caisse_ident/"+idsLocalStorage[1]
          ).then((response) => {
              setCaisseIDentName(response.data.data.code)
          }).catch((error) => {
            let messageError
            if(error.response==undefined){
              messageError='unknowError'
            }
            else if(error.response.status==403){
              messageError='forbiddenGeneral'
            }
            else {
              messageError='unknowError'
            }
            swal.fire(t(messageError), "", "error");
          })
      })()
  }, [])
  return [caisseIdentName]
}

const FooterPos = (props) =>{
    const t = useTranslate();
    const [caisseIdentName] = useLoad(props.idsLocalStorage)
    const [searchValue,setSearchValue]=useState('')
    const [showAlert,setShowAlert]=useState(false)
    const [priceAlert,setPriceAlert]=useState(t('searchInWait'))
    const [nameArticleAlert,setNameArticleAlert]=useState('')
    const [show,setShow]=useState(false)
    const [isFor,setIsFor]=useState(false)
    const [orderForm,setOrderForm]=useState(false)
    const [ticketCommande,setTicketCommande]=useState(false)
    const [lastSaleOrder,setLastSaleOrder]=useState(false)
    const [loadLastSaleOrder,setLoadLastCaleOrder]=useState(false)
    const [modalTitle,setModalTitle]=useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowAlert(true)
        setPriceAlert(t('searchInWait'))
        setNameArticleAlert('')
        let toSearch= document.getElementById('seePriceItem').value
        setSearchValue('')
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/reference/"+toSearch
        ).then((response) => {
            if(!response.data.valid){
              setPriceAlert(t(response.data.errors.article))

            }else{              
              setPriceAlert(new Intl.NumberFormat('fr-FR').format(response.data.data.article.price)+' XPF')
              if(response.data.data.article.current_promotion!=null && response.data.data.article.current_promotion[props.actualShop.code]){
                let promo = ''
                if(promotions2Inputs.includes(response.data.data.article.current_promotion[props.actualShop.code].type)){
                    promo = response.data.data.article.current_promotion[props.actualShop.code].value[0]+' '+t('for')+' '+response.data.data.article.current_promotion[props.actualShop.code].value[1]
                    
                }else if(promotionsPrefixe.includes(response.data.data.article.current_promotion[props.actualShop.code].type)){
                    if(response.data.data.article.current_promotion[props.actualShop.code].type=='percent'){
                        promo = '-'+response.data.data.article.current_promotion[props.actualShop.code].value+'%'
                    }else{
                        promo = '-'+response.data.data.article.current_promotion[props.actualShop.code].value
                    }
                }else{
                    promo = response.data.data.article.current_promotion[props.actualShop.code].value
                }

                setNameArticleAlert(
                  '<b>'+response.data.data.article.name+'</b><br/><br/>'+
                  '<div>'+t('currentPromo')+' :'+'</div>'+
                  '<div>'+t(response.data.data.article.current_promotion[props.actualShop.code].type)+' '+promo+'</div>'
                )
              }else{
                setNameArticleAlert(response.data.data.article.name)
              }
            }
        }).catch((error) => {
          setPriceAlert(t('unknowError'))
        })
    }

    const getLastSale = async () =>{//l'appel à l'api pour obtenir un ticket sur Pos.jsx sera relancé 
      props.setLoadingLastTicket(true)
      if(props.cookies.last_ticket){
        props.setCookie("current_ticket",props.cookies.last_ticket,{ path: "/", expires: props.expire_date }) 
        props.setIsLastTicket(true)
        props.setRelanceFocus(Date.now())
      }else{
        swal.fire(t('noPreviousSale'), "", "error"
        ).then((result) => {
          if (result.isConfirmed) {
            props.setRelanceFocus(Date.now())
          } else{
            props.setRelanceFocus(Date.now())
          }
        })
        props.setLoadingLastTicket(false)
      }
    }

    const handleChange = (e) => {
        setSearchValue(e.target.value)
    }

    const toggleAlert = () => {
        setShowAlert(false)
        props.setRelanceFocus(Date.now())
    };

    const handleClick = (module) =>{
        setIsFor(module)
        if(module!='caisseIdTag')setShow(true)
        switch (module) {
          case 'pay':
              //ici on affiche le total, on a donc besoin de renseigner
              //le montant total, la raison et la devise 
              props.aures_2_l('',props.totalAchat,'','total',props.devise,t,props.peripheriqueParam)
              setModalTitle(t('btnPay'))
          break;
          case 'lastSalesOrder':
              setModalTitle(t('salesOrder'))
              if(props.cookies.last_sale_order){
                setLoadLastCaleOrder(true)
                const test = axios.get(process.env.REACT_APP_API_URL+"api/pos/commande/get_by_id/"+props.cookies.last_sale_order+'/'+props.posid
                ).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach(interm=>messageError+=t(interm)+'. ')
                        messageError=messageError.replace('undefined','');
                        swal.fire(messageError, "", "error");
                    }else{  
                        if(response.data.data.cancel==1 || response.data.data.ticket.status==1){
                          props.removeCookie('last_sale_order',{ path: "/"})
                          setLastSaleOrder(false)
    
                        }else{
                          setLastSaleOrder(response.data.data)
                        }
                        setLoadLastCaleOrder(false)
                    }
                }).catch((error) => {
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                    setLoadLastCaleOrder(false)
                })
              }
          break;
          case 'saved':
              setModalTitle(t('receiptSaved'))
          break;
          case 'return':
              setModalTitle(t('itemReturn'))
          break;
          case 'giftcardcredit':
              setModalTitle(t('giftCardCredit'))
          break;
          case 'salesOrder':
              setModalTitle(t('salesOrder'))
          break;
          case 'caisseIdTag':
              imprimeCaisseIdent(props.peripheriqueParam,caisseIdentName,'pos',t)
          break;
        }
    }

    const closeModal = (module) =>{
      if(props.currentTicket && props.currentTicket.status==1 && module=='onCloseModal'){
        props.setCashierId(false);
        props.setClientId(false);
        props.setValueInputCashier('')  
        props.setValueInputClient('')
        props.setInvalidInput({
            searchCashier: true,
            searchClient: true
        })
        props.removeCookie('current_ticket',{ path: "/"})
        props.setCurrentTicket({})
      }
      setOrderForm(false)
      setTicketCommande(false)
      props.setRelanceFocus(Date.now())
      setShow(false)
    }

    return(
        <>
        <Modal size="xl" show={show} onHide={() => closeModal('onCloseModal')}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(isFor && isFor =='pay') ?
                  <PaymentForm 
                    posid={props.posid}
                    currentTicket={props.currentTicket}
                    setCurrentTicket={props.setCurrentTicket}
                    totalAchat={props.totalAchat}
                    cookies={props.cookies}
                    clientId={props.clientId}
                    setCookie={props.setCookie}
                    expire_date={props.expire_date}
                    orderForm={orderForm}
                    setOrderForm={setOrderForm}
                    ticketCommande={ticketCommande}
                    setTicketCommande={setTicketCommande}
                    resetSale={props.resetSale}
                    setModalTitle={setModalTitle}
                    setShow={setShow}
                  />
                : 
                  (isFor && isFor =='return') ?
                    <ReturnArticleModal 
                      posid={props.posid}
                      digit={props.digit}
                      devise={props.devise}

                      expire_date={props.expire_date}
                      cookies={props.cookies}
                      setCookie={props.setCookie}
                      setCurrentTicket={props.setCurrentTicket}
                      clientId={props.clientId}
                      cashierId={props.cashierId}
                      aures_2_l={props.aures_2_l}
                      peripheriqueParam={props.peripheriqueParam}
                    />
                  :
                    (isFor && isFor =='giftcardcredit') ?
                      <CreditGiftCardModal 
                        posid={props.posid}
                        digit={props.digit}
                        devise={props.devise}
                      />
                    :
                      (isFor && isFor =='salesOrder') ?
                        <SearchSalesOrder   
                          posid={props.posid}
                          digit={props.digit}
                          devise={props.devise}
                          expire_date={props.expire_date}
                          cookies={props.cookies}
                          setCookie={props.setCookie}
                          removeCookie={props.removeCookie}
                          resetSale={props.resetSale}
                          closeModal={closeModal}
                          setIsSalesOrder={props.setIsSalesOrder}
                          setModalTitle={setModalTitle}
                          setShow={setShow}
                        />
                      :
                        (isFor && isFor=='lastSalesOrder') ?
                          (loadLastSaleOrder) ?
                            <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm mt-2"></div></span>
                          :
                            (lastSaleOrder) ?
                                <OrderFormNext
                                  ticketCommande={lastSaleOrder}
                                  setTicketCommande={setLastSaleOrder}
                                  posid={props.posid}
                                  digit={props.digit}
                                  devise={props.devise}
                                  from='lastSaleOrder'
                                  expire_date={props.expire_date}
                                  cookies={props.cookies}
                                  setCookie={props.setCookie}
                                  removeCookie={props.removeCookie}
                                  resetSale={props.resetSale}
                                  closeModal={closeModal}
                                  setIsSalesOrder={props.setIsSalesOrder}
                                  setModalTitle={setModalTitle}
                                  setShow={setShow}
                                />
                              :
                                false
                        :
                          <SavedTicketModal 
                            cookies={props.cookies}
                            digit={props.digit}
                            devise={props.devise}
                            saveTicket={props.saveTicket}
                            setCookie={props.setCookie}
                            setCurrentTicket={props.setCurrentTicket}
                            posid={props.posid}
                            expire_date={props.expire_date}
                            closeModal={closeModal}
                            removeCookie={props.removeCookie}
                          />
                }
            </Modal.Body>
        </Modal>
        <Fragment>
          <div className="flex-grow-1"></div>
          <div id="footerPos">
            <div className="row">
              <div className="col-3">
                <h2 className="mb-2 totalPos">Total: <strong className='totalPos2'>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.totalAchat} />}</strong></h2>
                <h3 className="mb-0 dontRemise">{t('dontRemise')+':'} <strong className='totalPos2'>{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.totalRemise} />}</strong></h3>
              </div>
              <div className="col-2">
                <p className="mt-0 mb-0"><strong>Nbr {t('items')}: <span>{props.countItems}</span></strong></p>
                <p className="mt-0 mb-0"><strong>Nbr {t('itemReturn')}: <span>{props.countItemsReturn}</span></strong></p>
                <p className="mt-0 mb-0"><strong>Nbr {t('lines')}: <span>{props.countLines}</span></strong></p>
              </div>
              <div className="col-4 btnFooterPos">
                <button disabled={props.disabledPay} className="btn btn-success" onClick={()=>handleClick('pay')}>{t('btnPay')}</button>
                <DropdownButton
                  drop='up'
                  variant="info"
                  title={(props.loadingLastTicket) ? <div className="spinner-border spinner-border-sm"></div> : <FontAwesomeIcon className="fs-5" icon={faCloudUploadAlt} />}
                >
                  <Dropdown.Item eventKey="1" onClick={()=>props.saveTicket()}>{t('btnSave')}</Dropdown.Item>
                  <Dropdown.Item eventKey="2" onClick={()=>handleClick('saved')}>{t('receiptSaved')}</Dropdown.Item>
                  <Dropdown.Item eventKey="3" onClick={()=>getLastSale()}>{t('lastSale')}</Dropdown.Item>
                  <Dropdown.Item eventKey="4" onClick={()=>handleClick('giftcardcredit')}>{t('giftCardCredit')}</Dropdown.Item>
                  <Dropdown.Item eventKey="5" onClick={()=>handleClick('lastSalesOrder')}>{t('lastSalesOrder')}</Dropdown.Item>
                  <Dropdown.Item eventKey="6" onClick={()=>handleClick('salesOrder')}>{t('searchSalesOrder')}</Dropdown.Item>
                  {(caisseIdentName) ? <Dropdown.Item eventKey="7" onClick={()=>handleClick('caisseIdTag')}>{t('printCaisseIdent')}</Dropdown.Item> : false}
                </DropdownButton>
                <button className="btn btn-warning" onClick={()=>handleClick('return')}>{t('btnReturn')}</button>
                <button className="btn btn-danger" onClick={()=>props.resetSale('footer')}>{(props.loadResetTicket) ? <div className="spinner-border spinner-border-sm"></div> : (props.isLastTicket) ? t('btnClose') : t('btnCancel')}</button>
              </div>
              <div className="col-3">
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="form-group">
                    <input id="seePriceItem" type="text" className="form-control" placeholder={t('consultPriceItem')} value={searchValue} onChange={(e) => handleChange(e)} autoComplete="off" />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <SweetAlert show={showAlert} title={priceAlert} html={nameArticleAlert} onConfirm={() => toggleAlert()}/>
        </Fragment>
      </>
    )

}

export default FooterPos;