import React, {useState, useRef} from "react";
import { useTranslate } from 'react-redux-multilingual';
import {  InpDataList,CustomizeInterfaceBtn,FiltresCustomisation} from '@gull';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios'
import swal from "sweetalert2";
import { Modal,Button } from "react-bootstrap";

const EachDataFilter = (props) => {
    const [valueInput,setValueInput] = useState('')
    const inputRef = useRef('')
    const listRef = useRef('')
    const [datas,setDatas] = useState(props.list)
        
    const handleClickFilter = (id,label,nameInput) => {
        let updateFilter = props.sendData[nameInput]
        updateFilter.push({id:id,label:label})
        props.setSendData({...props.sendData,[nameInput]:updateFilter})        
    }

    const handleSearch = (e,declenchement) => {
        setValueInput(e.target.value);
        let ajour = props.list.filter((value)=>value.name.toLowerCase().includes(e.target.value.toLowerCase()))
        setDatas(ajour)
    }

    const handleFocus = () => {
        setDatas(props.list)
        listRef.current.classList.remove("d-none");
    }

    const handleBlur = () => {
        listRef.current.classList.add("d-none")
        setValueInput('')
    }

    return(
        <InpDataList
            placeholder="-- Select --"
            value={valueInput}
            onClick={handleClickFilter}
            onChange={handleSearch}
            datas={datas}
            declenchement={0}
            listRef={listRef}
            inputRef={inputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            inputName={props.inputName}
        />
    )
}

const Inputfilter = (props) => {
    const t = useTranslate();
    const [inputValueBadge, setInputValueBadge] = useState('')

    //Submit de la date
    const handleChangeBadgeDateValue = (e) =>{
        setInputValueBadge(e.target.value)
    }

    const preventDatePickerOnEnter = (e) => {
        if (e.which === 13) { // enter key
            e.preventDefault()

            let updateFilter = props.sendData[props.inputName];
    
            updateFilter.push({id:inputValueBadge,label:inputValueBadge})
            
            props.setSendData({...props.sendData,[props.inputName]:updateFilter})
            setInputValueBadge('')
        }
    }

    //Submit des inputs text 
    const handleChangeBadgeValue = (e) =>{
        setInputValueBadge(e.target.value)
        
    }

    const handleSetBadge = async (e) => {
        e.preventDefault()

        let updateFilter = props.sendData[props.inputName];

        updateFilter.push({id:inputValueBadge,label:inputValueBadge})
        
        props.setSendData({...props.sendData,[props.inputName]:updateFilter})
        setInputValueBadge('')
    }

    let isDate = [
        "commande",
        "expedition",
        "reception"
    ];

    return(
        <div>
            {(isDate.includes(props.inputName)) ?
                <input  type='date' className="form-control" value={inputValueBadge}  name={props.nameInput} onChange={(e)=>handleChangeBadgeDateValue(e)} onKeyDown={(e) => preventDatePickerOnEnter(e)}/>
                :
                <form onSubmit={(e)=>handleSetBadge(e)}>
                    <input  type='text' className="form-control" value={inputValueBadge}  name={props.nameInput} onChange={(e)=>handleChangeBadgeValue(e)} />
                </form>
            }
        </div>
    )
}

const ArticleFilterSearch2 = (props) => {
    const t = useTranslate();
    const [apiError,setApiError] = useState(false);
    const [validButton,setValidButton] = useState(false);

    let fields = props.customFields ? props.customFields.map(value => value.name) : false

    let apiAdress 
    switch(props.moduleName){
        case 'items':
            apiAdress = 'article/list'
            break;
        case 'purchaseOrder':
            apiAdress = 'po/onewithlist/'+props.id
            break;
        case 'shipmentVoucher':
            apiAdress = 'shipment/onewithlist/'+props.id
            break;
        case 'rppsl':
            apiAdress = 'rppsl/get_all'
            break;
    }

    const onSubmit = async (data=false) => {
        setValidButton(true)
        let obj={}
        if(data){
            obj = data
        } 
        else {
            let keys = Object.keys(props.sendData);
            for(var i = 0; i < keys.length; i++){
                if(Array.isArray(props.sendData[keys[i]])){
                    obj[keys[i]]=[]
                    props.sendData[keys[i]].forEach(value=>obj[keys[i]].push(value.id))
                }
                else{
                    obj[keys[i]]=props.sendData[keys[i]]
                }
            }
            props.setFiltre(obj)
        }

        const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/"+apiAdress+"/10/1",obj
        ).then((response) => {
            setValidButton(false)
            switch(props.moduleName){
                case 'items':
                    props.setDataTable(response.data.datas)
                    break;
                case 'purchaseOrder':
                    props.setDataTable(response.data.list)
                    break;
                case 'shipmentVoucher':
                    props.setDataTable(response.data.list)
                    break;
                case 'rppsl':
                    props.setDataTable(response.data.datas)
                    break;
            }
            
            props.setSizeDatas(response.data.count)
            props.setCurrentPage(1)
        }).catch((error) => {
            setValidButton(false)
            swal.fire(t('unknowError'), "", "error");
        })
    }

    if(props.initAllFilter){
        var allFilter = props.initAllFilter;

        let keysToReset = Object.keys(props.sendData).filter(key=> key != Object.keys(allFilter).find(value=>value===key))

        keysToReset.map(value=>
            allFilter[value]=[]
        )
    }
    
    const handleReset = () => {
        props.setSendData(allFilter)
        props.setFiltre({})
        onSubmit({})
    }

    const deleteFilter = (id,nameInput) => {
        props.setSendData({...props.sendData,[nameInput]:props.sendData[nameInput].filter(value=>value.id!=id)})
    }

    let customFilter=false;
    if(props.customInterface.user_conf){
        customFilter = props.customInterface.user_conf
    }
    else if(props.customInterface.default_conf){
        customFilter = props.customInterface.default_conf
    }

    return(<>
        <Modal size="xl" show={props.showModalCustomFiltre} onHide={() => props.setShowModalCustomFiltre(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{t('customizationInterface')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FiltresCustomisation interface={props.interface} fields={fields ? fields.concat(props.hardData) : props.hardData} setReload={props.setReload} setSendData={props.setSendData} setCustomInterface={props.setCustomInterface} moduleName={props.moduleName}/>
            </Modal.Body>
        </Modal>
        <div className="row mb-3">
            <div className="col" style={{fontSize:'1rem'}}>
                <CustomizeInterfaceBtn  setShow={props.setShowModalCustomFiltre}/>
            </div>
        </div>
        <div className="row">
            {(customFilter) &&
                customFilter.details.map((filtreName, index) =>(
                    <div key={index} className="col-lg-3 col-md-4 col-6 mb-3">
                        <div className="form-group">
                            <label className="col-form-label col-form-label-sm">
                                {
                                    (filtreName==='name')? t('itemName') : 
                                    (filtreName==='price')? t('priceTtc') :
                                    (filtreName==='indice')? t('prmpIndice') :
                                    (filtreName==='image')? t('imageLink') :
                                    (filtreName==='quota_commande')? t('quantity') :
                                    (filtreName==='commande')? t('orderDate') :
                                    (filtreName==='expedition')? t('expectedDeliveryDate') :
                                    (filtreName==='reception')? t('expectedReceiptDate') :
                                    (filtreName==='user')? t('receivedBy') :
                                    t(filtreName) ? t(filtreName) :
                                    filtreName
                                }
                            </label>
                            {(filtreName===props.hardData.find(value=>value===filtreName)) ? 
                                <div>                  
                                    <div className="col-12">
                                    {props.sendData[filtreName].map((value,ind)=>
                                        <span key={ind} className="badge badge-primary bg-primary mb-1" style={{cursor:'pointer'}} onClick={() => deleteFilter(value.id,filtreName)}>{value.label} <FontAwesomeIcon icon={faTimesCircle} /></span>
                                    )}
                                    </div>
                                    {(props.liaisons && props.liaisons.length!=0 && filtreName===Object.keys(props.liaisons).find(value=>value===filtreName)) ?
                                        <EachDataFilter 
                                            list={props.liaisons[filtreName]} 
                                            sendData={props.sendData} 
                                            setSendData={props.setSendData} 
                                            inputName={filtreName}
                                        /> 
                                    :
                                        <Inputfilter sendData={props.sendData} setSendData={props.setSendData} inputName={filtreName} customfield="false"/>
                                    }
                                </div>
                            :
                                <div>
                                    <div className="col-12">
                                        {props.sendData[filtreName].map((filtre,filtre_ind)=>
                                            <span key={filtre_ind} className="badge badge-primary bg-primary mb-1" style={{cursor:'pointer'}} onClick={() => deleteFilter(filtre.id,filtreName)}>{filtre.label} <FontAwesomeIcon icon={faTimesCircle} /></span>
                                        )}
                                    </div>
                                    {props.customFields.map((value,index)=>
                                        <div key={index}>
                                        {(value.name === filtreName && !value.free) ?
                                            <EachDataFilter list={value.list.map((val,ind)=>({name:val,id:val}))} sendData={props.sendData} setSendData={props.setSendData} inputName={filtreName} /> 
                                        :
                                        (value.name === filtreName && value.free) && 
                                            <Inputfilter sendData={props.sendData} setSendData={props.setSendData} inputName={filtreName} customfield="true"/>
                                        }
                                        </div>
                                    )}
                                </div>
                            }
                        </div>
                    </div>  
                ))
            }
        </div>
        {
            props.children
        }
        {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
        <button disabled={validButton} className="btn btn-primary mt-3" onClick={() => onSubmit()}>
            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
        </button>
        <button type="button" disabled={validButton} className="btn btn-warning mt-3" style={{marginLeft: '10px'}} onClick={() => handleReset()}>
            {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : 'Reset'}
        </button>
    </>)
};

export default ArticleFilterSearch2;