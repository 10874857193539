import React, { useState,useEffect } from "react";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import LineArticleRetour from './Comp/LineArticleRetour'

const ReturnArticleModal = (props) =>{
    const t = useTranslate();
    const [inputValue,setInputValue]=useState('')
    const [ticketRetourDetail,setTicketRetourDetail]=useState({})
    const [load,setLoad]=useState({
        ticket:false,
    })

    useEffect(function () {
        const input = document.querySelector('#scanRetour');
        input.focus()

    }, [])

    const handleChange = (e) =>{
        setInputValue(e.target.value)
    }

    const handleSubmit = async (e)=>{
        e.preventDefault();
        setLoad({...load,ticket:true})

        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/ticket/get_by_reference_with_section/"+props.posid+'/'+inputValue
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{  
                let obj = {}
                response.data.data.ticket_detail.map(value=>{
                    obj[value.id]=value
                })
                setTicketRetourDetail(obj)
                setLoad({...load,ticket:false})
                
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        setLoad({...load,ticket:false})
    }

    return(
        <>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-8">
                    <form onSubmit={(e)=>handleSubmit(e)}>
                        <input id="scanRetour" value={inputValue} type="text" className="form-control" autoComplete="off" placeholder={t('scanReceiptHere')} onChange={(e)=>handleChange(e)}/>
                    </form>
                </div>
            </div>
            {(load.ticket) ?
                <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm mt-2"></div></span>
            :
                (Object.keys(ticketRetourDetail).length!=0) ?
                    <div className="row d-flex justify-content-center">
                        <div className="col">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">{t('items')}</th>
                                        <th scope="col">{t('discount')}</th>
                                        <th scope="col">{t('qteItems')}</th>
                                        <th className="col text-end">{t('price')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {Object.keys(ticketRetourDetail).length!=0 ?
                                    Object.entries(ticketRetourDetail).map(([lineId, value],ind)=>{
                                        let style = (ind%2 == 0) ? 'stripeBg pointer btnTicket' : 'pointer btnTicket';
                                        if(value.special!='retour' || value.special!='gift_card'){
                                            if(value.quantity>0){
                                                return(
                                                    <LineArticleRetour 
                                                        key={value.id} 
                                                        cookies={props.cookies}
                                                        setCookie={props.setCookie}
                                                        ticketRetourDetail={ticketRetourDetail}
                                                        setTicketRetourDetail={setTicketRetourDetail}
                                                        posid={props.posid}
                                                        setCurrentTicket={props.setCurrentTicket}
                                                        value={value}
                                                        style={style}
                                                        lineId={lineId}
                                                        devise={props.devise}
                                                        digit={props.digit}
                                                        clientId={props.clientId}
                                                        cashierId={props.cashierId}
                                                        aures_2_l={props.aures_2_l}
                                                        peripheriqueParam={props.peripheriqueParam}
                                                    />
                                                )
                                            }
                                        }
                                    })
                                :
                                    <tr>
                                        <td className='border-0'>{t('noItemsToReturn')}</td>
                                    </tr>
                                }
                                </tbody>
                            </table>    
                        </div>
                    </div>  
                :
                    false
            }
        </>
    )
}

export default ReturnArticleModal;