import React,{useState, useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";

const RefundGiftCard = (props) =>{
    const t =useTranslate()
    const [inputVal,setInputVal]=useState('')
    const [load,setLoad]=useState(false)
    const [errorPay,setErrorPay]=useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    useEffect(()=>{//pour le foccus sur l'input scan giftcard
        const input = document.querySelector('#giftCardScan');
        input.focus()
        
    },[props.currentTicket])

    const handleChange = (e) =>{
        setInputVal(e.target.value)
    }

    const handleSubmit = async (e) =>{
        e.preventDefault();

        if(inputVal==''){
            setErrorPay(true)

        }else{
            let data={
                id:props.cookies.current_ticket,
                paiement_mode:'giftcard',
                paiement_montant:0,
                paiement_verse:0,
                paiement_rendu:0,
                gift_card_reference:inputVal,
                gift_card_montant:+props.total,
                gift_card_client_id:props.clientId
            }
            setLoad(true)
            const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0], data
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach(interm=>messageError+=t(interm)+'. ')
                    messageError=messageError.replace('undefined','');
                    swal.fire(messageError, "", "error");
                }
                else{   
                    props.setCurrentTicket(response.data.data)
                    setInputVal('')
                    setLoad(false)
                }
            }).catch((error) => {
                swal.fire(t('unknowError'), "", "error");
            })
            setLoad(false)
        }
        setTimeout(()=>{
            setErrorPay(false)
        },1500)
    }

    return(
        <form onSubmit={(e)=>handleSubmit(e)}>
            <div className="row input-group mb-2">
                <div className="col">
                    <label htmlFor="checknumber">{t('scanGiftCard')}</label>
                    <input id='giftCardScan' className='form-control'  value={inputVal} type="text" onChange={(e)=>handleChange(e)} />
                </div>
            </div>
            <div className="row input-group mb-2">
                <div className="col">
                    <label htmlFor="amount">{t('amount')}</label>
                    <input disabled className='form-control'  value={props.total} type="text" />
                </div>
            </div>
            {(errorPay) ? <div className="alert alert-danger mb-3">{t('allFieldsRequired')}</div> : false}
            <button disabled={load} className="btn btn-primary mt-1">
                {load ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('add')}
            </button>
        </form>
    )
}

export default RefundGiftCard;