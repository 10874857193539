import React, {useState, useEffect,useRef} from 'react';
import {InpDataList} from "@gull";
import { useForm } from "react-hook-form";
import { useTranslate } from 'react-redux-multilingual';
import swal from "sweetalert2";
import axios from 'axios';

const useLoad = (articleid) => {
    const t = useTranslate();
    const [apiError,setApiError]=useState(false)
    const [shopWithItems,setShopWithItems]=useState({})

    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/article/large_stock/"+articleid
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)

                }else{
                    setShopWithItems(response.data.by_section)
                }
            }).catch((error) => {
                swal.fire(t('unknowError'), "", "error");
            })
        })()
    }, [])

    return[shopWithItems,apiError]
}

const EachDataFilter = (props) => {
    const [valueInput,setValueInput] = useState('')
    const inputRef = useRef('')
    const listRef = useRef('')
    const [datas,setDatas] = useState(props.binsList)

    const handleSearch = (e,declenchement) => {
        setValueInput(e.target.value);
        if(e.target.value.length>=declenchement){
            listRef.current.classList.remove("d-none");
            let ajour = props.binsList.filter((value)=>value.toLowerCase().includes(e.target.value.toLowerCase()))
            setDatas(ajour)
        }
        else{
            listRef.current.classList.add("d-none");
        }
    }
    
    const handleClickFilter = (label) => {
        setValueInput(label)
        props.setField({
            ...props.field,
            bins:label,
            quantite:true
        })
        listRef.current.classList.add("d-none");
        setDatas([])     
    }

    return(
        <InpDataList
            placeholder="-- Select --"
            value={valueInput}
            onClick={handleClickFilter}
            onChange={handleSearch}
            datas={datas}
            declenchement={1}
            listRef={listRef}
            inputRef={inputRef}
            from='reorganizationStock'
        />
    )
}

const AdjustmentStockModal = (props) => {
    const t = useTranslate();
    const [shopWithItems,apiError]=useLoad(props.articleid)
    const { register, formState: { errors }, handleSubmit, setValue, reset} = useForm();
    const [validButton,setValidButton] = useState(false)
    const [error,setError] = useState(false)
    const [success,setSuccess] = useState(false)

    const [qteToMove,setQteToMove]=useState('')

    const [field,setField]=useState({
        action:false,
        section:false,
        zone:false,
        bins:false,
        quantite:false,
        memo:false,
        button:true
    })
    const [loading,setLoading]=useState({
        zone:false,
        bins:false
    })
    const [data,setData]=useState({
        zone:{},
        zoneContainArticle:{},
        bins:{},
        binsAll:[]
    })

    let shopsList = Object.entries(shopWithItems).filter(([shopName,value])=>value.total!=0) //on récupère uniquement les sections ayant l'article en magasin

    //on récupère tous les ids des zones de stock qui ont des bins 
    let hasBins = []
    if(Object.keys(data.zone).length!=0){
        let arr = Object.entries(data.zone).filter(([key,value])=>value.havebins==1)
        for(let i=0; i<arr.length; i++){
            hasBins.push(arr[i][1].zoneid)
        }
    }    

    const handleChange = (e,name) =>{
        switch (name) {
            case 'action':
                if(props.from=='pos'){ 
                    //si on effectue cette action depuis le pos, le champ section se remplie automatique avec le nom de notre commerce
                    //afin d'effectuer un ajustement uniquement sur notre commerce
                    setValue('section', props.posid)
                    setField({
                        ...field,
                        action:e.target.value, //dès que l'action est renseignée on affiche le champ section
                        section:props.posid,//dès que la section est renseignée on affiche le champ zone
                        zone:false, //si on revient sur le choix de l'action, tout le formulaire est remis à zéro
                        bins:false,
                        quantite:false
                    })
                    getData("api/erp/article/stock_in_section/"+props.articleid+'/'+props.posid,'zone') //on récupère tout les zones de stockages de la section 
                }else{
                    setField({ 
                        ...field,
                        action:e.target.value, //dès que l'action est renseignée on affiche le champ section
                        section:false,
                        zone:false, //si on revient sur le choix de l'action, tout le formulaire est remis à zéro
                        bins:false,
                        quantite:false
                    })
                    setValue('section','')
                }
                
                setValue('zone','')
                setValue('bins','')
                setValue('quantite','')
            break;
            case 'section':
                setField({
                    ...field,
                    section:e.target.value,//dès que la section est renseignée on affiche le champ zone
                    zone:false,
                    bins:false,
                    quantite:false
                })
                setValue('zone','')
                setValue('bins','')
                setValue('quantite','')
                getData("api/erp/article/stock_in_section/"+props.articleid+'/'+e.target.value,'zone') //on récupère tout les zones de stockages de la section 
            break;
            case 'zone':  
                if(hasBins.includes(+e.target.value)){
                    setField({
                        ...field,
                        zone:e.target.value,
                        quantite:false
                    })//si la zone contient des bins on affichera le champ bins 
                    if(field.action=='add'){
                        getData("api/erp/bins/getall/"+e.target.value,'bins') //si l'action ajouter est choisie, on affiche toutes les bins de la zone de stockage
                    }else{
                        getData("api/erp/article/stock_in_zone/"+props.articleid+'/'+e.target.value,'bins')//sinon si l'action retirer est choisie, on affiche uniquement les bins qui contien l'article 
                    }
                }else{
                    setField({
                        ...field,
                        zone:false,
                        quantite:true
                    }) //si il n'y a pas de bins, on affiche le champ quantite 
                    setData({
                        ...data,
                        bins:{},
                        binsAll:[]
                    })
                }
                setValue('bins','')
                setQteToMove('') 
                setValue('quantite','')
            break;
            case 'bins':
                setField({
                    ...field,
                    bins:e.target.value,
                    quantite:true
                })
            break;
            case 'quantite':
                setQteToMove(e.target.value=e.target.value.replace(/\D/g,''))
                setField({
                    ...field,
                    memo:true
                })
            break;
            case 'memo':
                if(e.target.value.length>0){
                    setField({...field,button:false})//dès que le memo est renseignée, le button valider est activé 
                }else{
                    setField({...field,button:true})//sinon il est désactivé 
                }
            break;
        }
    }

    const getData = async (apiPath,name) =>{
        switch (name) {
            case 'zone':
                setLoading({...loading,zone:true})
                break;
            case 'bins':
                setLoading({...loading,bins:true})
                break;
            }
        const response = await axios.get(process.env.REACT_APP_API_URL+apiPath
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach((interm)=>{
                    let finalMessage=interm.split('/!');
                    if(finalMessage.length==2){
                        messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                    }
                    else{
                        messageError+=t(interm)+'<br/>'
                    }
                })
                messageError=messageError.replace('undefined','');
                setError(messageError)
            }else{
                switch (name) {
                    case 'zone':
                        let zoneWithArticle = Object.entries(response.data.by_zone).filter(([zoneName,value])=>value.total!=0)
                        let obj={}
                        for(let i=0; i<zoneWithArticle.length; i++){
                            obj[zoneWithArticle[i][0]]=zoneWithArticle[i][1]
                        }
                        setData({
                            ...data,
                            zone:response.data.by_zone,
                            zoneContainArticle:obj
                        })                        
                        setLoading({...loading,zone:false})
                        break;
                    case 'bins':
                        if(field.action=='add'){
                            setData({...data,binsAll:response.data.datas})
                        }else{
                            setData({...data,bins:response.data.by_bins})
                        } 
                        setLoading({...loading,bins:false})                       
                        break;
                    }
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        switch (name) {
            case 'zone':
                setLoading({...loading,zone:false})
                break;
            case 'bins':
                setLoading({...loading,bins:false})
                break;
            }
    }

    const onSubmit = async (form) => {
        setValidButton(true)
        setError(false)
        setSuccess(false)

        let obj={
            action:form.action,
            section:form.section,
            zone:form.zone,
            quantite:form.quantite,
            memo:(form.memo!='') ? form.memo : null
        }
        if(Object.keys(data.bins).length!=0 || Object.keys(data.binsAll).length!=0){
            obj['bins']=field.bins
        }

        const callapi  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/article/stock_ajust/"+props.articleid, obj)
            .then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    setError(messageError)
                }
                else{
                    setSuccess(true)
                    props.setReadjustReload(Date.now())
                    setField({ 
                        ...field,
                        action:false,
                        section:false,
                        zone:false,
                        bins:false,
                        quantite:false,
                        memo:false,
                        button:true
                    })
                    setValue('action','')
                    setValue('section','')
                    setValue('zone','')
                    setValue('bins','')
                    setValue('quantite','')
                    setValue('memo','')
                }
            }).catch((error) => {
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            }
        )
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false);
        },1000)
    }

    return(
        <form>
            <div className="form-group">
                <label htmlFor="choose an action">{t('chooseAnAction')+'* :'}</label>
                <select  className="form-control" {...register('action', {onChange : (e)=>handleChange(e,'action')})} >
                    <option value="">-- Select --</option>
                    <option value="add">{t('addToStock')}</option>
                    <option value="remove">{t('removeFromStock')}</option>
                </select>
            </div>
            {(field.action && field.action!='') ?
                <div className="form-group">
                    <label htmlFor="select a shop">{t('selectSection')+'* :'}</label>
                    <select disabled={props.from=='pos'} className="form-control" {...register('section', {onChange : (e)=>handleChange(e,'section')})} >
                        <option value="">-- Select --</option>
                        {(field.action == 'remove') ? 
                            shopsList.map((value)=>(
                                <option key={value[1].sectionid} value={value[1].sectionid}>{value[0]}</option>
                            ))
                        :
                            Object.entries(shopWithItems).map(([shopName,value])=>(
                                <option key={value.sectionid} value={value.sectionid}>{shopName}</option>
                            ))
                        }
                    </select>
                </div>
            :
                false
            }
            {(field.section && field.section!='') ?
                <div className="form-group">
                    <label htmlFor="select a storage destination">{t('selectStorageDestination')+'* :'}</label>
                    {(loading.zone) ?
                            <div className="col-12 text-center"><div className="spinner-border spinner-border-sm"></div></div>
                        :
                            <select  className="form-control" {...register('zone', {onChange : (e)=>handleChange(e,'zone')})} >
                                <option value="">-- Select --</option>
                                {(field.action == 'add') ? 
                                    Object.entries(data.zone).map(([zoneName,value])=>(
                                        <option key={value.zoneid} value={value.zoneid}>{zoneName}</option>
                                    ))
                                :
                                    Object.entries(data.zoneContainArticle).map(([zoneName,value])=>(
                                        <option key={value.zoneid} value={value.zoneid}>{zoneName}</option>
                                        
                                    ))
                                }
                            </select>
                    }
                </div>
            :
                false
            }
            {(field.zone && field.zone!='') ?
                <div className="form-group">
                    <label htmlFor="bins number">{t('binsNumber')+'* :'}</label>
                    {(loading.bins) ?
                            <div className="col-12 text-center"><div className="spinner-border spinner-border-sm"></div></div>
                        :
                            (field.action == 'add') ?
                                <EachDataFilter binsList={data.binsAll} field={field} setField={setField} />
                            :
                                <select  className="form-control" {...register('bins', {onChange : (e)=>handleChange(e,'bins')})} >
                                    <option value="">-- Select --</option>
                                    {Object.entries(data.bins).map(([binsNum,qteArticle])=>(
                                        <option key={binsNum} value={binsNum}>{binsNum}</option>
                                    ))}
                                </select>}
                </div>
            :
                false
            }
            {(field.quantite) ?
                <div className="form-group">
                    <label htmlFor="quantity to move">{t('quantity')+'* :'}</label>
                    <input autoComplete='off' className="form-control" value={qteToMove} type="text" {...register('quantite', {onChange : (e)=>handleChange(e,'quantite')})} />
                </div>
            :
                false
            }
            {(field.memo) ?
                <div className="form-group">
                    <label htmlFor="quantity to move">Memo* :</label>
                    <textarea className="form-control" cols="30" rows="5" {...register('memo', {onChange : (e)=>handleChange(e,'memo')})}></textarea>
                </div>                
            :
                false
            }
            {(apiError || error) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError || error }}></div> : false}
            {(success) ? <div className="alert alert-success mt-3">{t('successOperation')}</div> : false}
            <button 
                disabled={field.button} 
                className="btn btn-primary mt-3"
                onClick={handleSubmit(onSubmit)}
            >
                {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
            </button>
        </form>
    )
}

export default AdjustmentStockModal;
