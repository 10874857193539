import React, {useState,useRef,useEffect} from 'react';
import axios from "axios";
import { useTranslate } from 'react-redux-multilingual'
import swal from "sweetalert2";

const Rp = (props) =>{
    const t = useTranslate();
    const [rpValue,setRpValue]=useState(0)
    const [editRp,setEditRp]=useState(false)
    const [loadSubmit,setLoadSubmit]=useState(false)

    useEffect(()=>{
        if(props.rpFocus){
            if(props.rpRef.current){
                setTimeout(()=>{
                    props.rpRef.current.focus()
                    props.rpRef.current.select()
                },400)
            }
        }
    },[props.rpFocus,props.refresh])

    const handleDbleClick = (value) =>{
        if(props.pslFocus){ //si l'input de modification du psl est actif on bloque l'action

        }else if(!props.rpFocus){//sinon on affiche l'input de modification du rp
            setEditRp(true) //on affiche l'input pour l'edit
            setRpValue(value) //on initialise la valeur à éditer 
            props.setRpFocus(true) //on met le focus sur le champ
            props.setRefresh(Date.now())
        }
    }

    const handleChangeRp = (e) => {
        setRpValue(e.target.value);        
    }

    const handleBlur = (e) =>{
        updateRpValue(e)
    }

    const keyUp = (e) =>{
        updateRpValue(e)
    }

    const updateRpValue= async(e)=>{
        if(!e.key || e.key === 'Enter' ){
            if(rpValue !== '' && rpValue !== NaN && rpValue !== null){
                setLoadSubmit(true)
                const response  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/rppsl/edit/"+props.value.id,{
                    rp: rpValue,
                    psl: props.value.psl
                }).then((response) => {
                    if(!response.data.valid){
                        let interms='';
                        var keys = Object.keys(response.data.errors);
                        for(var i = 0; i < keys.length; i++){
                            interms+=response.data.errors[keys[i]].join(',')+','
                        }
                        interms=interms.split(',')
                        let messageError='';
                        interms.forEach((interm)=>{
                            let finalMessage=interm.split('/!');
                            if(finalMessage.length==2){
                                messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                            }
                            else{
                                messageError+=t(interm)+'<br/>'
                            }
                        })
                            messageError=messageError.replace('undefined','');
                            swal.fire(messageError, "", "error");
                    }else{
                        setLoadSubmit(false)
                        let rppslId = props.rppsl.map(value=>value.id)
                        let indexToEdit = rppslId.indexOf(response.data.data.id)

                        let newArr = props.rppsl.filter(value=>value.id!=response.data.data.id)
                        newArr.splice(indexToEdit,0,response.data.data)

                        props.setRppsl(newArr) 
                    }
                }).catch((error) => {
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                })
            }
            setEditRp(false)
            props.setRpFocus(false)
        }
    }

    return(
        (editRp) ? 
            <td className='d-flex align-items-center'>
                <input 
                    ref={props.rpRef}
                    className=" col-12 text-center fs-6 form-control" 
                    type="number" 
                    value={rpValue} 
                    onChange={(e)=>handleChangeRp(e)} 
                    onKeyUp={(e)=>keyUp(e)} 
                    onBlur={(e)=>handleBlur(e)}
                />
                {(loadSubmit) && <span><span className="spinner-border spinner-border-sm"></span></span>}
            </td>
        :
            <td className='text-center pointer' onDoubleClick={()=>handleDbleClick(props.value.rp)}>{props.value.rp}</td>
    )
}

export default Rp;