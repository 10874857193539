import React from "react";

const InpDataList = (props) => {
    let delay = (props.delay) ? props.delay : false
    return (<>
        <input 
            ref={props.inputRef}
            type="text"
            className={(props.className) ? props.className : "form-control"}
            placeholder={(props.placeholder) && props.placeholder}
            value={(props.value) ? props.value : ''}
            onChange={(e) => props.onChange(e,props.declenchement,delay)}
            onBlur={(props.onBlur) ? () => props.onBlur(props.inputName) : () => {return false}}
            onFocus={(props.onFocus) ? () => props.onFocus(props.inputName) : () => {return false} }
            onKeyPress={(props.onKeyPress) ? (e) => props.onKeyPress(e) : () => {return false} }
        />
        <div ref={props.listRef}  className="list-group d-none dataListPerso z-index10000">
            {props.datas.map((value,ind)=>
                (props.from=='reorganizationStock') ?
                    <li onMouseDown={()=> props.onClick(value)} className="list-group-item list-group-item-action pointer" key={ind}>
                        {value}
                    </li>
                :
                    <li onMouseDown={()=> props.onClick(value.id,value.name,props.inputName,value.reference)} className="list-group-item list-group-item-action pointer" key={ind}>
                        {value.name}
                    </li>
            )}
        </div>
    </>)
};

export default InpDataList;