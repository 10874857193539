import React, {useState,useEffect,useContext} from "react";
import { IsAuthContext } from 'app/App';
import { SubmitForm } from "@gull";
import { useTranslate } from 'react-redux-multilingual';
import { useForm } from "react-hook-form";
import axios from 'axios'
import { CSVReader } from 'react-papaparse';

const useLoadCustomField = (id) => {
    const [loading,setLoading]=useState(true)
    const [error,setError]=useState(false)
    const [customField,setCustomField]=useState(false)
    const [showCsv,setShowCsv] = useState(false)
    useEffect(function () {
        (async function() {
            const response = await axios.get(process.env.REACT_APP_API_URL+"api/custom_fields/"+id
            ).then((response) => {
                setLoading(false)
                setCustomField(response.data)
                setShowCsv(!response.data.free)
            }).catch((error) => {
                setLoading(false)
                setError(true)
            })
        })()
    }, [])
    return [loading,error,customField,showCsv,setShowCsv]
}

const  EditCustomField = (props) => {
    const t = useTranslate();
    const [loading,error,customField,showCsv,setShowCsv] = useLoadCustomField(props.id)
    const { register, formState: { errors }, handleSubmit, setValue, reset } = useForm();
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    const [success,setSuccess] = useState(false)
    const [csvData,setCsvData] = useState(false)
    const [resetCsv,setResetCsv] = useState(false)
    const {authParam} = useContext(IsAuthContext);

    const onSubmit = async (data) => {
        setValidButton(true)
        setApiError(false)
        setSuccess(false)
        let send = {
            form: data.form,
            name: data.name,
            required: data.required,
            free: data.free
        };
        if(csvData){
            send['list']=csvData;
        }

        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/custom_fields/"+props.id, send
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                setApiError(messageError)
            }
            else{
                props.setCustomFields(response.data.datas)
                setResetCsv(true)
                setSuccess(true)
            }
        }).catch((error) => {
            if(error.response==undefined){
                setApiError('unknowError')
            }
            else if(error.response.status==403){
                setApiError('forbiddenGeneral')
            }
            else {
                setApiError('unknowError')
            }
        })
        setValidButton(false)
        setTimeout(()=>{
            setSuccess(false);
            setResetCsv(false)
            setCsvData(false)
        },1000)
    }

    const handleOnDrop = (data) => {
        let recupData = data.map(value=>value.data[0])
        setCsvData(recupData);
    };

    const handleOnError = (err, file, inputElem, reason) => {
        setCsvData(false)
    };
    
    const handleOnRemoveFile = (data) => {
        setCsvData(false)
    };

    const handleChangeFree = () => {
        if(showCsv){
            setCsvData(false)
        }
        setShowCsv(!showCsv)
    }

    if(loading){
        return (<div className="col-12 text-center"><div className="spinner-border spinner-border-sm"></div></div>)
    }
    if(error){
        return (<div className="alert alert-danger">{t('unknowError')}</div>)
    }
    if(customField){
        return(
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <input type="hidden" defaultValue="fournisseur"  {...register('form')}/>
                    <label htmlFor="nomcategorie">{t('fieldName')}</label>
                    <input defaultValue={customField.name} className="form-control" type="text" {...register('name', {required: true})}/>
                    {errors.name?.type === 'required' && (
                    <div className="text-danger mt-1 ml-2">
                        {t('categoryRequired')}
                    </div>
                    )}
                </div>
                <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={customField.required} className="form-check-input"  {...register('required')}/>
                    <label title="" className="form-check-label">{t('requiredField')}</label>
                </div>
                <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={customField.free} className="form-check-input"  {...register('free', {onChange:() => handleChangeFree()})} />
                    <label title="" className="form-check-label">{t('freeField')}</label>
                </div>
                {(showCsv) &&
                    <div className="form-group mt-3">
                        <label>{t('dataListpossible')}</label>
                        <CSVReader
                        onDrop={handleOnDrop}
                        isReset={resetCsv}
                        onError={handleOnError}
                        onRemoveFile={handleOnRemoveFile}
                        addRemoveButton
                        config={{
                            delimiter:authParam.company.advanceparams.csv_separator,
                            encoding:authParam.company.advanceparams.csv_encode
                        }}
                        >
                            <span>{t('dropCsv')}</span>
                        </CSVReader>
                    </div>
                }
                <SubmitForm error={apiError} success={success} validButton={validButton} />
            </form>
        )
    }

    return (false)
}

export default EditCustomField;