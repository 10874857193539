import React from 'react';
import { useTranslate } from 'react-redux-multilingual'
import { HeadInfos, EditModalActionButton, CustomizeInterfaceBtn,TagArticleBtn} from "@gull";

const Header = (props) =>{
    const t = useTranslate();

    return(
        <div className='row'>
            <div className='col-8'>
                <HeadInfos titre={props.article.name} titre2={'SKU : '+props.article.sku} titre3={props.article.parent_name!= null ? t('parent_name')+' '+':'+' '+props.article.parent_name : false} />
            </div>
            <div className='col-4'>
                <div className='row'>
                    <div className='col-4'>
                        <label className='fs-6 text fw-bold' htmlFor="internalid">{t('internalId')+' :'}</label><br/>
                        <span className='fs-6 text'>{props.article.internalid}</span>
                    </div>
                    <div className='col-4'>
                        <label className='fs-6 text fw-bold' htmlFor="codeupc">{t('reference')+' :'}</label><br/>
                        <span className='fs-6 text'>{props.article.reference}</span>
                    </div>
                    <div className='col-4'>
                        <label className='fs-6 text fw-bold' htmlFor="codeupc2">{t('reference2')+' :'}</label><br/>
                        <span className='fs-6 text'>{(props.article.reference2 && props.article.reference2!==null) ? props.article.reference2 : <div className='text-center'>--</div>}</span>
                    </div>
                </div>
                <div className="row mt-2">
                    <span>
                        <EditModalActionButton setShowEdit={props.setShowEdit} setModalEditId={props.setModalEditId} hisId={props.id}/>
                        <>&nbsp;&nbsp;</>
                        <CustomizeInterfaceBtn setShow={props.setShow} />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Header;