import React, { useState} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { FormatedPrice, SelectSeller} from "@gull";
import { Modal,Badge} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentAlt} from '@fortawesome/free-solid-svg-icons'
import { promotions2Inputs,promotionsPrefixe } from 'app/allPromotions';

const DetailItems = (props) => {
    const t = useTranslate()
    const [showNote,setShowNote]=useState(false)

    const getNote = (detailNote) =>{
        setShowNote(detailNote)
    }

    let remise = (props.value.remise == 0) ? false : <>- <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.value.remise} /></>

    let promoTitle = (props.value.promotion!=null) ? t(props.value.promotion.type) : (props.value.details!= null && props.value.details.type=='percent') ? t('manualDiscountPercent') : t('manualDiscountNet')
    
    let promo = ''
    if(props.value.promotion!=null){
        if(promotions2Inputs.includes(props.value.promotion.type)){
            promo = ' / '+props.value.promotion.value[0]+' '+t('for')+' '+props.value.promotion.value[1]
            
        }else if(promotionsPrefixe.includes(props.value.promotion.type)){
            if(props.value.promotion.type=='percent'){
                promo = ' / '+'-'+props.value.promotion.value+'%'
            }else{
                promo = ' / '+'-'+props.value.promotion.value
            }
        }else{
            promo = props.value.promotion.value
        }
        
    }else if(props.value.details!=null){
        promo = ' / '+props.value.details.reason
    }

    return(
        <div>
            <Modal size="md" show={showNote} onHide={() => setShowNote(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Note</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {showNote}
                    </div>
                </Modal.Body>
            </Modal>
            <div className={props.style}>
                <div className="d-flex justify-content-between">
                    <div>{'x'+props.value.quantity}</div> 
                    <div className="text-center pErpArticle text-muted">{(props.value.article!=null) ? props.value.article.name : false}</div> 
                    <div><FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.value.prix_remise} /></div>
                </div>
                <div className="d-flex justify-content-between">
                    <span className="d-flex align-items-center">
                        {(!remise) ?
                            false
                        :  
                            <>
                                <Badge bg="warning" text="dark">{promoTitle+promo+' / '}<span>{remise}</span></Badge>&nbsp;&nbsp;
                            </>
                        }
                        {(props.value.note!=null) ?
                            <>
                                <FontAwesomeIcon icon={faCommentAlt} className='pointer fs-5 text-primary' onClick={()=>getNote(props.value.note)} />
                            </>
                        :   
                            false
                        }
                    </span>
                    {(props.from=='salesOrder') ?
                        <span className="mx-1 px-1">
                            <input type="text" className="form-control" disabled value={(props.value.vendeur!=null) ? props.value.vendeur.name+' '+props.value.vendeur.firstname : false} />
                        </span>
                    :
                        <span className="mx-1 px-1">
                            <SelectSeller 
                                section={props.section}
                                posid={props.posid}
                                item={props.value}
                                lineId={props.value.id}
                                caisseIdent={props.caisseIdent}
                                pos_id={props.pos_id}
                                ticketId={props.ticektId}
                                setTicketDetail={props.setTicketDetail}
                                from='erp'

                                //ces deux props sont passées uniquement depuis l'edit du salesOrder depuis l'erp
                                module={props.module}
                                commandeDetail={props.commandeDetail}
                            />
                        </span>
                    }
                </div>
            </div>
        </div>
    )
}

export default DetailItems;