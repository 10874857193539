import React, {useState} from 'react';
import axios from "axios";
import { useTranslate } from 'react-redux-multilingual'
import { CardPerso, RedirectSwitchBtn, DescriptionRecords} from "@gull";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import swal from "sweetalert2";
import Moment from 'react-moment';


const LocationHtml = ({zone,bins}) => {
    return(<>
        {zone.section.name}<br/>{zone.name+bins}
    </>)
}

const RecordTable = (props) => {
    const t = useTranslate();
    const [perPage,setPerPage] = useState('10')
    const [currentPage,setCurrentPage] = useState(1)

    let type = {
        po: t('purchaseOrder'),
        shipment: t('shipmentVoucher'),
        entre: t('entreStockRecords'),
        simple: t('stockDeplacement'),
        cancelEntre: t('cancellationStockEntry'),
        ajust: t('stockAdjustment'),
        to: t('transferOrder'),
        ticket: t('ticket'),
        commande: t('salesOrder')
    }

    let articleRecordsList = new Array

    props.articleRecord.map((value,ind)=>{
        let bins = (value.bins!==null) ? (value.bins.fifth) ? ' - '+value.bins.first+'-'+value.bins.second+'-'+value.bins.third+'-'+value.bins.fourth+'-'+value.bins.fifth : (value.bins.fourth) ? ' - '+value.bins.first+'-'+value.bins.second+'-'+value.bins.third+'-'+value.bins.fourth : (value.bins.third) ? ' - '+value.bins.first+'-'+value.bins.second+'-'+value.bins.third : (value.bins.second) ? ' - '+value.bins.first+'-'+value.bins.second : value.bins.first : '';
        let obj = {
            id: value.id,
            quota: value.quota,
            type: type[value.type],
            description: <DescriptionRecords value={value}/>,
            created_at: <Moment format="DD/MM/YYYY HH:mm:ss">{value.created_at}</Moment>,
            create_by : value.user.name,
            redirige: <RedirectSwitchBtn urlDetails={value.redirige} />,
            location: (value.zone!==null) ? <LocationHtml zone={value.zone} bins={bins}/> : '',
            index: ind + 1,  
        }
        articleRecordsList.push(obj)
    })

    let columns = [
        {
            dataField: "quota",
            text: t('quantity'),
            editable: false,
            sort: false
        },
        {
            dataField: "type",
            text: 'Type',
            editable: false,
            sort: false
        },
        {
            dataField: "description",
            text: 'Description',
            editable: false,
            sort: false
        },
        {
            dataField: "location",
            text: t('location'),
            editable: false,
            sort: false
        },
        {
            dataField: "created_at",
            text: t('registrationDate'),
            editable: false,
            sort: false
        },
        {
            dataField: "create_by",
            text: t('createBy'),
            editable: false,
            sort: false
        },
        {
            dataField: "redirige",
            text: "Actions",
            editable: false,
            sort: false
        },
    ];

    let paginationOptions = {
        page:currentPage,
        paginationSize: 5,
        pageStartIndex: 1,
        firstPageText: t('tableFirst'),
        prePageText: t('tableBack'),
        nextPageText: t('tableNext'),
        lastPageText: t('tableLast'),
        nextPageTitle: t('tableNextTitle'),
        prePageTitle: t('tableBackTitle'),
        firstPageTitle: t('tableFistTitle'),
        lastPageTitle: t('tableLastTitle'),
        showTotal: true,
        totalSize: props.sizeDatas,
    };

    const handleTableChange = async (type, { page, sizePerPage }) => {
        setPerPage(sizePerPage)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/erp/articlerecords/"+props.id+"/"+sizePerPage+"/"+page
            ).then((response) => {
                props.setArticleRecord(response.data.datas)
                props.setSizeDatas(response.data.count)
                setCurrentPage(page)
            }).catch((error) => {
                swal.fire(t('unknowError'), "", "error");
            })
    }

    return(
        <div>
            <CardPerso header={t('relatedRecord')}>
                    <small>
                        <ToolkitProvider
                        keyField="index"
                        data={articleRecordsList}
                        columns={columns}
                        >
                        {(propst) => (
                            <>
                                <BootstrapTable
                                {...propst.baseProps}
                                bootstrap4
                                remote
                                pagination={ paginationFactory(paginationOptions) }
                                onTableChange={ handleTableChange }
                                noDataIndication={t('tableNoData')}
                                />
                            </>
                        )}
                        </ToolkitProvider>
                    </small>
                </CardPerso>
        </div>
    )
}

export default RecordTable;