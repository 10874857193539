import React from "react";
import { useTranslate } from 'react-redux-multilingual';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const  EditModalActionButton = (props) => {
    const t = useTranslate();

    const handleClick = (id) => {
        if(props.setModalEditId){
            props.setModalEditId(id)
        }
        props.setShowEdit(true)
    }

    return (
        <OverlayTrigger
            placement="bottom"
            overlay={
                <Tooltip id={`tooltip-${props.index}`}>
                    {t('edit')}
                </Tooltip>
            }
        >
            <span className="text-primary" onClick={() => handleClick(props.hisId)} style={{cursor:'Pointer'}}><FontAwesomeIcon icon={faEdit} /></span>
        </OverlayTrigger>  
    )
}

export default EditModalActionButton;