import React,{useState} from "react";
import { useTranslate } from 'react-redux-multilingual';
// Style import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import swal from "sweetalert2";
import axios from 'axios'

const  ExportArticleButton = (props) => {
    const t = useTranslate();
    const [exportCsvLoading,setExportCsvLoading] = useState(false)
    let sizeDatas = (props.sizeDatas) ? props.sizeDatas : 0
    const goExportCsv = async () => {
        if(sizeDatas>process.env.REACT_APP_CSV_EXPORT_LIMIT){
            return(
                swal.fire({
                    icon: 'error',
                    title: t('impossibleExport'),
                    text: t('maxLineAuth',{limit:process.env.REACT_APP_CSV_EXPORT_LIMIT,actual:sizeDatas}),
                  })
               ) 
        }
        else{
            setExportCsvLoading(true)
            let theLang = (props.lang=='en') ? 'en' : 'fr'
            let obj = props.filtre
            obj.lang=theLang
            obj.header=props.header
            const response = await axios.post(process.env.REACT_APP_API_URL+props.url,obj
                ).then((response) => {
                    setExportCsvLoading(false)
                    if(!response.data.valid){
                        return(
                            swal.fire({
                                icon: 'error',
                                title: t('impossibleExport'),
                                text: t('maxLineAuth',{limit:process.env.REACT_APP_CSV_EXPORT_LIMIT,actual:response.data.size}),
                            })
                        ) 
                    }
                    else{
                        return(
                            swal.fire({
                                icon: 'success',
                                title: t('csvWillBeReady')
                            })
                        ) 
                    }
                }).catch((error) => {
                    setExportCsvLoading(false)
                    return(
                        swal.fire({
                            icon: 'error',
                            title: t('unknowError')
                        })
                    ) 
                })
        }
    }

    return (<>
        {(props.type=='button') ?
            <button disabled={exportCsvLoading} className="btn btn-dark btn-sm mb-2" onClick={() => goExportCsv()}>
                {exportCsvLoading ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : props.title}
            </button>
            :
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id={`tooltip-${props.index}`}>
                        {props.title}
                    </Tooltip>
                }
            >
                <span className="text-success" style={{cursor:'Pointer'}} onClick={() => goExportCsv()}>{exportCsvLoading ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : <FontAwesomeIcon icon={faFileCsv} />}</span>
            </OverlayTrigger> 
        }
    </>)
}

export default ExportArticleButton;