import React from "react";

const FormatedPrice = (props)=>{

    /* 
        langRegion représente le code langue et le code pays

        Ex : 'en-CA' 
              en = English (language code)
              CA = Canada (country code)
        
        Ex : 'fr-FR' 
              fr = French (language code)
              FR = France (country code)
    */

    const formatedPrice = new Intl.NumberFormat(props.langRegion, {
        style: 'currency',
        currency: (props.devise && props.devise!==null) ? props.devise : 'XPF',
        minimumFractionDigits: props.digit
    });

    return(
        <>
            <span className={props.style}>{(props.devise) ? formatedPrice.format(props.priceToFormat) : 0}</span>
        </>
    )
}

export default FormatedPrice;