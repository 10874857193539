import React,{useState,useContext,useRef,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import {FormatedPrice} from "@gull"
import { Card } from "react-bootstrap";
import LinePaymentRecord from './PaymentRightComp/LinePaymentRecord'
import swal from "sweetalert2";
import axios from 'axios'
import { PeripheriqueContext } from '../../../../../app/App';
import { epson_tm_h6000V,epson_tm_h6000VOrder} from 'app/imprime';
import { openTiroir } from "app/ouvertureTiroir";
import Ticket from "../../../../../app/views/Pdf/Ticket"
import { BlobProvider } from '@react-pdf/renderer';
import { conditionsRetour } from 'app/conditionsRetour';
import { bbgShops } from 'app/bbgShops';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const PaymentFormRight =  (props) =>{
    const t =useTranslate()
    const btnPdf = useRef('')
    const [loading,setLoading]=useState({
        giftprint:false,
        pay:false,
        print:false,
        payprint:false,
        giftprintpdf:false,
        printpdf:false,
        payprintpdf:false,
        commande:false,
    })
    const idsLocalStorage=localStorage.getItem("pos").split(',')
    const { peripheriqueParam } = useContext(PeripheriqueContext)  

    const [ticketPdf,setTicketPdf]=useState({})
    const [totalAchat,setTotalAchat]=useState(0)
    const [totalTgc,setTotalTgc]=useState(false)
    const [giftPdf,setGiftPdf]=useState(false)
    const [showOrderBtn,setShowOrderBtn]=useState(true)

    let currentShop = bbgShops.find(value=>value.id==props.currentTicket.section_id)

    useEffect(function () {
        //on n'affiche pas le bouton commande client si il y a des articles retourné ou une gift card sur le ticket
        Object.keys(props.currentTicket).length!=0 && props.currentTicket.ticket_detail.find(value=>{
            if(value.special=='return' || value.special=='gift_card'){
                setShowOrderBtn(false)
            }
        })
    }, [])

    const finishPayment = async (name) =>{
        setLoading({...loading,[name]:true})
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            id:props.cookies.current_ticket,
            close:true
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                setLoading({...loading,[name]:false})

                props.setCookie("last_ticket",props.cookies.current_ticket,{ path: "/", expires: props.expire_date }) 

                if(name=='payprint'){
                    epson_tm_h6000V('print',props.currentTicket.section_id,response.data.data,t,props.devise,peripheriqueParam)

                }else if(name=='pay'){
                    openTiroir(peripheriqueParam,t)

                }else if(name=='payprintpdf'){
                    getPdfTicket(response.data.data.bar,'printpdf')
                    
                }
                props.setBtnDisabled(false)
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading({...loading,[name]:false})
        props.setBtnDisabled(false)
    }

    const getPdfTicket = async (ticketBar,stylePdf) =>{
        setLoading({...loading,[stylePdf]:true})
        if(stylePdf=='giftprintpdf'){ 
            setGiftPdf(true)
        }else{
            setGiftPdf(false)
        }
        const response = await axios.get(process.env.REACT_APP_API_URL+"api/pos/ticket/get_by_reference/"+ticketBar
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{ 
                setTicketPdf(response.data.data)

                var total = 0
                let totaltgc = {
                    0:0,
                    3:0,
                    6:0,
                    11:0,
                    22:0
                }
                response.data.data.ticket_detail.map(item=>{
                    total+=item.prix_remise
                    if(item.article_retour!=null){
                        totaltgc={...totaltgc,[item.article_retour.tgc]:totaltgc[item.article_retour.tgc]+=item.prix_remise}
                    }
                    if(item.article!=null){
                        totaltgc={...totaltgc,[item.article.tgc]:totaltgc[item.article.tgc]+=item.prix_remise}
                    }
                })
                setTotalAchat(total)
                setTotalTgc(totaltgc)

                setTimeout(()=>{
                    btnPdf.current.click()
                },1000)
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading({...loading,[stylePdf]:false})
    }

    const addToOrder = async(ticketid,sectionid) => {
        setLoading({...loading,commande:true});
        const response  = await axios.put(process.env.REACT_APP_API_URL+"api/pos/commande/add_more_article/"+ticketid+"/"+sectionid
        ).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{
                setLoading({...loading,commande:false});
                props.setTicketCommande(response.data.data)
                props.setCookie("last_sale_order",response.data.data.id,{ path: "/", expires: props.expire_date }) 
                props.resetSale('salesOrder')
                epson_tm_h6000VOrder('print',response.data.data.ticket.section_id,response.data.data,t,props.devise,peripheriqueParam)
                props.setBtnDisabled(false)
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        props.setBtnDisabled(false)
    }

    const handleClick = (name) =>{
        switch (name) {
            case 'giftprint':
                epson_tm_h6000V('giftprint',props.currentTicket.section_id,props.currentTicket,t,props.devise,peripheriqueParam)
            break;
            case 'print':
                epson_tm_h6000V('print',props.currentTicket.section_id,props.currentTicket,t,props.devise,peripheriqueParam)
            break;
            case 'giftprintpdf':
                getPdfTicket(props.currentTicket.bar,'giftprintpdf',)
            break;
            case 'printpdf':
                getPdfTicket(props.currentTicket.bar,'printpdf')
            break;
            case 'pay':
                finishPayment('pay');
                props.setBtnDisabled(true)
            break;
            case 'payprint':
                finishPayment('payprint');
                props.setBtnDisabled(true)
            break;
            case 'payprintpdf':
                finishPayment('payprintpdf');
                props.setBtnDisabled(true)
            break;
            case 'order':
                props.setOrderForm(true);
                props.setModalTitle(t('salesOrder'))
            break;
            case 'addToOrder':
                addToOrder(props.currentTicket.id,props.posid)
                props.setBtnDisabled(true)
            break;
        }
    }

    let tgc3 = +totalTgc[3] - (Math.round(+totalTgc[3] / 1.03))
    let tgc6 = +totalTgc[6]- (Math.round(+totalTgc[6] / 1.06))
    let tgc11 = +totalTgc[11] - (Math.round(+totalTgc[11] / 1.11))
    let tgc22 = +totalTgc[22] - (Math.round(+totalTgc[22] / 1.22))
    let tgcTotal = tgc3 + tgc6 + tgc11 + tgc22

    return(
        <>
            <BlobProvider 
                document={(Object.keys(ticketPdf).length!=0 && tgcTotal!=NaN && currentShop) ?
                    <Ticket 
                        currentShop={currentShop}
                        ticketPdf={ticketPdf}
                        t={t}
                        devise={props.devise}
                        digit={props.digit}
                        totalAchat={totalAchat}
                        tgc3={tgc3}
                        tgc6={tgc6}
                        tgc11={tgc11}
                        tgc22={tgc22}
                        tgcTotal={tgcTotal}
                        conditionsRetour={conditionsRetour}
                        giftPdf={giftPdf}
                    />
                : 
                    false
                }
            >
                {({ url }) => (
                    <a ref={btnPdf} className="d-none" href={url} target="_blank">Print</a>
                )}
            </BlobProvider>
            <Card id={props.id}>
                <Card.Header className="text-end">
                    {<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.total} />}
                </Card.Header>
                <Card.Body className='scrollPayRight'>
                    <strong className="textTotalPosition">Total :</strong>

                    <div className="bodyPaymentDue">
                        {Object.keys(props.currentTicket).length!= 0 &&
                            props.currentTicket.paiement_records.length!=0 ?
                                props.currentTicket.paiement_records.map((value,ind)=>{
                                    let style = (ind%2 == 0) ? 'row stripeBg stripePadding position-relative' : 'row stripePadding position-relative';
                                    return(
                                        <LinePaymentRecord 
                                            key={value.id}
                                            style={style}
                                            value={value}
                                            devise={props.devise}
                                            digit={props.digit}
                                            setCurrentTicket={props.setCurrentTicket}
                                            cookies={props.cookies}
                                            posid={props.posid}
                                        />
                                    )
                                })
                            :
                                false
                        }
                    </div>

                    <strong className="textDuePosition">{t('due')}</strong>
                </Card.Body>
                <Card.Footer className="text-end">
                    <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.valueDue} />
                </Card.Footer>
            </Card>
            <div className="row">
                {(props.currentTicket.commande!=null) ? 
                    <span className="mt-3">{t('salesOrder')+' : '}<strong>{props.currentTicket.commande.bar}</strong></span>
                : 
                    false
                }
                <div className="col text-end mt-4">
                    {(props.currentTicket.status==1) ? 
                        <>
                            <button className="btn btn-sm btn-warning" onClick={()=>handleClick('giftprint')}>{(loading.giftprint) ? <div className="spinner-border spinner-border-sm"></div> : t('btnGiftPrint')}</button>&nbsp;&nbsp;
                            <button className="btn btn-sm btn-warning" onClick={()=>handleClick('giftprintpdf')}>{(loading.giftprintpdf) ? <div className="spinner-border spinner-border-sm"></div> : <FontAwesomeIcon icon={faFilePdf} className='fs-5' />}</button>&nbsp;&nbsp;

                            <button className="btn btn-sm btn-primary" onClick={()=>handleClick('print')}>{(loading.print) ? <div className="spinner-border spinner-border-sm"></div> : t('ticket')}</button>&nbsp;&nbsp;
                            <button className="btn btn-sm btn-primary" onClick={()=>handleClick('printpdf')}>{(loading.printpdf) ? <div className="spinner-border spinner-border-sm"></div> : <FontAwesomeIcon icon={faFilePdf} className='fs-5' />}</button>&nbsp;&nbsp;
                        </>
                    :
                        <>
                            <button disabled={props.btnDisabled} className="btn btn-sm btn-success" onClick={()=>handleClick('pay')}>{(loading.pay) ? <div className="spinner-border spinner-border-sm"></div> : t('btnPay')}</button>&nbsp;&nbsp;
                            {(peripheriqueParam && peripheriqueParam.imprimante_ticket ) ? 
                                <>
                                    <button disabled={props.btnDisabled} className="btn btn-sm btn-info" onClick={()=>handleClick('payprint')}>{(loading.payprint) ? <div className="spinner-border spinner-border-sm"></div> : t('btnPayPrint')}</button>&nbsp;&nbsp;
                                </>
                            : 
                                <>
                                    <button disabled={props.btnDisabled} className="btn btn-sm btn-info" onClick={()=>handleClick('payprintpdf')}>{(loading.payprintpdf) ? <div className="spinner-border spinner-border-sm"></div> : t('btnPayPrint')}&nbsp;<FontAwesomeIcon icon={faFilePdf} className='fs-5' /></button>&nbsp;&nbsp;
                                </>
                            }
                            {(showOrderBtn && props.currentTicket.section_id==props.posid) ? 
                                <button disabled={props.btnDisabled || loading.commande} className="btn btn-sm btn-dark" onClick={()=>handleClick((props.currentTicket.commande!=null) ? 'addToOrder' : 'order')}>{(props.currentTicket.commande==null) ? t('salesOrder') : (loading.commande) ? <div className="spinner-border spinner-border-sm"></div> : t('addToOrder')}</button>
                            :
                                false
                            }
                        </>
                    }
                </div>
            </div>
            
        </>
    )
}

export default PaymentFormRight;