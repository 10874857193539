import React, { useState, useEffect } from "react";
import { useTranslate } from 'react-redux-multilingual'
import axios from 'axios'
import swal from "sweetalert2";
import { promotionsDescription,promotions2Inputs,promotionsPrefixe } from 'app/allPromotions';
import { useForm } from "react-hook-form";
import CheckBoxShop from './Comp/CheckBoxShop'

const useLoad = (modalEditId,setValue,setShowInputPromo,promoProfilId)=>{
    const t = useTranslate();
    const [loading,setLoading]=useState(false)
    const [promoOne,setPromoOne]=useState({})
    const [disabled,setDisabled]=useState(false)

    //En cas d'édition, on récupère les infos de la promo concerné pour les attribuer aux champs 
    useEffect(function () {
        (async function() {
            if(modalEditId || promoProfilId){
                let idPromo = (modalEditId) ? modalEditId : promoProfilId
                setLoading(true)
                const response = await axios.get(process.env.REACT_APP_API_URL+"api/erp/promotion/"+idPromo
                ).then((response) => {
                    setPromoOne(response.data.data)
                    setShowInputPromo(response.data.data.type)
                    setValue('name',response.data.data.name)
                    setValue('type',response.data.data.type)

                    if(promotions2Inputs.includes(response.data.data.type)){
                        setValue('value.0',response.data.data.value[0])
                        setValue('value.1',response.data.data.value[1])

                    }else{
                        setValue('value',response.data.data.value[0])
                    }
                    if(response.data.data.begin!=null){
                        let beginFormated = response.data.data.begin.split(/[-\s]+/)
                        setValue('begin',beginFormated[0]+'-'+beginFormated[1]+'-'+beginFormated[2])

                    }
                    if(response.data.data.end!=null){
                        let endFormated = response.data.data.end.split(/[-\s]+/)
                        setValue('end',endFormated[0]+'-'+endFormated[1]+'-'+endFormated[2])

                    }

                    setDisabled(true)
                    setLoading(false)
                }).catch((error) => {
                    let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                    swal.fire(t(errorName), "", "error");
                    setLoading(false)
                })
            }
        })()
    }, [])
    return [loading,promoOne,disabled,setDisabled]
}


const CreatePromoForm = (props) =>{
    const t = useTranslate();
    const { register, formState: { errors }, handleSubmit, setValue} = useForm();
    const [showInputPromo, setShowInputPromo]=useState(false)
    const [loading,promoOne,disabled,setDisabled]=useLoad(props.modalEditId,setValue,setShowInputPromo,props.promoProfilId)
    const [validButton,setValidButton] = useState(false)
    const [apiError,setApiError] = useState(false)
    
    let prefixePercent = showInputPromo ? showInputPromo.search('percent') : false

    let addOn = (prefixePercent == 0) ? '%' : '-'

    let placeHolder2 = (showInputPromo == 'xpoury') ? t('qteItems') : t('discountPrice');

    const handleChange = (e) =>{
        setShowInputPromo(e.target.value)

    }

    let editPromo = props.from=='profilPromo' ? props.promos.id : props.promos.find(value=>value.id==props.modalEditId)

    if(props.modalEditId){
        //On détermine l'index à éditer sur la modal edit 
        let refPromo = props.promos.map(value=>value.id)
        var indexToEdit = refPromo.indexOf(editPromo.id)
    }

    const onSubmit = async (data) => {
        let idPromo = (props.modalEditId) ? props.modalEditId : props.promoProfilId
        setValidButton(true)
        setApiError(false)

        let obj={
            begin: data.begin,
            end: data.end,
            name: data.name,
            section: (data.section==false) ? [] : data.section,
            type: data.type,
            value: data.value
        }
        if(props.modalEditId || props.promoProfilId){//Edition d'une promotion
            const response  = await axios.put(process.env.REACT_APP_API_URL+"api/erp/promotion/edit/"+idPromo,obj
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                    messageError=messageError.replace('undefined','');
                    setApiError(messageError)
                }
                else{
                    props.closeModal()
                    if(props.from=='profilPromo'){
                        props.setPromos(response.data.data)
                    }else{
                        let newData = props.promos.filter(value=>value.id!=props.modalEditId)
                        newData.splice(indexToEdit,0, response.data.data)
                        props.setPromos(newData)
                    }
                }
            }).catch((error) => {
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })

        }else{//Création d'une promotion
            const response  = await axios.post(process.env.REACT_APP_API_URL+"api/erp/promotion/create",data
            ).then((response) => {
                if(!response.data.valid){
                    let interms='';
                    var keys = Object.keys(response.data.errors);
                    for(var i = 0; i < keys.length; i++){
                        interms+=response.data.errors[keys[i]].join(',')+','
                    }
                    interms=interms.split(',')
                    let messageError='';
                    interms.forEach((interm)=>{
                        let finalMessage=interm.split('/!');
                        if(finalMessage.length==2){
                            messageError+=t(finalMessage[0],{name:finalMessage[1]})+'<br/>'
                        }
                        else{
                            messageError+=t(interm)+'<br/>'
                        }
                    })
                        messageError=messageError.replace('undefined','');
                        setApiError(messageError)
                }
                else{
                    props.closeModal()
                    if(props.currentPromo == showInputPromo){
                        props.setPromos([response.data.data,...props.promos])
                    }
                }
            }).catch((error) => {
                let errorName = (error.response==undefined) ? 'unknowError' : (error.response.status==403) ? 'forbiddenGeneral' : 'unknowError'
                swal.fire(t(errorName), "", "error");
            })
        }
        setValidButton(false)
        setDisabled(false)
    }

    return(
        <>
            {loading ? 
                <span className="d-flex justify-content-center"><div className="spinner-border spinner-border-sm"></div></span>
            :
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="nomcategorie">{t('labelName')+' :'}</label>
                        <input id="promotion name"  className="form-control" type="text" {...register('name', {required: true})}/>
                        {errors.name?.type === 'required' && (  
                        <div className="text-danger mt-1 ml-2">
                            {t('nameRequired')}
                        </div>
                        )}
                    </div>
                    <div className="form-group row">
                        <label htmlFor="email">{t('shops')}</label>
                        {(Object.keys(props.company).length!=0 && props.company.section.length!=0) && props.company.section.map((section)=>
                            <div key={section.id} className="col-3">
                                <CheckBoxShop section={section} register={register} promoOne={promoOne} modalEditId={props.modalEditId} />
                            </div>
                        )}
                    </div>
                    <div className="form-group">
                        <label htmlFor="type de promotion">Type :</label>
                        <select  disabled={disabled} className="form-control" type="text"  {...register('type', {required: true, onChange: (e) => handleChange(e)})}>
                            <option value="">-- Select --</option>
                            {
                                props.allPromotions.map((value,ind)=>{
                                    return(
                                        <option key={ind} value={value}>{t(value)}</option>
                                    )
                                })
                            }                               
                        </select>
                        {errors.type?.type === 'required' && (
                        <div className="text-danger mt-1 ml-2">
                            {t('typeRequired')}
                        </div>
                        )}
                    </div>
                    {(!showInputPromo) ?
                        false
                    :
                        <div className="alert alert-warning mt-3" role="alert">
                            {t(promotionsDescription[showInputPromo])}
                        </div> 
                    }
                    {(!showInputPromo) ?
                        false
                    :
                        <>
                            {(promotions2Inputs.includes(showInputPromo)) ?
                                <>
                                    <label htmlFor="description de la promotion">{t('promotion')+' :'}</label>
                                    <input  placeholder={t('qteItems')} className='form-control mb-2'  type="text" {...register('value.0', {required: true})} />
                                    <span className="mb-2 d-flex justify-content-center">{t('for')}</span>
                                    <input  placeholder={placeHolder2} className='form-control mb-2'  type="text" {...register('value.1', {required: true})} />
                                    {errors.value?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('fieldRequired')}
                                    </div>
                                    )}
                                    <div className="form-group">
                                        <label htmlFor="date de transfert">{t('beginPromo')+' :'}</label>
                                        <input  type='date' className="form-control" {...register('begin')}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="date de transfert">{t('endPromo')+' :'}</label>
                                        <input  type='date' className="form-control" {...register('end')}/>
                                    </div>
                                </>
                            :
                                <>
                                    <label htmlFor="description de la promotion">{t('promotion')+' :'}</label>
                                    <div className="input-group">
                                        {(promotionsPrefixe.includes(showInputPromo)) ?
                                            <div className="input-group-prepend">
                                                <div className="input-group-text rounded-0" >{addOn}</div>
                                            </div>
                                        :
                                            false
                                        }
                                        <input  className='form-control mb-2' type="text" aria-describedby="basic-addon1" {...register('value', {required: true})} />
                                        
                                    </div>
                                    {errors.value?.type === 'required' && (
                                    <div className="text-danger mt-1 ml-2">
                                        {t('fieldRequired')}
                                    </div>
                                    )}
                                    <div className="form-group">
                                        <label htmlFor="date de transfert">{t('beginPromo')+' :'}</label>
                                        <input  type='date' className="form-control" {...register('begin')}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="date de transfert">{t('endPromo')+' :'}</label>
                                        <input  type='date' className="form-control" {...register('end')}/>
                                    </div>
                                </>
                            }
                            {(apiError) ? <div className="alert alert-danger mt-3" dangerouslySetInnerHTML={{ __html: apiError }}></div> : false}
                        </>
                    }  
                    <button disabled={validButton} className="btn btn-primary mt-3">
                        {validButton ? <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span> : t('btnValidate')}
                    </button>
                </form>
            }
        </>
    )
}

export default CreatePromoForm;