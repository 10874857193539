import React,{useState,useEffect,useRef} from "react";
import { FormatedPrice,SelectSeller} from "@gull"
import { useTranslate } from 'react-redux-multilingual'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus,faMinus,faTimesCircle,faEdit, faCommentAlt} from '@fortawesome/free-solid-svg-icons'
import { Badge } from "react-bootstrap";
import EditPriceModal from "./Component/EditPriceModal"
import NoteModal from "./Component/NoteModal"
import axios from 'axios'
import swal from "sweetalert2";
import { promotions2Inputs,promotionsPrefixe } from 'app/allPromotions';

const LineItemPos = (props) =>{
    const t =useTranslate()
    const [qte,setQte]=useState(props.item.quantity)
    const [showEditPrice,setShowEditPrice]=useState(false)
    const [showNote,setShowNote]=useState(false)
    const [loading,setLoading]=useState({
        delete:false,
        total:false
    })
    const [timer,setTimer]=useState({});
    const [currentTimer,setCurrentTimer]=useState(false);
    const [disabled,setDisabled]=useState(false)
    const idsLocalStorage=localStorage.getItem("pos").split(',')

    const qteRef = useRef('')

    useEffect(function () {
        setQte(props.item.quantity)

    }, [props.currentTicket])
    
    let remise = (props.item.remise == 0) ? false : <>- <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.item.remise} /></>

    let promoTitle = (props.item.promotion!=null) ? t(props.item.promotion.type) : (props.item.details!= null && props.item.details.type=='percent') ? t('manualDiscountPercent') : t('manualDiscountNet')

    let promo = ''

    if(props.item.promotion!=null){
        if(promotions2Inputs.includes(props.item.promotion.type)){
            promo = ' / '+props.item.promotion.value[0]+' '+t('for')+' '+props.item.promotion.value[1]

        }else if(promotionsPrefixe.includes(props.item.promotion.type)){
            if(props.item.promotion.type=='percent'){
                promo = ' / '+'-'+props.item.promotion.value+'%'
            }else{
                promo = ' / '+'-'+props.item.promotion.value
            }
        }else{
            promo = props.item.promotion.value
        }

    }

    const changeQte = (e) =>{
        setQte(+e.target.value.replace(/\D/g,''))
        props.setDisabledPay(true)
    }

    function updateQteTimer () {

        if(currentTimer!=false){
            clearTimeout(timer[currentTimer]) 
        }

        setCurrentTimer(Date.now());

        let tempTimer = setTimeout(() => {
            submit()
        }, 1000)
        setTimer({...timer,[Date.now()]:tempTimer})        
    }

    const submit = async () =>{
        setLoading({...loading,total:true})
        setDisabled(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            quantity: qteRef.current.value,
            id:props.cookies.current_ticket,
            detail_id:props.item.id
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                setLoading({...loading,total:false})
                setDisabled(false)
                
                let articleAfficheur = response.data.data.ticket_detail.find(value=>value.article_id == props.item.article_id)
                props.aures_2_l(articleAfficheur.article.name,articleAfficheur.prix_remise,articleAfficheur.quantity,0,props.devise,t,props.peripheriqueParam)
                //le 0 sur la variable 'raison' est passé pour définir que les infos ne concerne pas le total
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        setLoading({...loading,total:false})
        props.setRelanceFocus(Date.now())
    } 

    const handleBlur = () =>{
        submit()
    }

    const handleKeyPress = (e) =>{
        if(e.key === 'Enter'){
            qteRef.current.blur()
        }
    }

    const changeQteBtn = (name) =>{
        if(name == 'plus'){
            setQte(qte+1)
            qteRef.current.value=qteRef.current.value+1

            updateQteTimer()
        }else{
            if(qteRef.current.value > 1){
                setQte(qte-1)
                qteRef.current.value=qteRef.current.value-1
            }
            
            updateQteTimer()
        }
        props.setDisabledPay(true)
    }

    const showModal = (name) =>{
        if(name == 'price'){
            setShowEditPrice(true)
        }else{
            setShowNote(true)
        }
    }

    const closeModal = (name) =>{
        if(name == 'price'){
            setShowEditPrice(false)
        }else{
            setShowNote(false)
        }
        props.setRelanceFocus(Date.now())
    }

    const removeArticle = async () =>{
        setLoading({...loading,delete:true})
        props.setDisabledPay(true)
        const response = await axios.post(process.env.REACT_APP_API_URL+"api/pos/ticket/construction/"+props.posid+"/"+idsLocalStorage[1]+"/"+idsLocalStorage[0],{
            delete_detail_id: props.item.id,
            id:props.cookies.current_ticket
        }).then((response) => {
            if(!response.data.valid){
                let interms='';
                var keys = Object.keys(response.data.errors);
                for(var i = 0; i < keys.length; i++){
                    interms+=response.data.errors[keys[i]].join(',')+','
                }
                interms=interms.split(',')
                let messageError='';
                interms.forEach(interm=>messageError+=t(interm)+'. ')
                messageError=messageError.replace('undefined','');
                swal.fire(messageError, "", "error");
            }
            else{   
                props.setCurrentTicket(response.data.data)
                setLoading({...loading,delete:false})
            }
        }).catch((error) => {
            swal.fire(t('unknowError'), "", "error");
        })
        props.setRelanceFocus(Date.now())
    }

    return(
        <>
            <NoteModal 
                showNote={showNote}
                closeModal={closeModal}
                posid={props.posid}
                cookies={props.cookies}
                currentTicket={props.currentTicket}
                setCurrentTicket={props.setCurrentTicket}
                lineId={props.lineId}
                item={props.item}
            />
            <EditPriceModal 
                showEditPrice={showEditPrice}
                closeModal={closeModal}
                cookies={props.cookies}
                item={props.item}
                setCurrentTicket={props.setCurrentTicket}
                setDisabledPay={props.setDisabledPay}
            />
            <div className={props.style}>
                <div className="row">
                    <strong className="fsArticlePos">{props.item.article.name}</strong> 
                </div>
                <div className="row marginLeftArticle">
                    <div className="col">
                        <div className="row">
                            <div className="col-3 m-0 p-0 input-group-sm z-index1000">
                                <input ref={qteRef} disabled={disabled} className='form-control' value={qte} type="text" onChange={(e)=>changeQte(e)} onBlur={()=>handleBlur()} onKeyPress={(e)=>handleKeyPress(e)}/>
                            </div>
                            <div className="col-9 m-0 p-0 text-center zIndexBtnNotePos">
                                <button disabled={disabled} className="btn btn-success btn-sm sizeBtnPos" onClick={()=>changeQteBtn('plus')}><FontAwesomeIcon icon={faPlus} /></button>&nbsp;
                                <button disabled={disabled} className="btn btn-danger btn-sm sizeBtnPos" onClick={()=>changeQteBtn('minus')}><FontAwesomeIcon icon={faMinus} /></button>&nbsp;
                                <button disabled={disabled} className="btn btn-primary btn-sm sizeBtnPos" onClick={()=>showModal('price')}><FontAwesomeIcon icon={faEdit} /></button>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="col-1 m-0 p-0 zIndexBtnNotePos">
                                <button className="btn btn-primary btn-sm sizeBtnPos" onClick={()=>showModal('note')}><FontAwesomeIcon icon={faCommentAlt} /></button>
                            </div>
                            <div className="col-11 m-0 p-0 text-end priceArticlePos unitPricePosition">{<FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.item.article.price} />}</div>
                        </div>
                    </div>
                    <div className="col m-0 p-0">
                        <SelectSeller 
                            section={props.section} 
                            cookies={props.cookies}
                            item={props.item}
                            lineId={props.item.id}
                            posid={props.posid}
                            currentTicket={props.currentTicket}
                            setCurrentTicket={props.setCurrentTicket} 
                            setRelanceFocus={props.setRelanceFocus}
                            from='pos'
                        />
                    </div>
                    <div className="col">
                        <div className="text-end m-0 p-0 priceArticlePos totalPricePosition">
                            {(loading.total) ?
                                <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span>
                            :
                                <FormatedPrice langRegion={'fr-FR'} devise={props.devise} digit={props.digit} priceToFormat={props.item.prix_remise} />
                            }
                        </div>
                        <span className="m-0 p-0 crossPosition">
                            {(loading.delete) ?
                                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm"></div></span>
                                :
                                    <FontAwesomeIcon icon={faTimesCircle}  className='pointer' onClick={()=>removeArticle()}/>
                            }
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {(!remise) ?
                            false
                        :
                            <Badge bg="warning" text="dark">{promoTitle+promo+' / '}<span>{remise}</span></Badge>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default LineItemPos;