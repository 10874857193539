import React from "react";
import { useTranslate } from 'react-redux-multilingual'

const Raccourci = (props) => {
    const t =useTranslate()

    const handleClick = (title) => {
        if(title=='giftcard'){
            props.raccourciAction(title,false,false)
        }else if(title=='article'){
            props.raccourciAction(title,props.raccourciTypeId,false)
        }else{
            props.raccourciAction(title,false,props.raccourciTypeId)
        }
    } 

    let raccourciTitle = (props.title.length>35) ? props.title.toUpperCase().substr(0, 35)+'...' : props.title.toUpperCase()
    let raccourciColor = (props.raccourciType=='giftcard') ? "col-12 bg-primary rounded raccourciPos text-white" : (props.raccourciType=='article') ? "col-12 bg-success rounded raccourciPos text-white" : "col-12 bg-info rounded raccourciPos text-white"

    return (
        <div className="col-4 position-relative" style={{padding:"1px"}}>
            <div className={raccourciColor} onClick={() => handleClick(props.raccourciType)}>
                <div className="textAlign">
                    {raccourciTitle}
                </div>
            </div>
            {(props.disabled) ?
                <div className="disabled rounded"></div>
            :
                false
            }
        </div>
    )
}

export default Raccourci;  