import React from "react";
import { Redirect } from "react-router-dom";
import sessionsRoutes from "./views/sessions/sessionsRoutes";
import welcomeRoutes from "./views/welcome/welcomeRoutes";
import administrationRoutes from "./views/administration/administrationRoutes";
import usersRoutes from "./views/users/usersRoutes";
import posBaseRoutes from "./views/pos/posBaseRoutes";
import wmsRoutes from "./views/wms/wmsRoutes";
import erpRoutes from "./views/erp/erpRoutes";
import historiqueRoutes from "./views/historique/historiqueRoutes";
import Guard from "./auth/Guard";

const redirectRoute = [
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/welcome/welcome" />
  }
];

const errorRoute = [
  {
    component: () => <Redirect to="/session/404" />
  }
];

const routes = [
  ...sessionsRoutes,
  {
    path: "/",
    component: Guard,
    routes: [
      ...welcomeRoutes,
      ...administrationRoutes,
      ...erpRoutes,
      ...usersRoutes,
      ...posBaseRoutes,
      ...wmsRoutes,
      ...historiqueRoutes,
      ...redirectRoute,
      ...errorRoute
    ]
  }
];

export default routes;
