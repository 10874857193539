import { modePaiementMustBeTranslate } from './modePaiementDefaultAll';
import { espace_mille } from './espace_mille';
import {bbgShops} from './bbgShops'
import swal from "sweetalert2";
import { conditionsRetour } from 'app/conditionsRetour';

export const epson_tm_h6000V = async (printMode,posid,currentTicket,t,devise,peripheriqueParam,from) =>{
    let paramArticle = ['name']
    let paramShop = ['name','commerce_zone','adresse','code_postal','tel','horaires','site','code','ridet']
    let paramInfoVente = ['created_at','bar','rh','client']
    let paramTaxes = ['ttc']

    let currentShop = bbgShops.find(value=>value.id==posid)
    let ticketDate = new Date(currentTicket.created_at)

    let formatedParamArticle = []
    let formatedInfoShop = []
    let fomatedPrice = []
    let formatedInfoVente = []
    let formatedTotaux = []
    let formatedTaxes = []
    let formatedReglement = []

    let messageBye = [currentShop.name,t('messageEndReceipt')]

    //section calcule des totaux
    let totalAchat = 0
    let totaltgc = {
        0:0,
        3:0,
        6:0,
        11:0,
        22:0
    }
    currentTicket.ticket_detail.map(item=>{
        totalAchat+=item.prix_remise
        if(item.article_retour!=null){
            totaltgc={...totaltgc,[item.article_retour.tgc]:totaltgc[item.article_retour.tgc]+=item.prix_remise}
        }
        if(item.article!=null){
            totaltgc={...totaltgc,[item.article.tgc]:totaltgc[item.article.tgc]+=item.prix_remise}
        }
    })

    let tgc3 = +totaltgc[3] - (Math.round(+totaltgc[3] / 1.03))
    let tgc6 = +totaltgc[6]- (Math.round(+totaltgc[6] / 1.06))
    let tgc11 = +totaltgc[11] - (Math.round(+totaltgc[11] / 1.11))
    let tgc22 = +totaltgc[22] - (Math.round(+totaltgc[22] / 1.22))
    let tgcTotal = tgc3 + tgc6 + tgc11 + tgc22
    //end section calcule des totaux

    paramShop.forEach((value)=>{//Formatage des infos du magasin
        if(currentShop[value] && currentShop[value]!=null){
            switch (value) {
                case 'tel':
                    formatedInfoShop.push(t('acroTel')+': '+currentShop[value])
                break;
                case 'horaires':
                    formatedInfoShop.push(t('labelHoraires')+': '+currentShop[value])
                break;
                case 'ridet':
                    formatedInfoShop.push('Ridet: '+currentShop[value])
                break;
                default :
                    formatedInfoShop.push(currentShop[value])
            }
            
        }
    })

    paramInfoVente.forEach(value=>{//Formatage des infos de vente
        if(currentTicket[value]){
            switch (value) {
                case 'rh':
                    formatedInfoVente.push(t('cashier')+': '+currentTicket[value].name+' '+currentTicket[value].firstname)
                break;
                case 'client':
                    formatedInfoVente.push(t('client')+': '+currentTicket[value].name+' '+currentTicket[value].firstname)
                break;
                case 'created_at':
                    formatedInfoVente.push(ticketDate.toLocaleString())
                break;
                default :
                    formatedInfoVente.push(currentTicket[value])
            }
        }
    })

    currentTicket.ticket_detail.forEach((value)=>{//Formatage du nom de l'article 
        let arr = []
        let n = 0
        while(n<paramArticle.length){
            if(n<paramArticle.length){
                if(value.special=='gift_card'){
                    if(paramArticle[n] == 'name'){
                        arr.push(t('giftcard')+' '+value.details.reference)
                    }else{
                        arr.push(value.details[paramArticle[n]])
                    }
                    
                }else if(value.special=='retour'){
                    arr.push(value.article_retour[paramArticle[n]])
                }else{
                    arr.push(value.article[paramArticle[n]])
                }
                n++
            }
            if(n==paramArticle.length){
                let newArr = arr.join("/@/")
                formatedParamArticle.push(newArr)
            }
        }
    })
    
    currentTicket.ticket_detail.forEach((value)=>{//Formatage des infos prix des articles
        let arr = []
        if(printMode!='giftprint'){
            if(value.special=='retour'){
                arr.push('')
                arr.push(t('itemReturn'))
                
            }else if(value.special=='gift_card'){
                arr.push('')
                arr.push(espace_mille(value.prix_remise)+devise)

            }else{
                arr.push(value.article.tgc+'%')
                arr.push(espace_mille(value.article.price)+devise)
                
            }

            arr.push('x'+value.quantity)
            if(+value.remise>0){
                arr.push('-'+value.remise)
            }else{
                arr.push(value.remise)
            }
            arr.push(espace_mille(value.prix_remise)+devise)
        }else{
            arr.push('')
            arr.push('')
            arr.push('x'+value.quantity)
            arr.push('')
            arr.push(espace_mille(''))
        }

        fomatedPrice.push(arr.join('/@/'))
    })
    
    paramTaxes.forEach(value=>{//Formatage des totaux
        let arr = []
        switch (value) {
            case 'ttc':
                arr.push('Total '+value.toUpperCase())
                arr.push(espace_mille(totalAchat)+devise)
            break;
            case 'ht':
                arr.push('Total '+value.toUpperCase())
                if(tgcTotal>0){
                    arr.push(espace_mille(+totalAchat - +tgcTotal)+devise)
                }else{
                    arr.push(espace_mille(+totalAchat)+devise)
                }
            break;
        }

        formatedTotaux.push(arr.join('/@/'))
    })

    Object.entries(totaltgc).forEach(([taxe,value])=>{//Formatage des taxes
        let arr = []
        if(value > 0){
            arr.push('Total '+taxe+'%')
            arr.push(espace_mille(value - (Math.round(value / (taxe/100+1))))+devise)
        }
        
        if(arr.length!=0) formatedTaxes.push(arr.join('/@/'))
    })

    formatedTaxes.push('Total TGC/@/'+espace_mille(tgcTotal)+devise)

    if(formatedTaxes.length==0){
        formatedTaxes.push('/@/')
    }

    if(currentTicket.paiement_records.length!=0){
        currentTicket.paiement_records.forEach((value)=>{//Formatage des règlements
            let arr = []

            if(modePaiementMustBeTranslate.includes(value.mode)){
                arr.push(t(value.mode))
            }else{//replace est utilisé ici pour supprimer les "" potentiels sur le nom du mode de paiement
                arr.push(value.mode.replace(/["]/g,'' ))
            }

            arr.push(espace_mille(value.montant)+devise)
            
            formatedReglement.push(arr.join('/@/'))
        })
    }else{
        formatedReglement.push('/@/')
    }

    let article = '"'+formatedParamArticle.join('/@!')+'"'
    let infoShop = '"'+formatedInfoShop.join('/@!')+'"'
    let price = '"'+fomatedPrice.join('/@!')+'"'   
    let infoVente = '"'+formatedInfoVente.join('/@!')+'"'
    let totaux = '"'+formatedTotaux.join('/@!')+'"'  
    let taxes = '"'+formatedTaxes.join('/@!')+'"'
    let reglement = '"'+formatedReglement.join('/@!')+'"' 
    let barCode = currentTicket.bar
    let msgBye = '"'+messageBye.join('/@!')+'"'
    let codeShop = '"'+currentShop.code+'"'
    let host = '"'+peripheriqueParam.imprimante_ticket.host+'"'
    let conditionsRetourMsg = '"'+conditionsRetour+'"'

    if(peripheriqueParam && peripheriqueParam.imprimante_ticket && peripheriqueParam.imprimante_ticket.model=='epson_tm_h6000V'){
        const response  = await fetch('http://localhost/portcom/print.php',{
            method: 'POST',
            body: JSON.stringify({item:article, price:price, infoShop:infoShop, infoVente:infoVente, totaux:totaux, taxes:taxes, reglement:reglement, barCode:barCode, conditionsRetour:conditionsRetourMsg,msgBye:msgBye,codeShop,codeShop,printMode:printMode, host:host}) 
        }).then((response) => {
        }).catch((error) => {
        })
    }else{
        if(from=='historiqueVente'){
            swal.fire(t('noConfiguredPeripheral'), "", "error");
        }
    }
}

export const epson_tm_h6000VOrder = async (printMode,posid,currentTicket,t,devise,peripheriqueParam,from) =>{
    let paramArticle = ['name']
    let paramShop = ['name','commerce_zone','adresse','code_postal','tel','horaires','site','code','ridet']
    let paramInfoVente = ['created_at','bar','rh','client','section_collect','prevu']
    let paramTaxes = ['ttc']

    let currentShop = bbgShops.find(value=>value.id==posid)
    let ticketDate = new Date(currentTicket.created_at)
    let deliveryDate = new Date(currentTicket.prevu)

    let formatedParamArticle = []
    let formatedInfoShop = []
    let fomatedPrice = []
    let formatedInfoVente = []
    let formatedTotaux = []
    let formatedTaxes = []
    let formatedReglement = []

    let messageBye = [currentShop.name,t('messageEndReceipt')]

    //section calcule des totaux
    let totalAchat = 0
    let totaltgc = {
        0:0,
        3:0,
        6:0,
        11:0,
        22:0
    }
    currentTicket.ticket.ticket_detail.map(item=>{
        totalAchat+=item.prix_remise
        if(item.article!=null){
            totaltgc={...totaltgc,[item.article.tgc]:totaltgc[item.article.tgc]+=item.prix_remise}
        }
    })

    let tgc3 = +totaltgc[3] - (Math.round(+totaltgc[3] / 1.03))
    let tgc6 = +totaltgc[6]- (Math.round(+totaltgc[6] / 1.06))
    let tgc11 = +totaltgc[11] - (Math.round(+totaltgc[11] / 1.11))
    let tgc22 = +totaltgc[22] - (Math.round(+totaltgc[22] / 1.22))
    let tgcTotal = tgc3 + tgc6 + tgc11 + tgc22
    //end section calcule des totaux

    paramShop.forEach((value)=>{//Formatage des infos du magasin
        if(currentShop[value] && currentShop[value]!=null){
            switch (value) {
                case 'tel':
                    formatedInfoShop.push(t('acroTel')+': '+currentShop[value])
                break;
                case 'horaires':
                    formatedInfoShop.push(t('labelHoraires')+': '+currentShop[value])
                break;
                case 'ridet':
                    formatedInfoShop.push('Ridet: '+currentShop[value])
                break;
                default :
                    formatedInfoShop.push(currentShop[value])
            }
            
        }
    })

    paramInfoVente.forEach(value=>{//Formatage des infos de vente
        if(currentTicket[value]){
            switch (value) {
                case 'rh':
                    formatedInfoVente.push(t('cashier')+': '+currentTicket.ticket[value].name+' '+currentTicket.ticket[value].firstname)
                break;
                case 'client':
                    formatedInfoVente.push(t('client')+': '+currentTicket.ticket[value].name+' '+currentTicket.ticket[value].firstname)
                break;
                case 'created_at':
                    formatedInfoVente.push(ticketDate.toLocaleString())
                break;
                case 'prevu':
                    formatedInfoVente.push(t('estimatedDelivery')+': '+deliveryDate.toLocaleString())
                break;
                case 'section_collect':
                    formatedInfoVente.push(t('collectZone')+': '+currentTicket[value].name)
                break;
                default :
                    formatedInfoVente.push(currentTicket[value])
            }
        }
    })

    currentTicket.ticket.ticket_detail.forEach((value)=>{//Formatage du nom de l'article 
        let arr = []
        let n = 0
        while(n<paramArticle.length){
            if(n<paramArticle.length){
                if(value.block_quantity>0){
                    arr.push(value.article[paramArticle[n]])
                    
                    n++
                }
            }
            if(n==paramArticle.length){
                let newArr = arr.join("/@/")
                formatedParamArticle.push(newArr)
            }
        }
    })
    
    currentTicket.ticket.ticket_detail.forEach((value)=>{//Formatage des infos prix des articles
        let arr = []
        if(value.block_quantity>0){
            arr.push(value.article.tgc+'%')
            arr.push(espace_mille(value.article.price)+devise)

            arr.push('x'+value.quantity)
            if(+value.remise>0){
                arr.push('-'+value.remise)
            }else{
                arr.push(value.remise)
            }
            arr.push(espace_mille(value.prix_remise)+devise)
        }

        fomatedPrice.push(arr.join('/@/'))
    })
    
    paramTaxes.forEach(value=>{//Formatage des totaux
        let arr = []
        switch (value) {
            case 'ttc':
                arr.push('Total '+value.toUpperCase())
                arr.push(espace_mille(totalAchat)+devise)
            break;
            case 'ht':
                arr.push('Total '+value.toUpperCase())
                if(tgcTotal>0){
                    arr.push(espace_mille(+totalAchat - +tgcTotal)+devise)
                }else{
                    arr.push(espace_mille(+totalAchat)+devise)
                }
            break;
        }

        formatedTotaux.push(arr.join('/@/'))
    })

    Object.entries(totaltgc).forEach(([taxe,value])=>{//Formatage des taxes
        let arr = []
        if(value > 0){
            arr.push('Total '+taxe+'%')
            arr.push(espace_mille(value - (Math.round(value / (taxe/100+1))))+devise)
        }
        
        if(arr.length!=0) formatedTaxes.push(arr.join('/@/'))
    })

    formatedTaxes.push('Total TGC/@/'+espace_mille(tgcTotal)+devise)

    if(formatedTaxes.length==0){
        formatedTaxes.push('/@/')
    }

    if(currentTicket.ticket.paiement_records.length!=0){
        currentTicket.ticket.paiement_records.forEach((value)=>{//Formatage des règlements
            let arr = []

            if(modePaiementMustBeTranslate.includes(value.mode)){
                // if(value.mode=='espece'){
                //     arr.push(t('especeSA'))
                // }else{
                //     arr.push(t(value.mode))
                // }
                arr.push(t(value.mode))
            }else{//replace est utilisé ici pour supprimer les "" potentiels sur le nom du mode de paiement
                arr.push(value.mode.replace(/["]/g,'' ))
            }

            arr.push(espace_mille(value.montant)+devise)
            
            formatedReglement.push(arr.join('/@/'))
        })
    }else{
        formatedReglement.push('/@/'+0+devise)
    }

    let article = '"'+formatedParamArticle.join('/@!')+'"'
    let infoShop = '"'+formatedInfoShop.join('/@!')+'"'
    let price = '"'+fomatedPrice.join('/@!')+'"'   
    let infoVente = '"'+formatedInfoVente.join('/@!')+'"'
    let totaux = '"'+formatedTotaux.join('/@!')+'"'  
    let taxes = '"'+formatedTaxes.join('/@!')+'"'
    let reglement = '"'+formatedReglement.join('/@!')+'"' 
    let barCode = currentTicket.bar
    let msgBye = '"'+messageBye.join('/@!')+'"'
    let codeShop = '"'+currentShop.code+'"'
    let host = '"'+peripheriqueParam.imprimante_ticket.host+'"'
    let conditionsRetourMsg = '"'+conditionsRetour+'"'
    let verse = (currentTicket.ticket.paiement_records.length!=0) ? 'yes' : 'no'

    if(peripheriqueParam && peripheriqueParam.imprimante_ticket && peripheriqueParam.imprimante_ticket.model=='epson_tm_h6000V'){
        const response  = await fetch('http://localhost/portcom/printOrder.php',{
            method: 'POST',
            body: JSON.stringify({item:article, price:price, infoShop:infoShop, infoVente:infoVente, totaux:totaux, taxes:taxes, reglement:reglement, barCode:barCode, conditionsRetour:conditionsRetourMsg,msgBye:msgBye,codeShop,codeShop,printMode:printMode, host:host, verse:verse}) 
        }).then((response) => {
        }).catch((error) => {
        })
    }else{
        if(from=='historiqueVente'){
            swal.fire(t('noConfiguredPeripheral'), "", "error");
        }
    }
}