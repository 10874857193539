import React, {useState,useRef,useEffect} from "react";
import { useTranslate } from 'react-redux-multilingual'
import { useForm } from "react-hook-form";
import axios from 'axios'

const ControlAccess = (props) => {
    const t =useTranslate()
    const { register, formState: { errors }, handleSubmit, reset } = useForm();
    const [apiError,setApiError] = useState(false)
    const [loadingBar,setLoadingBar] = useState(false)

    useEffect(()=>{
        setTimeout(()=>{
            const input = document.querySelector('#controlAccess');
            if(input) input.focus()
        },500)
    },[])

    const onSubmit = async (data) => {
        setLoadingBar(true)
        setApiError(false)
        const response  = await axios.get(process.env.REACT_APP_API_URL+"api/rh/bar/"+props.moduleId+'/'+data.bar, 
        ).then((response) => {
            if(!response.data.valid){
                setApiError('noRhOrNoAuth')
                reset()
            }
            else{
                if(props.restriction.grades.includes(response.data.data.grade_id)){
                    props.setRestriction({...props.restriction,go:true})
                    if(props.callback) props.callback((props) && props);
                }
                else{
                    setApiError('noRhOrNoAuth')
                }
            }
        }).catch((error) => {
            reset()
            if(error.response==undefined){
                setApiError('unknowError')
            }
            else if(error.response.status==403){
                setApiError('forbiddenGeneral')
            }
            else {
                setApiError('unknowError')
            }
        })
        setLoadingBar(false)
    }

    return(
        <div className="row" style={{marginTop: '-60px'}}>
            <div className="col-10 offset-1">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input  id='controlAccess' className="form-control" type="password" placeholder={t('scanBadge')+'...'} {...register('bar',{required: true})}/>
                    {(apiError) &&
                        <p className="text-danger text-center mt-3"><strong>{t(apiError)}</strong></p>
                    }
                </form>
            </div>
            <div className="col-12 text-center">
                {(loadingBar || props.callbackLoading) &&
                    <span><span>&nbsp;</span><div className="spinner-border spinner-border-sm mt-2"></div></span>
                }
            </div>
        </div>
    )
}

export default ControlAccess